import React, { useEffect, useState, useContext } from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemSecondaryAction from "@mui/material/ListItemSecondaryAction";
import Divider from "@mui/material/Divider";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import authService from "../api-authorization/AuthorizeService";
import TablePagination from "@mui/material/TablePagination";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import AddIcon from "@mui/icons-material/Add";
import Tooltip from "@mui/material/Tooltip";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ClearIcon from "@mui/icons-material/Clear";
import Typography from "@mui/material/Typography";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Autocomplete from "@mui/material/Autocomplete";
import UserContext from "../UserContext";
import { priceStringToNumberString } from "../Shared/Utilities";

export const TreatmentTypesManager = (props) => {
  const userCtx = useContext(UserContext);
  const pageSize = 10;
  const [page, setPage] = useState(0);
  const [treatmentTypes, setTreatmentTypes] = useState([]);
  const [selected, setSelected] = useState(null);
  const [rowsPerPage, setRowsPerPage] = useState(pageSize);
  const [tools, setTools] = useState([]);
  const [drugTypes, setDrugTypes] = useState([]);
  const [addDrugDialogOpen, setAddDrugDialogOpen] = useState(false);
  const [addToolDialogOpen, setAddToolDialogOpen] = useState(false);
  const [drugToAdd, setDrugToAdd] = useState(null);
  const [toolToAdd, setToolToAdd] = useState(null);
  const [newTreatmentType, setNewTreatmentType] = useState({
    title: "",
    description: "",
    price: 0,
    drugs: [],
    tools: [],
    status: 1,
  });

  const columns = [
    { id: "title", label: "Nome", numeric: false },
    { id: "description", label: "Descrizione", numeric: false },
    { id: "price", label: "Prezzo (€)", numeric: false },
    { id: "drugs", label: "Farmaci / Medical Devices", numeric: false },
    { id: "tools", label: "Strumenti", numeric: false },
    { id: "actions", label: "Azioni", numeric: false },
  ];

  //cleanup state on unmount
  useEffect(() => {
    return () => {
      setTreatmentTypes([]);
      setPage(1);
    };
  }, []);

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeNewTreatmentType = (event) => {
    const { id, value } = event.target;
    setNewTreatmentType({ ...newTreatmentType, [id]: value });
  };

  const GetTreatmentTypes = async () => {
    const token = await authService.getAccessTokenIfValid();
    const response = await fetch("/api/treatment/type", {
      headers: !token ? {} : { Authorization: `Bearer ${token}` },
    });
    const data = await response.json();
    if (!response.ok) {
      // get error message from body or default to response statusText
      const error = (data && data.message) || response.statusText;
      return Promise.reject(error);
    }
    if (data) {
      setTreatmentTypes(data);
    }
  };

  useEffect(() => {
    GetTreatmentTypes();
  }, []);

  const handleChange = (changedTreatment, event) => {
    const { id, value } = event.target;

    if (id === "price") {
      changedTreatment[id] = priceStringToNumberString(value);
      if (changedTreatment[id] < 0) changedTreatment[id] = 0;
    }
    setTreatmentTypes(
      treatmentTypes.map((currentType) =>
        currentType.idTreatmentType === changedTreatment.idTreatmentType
          ? { ...currentType, [id]: value }
          : currentType
      )
    );
  };

  const handleDelete = async (treatmentToBeDeleted, event) => {
    if (treatmentToBeDeleted && treatmentToBeDeleted.idTreatmentType) {
      let token = await authService.getAccessTokenIfValid();
      fetch("/api/treatment/type/delete", {
        method: "POST",
        headers: !token
          ? { "Content-Type": "application/json" }
          : {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
              Accept: "application/json",
            },
        body: JSON.stringify(treatmentToBeDeleted),
      })
        .then((response) => {
          if (!response.ok) {
            // get error message from body or default to response statusText
            const error = response.statusText;
            props.feedbackCallback(
              "error",
              "Impossibile eliminare la tipologia di intervento. Controlla che non sia già stata utilizzata in una visita.",
              true
            );
            return Promise.reject(error);
          }
          GetTreatmentTypes();
          props.feedbackCallback(
            "success",
            "Tipologia di intervento eliminata",
            true
          );
        })
        .catch((error) => {
          console.error("There was an error!", error);
        });
    }
  };

  const handleAddTreatmentType = (treatmentName) => {
    const newTreatmentType = {
      title: treatmentName,
      description: "",
      price: 0,
      drugs: [],
      tools: [],
      status: 1,
    };
    let newTreatmentTypes = [...treatmentTypes];
    newTreatmentTypes.unshift(newTreatmentType);
    setTreatmentTypes(newTreatmentTypes);
    setSelected(undefined); //set selected to undefined to selecte new treatment type
  };

  const handleBlurNewTreatmentType = async (event) => {
    if (
      newTreatmentType.title === "" ||
      newTreatmentType.title === undefined ||
      newTreatmentType.title === null
    ) {
      return;
    } else {
      handleBlur(newTreatmentType, event);
      setNewTreatmentType({
        title: "",
        description: "",
        price: 0,
        drugs: [],
        tools: [],
        status: 1,
      });
    }
  };

  const handleBlur = async (treatmentType, event) => {
    let token = await authService.getAccessTokenIfValid();
    let path = "/api/treatment/type/update";
    if (
      treatmentType.idTreatmentType === undefined ||
      treatmentType.idTreatmentType === ""
    ) {
      path = "/api/treatment/type";
    }
    const requestOptions = {
      method: "POST",
      headers: !token
        ? { "Content-Type": "application/json" }
        : {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            Accept: "application/json",
          },
      body: JSON.stringify(treatmentType),
    };
    fetch(path, requestOptions).then(async (response) => {
      const data = await response.json();
      // check for error response
      if (!response.ok) {
        // get error message from body or default to response statusText
        const error = (data && data.message) || response.statusText;
        props.feedbackCallback("error", "Errore nel salvataggio", true);
        return Promise.reject(error);
      } else {
        if (data) {
          {
            let index = treatmentTypes.findIndex(
              (x) => x.idTreatmentType === treatmentType.idTreatmentType
            );
            if (index === -1) {
              //this is a new treatment type so refresh the list and select it
              setSelected(data.idTreatmentType);
              GetTreatmentTypes();
            } else {
              treatmentTypes[index] = data;
              setTreatmentTypes([...treatmentTypes]);
            }
          }
          props.feedbackCallback("success", "Dati salvati con successo", true);
        }
      }
    });
  };

  useEffect(() => {
    //set the page to where the selected treatment type is
    if (selected) {
      let index = treatmentTypes.findIndex(
        (x) => x.idTreatmentType === selected
      );
      if (index > -1) {
        let page = Math.floor(index / 10);
        setPage(page);
      }
    }
  }, [selected, treatmentTypes]);

  const handleDeleteDrugType = async (treatmentType, DrugType) => {
    //remove drug type from treatment
    treatmentType.drugs = treatmentType.drugs.filter(
      (drug) => drug.idDrugType !== DrugType.idDrugType
    );
    //define callback to update treatment type
    const resultCallback = (result) => {
      if (result) {
        props.feedbackCallback("success", "Farmaco eliminato", true);
      } else {
        props.feedbackCallback(
          "error",
          "Errore nell'eliminazione del farmaco",
          true
        );
      }
    };
    updateTreatmentType(treatmentType, resultCallback);
  };

  const handleDeleteTool = async (treatmentType, Tool) => {
    //remove tool from treatment
    treatmentType.tools = treatmentType.tools.filter(
      (tool) => tool.idTool !== Tool.idTool
    );
    //define callback to update treatment type
    const resultCallback = (result) => {
      if (result) {
        props.feedbackCallback("success", "Strumento eliminato", true);
      } else {
        props.feedbackCallback(
          "error",
          "Errore nell'eliminazione dello strumento",
          true
        );
      }
    };
    updateTreatmentType(treatmentType, resultCallback);
  };

  const updateTreatmentType = async (treatmentType, feedbackCallback) => {
    let token = await authService.getAccessTokenIfValid();
    fetch("/api/treatment/type/update", {
      method: "POST",
      headers: !token
        ? { "Content-Type": "application/json" }
        : {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            Accept: "application/json",
          },
      body: JSON.stringify(treatmentType),
    })
      .then((response) => {
        if (!response.ok) {
          // get error message from body or default to response statusText
          const error = response.statusText;
          feedbackCallback(false);
          return Promise.reject(error);
        }
        GetTreatmentTypes();
        feedbackCallback(true);
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  };

  const handleAddDrugDialogConfirm = async (treatmentType, drugType) => {
    //add drug type to treatment
    treatmentType.drugs = [...treatmentType.drugs, drugType];
    //define callback to update treatment type
    const resultCallback = (result) => {
      if (result) {
        props.feedbackCallback("success", "Farmaco aggiunto", true);
      } else {
        props.feedbackCallback(
          "error",
          "Errore nell'aggiunta del farmaco",
          true
        );
      }
    };
    await updateTreatmentType(treatmentType, resultCallback);
    setAddDrugDialogOpen(false);
    setDrugToAdd(null);
  };

  const handleAddToolDialogConfirm = async (treatmentType, tool) => {
    //add drug type to treatment
    treatmentType.tools = [...treatmentType.tools, tool];
    //define callback to update treatment type
    const resultCallback = (result) => {
      if (result) {
        props.feedbackCallback("success", "Strumento aggiunto", true);
      } else {
        props.feedbackCallback(
          "error",
          "Errore nell'aggiunta dello strumento",
          true
        );
      }
    };
    await updateTreatmentType(treatmentType, resultCallback);
    setAddToolDialogOpen(false);
    setToolToAdd(null);
  };

  const renderDrugsDialog = (treatment) => {
    return (
      <Dialog open={addDrugDialogOpen}>
        <DialogTitle>Aggiungi farmaco</DialogTitle>
        <DialogContent>
          <Autocomplete
            id="add-drug"
            data-testid="drug-selector"
            options={drugTypes}
            getOptionLabel={(option) => option.name}
            onChange={(event, newValue) => {
              setDrugToAdd(newValue);
            }}
            renderInput={(params) => (
              <TextField {...params} label="Farmaco" variant="outlined" />
            )}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setAddDrugDialogOpen(false);
              setDrugToAdd(null);
            }}
          >
            Annulla
          </Button>
          <Button
            onClick={() => handleAddDrugDialogConfirm(treatment, drugToAdd)}
          >
            Conferma
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  const renderToolsDialog = (treatment) => {
    return (
      <Dialog open={addToolDialogOpen}>
        <DialogTitle>Aggiungi strumento</DialogTitle>
        <DialogContent>
          <Autocomplete
            id="add-tool"
            data-testid="tool-selector"
            options={tools}
            getOptionLabel={(option) => option.name}
            onChange={(event, newValue) => {
              setToolToAdd(newValue);
            }}
            renderInput={(params) => (
              <TextField {...params} label="Strumento" variant="outlined" />
            )}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setAddToolDialogOpen(false);
              setToolToAdd(null);
            }}
          >
            Annulla
          </Button>
          <Button
            onClick={() => handleAddToolDialogConfirm(treatment, toolToAdd)}
          >
            Conferma
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  const renderDrugs = (treatment) => {
    return (
      <>
        <List dense>
          {treatment.drugs &&
            treatment.drugs.map((drug) => (
              <div key={drug.idDrugType}>
                <ListItem key={drug.idDrugType} dense alignItems="flex-start">
                  <ListItemText
                    primary={drug.name}
                    secondary={drug.price + " €"}
                  />
                  <ListItemSecondaryAction>
                    <IconButton
                      onClick={() => handleDeleteDrugType(treatment, drug)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
                <Divider />
              </div>
            ))}
        </List>
        <div style={{ alignItems: "center", display: "grid"}}>
          <IconButton onClick={() => setAddDrugDialogOpen(true)} color="inherit">
            <AddIcon />
          </IconButton>
        </div>
      </>
    );
  };

  const renderTools = (treatment) => {
    return (
      <>
        <List dense>
          {treatment.tools &&
            treatment.tools.map((tool) => (
              <div key={tool.idTool}>
                <ListItem key={tool.idTool} dense alignItems="flex-start">
                  <ListItemText primary={tool.name} />
                  <ListItemSecondaryAction>
                    <IconButton
                      onClick={() => handleDeleteTool(treatment, tool)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
                <Divider />
              </div>
            ))}
        </List>
        <div style={{ alignItems: "center", display: "grid" }}>
          <IconButton onClick={() => setAddToolDialogOpen(true)} color="inherit">
            <AddIcon />
          </IconButton>
        </div>
      </>
    );
  };

  const GetDrugTypes = async () => {
    const token = await authService.getAccessTokenIfValid();
    const response = await fetch("/api/drug/type", {
      headers: !token ? {} : { Authorization: `Bearer ${token}` },
    });
    const data = await response.json();
    if (!response.ok) {
      // get error message from body or default to response statusText
      const error = (data && data.message) || response.statusText;
      return Promise.reject(error);
    }
    if (data) {
      setDrugTypes(data);
    }
  };

  const GetTools = async () => {
    const token = await authService.getAccessTokenIfValid();
    const response = await fetch(
      "/api/tool/byDoctor/" + userCtx.doctor.idDoctor,
      {
        headers: !token ? {} : { Authorization: `Bearer ${token}` },
      }
    );
    const data = await response.json();
    if (!response.ok) {
      // get error message from body or default to response statusText
      const error = (data && data.message) || response.statusText;
      return Promise.reject(error);
    }
    if (data) {
      setTools(data);
    }
  };

  const handleShowDrugs = (treatment, event) => {
    event.preventDefault();

    GetDrugTypes();
  };

  const handleShowTools = (treatment, event) => {
    event.preventDefault();

    GetTools();
  };

  const renderTable = () => {
    let treatmentTypesToShow = [];
    if (treatmentTypes.length > rowsPerPage) {
      //select subscriptions to show
      const firstPageIndex = page * rowsPerPage;
      const lastPageIndex = firstPageIndex + rowsPerPage;
      treatmentTypesToShow = treatmentTypes.slice(
        firstPageIndex,
        lastPageIndex
      );
    } else {
      treatmentTypesToShow = [...treatmentTypes];
    }
    return (
      <TableContainer>
        <Table sx={{ minWidth: 200 }} size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              {columns.map((headCell) => (
                <TableCell
                  key={headCell.id}
                  align="center"
                  padding={headCell.disablePadding ? "none" : "normal"}
                >
                  {headCell.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>

          <TableBody>
            {treatmentTypesToShow.map((row) =>
              selected !== row.idTreatmentType ? (
                <TableRow
                  key={row.idTreatmentType}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  onClick={() => {
                    GetDrugTypes();
                    GetTools();
                    setSelected(row.idTreatmentType);
                  }}
                >
                  <TableCell align="left">{row.title}</TableCell>
                  <TableCell align="center">{row.description}</TableCell>
                  <TableCell align="center">{row.price}</TableCell>
                  <TableCell align="center">
                    <IconButton onClick={(e) => handleShowDrugs(row, e)}>
                      <VisibilityIcon />
                    </IconButton>
                  </TableCell>
                  <TableCell align="center">
                    <IconButton onClick={(e) => handleShowTools(row, e)}>
                      <VisibilityIcon />
                    </IconButton>
                  </TableCell>
                  <TableCell align="center">
                    <Tooltip title="Modifica">
                      <IconButton>
                        <EditIcon />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Elimina">
                      <IconButton
                        onClick={(e) => handleDelete(row, e)}
                        data-testid={row.idTool + "-deleteButton"}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              ) : (
                <TableRow
                  key={row.idTreatmentType}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell align="left">
                    <TextField
                      data-testid={
                        row.idTreatmentType
                          ? row.idTreatmentType + "-titleInput"
                          : "newTitleInput"
                      }
                      variant="filled"
                      hiddenLabel
                      fullWidth
                      margin="dense"
                      InputProps={{
                        disableUnderline: true,
                        style: { fontSize: 13 },
                      }}
                      id="title"
                      value={row.title}
                      onChange={(e) => handleChange(row, e)}
                      onBlur={(e) => handleBlur(row, e)}
                      size="small"
                    />
                  </TableCell>
                  <TableCell align="left">
                    <TextField
                      data-testid={
                        row.idTreatmentType
                          ? row.idTreatmentType + "-descriptionInput"
                          : "newDescriptionInput"
                      }
                      variant="filled"
                      hiddenLabel
                      fullWidth
                      margin="dense"
                      InputProps={{
                        disableUnderline: true,
                        style: { fontSize: 13 },
                      }}
                      id="description"
                      value={row.description}
                      onChange={(e) => handleChange(row, e)}
                      onBlur={(e) => handleBlur(row, e)}
                      size="small"
                    />
                  </TableCell>
                  <TableCell align="left">
                    <TextField
                      data-testid={
                        row.idTreatmentType
                          ? row.idTreatmentType + "-priceInput"
                          : "newPriceInput"
                      }
                      variant="filled"
                      hiddenLabel
                      fullWidth
                      margin="dense"
                      InputProps={{
                        disableUnderline: true,
                        style: { fontSize: 13 },
                      }}
                      id="price"
                      value={row.price}
                      onChange={(e) => handleChange(row, e)}
                      onBlur={(e) => handleBlur(row, e)}
                      size="small"
                    />
                  </TableCell>
                  <TableCell align="center" valign="bottom" sx={{verticalAlign: "bottom"}}>{renderDrugs(row)}</TableCell>
                  <TableCell align="center" valign="bottom" sx={{verticalAlign: "bottom"}}>{renderTools(row)}</TableCell>
                  <TableCell align="center">
                    <Tooltip title="Esci da modifica">
                      <IconButton
                        onClick={() => setSelected(null)}
                        data-testid={row.idTool + "-clearButton"}
                      >
                        <ClearIcon />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Elimina">
                      <IconButton
                        onClick={(e) => handleDelete(row, e)}
                        data-testid={row.idTool + "-deleteButton"}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                  {renderDrugsDialog(row)}
                  {renderToolsDialog(row)}
                </TableRow>
              )
            )}
          </TableBody>
        </Table>
      </TableContainer>
    );
  };

  return (
    <Card
      sx={{
        boxShadow: 0,
      }}
    >
      <CardContent>
        <Typography
          gutterBottom
          variant="subtitle3"
          component="div"
          align="left"
          sx={{ paddingBottom: "10px" }}
        >
          Tipoligie di intervento
          <Box sx={{ float: "right" }}>
            <TextField
              data-testid="newToolInput"
              variant="filled"
              hiddenLabel
              placeholder="Aggiungi intervento"
              size="small"
              margin="dense"
              InputProps={{
                disableUnderline: true,
                style: { fontSize: 11, paddingBottom: "0px" },
              }}
              id="title"
              value={newTreatmentType.title}
              onChange={handleChangeNewTreatmentType}
            />
            <IconButton onClick={handleBlurNewTreatmentType} color="inherit">
              <AddIcon />
            </IconButton>
          </Box>
        </Typography>
        {renderTable()}
        <TablePagination
          rowsPerPageOptions={[5, 10, 25, 50]}
          count={treatmentTypes.length}
          onChange={handlePageChange}
          component="div"
          labelRowsPerPage="Righe per pagina"
          page={page}
          onPageChange={handlePageChange}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </CardContent>
    </Card>
  );
};

export default TreatmentTypesManager;
