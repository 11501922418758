import React, { useEffect, useState, useRef, useContext } from "react";
import Card from "react-bootstrap/Card";
import Invoice from "../../Shared/Invoice/Invoice";
import InvoicesList from "../../Shared/Invoice/InvoicesList";
import * as YpatientsConsts from "../../Shared/Constants";
import { PatientContext } from "../PatientContext";
import { UserContext } from "../../UserContext";

export const InvoicesCard = (props) => {
    const userCtx = useContext(UserContext);

    const [idPatientInvoice, setIdPatientInvoice] = useState(YpatientsConsts.emptyGuid);
    const [pageIndex, setPageIndex] = useState(0);
    const [patient, setPatient] = useContext(PatientContext);

    const OpenInvoice = (idPatientInvoice, pageIndex) => {
        setIdPatientInvoice(idPatientInvoice);
        setPageIndex(pageIndex);
    };

    const GoToList = (pageIndex) => {
        setIdPatientInvoice(YpatientsConsts.emptyGuid);
        setPageIndex(pageIndex);
    };

    return (
        <>
            {(idPatientInvoice !== YpatientsConsts.emptyGuid) &&
                <Invoice
                    idPatientInvoice={idPatientInvoice}
                    feedbackCallback={props.feedbackCallback}
                    goToListCallback={GoToList}
                    pageIndex={pageIndex}
                    doctor={userCtx.doctor}
                />
            }

            {(idPatientInvoice === YpatientsConsts.emptyGuid) &&
                <InvoicesList
                    idCompany={props.idCompany}
                    idVisit={props.idVisit}
                    doctor={userCtx.doctor}
                    feedbackCallback={props.feedbackCallback}
                    openInvoiceCallback={OpenInvoice}
                    pageIndex={pageIndex}
                    patient={patient}
                />
            }
        </>
    );
};

export default InvoicesCard;
