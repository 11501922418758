import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";
import { GetDateItalianFormat, GetDateTimeValueForFile, GetAmountEuroFormat2 } from "../Shared/Utilities.js";
import { sendEmail } from "../Shared/emailService";
import * as YpatientsConsts from "../Shared/Constants";

const pdf_settings = { orientation: 'p', unit: 'px', format: 'a4', x: 20, y: 20, width: 380, scale: 0.7 };
const margins = {
    top: 40,
    right: 50,
    bottom: 50,
    left: 50
};


const getTemplateHtml = () => {

    let template_file;

    if ( process.env.NODE_ENV === 'test') {
        template_file = require("./quotation_template.html");
        return template_file;
    }
    else if (!process.env.NODE_ENV || process.env.NODE_ENV === 'test') {
        template_file = require("./quotation_template.html");
    }
    else {
        //disabling eslint
        // eslint-disable-next-line import/no-webpack-loader-syntax
        template_file = require("raw-loader!./quotation_template.html");
    }

    return template_file.default;
}
const buildPdf = (html, filename) => {


    let pdf = new jsPDF(pdf_settings.orientation, pdf_settings.unit, pdf_settings.format);

    window.html2canvas = html2canvas

   console.log("AFSDDFSAFAD "+ pdf);

    pdf.html(
        html, {
        autoPaging: "text",
        callback: function (doc) {
            addFooters(doc);
            doc.save(filename);
        },
        x: pdf_settings.x,
        y: pdf_settings.y,
        width: pdf_settings.width,
        html2canvas: { scale: pdf_settings.scale }
    });
}
const buildPdfAndSend = async (shtml, fileName, email, fcallback) => {

    var pdf = new jsPDF(pdf_settings.orientation, pdf_settings.unit, pdf_settings.format);

    window.html2canvas = html2canvas;

    
    pdf.html(
        shtml, {
        callback: async function (doc) {
            var attachpdf = doc.output('datauristring');

            attachpdf = attachpdf.replace('generated.pdf', fileName);

            const attachments = [attachpdf];
            const emailResult = await sendEmail(
                "noreply@mosaycon.com",
                email,
                "Preventivo " + fileName,
                "In allegato il preventivo. Grazie. Si prega di non rispondere a questa email.",
                false,
                attachments
            );
            fcallback(emailResult);
        },
        x: pdf_settings.x,
        y: pdf_settings.y,
        width: pdf_settings.width,
        html2canvas: { scale: pdf_settings.scale }
    });

}

const addFooters = doc => {
    const pageCount = doc.internal.getNumberOfPages()

    doc.setFont("helvetica", "italic")
    doc.setFontSize(8)
    doc.setDrawColor(150, 150, 150);
    doc.setTextColor(150, 150, 150);
    for (let i = 1; i <= pageCount; i++) {
        doc.setPage(i)
        let line_y = doc.internal.pageSize.height - 15;
        doc.line(0, line_y, doc.internal.pageSize.width, line_y);
        doc.text("Pagina " + String(i) + " di " + String(pageCount), doc.internal.pageSize.width - margins.right, doc.internal.pageSize.height - 5, {
            align: "right"
        })
    }
}

export const createQuotationPdf = (doctor, quotation, calendar, patient) => {
    let _template_html = buildTemplate(doctor, quotation, calendar, patient);
    buildPdf(_template_html, getQuotationFileName());
}

export const sendQuotationPdf = (doctor, quotation, calendar, patient, email, callBackPdf) => {
    let _template_html = buildTemplate(doctor, quotation, calendar, patient);
    let fileName = getQuotationFileName();
    buildPdfAndSend(_template_html, fileName, email, callBackPdf);
}

const buildTemplate = (doctor, quotation, calendar, patient) => {
    let _template_html = getTemplateHtml();

    _template_html = addDoctor(_template_html, doctor);
    _template_html = addHeader(_template_html, patient);
    _template_html = addTreatments(_template_html, quotation.rows, quotation.discount);
    _template_html = addCalendar(_template_html, calendar);
    _template_html = addInfo(_template_html, quotation);
    return _template_html;
}

const addHeader = (template, patient) => {
    if (template && patient) {
        template = template.replaceAll('[Nome paziente]', `${patient.name} ${patient.surname}`)

        let _phone = patient.phone;
        if (_phone == undefined) {
            _phone = '';
        }
        template = template.replaceAll('[Tel paziente]', `tel: ${_phone}`)

        let _email = patient.email;
        if (_email == undefined) {
            _email = '';
        }
        template = template.replaceAll('[Email paziente]', `email: ${_email}`)
    }
    return template;
}

const addDoctor = (template, doctor) => {
    if (template && doctor) {
        template = template.replaceAll('[Titolo]', doctor.title);
        template = template.replaceAll('[Nome medico]', `${doctor.name} ${doctor.surname}`);
        template = template.replaceAll('[Indirizzo medico]', `${doctor.address} ${doctor.number}<br>${doctor.zipCode} ${doctor.city}`);

        var stel = '';
        if (doctor.phone != undefined && doctor.mobile != undefined) {
            stel = `tel: ${doctor.phone}<br>cel: ${doctor.mobile}`;
        }
        else if (doctor.phone != undefined) {
            stel = `tel: ${doctor.phone}`;
        }
        else if (doctor.mobile != undefined) {
            stel = `cel: ${doctor.mobile}`;
        }
        template = template.replaceAll('[Tel medico]', stel);
    }
    return template;
}

const addTreatments = (template, treatments, discount) => {
    if (template && treatments) {
        let indexStartTemplateRow = template.indexOf('<tr id="rowTemplate"');
        let closeTrTemplate = template.indexOf("</tr>", indexStartTemplateRow);
        let rowTemplate = template.substring(indexStartTemplateRow, closeTrTemplate + 5);

        var rowsString = '';
        treatments.forEach(function (row) {
            var rowString = rowTemplate.replaceAll(' id="rowTemplate"', '');
            rowString = rowString.replaceAll('[Descrizione Riga]', row.treatment);
            rowString = rowString.replaceAll('[Prezzo Riga]', GetAmountEuroFormat2(row.price));
            rowString = rowString.replaceAll('[Qt Riga]', `${row.quantity}`);
            rowsString = rowsString + rowString;
        });
        if (discount > 0) {
            var rowString = rowTemplate.replaceAll(' id="rowTemplate"', '');
            rowString = rowString.replaceAll('[Descrizione Riga]', '<em>SCONTO</em>');
            rowString = rowString.replaceAll('[Prezzo Riga]', `<em>- ${GetAmountEuroFormat2(discount)}</em>`);
            rowString = rowString.replaceAll('[Qt Riga]', ' ');
            rowsString = rowsString + rowString;
        }
        template = template.replaceAll('<!-- [ROWS] -->', rowsString);
        template = template.replaceAll(rowTemplate, '');
    }
    return template;
}

const addCalendar = (template, calendars) => {
    if (template && calendars) {
        let indexStartTemplateRow = template.indexOf('<tr id="rowCalendarTemplate"');
        let closeTrTemplate = template.indexOf("</tr>", indexStartTemplateRow);
        let rowTemplate = template.substring(indexStartTemplateRow, closeTrTemplate + 5);

        var rowsString = '';
        calendars.forEach(function (row) {
            var rowString = rowTemplate.replaceAll(' id="rowCalendarTemplate"', '');
            rowString = rowString.replaceAll('[Descrizione Riga]', row.treatment);
            rowString = rowString.replaceAll('[Data Riga]', GetDateItalianFormat(row.appointment.appTime));
          
            rowsString = rowsString + rowString;
        });

        template = template.replaceAll('<!-- [ROWS Calendar] -->', rowsString);
        template = template.replaceAll(rowTemplate, '');
    }
    return template;
}

const addInfo = (template, quotation) => {
    if (template && quotation) {
        template = template.replaceAll('[Totale]', GetAmountEuroFormat2(quotation.total));
        template = template.replaceAll('[Data preventivo]', GetDateItalianFormat(quotation.date));
    }
    return template;
}

function getQuotationFileName() {

    return `preventivo_${GetDateTimeValueForFile(new Date()) }.pdf`;
}


export const getCalendarsOfQuotation = (quotation) => {
    var array = [];
    if(quotation == undefined || quotation.rows == undefined) return array;
    quotation.rows.forEach(function (row) {
        if (row.idQuotationRow != YpatientsConsts.emptyGuid) {
            row.appointments.forEach(function (appointment) {
                if (appointment.idQuotationAppointment != YpatientsConsts.emptyGuid) {
                    array.push({ treatment: row.treatment, appointment: appointment });
                }
            });
        }
    });

    array.sort((a, b) => new Date( a.appointment.appTime).getTime() - new Date(b.appointment.appTime).getTime());

    return array;
}