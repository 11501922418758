import React, { useEffect, useState, useRef, useContext } from "react";
import authService from "../../api-authorization/AuthorizeService";
import Card from "react-bootstrap/Card";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import SaveIcon from "@mui/icons-material/Save";
import SendIcon from "@mui/icons-material/Send";
import DownloadIcon from "@mui/icons-material/Download";
import { Prescription } from "./Prescription";
import { PrescriptionsList } from "./PrescriptionsList";
import { emptyGuid } from "../../Shared/Constants";
import { downloadPrescriptionPdf, emailPrescriptionPdf} from "./PrescriptionUtils";
import { GetDateItalianFormat } from "../../Shared/Utilities.js";
import { VisitContext } from "../VisitContext";
import { UserContext } from "../../UserContext";

export const PrescriptionCard = (props) => {
  const userCtx = useContext(UserContext);
  const [prescription, setPrescription] = useState({
    idPrescription: "",
    idVisit: props.idVisit,
    date: new Date(),
    description: "",
  });
  const [prescriptions, setPrescriptions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [visit, setVisit] = useContext(VisitContext);

  const componentRef = useRef();

  const closePrescription = () => {
    setPrescription({
      idPrescription: "",
      idVisit: props.idVisit,
      date: new Date(),
      description: "",
    });
  };

  const downloadPdf = () => {
    downloadPrescriptionPdf(prescription, GetDateItalianFormat(prescription.date), props.patient, userCtx.doctor);
  };

  const sendPrescriptionPdf = async () => {
    //send email with pdf attachment if patient has an email address
    if (!props.patient.email) {
      props.feedbackCallback("error", "Il paziente non ha un indirizzo email", true);
      return;
    }
    emailPrescriptionPdf(prescription, GetDateItalianFormat(prescription.date), props.patient, userCtx.doctor, 
    (result) => {
      result? props.feedbackCallback("success", "Email inviata", true) : props.feedbackCallback("error", "Errore nell'invio dell'email", true);
    } );
  };

  const updatePrescriptions = (prescription, isDeleteAction) => {
    //look for prescription in the list
    let prescriptionIndex = prescriptions.findIndex(
      (presc) => presc.idPrescription === prescription.idPrescription
    );
    if (prescriptionIndex >= 0 && isDeleteAction) {
      //delete prescription
      let newPrescriptions = [...prescriptions];
      newPrescriptions.splice(prescriptionIndex, 1);
      setPrescriptions(newPrescriptions);
      return;
    }
    //update if present
    if (prescriptionIndex >= 0) {
      let updatedPrescriptions = [...prescriptions];
      updatedPrescriptions[prescriptionIndex] = prescription;
      setPrescriptions(updatedPrescriptions);
    } //add if not present
    else {
      setPrescriptions(prescriptions.concat(prescription));
    }
  };

  const handleSave = async () => {
    let path = "";
    if (
      prescription.idPrescription === "" ||
      prescription.idPrescription === emptyGuid ||
      prescription.idPrescription === undefined
    ) {
      //create new prescription
      path = `/api/prescription`;
    } else {
      //update existing prescription
      path = `/api/prescription/update`;
    }
    const token = await authService.getAccessTokenIfValid();
    const requestOptions = {
      method: "POST",
      headers: !token
        ? { "Content-Type": "application/json" }
        : {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
      body: JSON.stringify(prescription),
    };
    fetch(path, requestOptions)
      .then(async (response) => {
        const data = await response.json();
        // check for error response
        if (!response.ok) {
          // get error message from body or default to response statusText
          const error = (data && data.message) || response.statusText;
          return Promise.reject(error);
        } else {
          if (data) {
            setPrescription(data);
            updatePrescriptions(data, false);
            props.feedbackCallback("success", "Dati salvati con successo!", true);
          }
        }
      })
      .catch((error) => {
        if (error) {
          props.feedbackCallback(
            "error",
            "Unable to save prescription: " + error.toString(),
            true
          );
        }
      });
  };

  useEffect(() => {
    let isMounted = true;
    const getPrescriptions = async () => {
      const token = await authService.getAccessTokenIfValid();
      fetch("/api/prescription/" + props.idVisit, {
        headers: !token ? {} : { Authorization: "Bearer " + token },
      })
        .then(async (response) => {
          const data = await response.json();
          if (isMounted) {
            setLoading(false);
          }
          // check for error response
          if (!response.ok) {
            // get error message from body or default to response statusText
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
          } else {
            if (data) {
              if (isMounted) {
                setPrescriptions(data);
              }
            }
          }
        })
        .catch((error) => {
          if (error) {
            console.error("There was an error!", error);
          }
        });
    };
    if (props.idVisit) {
      getPrescriptions();
    } else {
      setLoading(false);
    }

    return () => {
      isMounted = false;
    };
  }, [props.idVisit]);

  const renderContent = () => {
    if (prescription.idPrescription !== "") {
      return (
        <>
          <Grid
            container
            spacing={1}
            alignItems="center"
            justifyContent="flex-end"
          >
            <Grid item>
              <IconButton onClick={handleSave}>
                <SaveIcon />
              </IconButton>
            </Grid>
            <Grid item>
              <IconButton onClick={downloadPdf}>
                <DownloadIcon />
              </IconButton>
            </Grid>
            <Grid item>
              <IconButton onClick={sendPrescriptionPdf}>
                <SendIcon />
              </IconButton>
              <IconButton onClick={closePrescription}>
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
          <Prescription
            onPrescriptionUpdate={(updatedPrescription) => {
              setPrescription(updatedPrescription);
            }}
            prescription={prescription}
            ref={componentRef}
            patient={props.patient}
          />
        </>
      );
    } else {
      return (
        <PrescriptionsList
          prescriptions={prescriptions}
          setPrescription={setPrescription}
          patient={props.patient}
          idVisit={props.idVisit}
          visitDate={visit.date}
          onPrescriptionDelete={updatePrescriptions}
        />
      );
    }
  };

  return <div>{renderContent()}</div>;
};

export default PrescriptionCard;
