import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import InventoryIcon from "@mui/icons-material/Inventory";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Button from "@mui/material/Button";


export const WarehouseTile = () => {
  const history = useHistory();

  const GoToWarehouse = (event) => {
    event.preventDefault();
    history.push({
      pathname: "/warehouse/requests",
    });
  };

  return (
    <Card>
      <CardHeader
          title="Inventario"
          titleTypographyProps={{
            variant: "body1",
          }}
        />
      <CardContent>
        <Button fullWidth data-testid="wareHouseButton" onClick={GoToWarehouse}>
          <InventoryIcon fontSize="large" sx={{ fontSize: "80px" }} />
        </Button>
      </CardContent>
    </Card>
  );
};

export default WarehouseTile;