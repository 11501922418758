import { useState, useEffect, useContext } from "react";
import Grid from "@mui/material/Grid";
import ToolsList from "./ToolsList";
import authService from "../api-authorization/AuthorizeService";
import { UserContext } from "../UserContext";

export const ToolsManager = (props) => {
    const userCtx = useContext(UserContext);

    const [tools, setTools] = useState([]);

    const [refresh, setRefresh] = useState(false);

    //cleanup state on unmount
    useEffect(() => {
        return () => {
            setTools([]);
        };
    }, []);

    useEffect(() => {
        if (userCtx.doctor === undefined || userCtx.doctor.idDoctor === undefined || refresh) {
            //do nothing if idDoctor is not set
            setRefresh(false);
            return;
        }
        const getTools = async () => {
            const token = await authService.getAccessTokenIfValid();
            const response = await fetch(
                "/api/tool/byDoctor/" + userCtx.doctor.idDoctor,
                {
                    headers: !token ? {} : { Authorization: `Bearer ${token}` },
                }
            );
            const data = await response.json();
            if (!response.ok) {
                // get error message from body or default to response statusText
                const error = (data && data.message) || response.statusText;
                return Promise.reject(error);
            }
            if (data) {
                setTools(data);
            }
        };
        getTools();
    }, [userCtx.doctor, refresh]);

    const addNew = () => {
        const newTool = {    
        description:'',
        idCompany:null,
        idDoctor: userCtx.doctor.idDoctor,
        minQuantity:0,
        name:'',
        quantity:0,
        status:1
        };
        let newTools = [...tools];
        newTools.unshift(newTool);
        setTools(newTools);
      };

    return (
        <Grid container spacing={2}>
            <Grid item lg={12} xs={12}>
                <ToolsList tools={tools} feedbackCallback={props.feedbackCallback} refreshData={() => setRefresh(true)} addNew={addNew}/>
            </Grid>
        </Grid>
    );
};

export default ToolsManager;
