import { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import InputLabel from "@mui/material/InputLabel";
import IconButton from "@mui/material/IconButton";
import FilledInput from "@mui/material/FilledInput";
import InputAdornment from "@mui/material/InputAdornment";
import { VisibilityOff } from "@mui/icons-material";
import { Visibility } from "@mui/icons-material";
import authService from "../api-authorization/AuthorizeService";
import Button from "@mui/material/Button";
import { Stack } from "@mui/material";
import { IsValidPassword } from "../Shared/Utilities.js";
import Typography from '@mui/material/Typography';

export const PasswordUpdate = (props) => {
  const [passwordData, setPasswordData] = useState({
    oldPassword: "",
    newPassword: "",
  });

  const [confirmPassword, setConfirmPassword] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [newPasswordError, setNewPasswordError] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  //cleanup state on unmount
  useEffect(() => {
    return () => {
      setPasswordData({
        oldPassword: "",
        newPassword: "",
      });
      setConfirmPassword("");
      setConfirmPasswordError("");
      setPasswordError("");
      setNewPasswordError("");
    }
  } ,[]);


  const handleSave = async () => {
    //validtion of input

    //password format is invalid
    if (!IsValidPassword(passwordData.newPassword)) {
      setNewPasswordError(
        "La password deve avere minimo 8 caratteri, contenere almeno una lettera maiuscola, una lettera minuscola, un numero e un carattere speciale"
      );
      return;
    }
    //confim password is not the same as new password
    if (passwordData.newPassword !== confirmPassword) {
      setConfirmPasswordError("Le password non corrispondono");
      return;
    }
    let token = await authService.getAccessTokenIfValid();
    const path = "/api/user/password";
    const requestOptions = {
      method: "POST",
      headers: !token
        ? { "Content-Type": "application/json" }
        : {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            Accept: "application/json",
          },
      body: JSON.stringify(passwordData),
    };
    fetch(path, requestOptions).then(async (response) => {
      // check for error response
      if (!response.ok) {
        const data = await response.json();
        // get error message from body or default to response statusText
        const error = (data && data.message) || response.statusText;
        props.feedbackCallback(
          "error",
          "Errore nell'aggiornamento della password",
          true
        );
        return Promise.reject(error);
      } else {
        props.feedbackCallback(
          "success",
          "Password aggiornata correttamente",
          true
        );
      }
    });
  };

  const handleChangePassword = (event) => {
    setPasswordData({ ...passwordData, oldPassword: event.target.value });
    setPasswordError("");
  };

  const handleChangeConfirmPassword = (event) => {
    setConfirmPassword(event.target.value);
    setConfirmPasswordError("");
  };

  const handleChangeNewPassword = (event) => {
    setPasswordData({ ...passwordData, newPassword: event.target.value });
    setNewPasswordError("");
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
      <Card
        sx={{ 
          boxShadow: 0 
        }}
      >
        <CardContent>
          <Typography gutterBottom variant="subtitle3" component="div">
              Cambia Password
          </Typography>
          <Stack spacing={2}>
            <FormControl  variant="filled">
              <InputLabel htmlFor="old-password">Password attuale</InputLabel>
              <FilledInput
                id="old-password"
                type={showPassword ? "text" : "password"}
                value={passwordData.oldPassword}
                onChange={handleChangePassword}
                error={passwordError !== ""}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
                label="oldPassword"
              />
              <FormHelperText id="password-helper-text" color="danger">{passwordError}</FormHelperText>
            </FormControl>

            <FormControl variant="filled">
              <InputLabel htmlFor="new-password">
                Nuova password
              </InputLabel>
              <FilledInput
                id="new-password"
                type={showPassword ? "text" : "password"}
                value={passwordData.newPassword}
                onChange={handleChangeNewPassword}
                error={newPasswordError !== ""}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
                label="newPassword"
              />
              <FormHelperText id="newPassword-helper-text" color="danger">{newPasswordError}</FormHelperText>
            </FormControl>

            <FormControl  variant="filled">
              <InputLabel htmlFor="confirm-password">
                Conferma password
              </InputLabel>
              <FilledInput
                id="confirm-password"
                type={showPassword ? "text" : "password"}
                value={confirmPassword}
                onChange={handleChangeConfirmPassword}
                error={confirmPasswordError !== ""}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
                label="confirmPassword"
              />
              <FormHelperText id="confirmPassword-helper-text" color="danger">{confirmPasswordError}</FormHelperText>
            </FormControl>

            <Button onClick={handleSave}>Aggiorna password</Button>
          </Stack>
        </CardContent>
      </Card>
  );
};

export default PasswordUpdate;
