import { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import DrugsInventory from "./DrugsInventory";
import DrugTypesManager from "./DrugTypesManager";



export const DrugsManager = (props) => {
  const [drugType, setDrugType] = useState({});
   //cleanup state on unmount
   useEffect(() => {
    return () => {
      setDrugType({});
    }
  } ,[]);

  return (
    <Grid container spacing={2}>
      <Grid item lg={4} xs={12}>
        <DrugTypesManager feedbackCallback={props.feedbackCallback} setDrugTypeCallback={setDrugType}/>
      </Grid>
      <Grid item lg={8} xs={12}>
       <DrugsInventory onSelect={props.onSelect} feedbackCallback={props.feedbackCallback} drugType={drugType}/>
        
      </Grid>
    </Grid>
  );
};

export default DrugsManager;
