import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { guid, getLast, getFirst } from "./Helpers.js";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import PatientSelect from "../PatientSelect.js";
import DoctorSelect from "../DoctorSelect.js";
import "./ReactAgendaCtrl.css";

var now = new Date();

export const ReactAgendaCtrl = (props) => {
  const [editMode, setEditMode] = useState(false);

  const [item, setItem] = useState({
    id: "",
    description: "",
    idDoctor: "",
    idPatient: "",
    name: "",
    surname: "",
    classes: "color-1",
    startTime: now,
    endTime: now,
  });

  useEffect(() => {
    const getItem = () => {
      if (props.itemColors) {
        setItem({ ...item, classes: Object.keys(props.itemColors)[0] });
      }

      if (!props.selectedCells) {
        let start = now;
        let endT = moment(now).add(15, "Minutes");
        setItem({ ...item, description: "", startTime: start, endTime: endT, idDoctor: props.selectedIdDoctor? props.selectedIdDoctor: "" });
        setEditMode(false);
        return;
      }

      if (
        props.selectedCells &&
        props.selectedCells[0] &&
        props.selectedCells[0].idAppointment
      ) {
        let start = moment(props.selectedCells[0].startTime);
        let endT = moment(props.selectedCells[0].endTime);

        setEditMode(true);
        setItem({
          ...item,
          id: props.selectedCells[0].idAppointment,
          description: props.selectedCells[0].description,
          idPatient: props.selectedCells[0].idPatient,
          idDoctor: props.selectedCells[0].idDoctor,
          name: props.selectedCells[0].name,
          surname: props.selectedCells[0].surname,
          classes: props.selectedCells[0].classes,
          startTime: start,
          endTime: endT,
        });
        return;
      }

      if (props.selectedCells && props.selectedCells.length === 1) {
        let start = moment(getFirst(props.selectedCells));
        let endT = moment(getLast(props.selectedCells)).add(15, "Minutes");
        setItem({ ...item, description: "", startTime: start, endTime: endT, idDoctor: props.selectedIdDoctor? props.selectedIdDoctor: "" });
        setEditMode(false);
        return;
      }

      if (props.selectedCells && props.selectedCells.length > 0) {
        let start = moment(getFirst(props.selectedCells));
        let endT = moment(getLast(props.selectedCells)) || now;
        setItem({ ...item, description: "", startTime: start, endTime: endT, idDoctor: props.selectedIdDoctor? props.selectedIdDoctor: "" });
        setEditMode(false);
        return;
      }
    };
    getItem();
  }, []);

  const handleChange = (event) => {
    if (event.target.tagName === "BUTTON") {
      event.preventDefault();
    }

    setItem({ ...item, [event.target.name]: event.target.value });
  };

  const handleStartDateChange = (newValue) => {
    if (newValue >= item.endTime) {
      setItem({
        ...item,
        startTime: newValue,
        endTime: moment(new Date(newValue)).add(15, "Minutes"),
      });
    } else {
      setItem({ ...item, startTime: newValue });
    }
  };

  const handleEndDateChange = (newValue) => {
    if (newValue <= item.startTime) {
      return;
    }
    setItem({ ...item, endTime: newValue });
  };

  const dispatchEvent = (obj) => {
    let newAdded = [];
    let items = props.items;
    if (obj["multiple"]) {
      let array = obj["multiple"];
      Object.keys(array).forEach(function (key) {
        let newAr = array[key].filter(function (val, ind) {
          return array[key].indexOf(val) === ind;
        });
        let start = newAr[0];
        let endT = newAr[newAr.length - 1] || now;
        let lasobj = {
          idAppointment: guid(),
          description: obj.description,
          idPatient: obj.idPatient,
          idDoctor: obj.idDoctor,
          name: obj.name,
          surname: obj.surname,
          startTime: new Date(start),
          endTime: new Date(endT),
          classes: obj.classes,
        };
        items.push(lasobj);
        newAdded.push(lasobj);
      });
      return props.Addnew(items, newAdded);
    }

    obj.idAppointment = guid();
    items.push(obj);
    props.Addnew(items, obj);
  };

  const addEvent = (e) => {
    if (item.description.length < 1) {
      return;
    }

    if (props.selectedCells && props.selectedCells.length > 0) {
      var obj = props.selectedCells.reduce(
        (r, v, i, a, k = v.substring(0, 10)) => (
          (r[k] = r[k] || []).push(v), r
        ),
        {}
      );

      if (Object.values(obj).length > 1) {
        let newObj = {
          description: item.description,
          idPatient: item.idPatient,
          idDoctor: item.idDoctor,
          name: item.name,
          surname: item.surname,
          startTime: new Date(item.startTime),
          endTime: new Date(item.endTime),
          classes: item.classes,
          multiple: obj,
        };

        return dispatchEvent(newObj);
      }
    }

    let newObj = {
      description: item.description,
      idPatient: item.idPatient,
      idDoctor: item.idDoctor,
      name: item.name,
      surname: item.surname,
      startTime: new Date(item.startTime),
      endTime: new Date(item.endTime),
      classes: item.classes,
    };

    dispatchEvent(newObj);
  };

  const updateEvent = (e) => {
    if (props.selectedCells[0].idAppointment && props.items) {
      var newObj = {
        idAppointment: props.selectedCells[0].idAppointment,
        description: item.description,
        idDoctor: item.idDoctor,
        idPatient: item.idPatient,
        name: item.name,
        surname: item.surname,
        startTime: new Date(item.startTime),
        endTime: new Date(item.endTime),
        classes: item.classes,
      };
      var items = props.items;
      for (let i = 0; i < items.length; i++) {
        if (items[i].idAppointment === newObj.idAppointment) items[i] = newObj;
      }
      if (props.edit) {
        props.edit(items, newObj);
      }
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    addEvent(e);
  };

  const handleEdit = (e) => {
    e.preventDefault();
    updateEvent(e);
  };

  let itc = Object.keys(props.itemColors);
  let colors = itc.map(function (_item, idx) {
    return (
      <div
        style={{
          background: props.itemColors[_item],
        }}
        className="agendCtrls-radio-buttons"
        key={_item}
      >
        <button
          name="classes"
          value={_item}
          className={
            item.classes === _item
              ? "agendCtrls-radio-button--checked"
              : "agendCtrls-radio-button"
          }
          onClick={handleChange}
        />
      </div>
    );
  });

  return (
    <form onSubmit={editMode ? handleEdit : handleSubmit}>
      <Grid container spacing={2} justifyContent="center" alignItems="center">
        {props.allowDoctorSelection && (
          <Grid item xs={7}>
            <DoctorSelect
              idDoctor={item.idDoctor}
              disabled={editMode}
              onDoctorSelected={(doctor) => {
                if (doctor && doctor.idDoctor) {
                  setItem({ ...item, idDoctor: doctor.idDoctor });
                }
                else {
                  setItem({ ...item, idDoctor: "" });
                }
              }}
            />
          </Grid>
        )}
        <Grid item md={6} xs={6}>
          <TextField
            variant="filled"
            fullWidth
            label="Descrizione appuntamento"
            name="description"
            size="small"
            required
            placeholder="Descrizione appuntamento"
            onChange={handleChange}
            value={item.description}
          />
        </Grid>
        <Grid item md={6} xs={6}>
          <PatientSelect
            patient={{
              idPatient: item.idPatient,
              name: item.name,
              surname: item.surname,
            }}
            onPatientSelected={(patient) => {
              setItem({
                ...item,
                idPatient: patient?.idPatient ?? "",
                name: patient?.name ?? "",
                surname: patient?.surname ?? "",
              });
            }}
            required={false}
            doctorIdFilter={item.idDoctor}
          />
        </Grid>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <Grid item md={6} xs={6}>
            <DateTimePicker
              fullWidth
              variant="filled"
              inputFormat="dd/MM/yyyy HH:mm"
              label="Inizio"
              value={item.startTime}
              onChange={handleStartDateChange}
              renderInput={(params) => <TextField {...params} />}
            />
          </Grid>
          <Grid item md={6} xs={6}>
            <DateTimePicker
              fullWidth
              variant="filled"
              inputFormat="dd/MM/yyyy HH:mm"
              label="Fine"
              value={item.endTime}
              onChange={handleEndDateChange}
              renderInput={(params) => <TextField {...params} />}
            />
          </Grid>
        </LocalizationProvider>
        <Grid item md={10} xs={10} className="text-center pt-5">
          <label>Colore:</label>
          <div className="agendCtrls-radio-wrapper float-end">{colors}</div>
        </Grid>
        <Grid item md={10} xs={10} className="text-center pt-5">
          <Button
            disabled={item.description == null || item.description.length < 1 || (props.allowDoctorSelection && (item.idDoctor == null || item.idDoctor.length < 1))}
            type="submit"
            variant="contained"
            sx={{ textAlign: "center" }}
          >
            Salva
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default ReactAgendaCtrl;

ReactAgendaCtrl.propTypes = {
  items: PropTypes.array,
  itemColors: PropTypes.object,
  selectedCells: PropTypes.array,
  edit: PropTypes.func,
  Addnew: PropTypes.func,
  allowDoctorSelection: PropTypes.bool,
};

ReactAgendaCtrl.defaultProps = {
  items: [],
  itemColors: {},
  selectedCells: [],
};
