import React, { useState, useEffect, useContext } from "react";
import { Loading } from "../Shared/Loading.js";
import authService from "../api-authorization/AuthorizeService";
import { useParams, useHistory } from "react-router-dom";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Grid from "@mui/material/Grid";
import { Button } from "@mui/material";
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import { PersonalPicture } from "./PersonalPicture/PersonalPicture";
import UserContext from "../UserContext.js";
import { GetDateItalianFormat } from "../Shared/Utilities.js";
import Typography from "@mui/material/Typography";

export const PatientTile = (props) => {
    const history = useHistory();
    const userCtx = useContext(UserContext);
    const { id } = useParams();
    const [idPatient, setidPatient] = useState(id);
    const [patient, setPatient] = useState({
        idPatient: "",
        idDoctor: userCtx.doctor.idDoctor,
        name: "",
        surname: "",
        address: "",
        zipCode: "",
        city: "",
        province: "",
        country: "",
        birthDate: "",
        birthPlace: "",
        gender: "",
        fiscalCode: "",
        healthCard: "",
        familyDoctor: "",
        email: "",
        phone: "",
        mobile: "",
    });
    const [loading, setLoading] = useState(true);
    const [errorMessage, setErrorMessage] = useState();
    const [forceUpdateAvatar, setForceUpdateAvatar] = useState(false);


    
    const getPatientInfo = async () => {
        const token = await authService.getAccessTokenIfValid();
        fetch("/api/patients/" + idPatient, {
            headers: !token ? {} : { Authorization: "Bearer " + token },
        })
            .then(async (response) => {
                const data = await response.json();
                setLoading(false);
                // check for error response
                if (!response.ok) {
                    // get error message from body or default to response statusText
                    const error = (data && data.message) || response.statusText;
                    return Promise.reject(error);
                } else {
                    if (data) {
                        setPatient(data);
                    }
                }
            })
            .catch((error) => {
                if (error) {
                    setErrorMessage(
                        "Unable to retrieve patient's data: " + error.toString()
                    );
                    console.error("There was an error!", error);
                }
            });
    };
    useEffect(() => {
        props.setPatient(patient);

        if (props.id === undefined) {
            viewInfoPatient();
            
        }

    }, [patient, props]);

    useEffect(() => {
        //Async function to retrieve Patient details from API endpoint patients/idPatient
        if (idPatient) {
            getPatientInfo();
        } else {
            setLoading(false);
        }
        return;
    }, [idPatient]);

    const viewInfoPatient = () => {
        props.togleInfoPatient(true);
    }
    const hideInfoPatient = () => {
        if (props.newId !== undefined) {
            history.replace({
                pathname: `/patient/${props.newId}`,
            });
        }
        else {
            props.togleInfoPatient(false);
            getPatientInfo();
            setForceUpdateAvatar(!forceUpdateAvatar);
        }
    }

    const fieldsInColumn = () => {
        let keys = Object.keys(patient);
        let patientFullName = patient.name + " " + patient.surname;

        return (
            <>
                <Card sx={{
                    marginBottom: 2,
                }}>

                    <CardHeader
                        title="Paziente"
                        data-testid="section-title"
                        titleTypographyProps={{
                            variant: "body1",
                        }}
                        sx={{
                            opacity: props.visibleInfoPatient ? 0.4 : 1, 
                            transition: 'opacity 0.3s ease', 
                        }}
                    />
                    <CardContent>
                        <Grid sx={{
                            opacity: props.visibleInfoPatient ? 0.4 : 1, 
                            transition: 'opacity 0.3s ease', 
                        }}>
                        <PersonalPicture
                            idPatient={idPatient}
                            patientName={patientFullName}
                            feedbackCallback={props.feedbackCallback}
                            isReadonly={true}
                            forceUpdateAvatar={forceUpdateAvatar}
                            
                            />
                        </Grid>
                        <Grid container spacing={1}>
                            <Grid key="name" item xs={12} sx={{
                                opacity: props.visibleInfoPatient ? 0.4 : 1, 
                                transition: 'opacity 0.3s ease', 
                            }}>
                                <Typography color="primary" align="center" mt={0} variant="h5" >
                                   
                                        {patient["name"] || ""} {patient["surname"] || ""}
                                </Typography> 
                                <Typography color="secondary" align="center" mt={0}>
                                        {patient["birthDate"] ? GetDateItalianFormat(patient["birthDate"]) : ""}
                                </Typography> 
                            </Grid>
                            
                            <Grid item xs={12}>

                                <Button
                                    data-testid="button-anagrafica"
                                    variant={props.visibleInfoPatient ? "contained" : "outlined"}
                                    onClick={() => {
                                        props.visibleInfoPatient ? hideInfoPatient() : viewInfoPatient();
                                    }}
                                    endIcon={props.visibleInfoPatient ? <KeyboardArrowLeftIcon /> : <KeyboardArrowRightIcon />}
                                    sx={{ width: '100%' }}
                                >
                                    {props.visibleInfoPatient ? "Chiudi Anagrafica" : "Anagrafica"}
                                </Button>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
                
            </>
        );
    };

    let contents = loading ? (
        <div className="w-100 d-flex justify-content-center pb-3">
            <Loading />
        </div>
    ) : (
            <div>
                <form>{fieldsInColumn()}</form>
            </div>
    );


    return <div>{contents}</div>;
    };

export default PatientTile;
