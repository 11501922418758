import React, { useState, useEffect, useContext } from "react";
import authService from "../../api-authorization/AuthorizeService";
import Box from "@mui/material/Box";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import IconButton from "@mui/material/IconButton";
import ConfirmDialog from "../../Shared/ConfirmDialog";
import QuestionDialog from "./QuestionDialog";
import MoveUpIcon from '@mui/icons-material/MoveUp';
import MoveDownIcon from '@mui/icons-material/MoveDown';
import "./Question.css";
import { PatientContext } from "../PatientContext";
import { VisitContext } from "../VisitContext";
import QuestionRenderer from "./QuestionRenderer";

export const Question = (props) => {
  const [patient, setPatient] = useContext(PatientContext);
  const [visit, setVisit] = useContext(VisitContext);
  const [input, setInput] = useState("");
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [question, setQuestion] = useState(props.question);
  const [questionEditDialogOpen, setQuestionEditDialogOpen] = useState(false);

  useEffect(() => {
    setQuestion(props.question);
  }, [props.question]);

  const updateQuestion = async (udpdatedQuestion) => {

    let  path = "/api/question/update";
    const token = await authService.getAccessTokenIfValid();
    const requestOptions = {
      method: "POST",
      headers: !token
        ? { "Content-Type": "application/json" }
        : {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
      body: JSON.stringify(udpdatedQuestion),
    };
    fetch(path, requestOptions).then(async (response) => {
      const data = await response.json();
      // check for error response
      if (!response.ok) {
        // get error message from body or default to response statusText
        const error = (data && data.message) || response.statusText;
        return Promise.reject(error);
      } else {
        if (data) {
          setQuestion(data);
          props.updateQuestionsCallback(data, false);
          props.savedCallback();
        }
      }
    });

  
  }

  const handleMoveUp = () => {
    let updatedQuestion = { ...question };
    if(updatedQuestion.positionIndex > 0) updatedQuestion.positionIndex--;
    updateQuestion(updatedQuestion);
  }
  
  const handleMoveDown = () => {
    let updatedQuestion = { ...question };
    updatedQuestion.positionIndex++;
    updateQuestion(updatedQuestion);
  }

  const handleDeleteClick = async (event) => {

    let path = `/api/question/delete`;
    const token = await authService.getAccessTokenIfValid();
    const requestOptions = {
      method: "POST",
      headers: !token
        ? { "Content-Type": "application/json" }
        : {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
      body: JSON.stringify(question),
    };
    fetch(path, requestOptions).then(async (response) => {
      const data = await response.json();
      // check for error response
      if (!response.ok) {
        // get error message from body or default to response statusText
        const error = (data && data.message) || response.statusText;
        return Promise.reject(error);
      } else {
        if (data) {
          props.updateQuestionsCallback(question, true);
          props.savedCallback();
        } else {
          setConfirmDialogOpen(true);
        }
      }
    });
    // }
  };

  const handleEditOpen = () => {
    setQuestionEditDialogOpen(true);
  };

  const handleQuestionEditDialogClose = () => {
    setQuestionEditDialogOpen(false);
  };

  const renderMoveButtons = () => {
    if(props.editMode) {
      return (
        <>
        <IconButton
          data-testid="FAB-Edit"
          size="small"
          aria-label="edit"
          onClick={handleMoveUp}
          hidden={!props.editMode}
        >
          <MoveUpIcon fontSize="small" />
        </IconButton>
        <IconButton
        data-testid="FAB-Edit"
        size="small"
        aria-label="edit"
        onClick={handleMoveDown}
        hidden={!props.editMode}
      >
        <MoveDownIcon fontSize="small" />
      </IconButton>
      </>
      );
    }
  }


  const renderEditQuestionButton = () => {
    if (props.editMode) {
      return (
        <IconButton
          data-testid="FAB-Edit"
          size="small"
          aria-label="edit"
          onClick={handleEditOpen}
          hidden={!props.editMode}
        >
          <EditIcon fontSize="small" />
        </IconButton>
      );
    }
  };

  const renderDeleteQuestionButton = () => {
    if (props.editMode) {
      return (
        <IconButton
          size="small"
          aria-label="delete"
          onClick={handleDeleteClick}
          hidden={!props.editMode}
        >
          <DeleteIcon fontSize="small" />
        </IconButton>
      );
    }
  };

  const handleDeleteConfirmation = async (answer) => {
    if (answer) {
      let path = `/api/question/delete/force/true`;
      const token = await authService.getAccessTokenIfValid();
      const requestOptions = {
        method: "POST",
        headers: !token
          ? { "Content-Type": "application/json" }
          : {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
        body: JSON.stringify(question),
      };
      fetch(path, requestOptions).then(async (response) => {
        const data = await response.json();
        // check for error response
        if (!response.ok) {
          // get error message from body or default to response statusText
          const error = (data && data.message) || response.statusText;
          return Promise.reject(error);
        } else {
          if (data) {
            props.updateQuestionsCallback(question, true);
            props.savedCallback();
          }
        }
      });
    }
  };

  const parseAnswer = (answerArray) => {
    let answerDictionary = {};
    props.question.answerOptions.forEach((option) => {
      answerDictionary[option.optionDescription] = false;
    });
    if (answerArray) {
      answerArray.forEach((answer) => {
        answerDictionary[answer] = true;
      });
    }
    return answerDictionary;
  };

  const [checked, setChecked] = useState(
    props.question.answer !== ""
      ? parseAnswer(props.question.answer.split(";"))
      : parseAnswer()
  );

  const handleAnswerChange = (event, newvalue) => {
    setQuestion({ ...question, answer: newvalue });
  };

  const handleOptionChange = (event) => {
    const { id, value } = event.target;
    const newValue = !checked[id];
    const newChecked = { ...checked, [id]: newValue };
    setChecked(newChecked);
    let answerString = "";
    Object.keys(newChecked).forEach((key) => {
      if (newChecked[key]) answerString = `${answerString};${key}`;
    });
    if (answerString === ";") {
      answerString = "";
    }
    setQuestion({ ...question, answer: answerString });
  };

  const handleQuestionBlur = async (event) => {
    let path = `/api/question/patient/${patient.idPatient}/visit/${visit.idVisit}`;
    const token = await authService.getAccessTokenIfValid();
    const requestOptions = {
      method: "POST",
      headers: !token
        ? { "Content-Type": "application/json" }
        : {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
      body: JSON.stringify(question),
    };
    fetch(path, requestOptions).then(async (response) => {
      const data = await response.json();
      // check for error response
      if (!response.ok) {
        // get error message from body or default to response statusText
        const error = (data && data.message) || response.statusText;
        return Promise.reject(error);
      } else {
        if (data) {
          props.updateQuestionsCallback(data, false);
          props.savedCallback();
        }
      }
    });
  };

  let boxSx = props.editMode ? { border: "1px dashed grey" } : undefined;
  return (
    <div>
      <Box sx={boxSx}>
        <div className="d-flex justify-content-end" hidden={props.editMode}>
          {renderMoveButtons()}
          {renderEditQuestionButton()}
          {renderDeleteQuestionButton()}
        </div>
        <div className="d-block">
          <QuestionRenderer
            question={question}
            handleAnswerChange={handleAnswerChange}
            handleQuestionBlur={handleQuestionBlur}
            handleOptionChange={handleOptionChange}
            checked={checked}
          /></div>
      </Box>
      <QuestionDialog
        open={questionEditDialogOpen}
        onClose={handleQuestionEditDialogClose}
        savedCallback={props.savedCallback}
        updateQuestionsCallback={props.updateQuestionsCallback}
        question={question}
        edit={true}
      />
      <ConfirmDialog
        open={confirmDialogOpen}
        answerCallback={handleDeleteConfirmation}
        title="Attenzione!"
        text="La domanda contiene delle risposte per questo o altro paziente. Procedere con l'eliminazione di tutte le risposte associate?"
        agreeText="Delete"
        disagreeText="Cancel"
      />
    </div>
  );
};
export default Question;
