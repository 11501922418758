import React, { Component } from 'react';
import { Container } from '@mui/material';
import { NavMenu } from './NavMenu';
export class Layout extends Component {
  static displayName = Layout.name;

   
  render () {
    return (
      <div>
            <NavMenu onMount={this.handleNavMount} />
            <Container
                maxWidth="false"
                sx={{
                    marginY: 0,
                    paddingLeft: "10px",
                    paddingRight: "10px",
                    height: `calc(100vh - 64px)`,
                }}
                //className="vh-100"
                disableGutters>
          {this.props.children}
        </Container>
      </div>
    );
  }
}
