import React from "react";
import CircularProgress from "@mui/material/CircularProgress";



export const Loading = (props) => {
  
  const renderMessage = () => {
  if (props.message) {
    return (
      <p>{props.message}</p>

    );
  } 
};
  return (
    <div className="d-flex flex-column">
      <div className="d-flex justify-content-center">
      {renderMessage()}
      </div>
      <div className="d-flex justify-content-center">
      <CircularProgress color={props.color? props.color: "inherit"} />
      </div>
    </div>
  );
};
export default Loading;