import React, { useState, useContext } from "react";
import Card from "react-bootstrap/Card";
import Grid from "@mui/material/Grid";
import QuestionDialog from "./QuestionDialog";
import Question from "./Question";
import { SettingsContext } from "../../Settings/SettingsContext";

export const Section = (props) => {
  const [loading, setLoading] = useState(true);
  const [settings, setSettings] = useContext(SettingsContext);


  const renderAddQuestionButton = (positionIndex) => {
    if (settings.editMode) {
      return (
        <QuestionDialog
          savedCallback={props.savedCallback}
          section={props.section}
          positionIndex={positionIndex}
          updateQuestionsCallback={props.updateQuestionsCallback}
        />
      );
    }
  };

  const renderQuestions = () => {
    if (undefined !== props.questions) {
      if (props.questions.length > 0) {
        return props.questions.map((question, questionIndex) => {
          let xs = props.questions[questionIndex].size || 6;
          let md = props.questions[questionIndex].size || 6;
          let justify = "flex-start";
          if (props.questions[questionIndex].dataType === "tit") {
            xs = 12;
            md = 12;
            justify = "flex-start";
          }
          if (props.questions[questionIndex].section === props.section) {
              return (
                <Grid
                  key={questionIndex}
                  item
                  container
                  xs={xs}
                  md={md}
                  justifyContent={justify}
                >
                  <Grid
                    key={questionIndex + "-question"}
                    item
                    xs
                    md
                    justifyContent={justify}
                  >
                    <Question
                      savedCallback={props.savedCallback}
                      question={props.questions[questionIndex]}
                      updateQuestionsCallback={props.updateQuestionsCallback}
                      editMode={settings.editMode}
                    />
                  </Grid>
                  <Grid
                    key={questionIndex + "-addButton"}
                    item
                    style={{width: "30px"}}
                    justifyContent="center"
                  >
                    {renderAddQuestionButton(props.questions[questionIndex].positionIndex + 1)}
                  </Grid>
                </Grid>
              );
          }
          return <></>;
        });
      }
    }
    return (
      <div>
        {renderAddQuestionButton(0)}
      </div>
    );
  };
  return (
      <Grid container rowSpacing={1} columnSpacing={0} justifyContent="flex-start" p={2}>
        {renderQuestions()}
      </Grid>
  );
};

export default Section;
