import React, { useState,  useEffect } from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";

export const SearchTreatmentType = (props) => {
    const [treatmentsType, setTreatmentsType] = useState([]);
    const handleAddTreatment = async (_treatment) => {
        props.AddTreatment(_treatment);
    }

    useEffect(() => {
        
    }, []);

    useEffect(() => {
        setTreatmentsType(props.treatmentsType);
    }, [props.treatmentsType]);

    return (
        <>
            <Autocomplete
                id="treatmentSelect"
                options={treatmentsType}

                getOptionLabel={(option) => option.title || ""}
                defaultValue={""}
                value={null}
                renderOption={(props, option) => (
                    <Box
                        component="li"
                        sx={{ display: "flex", alignItems: "flex-end" }}
                        {...props}
                        key={option.title}
                    >
                        <div className="d-flex justify-content-between w-100">
                            <div>{option.title}</div>
                        </div>
                    </Box>
                )}
                renderInput={(params) => (
                    <TextField
                        size="small"
                        id="treatmentTypeSearch"
                        data-testid="typeInputField"
                        label="Aggiungi trattamento"
                        {...params}
                        variant="filled"
                    />
                )}
                onChange={(event, value) => {
                    handleAddTreatment(value);
                }
                }
                variant="filled"
                size="small"
            />
        </>
    );
};

export default SearchTreatmentType;