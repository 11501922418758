import React, {  Fragment, useContext } from 'react';
import Switch from '@mui/material/Switch';
import InventoryIcon from "@mui/icons-material/Inventory";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import {ThemeContext} from '../../theme';
import { SettingsContext } from './SettingsContext';
import DarkModeSwitch from '../../components/Shared/DarkModeSwitch';
import AssessmentIcon from '@mui/icons-material/Assessment';
import UserContext from "../UserContext";
import { useHistory } from 'react-router-dom';


export const SettingsMenu = (props) => {
    const userCtx = useContext(UserContext);
    const history = useHistory();
    const [light, setLight] = useContext(ThemeContext);
    const [settings, setSettings] = useContext(SettingsContext);


    const handleEditModeChange = (event) => {
        setSettings({ ...settings, editMode: !settings.editMode });
    }


    const handleDarkThemeChange = () => {
        setLight(!light);
    }

    const handleInventoryClick = () => {
        history.push('/warehouse/requests');
        props.handleClose();
    }

    const handleReportsClick = () => {
        history.push('/reports/invoices');
        props.handleClose();
    }

    return (
        <>
            <MenuItem onClick={handleInventoryClick} disabled={!userCtx.roles.includes("Doctor") && !userCtx.roles.includes("Secretary") }>
                <ListItemIcon>
                    <InventoryIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText primary="Inventario" />
            </MenuItem>

            <MenuItem onClick={handleReportsClick} disabled={!userCtx.roles.includes("Doctor") && !userCtx.roles.includes("Secretary")} >
                <ListItemIcon>
                    <AssessmentIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText primary="Reportistica" />
            </MenuItem>

            <MenuItem onClick={handleDarkThemeChange}>
                <ListItemIcon>
                    <DarkModeSwitch
                        checked={!light}
                        onChange={handleDarkThemeChange} />
                </ListItemIcon>
                <ListItemText primary="Modalità notte" />
            </MenuItem>

            <MenuItem onClick={handleEditModeChange} disabled={!userCtx.roles.includes("Doctor")}>
                <ListItemIcon>
                    <Switch
                        checked={settings.editMode}
                        onChange={handleEditModeChange} />
                </ListItemIcon>
                <ListItemText primary="Modifica scheda paziente" />

            </MenuItem>
        </>
    );
};

export default SettingsMenu;



