import React, { useState, useContext, useEffect } from "react";
import { useParams } from "react-router-dom";
import PatientTile from "./PatientTile";
import PatientPersonalDetails from "./PatientPersonalDetails";
import Grid from "@mui/material/Grid";
import { PatientContext } from "./PatientContext";
import VisitsCard from "./VisitsCard";
import RequestCard from "./RequestCard";
import FeedbackAlert from "../Shared/FeedbackAlert";
import ExpiredLicense from "../Shared/Licenses/ExpiredLicense";
import UserContext from "../UserContext";

export const PatientFile = (props) => {
  const userCtx = useContext(UserContext);
  const { id } = useParams();
  const [currentRequest, setCurrentRequest] = useState();
    const [patient, setPatient] = useState();
    const [visibleInfoPatient, setVisibleInfoPatient] = useState(false);
  const [feedback, setFeedback] = useState({
    severity: "success",
    text: "Success",
    open: false,
  });

  const feedbackCallback = (severity, text, open) => {
    setFeedback({ severity: severity, text: text, open: open });
  };

  const alertClosed = () => {
    setFeedback({ ...feedback, open: false });
  };
    const togleInfoPatient = (visible) => {
        setVisibleInfoPatient(visible)
    };

    useEffect(() => {
       
    }, [visibleInfoPatient]);

    const [newId, setNewId] = useState();

  return (
    <>
      {userCtx.isValidLicense && (userCtx.roles.includes("Doctor") || userCtx.roles.includes("Secretary")) && (
        <PatientContext.Provider value={[patient, setPatient]}>
          <Grid container spacing={2} pt={2} pl={0} pr={0}>
            <Grid item md={2} xs={12}>
              <PatientTile
                              setPatient={setPatient}
                              id={id}
                              feedbackCallback={feedbackCallback}
                              doctorsView={true}
                              togleInfoPatient={togleInfoPatient}
                              visibleInfoPatient={visibleInfoPatient}
                              newId={newId}
                          />
                          {(!visibleInfoPatient && id !== undefined) &&
                              <RequestCard
                                  setRequest={setCurrentRequest}
                                  idPatient={id}
                                  feedbackCallback={feedbackCallback}
                              />
                          }
            </Grid>
                      <Grid item md={10} xs={12}>
                          { (visibleInfoPatient || id === undefined) ?
                              <PatientPersonalDetails
                                  setPatient={setPatient}
                                  id={id}
                                  feedbackCallback={feedbackCallback}
                                  doctorsView={true}
                                  setNewId={setNewId}
                              />
                              :
                              <VisitsCard
                                  request={currentRequest}
                                  idPatient={id}
                                  patient={patient}
                                  feedbackCallback={feedbackCallback}
                              />
                          }
                          
            </Grid>
          </Grid>
          <FeedbackAlert
            severity={feedback.severity}
            text={feedback.text}
            open={feedback.open}
            closedCallback={alertClosed}
          />
        </PatientContext.Provider>
      )}
    </>
  );
};
