import React, { useEffect, useState, useContext } from "react";
import { NavLink } from "reactstrap";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Stack from "@mui/material/Stack";
import { Link, MemoryRouter } from "react-router-dom";
import { LoginMenu } from "./api-authorization/LoginMenu";
import authService from "./api-authorization/AuthorizeService";
import "./NavMenu.css";
import HomeIcon from "@mui/icons-material/Home";
import { ThemeContext } from "../theme";
import Typography from "@mui/material/Typography";
import logo from '../logo.png';
import UserContext from "./UserContext";
import DoctorSelect from "./Shared/DoctorSelect";
import Home from "./Home/Home";

export const NavMenu = () => {
    const displayName = NavMenu.name;
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [light, setLight] = useContext(ThemeContext);
    const userCtx = useContext(UserContext);


    const checkAuthetication = async () => {
        const [isAuthenticated] = await Promise.all([
            authService.isAuthenticated(),
        ]);
        setIsAuthenticated(isAuthenticated);
    };

    useEffect(() => {
        checkAuthetication();
    }, []);

    return (
        <AppBar
            position="sticky"
            sx={{ backgroundColor: "#004561"}}
        >
            <Toolbar>
                <Typography variant="h6" component="div" sx={{ flexGrow: 1, display: { xs: 'block', sm: 'block' } }}>
                    <NavLink to="/home" tag={Link} ><img className="logo-img" src={logo} alt="Ypatients" /></NavLink>
                </Typography>
                <Stack direction="row" spacing={1} sx={{alignItems:"center"}}>
                    {isAuthenticated && userCtx.roles.includes("Secretary") &&
                        <DoctorSelect onDoctorSelected={(doctor) => userCtx.updateDoctor(doctor)} doctor={userCtx.doctor}/>
                    }
                    {isAuthenticated &&
                        <NavLink to="/home" tag={Link} className="active">
                            <HomeIcon fontSize="large" />
                        </NavLink>
                    }
                    
                    

                    <LoginMenu></LoginMenu>
                </Stack>
            </Toolbar>
        </AppBar>
    );
};

export default NavMenu;
