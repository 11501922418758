import React, { useEffect, useState, useContext } from "react";
import PatientsTile from "./PatientsTile";
import Loading from "../Shared/Loading";
import Grid from "@mui/material/Grid";
import Backdrop from "@mui/material/Backdrop";
import Agenda from "./Agenda";
import ReportsTile from "./ReportsTile";
import WarehouseTile from "./WarehouseTile";
import AccountsTile from "./AccountsTile";
import SettingsTile from "./SettingsTile";
import LicensesTile from "./LicensesTile";
import TrialTile from "./TrialTile";
import QuotationsTile from "./QuotationsTile";
import AddressBookTile from "../Shared/AddressBook/AddressBookTile";
import Welcome from "./Welcome";
import ExpiredLicense from "../Shared/Licenses/ExpiredLicense";
import UserContext from "../UserContext";
import { headerHeight } from "../Shared/Utilities.js";

export const Home = (props) => {
    const userCtx = useContext(UserContext);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (userCtx.isFirstLogin) {
            const timer = setTimeout(() => {
                setLoading(false);
                if (userCtx.roles.includes("Administrator")
                    && !userCtx.roles.includes("Doctor")
                ) { }

                userCtx.updateIsFirstLogin(false);
            }, 3000);
            return () => clearTimeout(timer);
        } else {
            setLoading(false);
        }
    }, []);

    
    const renderWithExpiredLicense = () => {
        return (
            <>
                {userCtx.roles.includes("Administrator") && ( // An administrator can have also a license
                    <Grid
                        item
                        container
                        xs={12}
                        md={12}
                        justifyContent="center"
                        alignItems="flex-start"
                        spacing={2}
                        direction="row"
                    >
                        <Grid item xs={3} md={3}>
                            <AccountsTile />
                        </Grid>
                        <Grid item xs={3} md={3}>
                            <LicensesTile />
                        </Grid>
                    </Grid>
                )}

                <Grid
                    item
                    container
                    xs={12}
                    md={12}
                    justifyContent="center"
                    alignItems="flex-start"
                    spacing={2}
                    direction="row"
                >
                    <ExpiredLicense />
                </Grid>
            </>
        );
    };

    if (loading) {
        return (
            <Grid
                container
                spacing={2}
                className="vh-100"
                alignContent="center"
                justifyContent="center"
            >
                <Grid item xs={12}>
                    <Backdrop
                        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
                        open={true}
                    >
                        <Loading message="Caricamento applicazione" />
                    </Backdrop>
                </Grid>
            </Grid>
        );
    }

    if (userCtx.roles.includes("Doctor")) {
        return (
            <>
                {userCtx.isValidLicense && (
                    <Grid
                        container
                        p={2}
                       // className="vh-100"
                        rowSpacing={1}
                        columnSpacing={{ xs: 2, sm: 2, md: 2 }}
                        justifyContent="center"
                        alignItems="flex-start"
                        sx={{ height: `calc(100vh - ${headerHeight()}px)` }}
                    >
                        <Grid item container xs={12} md={3} spacing={2} order={{ xs: 2, md: 1 }}>
                            <Grid item xs={12} md={12}>
                                <PatientsTile className="vw-100" />
                            </Grid>
                            <Grid item container xs={12} spacing={2}>
                                <Grid item xs={6} md={6}>
                                    <WarehouseTile />
                                </Grid>
                                <Grid item xs={6} md={6}>
                                    <ReportsTile />
                                </Grid>
                                {/*<Grid item xs={6} md={6}>*/}
                                {/*    <SettingsTile />*/}
                                {/*</Grid>*/}
                                {/*{userCtx.roles.includes("Administrator") &&*/}
                                {/*<>*/}
                                {/*    <Grid item xs={6} md={6}>*/}
                                {/*        <AccountsTile />*/}
                                {/*    </Grid>*/}
                                {/*    <Grid item xs={6} md={6}>*/}
                                {/*        <LicensesTile />*/}
                                {/*    </Grid>*/}
                                {/*</>*/}
                                {/*}*/}
                                <Grid item xs={6} md={6}>
                                    <QuotationsTile />
                                </Grid>
                                <Grid item xs={6} md={6}>
                                    <AddressBookTile />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid
                            item xs={12} md={9}
                         //   className="vh-100"
                            order={{ xs: 1, md: 2 }}
                            sx={{ height: `calc(100vh - ${headerHeight()}px - 10px)` }} >
                           <Agenda />
                        </Grid>
                    </Grid>
                )}
                {!userCtx.isValidLicense && (
                    <Grid container spacing={2} p={2}
                        //className="vh-100"
                        sx={{ height: `calc(100vh - ${headerHeight()}px - 10px)` }}  
                    >
                        {renderWithExpiredLicense()}
                    </Grid>
                )}
            </>
        );
    }

    if (userCtx.roles.includes("Secretary")) {
        return (
            <>
                {userCtx.isValidLicense && (
                    <Grid
                        container
                        p={2}
                        className="vh-100"
                        rowSpacing={1}
                        columnSpacing={{ xs: 2, sm: 2, md: 2 }}
                        justifyContent="center"
                        alignItems="flex-start"
                    >
                        <Grid item container xs={12} md={3} spacing={2}>
                            <Grid item xs={12} md={12}>
                                <PatientsTile className="vw-100" />
                            </Grid>
                            <Grid item container xs={12} spacing={2}>
                                <Grid item xs={6} md={6}>
                                    <WarehouseTile />
                                </Grid>
                                <Grid item xs={6} md={6}>
                                    <ReportsTile />
                                </Grid>
                                {/*<Grid item xs={6} md={6}>*/}
                                {/*    <SettingsTile />*/}
                                {/*</Grid>*/}
                                <Grid item xs={6} md={6}>
                                    <QuotationsTile />
                                </Grid>
                                 <Grid item xs={6} md={6}>
                                    <AddressBookTile />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} md={9} className="vh-100">
                            <Agenda doctor={userCtx.doctor} /> 
                        </Grid>
                    </Grid>
                )},
                {!userCtx.isValidLicense && (
                    <Grid container spacing={2} className="vh-100" p={2}>
                        {renderWithExpiredLicense()}
                    </Grid>
                )}
            </>
        );
    }

    if (userCtx.roles.includes("Administrator")) {
        return (
            <Grid container spacing={4} p={2} className="vh-100">
                <Grid
                    item
                    container
                    xs={12}
                    md={12}
                    justifyContent="center"
                    alignItems="flex-start"
                    spacing={2}
                    direction="row"
                >
                    <Grid item container xs={6} spacing={2}>
                        <Grid item xs={4} md={4}>
                            <SettingsTile />
                        </Grid>
                        <Grid item xs={4} md={4}>
                            <AccountsTile />
                        </Grid>
                        <Grid item xs={4} md={4}>
                            <LicensesTile />
                        </Grid>
                        <Grid item xs={4} md={4}>
                            
                        </Grid>
                        <Grid item xs={4} md={4}>
                        {(!userCtx.license.idSubscription) &&
                                <TrialTile loading={setLoading} />
                        }
                        </Grid>
                      
                    </Grid>
                </Grid>
            </Grid>
        );
    }

    //no roles means first registration
    return (
        <Grid container spacing={2} className="vh-100" p={2}>
            <Welcome />
        </Grid>
    );
};

export default Home;
