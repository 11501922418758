import React, { Fragment, useEffect, useContext, useState } from "react";
import { NavItem, NavLink } from "reactstrap";
import { Link } from "react-router-dom";
import authService from "./AuthorizeService";
import { ApplicationPaths } from "./ApiAuthorizationConstants";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import Avatar from "@mui/material/Avatar";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import LogoutIcon from "@mui/icons-material/Logout";
import Divider from "@mui/material/Divider";
import SettingsMenu from "../Settings/SettingsMenu";
import UserContext from "../UserContext";
import { useHistory } from "react-router-dom";


import WarningExpirationLicense from "../Shared/Licenses/WarningExpirationLicense";
import * as YpatientsConsts from "../Shared/Constants";
import * as YpatientsUtilities from "../Shared/Utilities";

export function LoginMenu() {
    const userCtx = useContext(UserContext);
    const [userName, setUserName] = React.useState("");
    const [isAuthenticated, setIsAuthenticated] = React.useState(false);
    const [subscriptionId, setsubscriptionId] = React.useState(null);

    const populateState = async () => {
        const [isAuthenticated, user] = await Promise.all([
            authService.isAuthenticated(),
            authService.getUser(),
        ]);
        setIsAuthenticated(isAuthenticated);
        if (isAuthenticated) {
            setUserName(user.name);
            userCtx.updateIdUser(user.sub); // Id of AspNetUser table
        }
    };

    useEffect(() => {
        setsubscriptionId(authService.subscribe(() => populateState()));
        populateState();
        return () => authService.unsubscribe(subscriptionId); //returning a function to unsubscribe from the subscription
    }, []);

    const renderMenu = () => {
        if (!isAuthenticated) {
            const registerPath = `${ApplicationPaths.Register}`;
            const loginPath = `${ApplicationPaths.Login}`;
            return anonymousView(registerPath, loginPath);
        } else {
            const profilePath = `${ApplicationPaths.Profile}`;
            const logoutPath = {
                pathname: `${ApplicationPaths.LogOut}`,
                state: { local: true },
            };
            return authenticatedView(userName, profilePath, logoutPath);
        }
    };

    const authenticatedView = (userName, profilePath, logoutPath) => {
        return (
            <AccountMenu
                userName={userName}
                profilePath={profilePath}
                logoutPath={logoutPath}
            />
        );
    };

    const anonymousView = (registerPath, loginPath) => {
        return (
            <Fragment>
                <Stack direction="row" spacing={1}>
                    <NavLink tag={Link} to={registerPath}>
                        Registrazione
                    </NavLink>
                    <Divider orientation="vertical" flexItem p={1} />
                    <NavLink tag={Link} to={loginPath}>
                        Accedi
                    </NavLink>
                </Stack>
            </Fragment>
        );
    };

    return renderMenu();
};


const AccountMenu = (props) => {
    const history = useHistory();
    const userCtx = useContext(UserContext);

    const [endDate, setEndDate] = useState("");

    const checkValidLicenseWithBufferExpiration = (license) => {
        if (license.type === "TRI") {
            return license.expired; // Without buffer
        }

        if (license.expired) {
            let currentdate = new Date(); 
            let days = YpatientsUtilities.DiffTwoDatesInDays(currentdate, license.endDate);
            if (days <= 7) { // With a buffer of 7 days within which the functionalities are still available 
                return false;
            }
        } 

        return license.expired;
    };

    const getMyLicense = async () => {
        const token = await authService.getAccessTokenIfValid();
        const response = await fetch("/api/subscription/info/" + YpatientsConsts.emptyGuid, {
            headers: !token ? {} : { Authorization: "Bearer " + token },
        });

        if (response.ok) {
            const data = await response.json();
            setEndDate(data.endDate);
            userCtx.updateLicense(data);
            const expiredLicense = checkValidLicenseWithBufferExpiration(data);
            userCtx.updateValidLicense(!expiredLicense);
        } else {
            userCtx.updateValidLicense(false);
        }

    };
    

    const getMyRoles = async () => {
        const token = await authService.getAccessTokenIfValid();
            const response = await fetch("/api/user/roles", {
                headers: !token ? {} : { Authorization: "Bearer " + token },
            });

            if (response.redirected) {
                window.location.href = response.url.split("?")[0];  // redirect to login page
            }

            if (response.ok) {
                const data = await response.json();
                userCtx.updateRoles(data);
            }

            if (response.status === 401) {
                window.location.href = "/Account/Login";
                authService.signOut();
            }
    };


    const getDoctorProfile = async () => {
        const token = await authService.getAccessTokenIfValid();
        const getUrl = "/api/user/doctor";
        const response = await fetch(getUrl, {
            headers: !token ? {} : { Authorization: "Bearer " + token }
        });

        if (response.ok) {
            const data = await response.json();
            userCtx.updateDoctor(data);
        }
    };

    const getUserAttributes = async () => {
        const token = await authService.getAccessTokenIfValid();
        const getUrl = "/api/user/properties";
        const response = await fetch(getUrl, {
            headers: !token ? {} : { Authorization: "Bearer " + token }
        });

        if (response.ok) {
            const data = await response.json();
            userCtx.updateIdUser(data.id);
            if (data.idCompany == null) {
                // First login by first user
                userCtx.updateIdCompany(YpatientsConsts.emptyGuid);
            } else {
                userCtx.updateIdCompany(data.idCompany);
            }
        }
    };


    useEffect(() => {
        getMyLicense();
        getMyRoles();
        getDoctorProfile();
        getUserAttributes();
    }, []);

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleLogout = () => {
        handleClose();
        history.push(props.logoutPath);
    };

    return (
        <React.Fragment>
            <Box sx={{ display: "flex", alignItems: "center", textAlign: "center" }}>
                <Tooltip title="Impostazioni">
                    <IconButton
                        onClick={handleClick}
                        size="small"
                        sx={{ ml: 2 }}
                        aria-controls={open ? "account-menu" : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? "true" : undefined}
                    >
                        <Avatar sx={{ width: 32, height: 32 }}>
                            {props.userName.substring(1, 0).toUpperCase()}
                        </Avatar>
                    </IconButton>
                </Tooltip>
                <WarningExpirationLicense endDate={endDate} />
            </Box>
            <Menu
                dense="true"
                anchorEl={anchorEl}
                id="account-menu"
                open={open}
                onClose={handleClose}
                PaperProps={{
                    elevation: 0,
                    sx: {
                        overflow: "visible",
                        filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                        mt: 1.5,
                        "& .MuiAvatar-root": {
                            width: 32,
                            height: 32,
                            ml: -0.5,
                            mr: 1,
                        },
                        "&:before": {
                            content: '""',
                            display: "block",
                            position: "absolute",
                            top: 0,
                            right: 14,
                            width: 10,
                            height: 10,
                            bgcolor: "background.paper",
                            transform: "translateY(-50%) rotate(45deg)",
                            zIndex: 0,
                        },
                    },
                }}
                transformOrigin={{ horizontal: "right", vertical: "top" }}
                anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
            >
                <MenuItem onClick={handleClose}>
                    <Avatar />{" "}
                    <NavLink tag={Link} to={props.profilePath}>
                        {props.userName}
                    </NavLink>
                </MenuItem>
                <Divider />

                <SettingsMenu handleClose={handleClose} />
                <Divider />

                <MenuItem onClick={handleLogout}>
                    <ListItemIcon>
                        <LogoutIcon fontSize="small" />
                    </ListItemIcon>
                    Esci
                </MenuItem>
            </Menu>
        </React.Fragment>
    );
};
