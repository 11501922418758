import React, { useRef, useEffect,useState, useContext } from "react";
import authService from "../../api-authorization/AuthorizeService";
import Avatar from "@mui/material/Avatar";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import Loading from "../Loading";
import List from "@mui/material/List";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemButton from "@mui/material/ListItemButton";
import * as YpatientsConsts from "../Constants";
import { GetDateItalianFormat } from "../Utilities.js";
import Typography from "@mui/material/Typography";
import Paper from '@mui/material/Paper';
import { headerHeight } from "../Utilities.js";


export const AddressBookTile = (props) => {
    const [simplePatients, setSimplePatients] = useState([]);
    const [loading, setLoading] = useState(true);

    const divRef = useRef(null);
    const [elementTop, setElementTop] = useState(0);


    const loadPatients = async (idDoctor) => {
        const token = await authService.getAccessTokenIfValid();
        const path = '/api/patients/doctor/' + idDoctor;
        
        const requestOptions = {
            method: "GET",
            headers: !token ? {} : { Authorization: `Bearer ${token}` },
        };

        fetch(path, requestOptions).then(async (response) => {
            const data = await response.json();
            // check for error response
            if (response.ok && Array.isArray(data)) {
                setSimplePatients(data);
            }
            else {
                setSimplePatients([]);
            }
            setLoading(false);
        });
    };

    const handleSelect = (patient) => { };

    useEffect(() => {
        if (props.idDoctor == undefined || props.idDoctor == YpatientsConsts.emptyGuid) {
            setSimplePatients([])
        }
        else {
            setLoading(true);
            loadPatients(props.idDoctor);
        }

    }, [ props.idDoctor]);

    useEffect(() => {
        const handleResize = () => {
            if (divRef != null && divRef.current != null) {
                const rect = divRef.current.getBoundingClientRect();
                setElementTop(rect.top);
            }
        };

        // Aggiungi un listener per gestire gli eventuali ridimensionamenti della finestra
        window.addEventListener('resize', handleResize);

        // Esegui la funzione handleResize una volta per ottenere la posizione iniziale
        handleResize();

        return () => {
            // Rimuovi il listener al momento del unmount o del cambio di dipendenze
            window.removeEventListener('resize', handleResize);
        };
    }, [simplePatients]);

    
    const handleEmailClick = (email) => {
       
        window.location.href = `mailto:${email}`;
    };


    const renderSpecialRow = (index) => {
        if ( index == 0 ||
            (
                simplePatients[index].surname !== undefined &&
                simplePatients[index - 1].surname !== undefined &&
            simplePatients[index - 1].surname.substring(0, 1) !== simplePatients[index].surname.substring(0, 1)
            )
        ){
            return (
                <Grid item xs={12}>
                    <div>{simplePatients[index].surname !== undefined && simplePatients[index].surname.substring(0, 1)}
                        <hr color="secondary" style={{ margin: '0' }} /></div>
                </Grid>
            );
        }
        return null;
    };

    const renderList = () => {
       
        return (
            <Paper  elevation={0}
               >
            <List
                sx={{
                    position: "relative",
                    overflow: "auto",
                    "& ul": { padding: 0 },
                }}
                dense
            >
                {simplePatients.map((row,index) => (
                    <React.Fragment key={row.idPatient}>
                    
                       { renderSpecialRow(index)}
                        <ListItemButton
                            id={row.idPatient}
                            onClick={() => handleSelect(row)}
                        >
                            <ListItemAvatar>
                                <Avatar>
                                    {row.surname !== undefined && row.surname !== '' ? row.surname.substring(0, 1) : '-'}
                                    {row.name !== undefined && row.name !== '' ? row.name.substring(0, 1) : '-'}
                                </Avatar>
                            </ListItemAvatar>
                            <Grid container spacing={2}>
                                <Grid item xs={6}>
                                    <Typography color="primary" mt={0} variant="h5" style={{ fontSize: '1.2rem' }} >
                                        {row.surname + " " + row.name}
                                    </Typography>
                                    <Typography color="secondary">
                                        {row.birthDate ? GetDateItalianFormat(row.birthDate) : ''}
                                    </Typography>
                                </Grid>
                                <Grid item xs={3}>
                                    <div style={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
                                        <a href={`tel:${row.numeroTelefono}`}>{row.phone}</a></div>
                                </Grid>
                                <Grid item xs={3}>
                                    <div style={{ cursor: 'pointer' }} onClick={() => handleEmailClick(row.email)}>{row.email}</div>
                                </Grid>
                            </Grid>
                        </ListItemButton>
                        <Divider />
                        
                
                    </React.Fragment>
                ))}
                </List>
            </Paper>
        );
    };


    return (
        <Card style={{ width: '100%' }}
            sx={{
                boxShadow: 0,
            }}
        >
            <CardContent
                ref={divRef}
                sx={{
                    height: `calc(100vh - ${elementTop}px - 40px)`,
                    overflow: 'auto'
                }} 
            >
                
                {
                    props.loading ? (
                        <Loading message="Caricamento lista" />
                    ) : (
                        renderList()
                    )
                }
                    
            </CardContent>
        </Card>
    );
};

export default AddressBookTile;