import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import DrugsManager from "../../Warehouse/DrugsManager";

export const AddDrugDialog = (props) => {
  const [open, setOpen] = useState(props.open);
  const [selectedDrugs, setSelectedDrugs] = useState([]);
  

  useEffect(() => {
    setOpen(props.open);
  }, [props.open]);

  const handleClose = () => {
    props.onClose();
    setOpen(false);
  };

  const handleAccept = () => {
    props.answerCallback(selectedDrugs);
    setOpen(false);
    props.onClose();
  };

  const handleReject = () => {
    setSelectedDrugs([]);
    props.answerCallback([]);
    setOpen(false);
    props.onClose();
  };

  const handleDrugSelection = (selectedDrugs) => {
    setSelectedDrugs(selectedDrugs);
  };


  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullWidth={true}
      maxWidth="xl"
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">Aggiungi farmaco</DialogTitle>
      <DialogContent>
        <DrugsManager onSelect={handleDrugSelection} feedbackCallback={props.feedbackCallback}/>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleReject} autoFocus data-testid="cancelButton">
          Annulla
        </Button>
        <Button onClick={handleAccept} data-testid="goButton">
          Conferma
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default AddDrugDialog;
