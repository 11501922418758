import React, { useState, useEffect } from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import authService from "../api-authorization/AuthorizeService";

export const DoctorSelect = (props) => {
    const [doctors, setDoctors] = useState([]);
    const [selectedDoctor, setSelectedDoctor] = useState(props.idDoctor && doctors? {idDoctor: props.idDoctor ?? undefined,
      name: (props.idDoctor && doctors && doctors.filter((d) => d.idDoctor === props.idDoctor)[0] && doctors.filter((d) => d.idDoctor === props.idDoctor)[0].name) ?? undefined,
      surname: (props.idDoctor && doctors && doctors.filter((d) => d.idDoctor === props.idDoctor)[0] && doctors.filter((d) => d.idDoctor === props.idDoctor)[0].surname) ?? undefined,
    }: null);
    const [inputValue, setInputValue] = useState("");

    useEffect(() => {
        if (props.idDoctor !== undefined && props.idDoctor !== null && props.idDoctor !== "") {
           let doctor = doctors.filter((d) => d.idDoctor === props.idDoctor)[0];
           console.log(doctor);
          setSelectedDoctor({...doctor});
        }
    }, [props.idDoctor]);

    useEffect(() => {
      if (props.idDoctor && props.idDoctor !== undefined && doctors.length > 0) {
          //set doctor based on props.idDoctor
          setSelectedDoctor(doctors.find((p) => p.idDoctor === props.idDoctor));
      }
      if ( doctors.length > 0) {
        setSelectedDoctor(doctors[0]);
        props.onDoctorSelected(doctors[0]);
      }
    }, [doctors]);


  const getDoctors = async () => {
    const token = await authService.getAccessTokenIfValid();
    const requestOptions = {
      method: "GET",
      headers: !token
        ? { "Content-Type": "application/json" }
        : {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            Accept: "application/json",
          },
    };
    return fetch("/api/user/doctors", requestOptions).then(async (response) => {
      const data = await response.json();
      // check for error response
      if (!response.ok) {
        // get error message from body or default to response statusText
        const error = (data && data.message) || response.statusText;
        console.log(Promise.reject(error));
        return false;
      } else {
        if (data) {
          setDoctors(data);
        }
        return true;
      }
    });
  };

  useEffect(() => {
    getDoctors();
    }, []);


  return (
    <Autocomplete
    sx={{minWidth: "200px"}}
    size="small"
    required
    disableClearable
    disabled={props.disabled}
    autoComplete
    clearOnEscape
    selectOnFocus
    clearOnBlur
    handleHomeEndKeys
    inputValue={inputValue}
    value={selectedDoctor || null}
    options={doctors}
    onFocus={getDoctors}
    onInputChange={(event, newValue) => {
      setInputValue(newValue);
    }}
    onChange={(event, value) => {
      setSelectedDoctor(value);
      props.onDoctorSelected(value);
    }}
    getOptionLabel={(option) => option.surname + " " + option.name}
    renderOption={(props, option) => (
      <Box
        component="li"
        sx={{ display: "flex", alignItems: "flex-end" }}
        {...props}
        key={option.idDoctor}
      >
        <div className="d-flex justify-content-between w-100">
          <div>
            {option.surname} {option.name}
          </div>
        </div>
      </Box>
    )}
    id="patientSearch"
    renderInput={(params) => (
      <TextField
        size="small"
        data-testid="doctorInputField"
        required={props.required}
        {...params}
        label="Medico"
        variant="filled"
      />
    )}
  />
  );
};

export default DoctorSelect;
