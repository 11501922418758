import React, { useState, useEffect, useRef } from "react";
import moment from "moment";
import ReactAgendaItem from "./ReactAgendaItem";
import classNames from "classnames";
import { guid, getLast, getFirst, mapItems } from "./Helpers.js";

let startSelect;

let endSelect;
let isDragging = false;
let isMouseDown = false;
let draggedElement;
let timeNow = moment();
let draggedItem;
let ctrlKey = false;

let DEFAULT_ITEM = {
  description: "",
  classes: "",
  cellRefs: [],
};

let mouse = {
  x: 0,
  y: 0,
  startX: 0,
  startY: 0,
};
let element = null;
let helper = null;

export const ReactAgenda = (props) => {
  const [date, setDate] = useState(moment());
  const [items, setItems] = useState({});
  const [numberOfDays, setNumberOfDays] = useState(4);
  const [maxDate, setMaxDate] = useState(null);
  const [minDate, setMinDate] = useState(null);
  const [selectedItem, setSelectedItem] = useState(null);

  const column0Ref = useRef(null);
  const cellRefs = useRef([]);
  const columnRefs = useRef([]);
  const hourRefs = useRef([]);
  const agendaScrollContainerRef = useRef();

  /********************/
  /*  Life Cycle      */
  /********************/
  useEffect(() => {
    handleBeforeUpdate(props);
    if (props.autoScale) {
      window.removeEventListener("resize", updateDimensions);
    }
    if (props.locale && props.locale != "en") {
      moment.locale(props.locale);
    }
  }, []);

  useEffect(() => {
    handleBeforeUpdate(props);
  }, [props]);

  useEffect(() => {
    if (props.autoScale) {
      window.addEventListener("resize", updateDimensions);
      updateDimensions();
    }
    document
      .getElementById("agenda-scrollable")
      .scrollTo(
        0,
        props.cellHeight * props.rowsPerHour * (props.autoscrollToHour + 1)
      );
  }, []);

  const updateDimensions = () => {
    let width = Math.round(
      document.getElementById("agenda-scrollable").offsetWidth / 150 - 1
    );
    setNumberOfDays(width);
  };

  /********************/
  /*  Item Renderers  */
  /********************/
  const getHeaderColumns = () => {
    let cols = [];
    for (let i = 0; i < numberOfDays; i++) {
      cols.push(moment(date).add(i, "days").toDate());
    }
    return cols;
  };

  const getBodyRows = () => {
    let rows = [];
    let interval = 60 / props.rowsPerHour;

    if (props.startAtTime && typeof props.startAtTime === "number") {
      for (let i = 0; i < 24 * props.rowsPerHour; i++) {
        if (
          props.endAtTime != 0 &&
          (props.endAtTime - props.startAtTime) * props.rowsPerHour >= i
        ) {
          rows.push(
            moment(date)
              .hours(props.startAtTime)
              .minutes(0)
              .seconds(0)
              .milliseconds(0)
              .add(Math.floor(i * interval), "minutes")
          );
        }
      }
      return rows;
    }

    for (let i = 0; i < 24 * props.rowsPerHour; i++) {
      rows.push(
        moment(date)
          .hours(0)
          .minutes(0)
          .seconds(0)
          .milliseconds(0)
          .add(Math.floor(i * interval), "minutes")
      );
    }
    return rows;
  };

  const getMinuteCells = (rowMoment) => {
    let cells = [];
    for (let i = 0; i < numberOfDays; i++) {
      let cellRef = moment(rowMoment)
        .add(i, "days")
        .format("YYYY-MM-DDTHH:mm:ss");
      cells.push({
        cellRef: cellRef,
        item: items[cellRef] || DEFAULT_ITEM,
      });
    }
    return cells;
  };

  /********************/
  /*  Event Handlers  */
  /********************/
  const handleBeforeUpdate = (props) => {
    if (
      props.hasOwnProperty("startDate") &&
      props.startDate !== date.toDate()
    ) {
      setDate(moment(props.startDate));
    }

    if (props.hasOwnProperty("items")) {
      setItems(mapItems(props.items, props.rowsPerHour, props.timezone));
    }

    if (
      props.hasOwnProperty("numberOfDays") &&
      props.numberOfDays !== numberOfDays &&
      !props.autoScale
    ) {
      setNumberOfDays(props.numberOfDays);
    }

    if (
      props.hasOwnProperty("minDate") &&
      (!minDate || props.minDate !== minDate.toDate())
    ) {
      setMinDate(moment(props.minDate));
    }

    if (
      props.hasOwnProperty("maxDate") &&
      (!maxDate || props.maxDate !== maxDate.toDate())
    ) {
      setMaxDate(moment(props.maxDate));
    }
  };

  const handleOnNextButtonClick = () => {
    let nextStartDate = moment(date).add(numberOfDays, "days");
    if (props.hasOwnProperty("maxDate")) {
      nextStartDate = moment.min(nextStartDate, maxDate);
    }

    let newStart = nextStartDate;
    let newEnd = moment(newStart).add(numberOfDays - 1, "days");

    if (nextStartDate !== date) {
      setDate(nextStartDate);
    }

    if (props.onDateRangeChange) {
      props.onDateRangeChange(
        newStart.startOf("day").toDate(),
        newEnd.endOf("day").toDate()
      );
    }
  };

  const handleOnPrevButtonClick = () => {
    let prevStartDate = moment(date).subtract(numberOfDays, "days");
    if (props.hasOwnProperty("minDate")) {
      prevStartDate = moment.max(prevStartDate, minDate);
    }

    let newStart = prevStartDate;
    let newEnd = moment(newStart).add(numberOfDays - 1, "days");

    if (prevStartDate !== date) {
      setDate(prevStartDate);
    }

    if (props.onDateRangeChange) {
      props.onDateRangeChange(newStart.toDate(), newEnd.toDate());
    }
  };

  const handleMouseClick = (cell, bypass) => {
    if (typeof cell != "string" && cell.tagName) {
      let dt = moment(cell.innerText, ["h:mm A"]).format("HH");
      let old = parseInt(dt);
      let now = new Date();
      let newdate = new Date(
        now.getFullYear(),
        now.getMonth(),
        now.getDate(),
        old + 1,
        0
      );
      let mom = newdate
        .toISOString()
        .substring(0, newdate.toISOString().length - 5);
      if (props.onCellSelect) {
        return props.onCellSelect(mom, bypass);
      }
    }
    if (props.onCellSelect) {
      props.onCellSelect(cell, bypass);
    }
  };

  const setMousePosition = (e) => {
    let ev = e || window.event; //Moz || IE

    mouse.x = ev.pageX;
    mouse.y = ev.pageY;
  };

  const handleMouseOver = (e) => {
    setMousePosition(e);
    if (e.buttons === 0) {
      return false;
    }
    e.preventDefault ? e.preventDefault() : (e.returnValue = false);
    removeSelection();
    if (element) {
      element.style.width = Math.abs(mouse.x - mouse.startX) + "px";
      element.style.height = Math.abs(mouse.y - mouse.startY) + "px";
      element.style.left =
        mouse.x - mouse.startX < 0 ? mouse.x + "px" : mouse.startX + "px";
      element.style.top =
        mouse.y - mouse.startY < 0 ? mouse.y + "px" : mouse.startY + "px";
    }

    if (helper) {
      helper.style.left = mouse.x + "px";
      helper.style.top = mouse.y + "px";
      if (
        e.target.classList.contains("agenda__cell") &&
        !e.target.classList.contains("--time")
      ) {
        let strt = moment(startSelect);
        let endd = moment(e.target.id);

        helper.innerHTML =
          endd.diff(strt) > 0
            ? strt.format("LT") + " -- " + endd.format("LT")
            : endd.format("LT") + " -- " + strt.format("LT");
      }
    }
  };

  const removeSelection = () => {
    let old = document.getElementsByClassName("agenda__cell_selected");

    for (let i = old.length - 1; i >= 0; --i) {
      if (old[i]) {
        old[i].classList.remove("agenda__cell_selected");
      }
    }
  };

  const handleAllClickStarts = (e, n) => {
    isMouseDown = true;

    removeSelection();
    if (
      e.target.classList.contains("--time") ||
      (e.target.classList.contains("--time-now") && !isDragging)
    ) {
      return handleMouseClick(e.target);
    }

    if (
      e.target.classList.contains("agenda__cell") &&
      !e.target.classList.contains("--time") &&
      !isDragging
    ) {
      removeSelection();
      e.target.classList.toggle("agenda__cell_selected");
      startSelect = e.target.id;
      if (e.buttons === 0) {
        return false;
      }
      handleMouseClick(e.target.id);
      mouse.startX = mouse.x;
      mouse.startY = mouse.y;
      element = document.createElement("div");
      element.className = "rectangle";
      element.style.left = mouse.x + "px";
      element.style.top = mouse.y + "px";
      document.body.appendChild(element);

      if (props.helper) {
        helper = document.createElement("div");
        helper.className = "helper-reactangle";
        document.body.appendChild(helper);
      }
    }
  };

  const handleAllClickEnds = (e, n) => {
    //  e.preventDefault ? e.preventDefault() : e.returnValue = false
    isMouseDown = false;
    isDragging = false;

    endSelect = e.target.id;

    let old = document.getElementsByClassName("rectangle");
    let old2 = document.getElementsByClassName("helper-reactangle");

    for (let i = old.length - 1; i >= 0; --i) {
      if (old[i]) {
        old[i].remove();
      }
    }

    for (let i = old2.length - 1; i >= 0; --i) {
      if (old2[i]) {
        old2[i].remove();
      }
    }
    element = null;
    helper = null;

    if (startSelect && endSelect && startSelect != endSelect) {
      return getSelection(startSelect, endSelect);
    }
  };

  /**************** ****/
  /*  Drag Handlers   */
  /*******************/

  const onDragStart = (e) => {
    isDragging = true;
    isMouseDown = false;
    draggedItem = e.target.id;
    e.dataTransfer.setData("text/html", e.target);
    e.dataTransfer.dropEffect = "move";
    e.dataTransfer.setDragImage(e.target, 0, 0);
  };

  const onDragEnter = (e) => {
    e.preventDefault();
    if (!isDragging) {
      removeSelection();
    }
    e.dataTransfer.dropEffect = "move";
    if (e.ctrlKey) {
      e.dataTransfer.effectAllowed = "copy";
      e.dataTransfer.dropEffect = "copy";
    }
  };

  const onDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();

    if (e.target.id === draggedElement) {
      return false;
    }

    if (e.ctrlKey) {
      e.dataTransfer.effectAllowed = "copy";
      ctrlKey = true;
    } else {
      e.dataTransfer.dropEffect = "move";
    }

    if (e.target.classList.contains("cell-item")) {
      return (draggedElement = e.target.parentNode.parentNode.id);
    }

    if (e.target.classList.contains("handler")) {
      return (draggedElement = e.target.parentNode.id);
    }
    if (e.target.classList.contains("dragDiv")) {
      return (draggedElement = e.target.parentNode.id);
    }

    draggedElement = e.target.id;
  };

  const dragEvent = (id, d) => {
    if (!props.onChangeEvent) {
      return;
    }
    let date = d;
    let itm;
    if (!cellRefs.current[d]) {
      return;
    }
    if (cellRefs.current[d].tagName !== "TD") {
      // when user drag and drop an event into another we assign parent id
      date = cellRefs.current[d].parentNode.id;
    }
    let items = [...props.items];
    if (id && date && items) {
      for (let i in items) {
        if (items[i].idAppointment === id) {
          let start = moment(items[i].startTime);
          let end = moment(items[i].endTime);
          let duration = moment.duration(end.diff(start));
          let newdate = moment(date).subtract(
            duration % (60 / props.rowsPerHour)
          );
          let newEnddate = moment(newdate).add(duration);
          items[i].startTime = new Date(newdate);
          items[i].endTime = new Date(newEnddate);
          itm = items[i];
          break;
        }
      }
      props.onChangeEvent(items, itm);
    }
  };

  const duplicateEvent = (id, d) => {
    let date = d;
    let itm;
    let oldItm;
    if (!cellRefs.current[d]) {
      return;
    }
    if (cellRefs.current[d].tagName !== "TD") {
      // when user drag and drop an event into another we assign parent id
      date = cellRefs.current[d].parentNode.id;
    }
    let items = props.items;
    if (id && date && items) {
      for (let i in items) {
        if (items[i].idAppointment === id) {
          itm = Object.assign({}, items[i], { idAppointment: guid() });
          let start = moment(itm.startTime);
          let end = moment(itm.endTime);
          let duration = moment.duration(end.diff(start));
          let newdate = moment(date);
          let newEnddate = moment(newdate).add(duration);
          itm.startTime = new Date(newdate);
          itm.endTime = new Date(newEnddate);
          items.push(itm);
          if (props.onChangeEvent) {
            props.onChangeEvent(items, itm);
          }
          break;
        }
      }
    }
  };

  const resizeEvent = (id, date) => {
    if (!props.onChangeDuration) {
      return;
    }

    let items = [...props.items];
    if (id && date && items) {
      for (let i in items) {
        if (items[i].idAppointment === id) {
          let difference = new Date(date) - new Date(items[i].startTime);
          if (difference < 1) {
            let strt = new Date(items[i].startTime);
            items[i].endTime = new Date(
              strt.getFullYear(),
              strt.getMonth(),
              strt.getDate(),
              strt.getHours(),
              strt.getMinutes() + 15,
              0
            );
            setItems(items);
            return props.onChangeDuration(items, items[i]);
          }
          let newdate = moment(date);
          items[i].endTime = new Date(newdate);
          return props.onChangeDuration(items, items[i]);
        }
      }
    }
  };

  const onDragEnd = (e) => {
    let newDate = draggedElement;

    if (ctrlKey) {
      duplicateEvent(e.target.id, newDate);
    } else {
      dragEvent(e.target.id, newDate);
    }
    isDragging = false;
    isMouseDown = false;
    ctrlKey = false;
    draggedElement = "";
    draggedItem = "";
  };

  const onDragHandlerStart = (e) => {
    isDragging = true;
    e.dataTransfer.setData("text/html", e.target);
    e.dataTransfer.dropEffect = "move";
    //e.dataTransfer.setData("text/html", e.target);
    e.dataTransfer.effectAllowed = "all";
  };

  const onDragHandlerEnd = (e, n) => {
    if (typeof draggedElement === undefined || draggedElement === "") {
      return;
    }
    let item = e.target.id || e.target.offsetParent.id;

    if (
      cellRefs.current[draggedElement] &&
      cellRefs.current[e.target.id] &&
      cellRefs.current[e.target.id].tagName === "DIV" &&
      cellRefs.current[draggedElement].tagName === "DIV"
    ) {
      //detect if we are resizing an event
      item = e.target.id;
      draggedElement = cellRefs.current[draggedElement].parentNode.id;
      return resizeEvent(item, draggedElement);
    }

    if (
      draggedElement === "" &&
      !cellRefs.current[draggedElement] &&
      cellRefs.current[e.target.id].tagName === "DIV"
    ) {
      // when user drag and drop an event into another we assign parent id
      draggedElement = cellRefs.current[e.target.id].parentNode.id;
      return;
    }

    if (!cellRefs.current[draggedElement] && draggedElement) {
      //detect if we are dragging an event from its description panel (item component)
      let old = document.getElementById(draggedElement);
      draggedElement = old.parentNode.id;
    }

    resizeEvent(item, draggedElement);

    isDragging = false;
    isMouseDown = false;
    draggedElement = "";
  };

  /**************************/
  /*  selection Handlers   */
  /************************/

  const getSelection = (start, end) => {
    let strt = moment(start);
    let endd = moment(end);
    let arr = endd.diff(strt) > 0 ? [start, end] : [end, start];

    props.onRangeSelection(arr);
  };

  /***************************/
  /*  EVENTS MODIFiCATION   */
  /*************************/

  const editEvent = (item) => {
    if (props.onItemEdit) {
      props.onItemEdit(item, true);
    }
  };

  const removeEvent = (item) => {
    let items = props.items;
    let newItems = items.filter(function (el) {
      return el.idAppointment !== item.idAppointment;
    });
    if (props.onItemRemove) {
      props.onItemRemove(newItems, item);
    }
  };

  const renderHeaderColumns = (col, i) => {
    let headerLabel = moment(col);
    headerLabel.locale(props.locale);
    return (
      <th
        ref={(el) => (columnRefs.current[i + 1] = el)}
        key={"col-" + i}
        className="agenda__cell --head"
      >
        {props.headFormat
          ? headerLabel.format(props.headFormat)
          : headerLabel.format("dddd DD MMM YY")}
      </th>
    );
  };

  const renderBodyRows = (row, i) => {
    if (i % props.rowsPerHour === 0) {
      let timeLabel = moment(row);
      let differ = timeLabel.diff(timeNow, "minutes");

      timeLabel.locale(props.locale);
      return (
        <tr
          key={"row-" + i}
          ref={(el) =>
            (hourRefs.current[Math.floor(i / props.rowsPerHour)] = el)
          }
          draggable={false}
          className="agenda__row   --hour-start"
        >
          <td
            className={
              differ <= 60 && differ >= 0
                ? "disable-select agenda__cell --time-now"
                : "disable-select agenda__cell --time"
            }
            rowSpan={props.rowsPerHour}
          >
            {timeLabel.format("LT")}
          </td>
          {getMinuteCells(row).map(renderMinuteCells)}
        </tr>
      );
    } else {
      return (
        <tr key={"row-" + i}>{getMinuteCells(row).map(renderMinuteCells)}</tr>
      );
    }
  };


  let Colors = props.itemColors;

  let ItemComponent = props.itemComponent
    ? props.itemComponent
    : ReactAgendaItem;

  let renderItemCells = function (cell, i) {
    let cellClasses = {
      agenda__cell: true,
    };
    cell["item"].forEach(function (itm) {
      cellClasses[itm.classes] = true;
    });

    let classSet = classNames(cellClasses);

    let splt = classSet.split(" ");

    splt = splt.filter((i) => !i.includes("agenda__cell"));
    splt = splt.filter((i) => !i.includes("undefined"));

    let nwsplt = [];
    splt.forEach(function (value) {
      if (value.length > 0) {
        nwsplt.push(Colors[value]);
      }
    });

    let styles = {
      height: props.cellHeight + "px",
    };
    if (splt.length > 1) {
      if (nwsplt[1] === nwsplt[2]) {
        nwsplt.splice(1, 0, "rgb(255,255,255)");
      }
      nwsplt = nwsplt.join(" , ");
      styles = {
        height: props.cellHeight + "px",
      };
    }

    let itemElement = cell.item.map(function (item, idx) {
      let last1 = getLast(item.cellRefs);
      let first1 = getFirst(item.cellRefs);

      let ItemWrapperStyle = {
        marginTop:
          -props.cellHeight / 2 +
          (props.cellHeight *
            (item.startTime.getTime() - new Date(cell.cellRef).getTime())) /
            1800000 +
          "px",
      };

      if (first1 === cell.cellRef) {
        return (
          <div
            id={item.idAppointment}
            ref={(elem) => (cellRefs.current[cell.cellRef] = elem)}
            key={idx}
            className="dragDiv"
            onDragStart={onDragStart}
            onDragEnd={onDragEnd}
            draggable="true"
            style={ItemWrapperStyle}
          >
            <ItemComponent
              item={item}
              parent={cell.cellRef}
              itemColors={Colors}
              cellHeight={props.cellHeight}
              backgroundColor={Colors[item.classes]}
              selected={selectedItem === item.idAppointment}
              setSelected={setSelectedItem}
              edit={props.onItemEdit ? editEvent : null}
              remove={props.onItemRemove ? removeEvent : null}
              days={props.numberOfDays}
              itemsInCell={cell.item.length}
              itemIndex={idx}
            />
          </div>
        );
      }

      return "";
    });

    return (
      <td
        ref={(el) => (cellRefs.current[cell.cellRef] = el)}
        key={"cell-" + i}
        className={classSet}
        style={styles}
        id={cell.cellRef}
      >
        {itemElement}
      </td>
    );
  };

  let renderMinuteCells = function (cell, i) {
    if (cell.item[0] && !cell.item.idAppointment) {
      return renderItemCells(cell, i);
    }

    let cellClasses = {
      agenda__cell: true,
    };

    cellClasses[cell.item.classes] = true;
    if (cell.item.cellRefs) {
      var last = getLast(cell.item.cellRefs); // this is var because it has to be global
      var first = getFirst(cell.item.cellRefs); // this is var because it has to be global
    }

    let classSet = classNames(cellClasses);

    let splt = classSet.split(" ");
    splt = splt.filter((i) => !i.includes("agenda__cell"));
    splt = splt.filter((i) => !i.includes("undefined"));
    let nwsplt = [];
    splt.forEach(function (value) {
      if (value.length > 0) {
        nwsplt.push(Colors[value]);
      }
    });

    let styles = {
      height: props.cellHeight + "px",
    };

    let backgroundColor;
    if (splt.length > 1) {
      nwsplt = nwsplt.join(" , ");
      styles = {
        // background: "linear-gradient(to left," + nwsplt + ")",
        height: props.cellHeight + "px",
        border: "0px",
      };
      backgroundColor = nwsplt[0];
    }

    if (splt.length === 1) {
      styles = {
        //background: backgroundColor,
        height: props.cellHeight + "px",
      };
      backgroundColor = nwsplt[0];
    }

    return (
      <td
        ref={(el) => (cellRefs.current[cell.cellRef] = el)}
        key={"cell-" + i}
        className={classSet}
        style={styles}
        id={cell.cellRef}
      >
        {first === cell.cellRef ? (
          <div
            id={cell.item.idAppointment}
            ref={(el) => (cellRefs.current[cell.item.idAppointment] = el)}
            className="dragDiv 844"
            onDragStart={onDragStart}
            onDragEnd={onDragEnd}
            draggable="true"
            style={{
              marginTop:
                -props.cellHeight / 2 +
                (props.cellHeight *
                  (cell.item.startTime.getTime() -
                    new Date(cell.cellRef).getTime())) /
                  1800000 +
                "px",
            }}
          >
            {first === cell.cellRef ? (
              <ItemComponent
                item={cell.item}
                parent={cell.cellRef}
                itemColors={Colors}
                cellHeight={props.cellHeight}
                selected={selectedItem === cell.item.idAppointment}
                setSelected={setSelectedItem}
                backgroundColor={backgroundColor}
                edit={props.onItemEdit ? editEvent : null}
                remove={props.onItemRemove ? removeEvent : null}
                days={props.numberOfDays}
                itemsInCell={1}
                itemIndex={0}
              />
            ) : (
              ""
            )}
          </div>
        ) : (
          ""
        )}

      </td>
    );
  };

  const disablePrev = function (minDate) {
    if (!minDate) {
      return false;
    }
    return date.toDate().getTime() === minDate.toDate().getTime();
  };

  const disableNext = function (maxDate) {
    if (!maxDate) {
      return false;
    }

    return date.toDate().getTime() === maxDate.toDate().getTime();
  };

  return (
    <div className="agenda_wrapper">
      <div className="agenda__table --header">
        <table>
          <thead>
            <tr>
              <th ref={column0Ref} className="agenda__cell --controls">
                <div className="agenda-controls-layout">
                  <button
                    className={
                      "agenda__prev" +
                      (disablePrev(minDate) ? " --disabled" : "")
                    }
                    onClick={handleOnPrevButtonClick}
                  ></button>
                  <button
                    className={
                      "agenda__next" +
                      (disableNext(maxDate) ? " --disabled" : "")
                    }
                    onClick={handleOnNextButtonClick}
                  ></button>
                </div>
              </th>
              {getHeaderColumns(props.view).map(renderHeaderColumns, this)}
            </tr>
          </thead>
        </table>
      </div>
      <div className="agenda">
        <div
          id="agenda-scrollable"
          ref={agendaScrollContainerRef}
          className="agenda__table --body"
          style={{
            position: "relative",
          }}
        >
          <table cellSpacing="0" cellPadding="0">
            <tbody
              onMouseDown={handleAllClickStarts}
              onTouchStart={handleAllClickStarts}
              onDragEnter={onDragEnter}
              onDragOver={onDragOver}
              onMouseUp={handleAllClickEnds}
              onMouseOver={handleMouseOver}
            >
              {getBodyRows().map(renderBodyRows, this)}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default ReactAgenda;
