import authService from "../api-authorization/AuthorizeService";
import * as YpatientsConsts from "./Constants";

export const StartTrial = async (userCtx) => {
    try {
        if (userCtx.idCompany == YpatientsConsts.emptyGuid) {
            //create empty company
            userCtx.idCompany = await createEmptyCompany();
        }
        if (userCtx.doctor.idDoctor == '') {
            //create empty doctor
            userCtx.doctor.idDoctor = await createEmptyDoctor(userCtx.idUser, userCtx.idCompany);
        }
        const missingTrial = await checkTrial();
        if (missingTrial) {
            await addTrial();
        }
        //add subscription to user
        await addTrialToUser(userCtx);

        
        return true;
    } catch(error) {
        console.log(error);
        return false;
    }
};

const createEmptyCompany = async () => {
    const companyData = {
        idCompany: YpatientsConsts.emptyGuid,
        companyName: "",
        address: "",
        zipCode: "",
        city: "",
        province: "",
        country: "",
        vatNumber: "",
        fiscalCode: "",
        email: "",
        pec: "",
        healthCenter: false,
        einvoicingCode: "",
        iban: "",
        swift: ""
    };
    const token = await authService.getAccessTokenIfValid();
    let postUrl = "/api/company/new";

    const response = await fetch(postUrl, {
        method: 'POST',
        body: JSON.stringify(companyData),
        headers: !token ? {} : {
            Authorization: `Bearer ${token}`, 'Content-Type': 'application/json'
        }
    });

    if (response.ok) {
        const data = await response.json();
        if (data) {
            return data;
        }
    } else {
        const error = await response.text();
        console.error(error);
    }
}

const createEmptyDoctor = async (idUser, idCompany) => {
    const emptyDoctor = {
        idDoctor: "",
        idCompany: idCompany,
        title: "",
        name: "",
        surname: "",
        address: "",
        zipCode: "",
        city: "",
        province: "",
        country: "",
        number: "",
        fiscalCode: "",
        email: "",
        pec: "",
        phone: "",
        mobile: "",
        iban: "",
        swift: ""
    };

    let token = await authService.getAccessTokenIfValid();
    let path = "/api/user/doctor/new/" + idUser;

    const requestOptions = {
        method: "POST",
        headers: !token
            ? { "Content-Type": "application/json" }
            : {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
            },
        body: JSON.stringify(emptyDoctor),
    };
    const response = await fetch(path, requestOptions);
    const data = await response.json();
    // check for error response
    if (!response.ok) {
        // get error message from body or default to response statusText
        const error = (data && data.message) || response.statusText;
        console.error(error);
    } else {
        if (data) {
            return data.idDoctor;
        }
    }

};

const checkTrial = async () => {
    let result = false;
    const token = await authService.getAccessTokenIfValid();
    const response = await fetch("/api/subscription/checktrial", {
        headers: !token ? {} : { Authorization: `Bearer ${token}` },
    });


    if (response.ok) {
        const data = await response.json();
        result = data;

    } else {
        const error = await response.text();
        console.error(error);
    }
    return result;
};

const addTrial = async () => {
    let result = false;
    const token = await authService.getAccessTokenIfValid();
    const response = await fetch("/api/subscription/addtrial", {
        headers: !token ? {} : { Authorization: `Bearer ${token}` },
    });

    if (response.ok) {
        const data = await response.json();
        result = data;
    }
    else {
        const error = await response.text();
        console.error(error);
    }
    return result;
};

const addTrialToUser = async (userCtx) => {
    const user =
    {
        id: userCtx.idUser,
        idSubscription: undefined,
        subscriptionEndDate: undefined,
        email: userCtx.email,
        phoneNumber: userCtx.phoneNumber,
        emailConfirmed: userCtx.emailConfirmed,
        roles: ["Administrator", "Doctor"],
    };

    const token = await authService.getAccessTokenIfValid();
    const path = "/api/subscription/assign/TRI";

    const requestOptions = {
        method: "POST",
        headers: !token
            ? { "Content-Type": "application/json" }
            : {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
            },
        body: JSON.stringify(user),
    };

    const response = await fetch(path, requestOptions);
    if (response.ok) {
        const data = await response.json();
        if (data.id === userCtx.idUser) {
            userCtx.updateRoles(data.roles);
            userCtx.updateValidLicense(true);
        }
    }
    else {
        const error = await response.text();
        console.error(error);
    }
};


