import React, { useEffect, useState } from "react";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Divider from "@mui/material/Divider";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { Grid } from "@mui/material";
import Loading from "../Shared/Loading";
import Button from "@mui/material/Button";
import Avatar from "@mui/material/Avatar";
import TextSnippetIcon from '@mui/icons-material/TextSnippet';

export const QuotationsList = (props) => {

    const [quotations, setQuotations] = useState(props.quotations);

    const handleEditRequestQuotation = (requestQuotation) => {
        props.EditQuotation(requestQuotation);
    };

    const handleNewRequestQuotation = () => {
        props.NewQuotation();
    };

    useEffect(() => {
        setQuotations(props.quotations);

    }, [props.quotations, props.loading]);


    //cleanup state on unmount
    useEffect(() => {
        return () => {
            setQuotations([]);
        };
    }, []);


    const renderList = () => {
       
        return (
            <>
                <List
                    sx={{
                        position: "relative",
                        overflow: "auto",
                        "& ul": { padding: 0 },
                    }}
                    dense
                >
                    {quotations.map((row) => (
                        <React.Fragment key={row.idQuotation}>
                            <ListItemButton
                                id={row.idQuotation}
                                onClick={() => handleEditRequestQuotation(row)}
                            >
                                <ListItemAvatar>
                                    <Avatar>
                                        <TextSnippetIcon />
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText
                                    primary={row.preview}
                                    secondary={new Date(row.date).toLocaleDateString("it-IT")}
                                />
                            </ListItemButton>
                            <Divider />
                        </React.Fragment>
                    ))}
                </List>
            </>
        );
    };


    return (
        <Card style={{ width: '100%' }}
            sx={{
                boxShadow: 0,
            }}
        >
            <CardContent>
                <Grid container spacing={2} >
                    
                    <Grid item xs={12} md={10}  >
                    {
                        props.loading ? (
                            <Loading message="Caricamento lista" />
                        ) : (
                            renderList()
                        )
                    }
                    </Grid>
                    <Grid item xs={12} md={2} >
                        <div className="w-100 d-flex justify-content-end pt-3 pe-1">
                            <Button data-testid="Add-Visit" onClick={handleNewRequestQuotation} variant="contained" >Nuovo Preventivo</Button>
                        </div>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
};

export default QuotationsList;
