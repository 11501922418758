import React from "react";
import { useHistory } from "react-router-dom";
import Button from "@mui/material/Button";
import ContactPhoneIcon from '@mui/icons-material/ContactPhone';
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";


export const AddressBookTile = (props) => {

    const handleButtonClick = () => {
        GoToAddressBook();
    };

    const history = useHistory();

    const GoToAddressBook = () => {
        history.push({
            pathname: "/reports/addressbook",
        });
    };

    return (

        <Card>
            <CardHeader
                title="Rubrica"
                data-testid="addressBookTile-title"
                titleTypographyProps={{
                    variant: "body1",
                }}
            />
            <CardContent>
                <Button fullWidth data-testid="addressBookButton" onClick={handleButtonClick}>
                    <ContactPhoneIcon fontSize="large" sx={{ fontSize: "80px" }} />

                </Button>
            </CardContent>
        </Card>

    );
};

export default AddressBookTile;