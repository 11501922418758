import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import BadgeIcon from '@mui/icons-material/Badge';
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Button from "@mui/material/Button";


export const LicensesTile = () => {
    const history = useHistory();

    const GoToLicenses = (event) => {
        event.preventDefault();
        history.push({
            pathname: "/Account/settings/subscription",
        });
    };

    return (
        <Card>
            <CardHeader
                title="Licenze"
                titleTypographyProps={{
                    variant: "body1",
                }}
            />
            <CardContent>
                <Button fullWidth data-testid="licensesButton" onClick={GoToLicenses}>
                    <BadgeIcon fontSize="large" sx={{ fontSize: "80px" }} />
                </Button>
            </CardContent>
        </Card>
    );
};

export default LicensesTile;