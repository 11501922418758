import React from "react";


export const PatientContext = React.createContext(
    {
        idPatient: "",
        name: "",
        surname: "",
        address: "",
        zipCode: "",
        city: "",
        province: "",
        country: "",
        birthDate: "",
        birthPlace: "",
        gender: "",
        fiscalCode: "",
        healthCard: "",
        familyDoctor: "",
        email: "",
        phone: "",
        mobile: "",
    }
);