import React, { useEffect, useState, useContext } from "react";
import List from "@mui/material/List";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Pagination from "@mui/material/Pagination";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import CopyIcon from "@mui/icons-material/ContentCopy";
import AddIcon from "@mui/icons-material/Add";
import PdfIcon from "@mui/icons-material/PictureAsPdf";
import ViewIcon from "@mui/icons-material/Visibility";
import { IsMasterDoctor } from "./DocumentUtil.js";
import { Grid } from "@mui/material";
import { UserContext } from "../../UserContext";
import Loading from "../Loading";
import Tooltip from "@mui/material/Tooltip";

export const DocumentsList = (props) => {
    const userCtx = useContext(UserContext);
    const pageSize = 5;
    const [documentPage, setDocumentPage] = useState(1);
    const [masterDocumentPage, setMasterDocumentPage] = useState(1);
    const [documents, setDocuments] = useState(props.documents);
    const [masterDocuments, setMasterDocuments] = useState(props.masterDocuments);

    const handleDocumentPageChange = (event, value) => {
        setDocumentPage(value);
    };

    const handleMasterDocumentPageChange = (event, value) => {
        setMasterDocumentPage(value);
    };

    const handleDeleteRequestDocument = (requestDocument) => {
        props.onDeleteRequestDocument(requestDocument);
    };
    const handleEditRequestDocument = (requestDocument) => {
        props.onEditRequestDocument(requestDocument);
    };

    const handleDuplicateRequestDocument = (requestDocument) => {
        props.onDuplicateRequestDocument(requestDocument);
    };

    const handlePdfRequestDocument = (requestDocument) => {
        props.onPdfRequestDocument(requestDocument);
    };

    useEffect(() => {
        setDocuments(props.documents);
        setMasterDocuments(props.masterDocuments);

    }, [props.documents, props.masterDocuments]);



    //cleanup state on unmount
    useEffect(() => {
        return () => {
            setDocuments([]);
            setDocumentPage(1);
            setMasterDocumentPage(1);
        };
    }, []);

    const isEditable = (row) => {
        return !!row && (!row.isMaster || IsMasterDoctor(userCtx.doctor.idDoctor));
    };

    const renderTable = (documentsInTheList, page, setPage, enableAdd) => {
        let documentsToShow = [];
        if (documentsInTheList.length > pageSize) {
            const firstPageIndex = (page - 1) * pageSize;
            const lastPageIndex = firstPageIndex + pageSize;
            documentsToShow = documentsInTheList.slice(firstPageIndex, lastPageIndex);
        } else {
            documentsToShow = [...documentsInTheList];
        }
        return (
            <>
                <TableContainer>
                    <Table sx={{ minWidth: 300 }} size="small" aria-label="table of documents">
                        <TableBody>
                            {documentsToShow.map((row) => (
                                <TableRow
                                    key={row.idDocumentTemplate}
                                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                                >
                                    <TableCell align="left">{row.title}</TableCell>

                                    <TableCell align="right">
                                        {isEditable(row) && (
                                            <Tooltip title="modifica">
                                                <IconButton
                                                    data-testid="btn-edit"
                                                    aria-label="modifica"
                                                    onClick={() => handleEditRequestDocument(row)}
                                                    sx={{ padding: "4px" }}
                                                >
                                                    <EditIcon />
                                                </IconButton>
                                            </Tooltip>
                                        )}
                                        {!isEditable(row) && (
                                            <Tooltip title="visualizza">
                                                <IconButton
                                                    data-testid="btn-view"
                                                    aria-label="visualizza"
                                                    onClick={() => handleEditRequestDocument(row)}
                                                    sx={{ padding: "4px" }}
                                                >
                                                    <ViewIcon />
                                                </IconButton>
                                            </Tooltip>
                                        )}
                                        <Tooltip title="duplica">
                                            <IconButton
                                                data-testid="btn-duplica"
                                                aria-label="duplica"
                                                onClick={() => handleDuplicateRequestDocument(row)}
                                                sx={{ padding: "4px" }}
                                            >
                                                <CopyIcon />
                                            </IconButton>
                                        </Tooltip>
                                        <Tooltip title="download pdf">
                                            <IconButton
                                                data-testid="btn-pdf"
                                                aria-label="pdf"
                                                onClick={() => handlePdfRequestDocument(row)}
                                                sx={{ padding: "4px" }}
                                            >
                                                <PdfIcon />
                                            </IconButton>
                                        </Tooltip>
                                        {isEditable(row) && (
                                            <Tooltip title="elimina">
                                                <IconButton
                                                    data-testid="btn-delete"
                                                    aria-label="elimina"
                                                    onClick={() => handleDeleteRequestDocument(row)}
                                                    sx={{ padding: "4px" }}
                                                >
                                                    <DeleteIcon />
                                                </IconButton>
                                            </Tooltip>
                                        )}
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                {documentsInTheList.length > pageSize && (
                    <Pagination
                        count={
                            (documentsInTheList.length === 0 ? 1 : 0) +
                            Math.floor(documentsInTheList.length / pageSize) +
                            (documentsInTheList.length % pageSize === 0 ? 0 : 1)
                        }
                        defaultPage={1}
                        onChange={setPage}
                    />
                )}
            </>
        );
    };


    return (
        <Card
            sx={{
                boxShadow: 0,
            }}
        >
            <CardContent>

                <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                        <Typography gutterBottom variant="subtitle3" component="div">
                            Template Personali
                            <Tooltip title="aggiungi nuovo">
                                <IconButton
                                    data-testid="btn-add"
                                    aria-label="add"
                                    onClick={() => handleEditRequestDocument({
                                        idDocumentTemplate: undefined,
                                        idDoctor: userCtx.doctor.idDoctor,
                                        documentTemplateType: 1,
                                        title: "",
                                        body: "",
                                        isMaster: false,
                                    })}
                                    sx={{ padding: "8px 8px 0 -8px", float: "right" }}
                                    color="inherit"
                                >
                                    <AddIcon />
                                </IconButton>
                            </Tooltip>
                        </Typography>
                        {
                            props.loading ? (
                                <Loading message="Caricamento template" />
                            ) : (
                                renderTable(
                                props.documents,
                                documentPage,
                                handleDocumentPageChange, true
                                )
                            )
                        }
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Typography gutterBottom variant="subtitle3" component="div">
                            Documenti Master
                        </Typography>
                        {renderTable(
                            props.masterDocuments,
                            masterDocumentPage,
                            handleMasterDocumentPageChange, false
                        )}
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
};


export default DocumentsList;
