import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import QuoteIcon from '@mui/icons-material/RequestQuoteOutlined';
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Button from "@mui/material/Button";


export const QuotationsTile = () => {
    const history = useHistory();

    const GoToQuotations = (event) => {
        event.preventDefault();
        history.push({
            pathname: "/quotation",
        });
    };

    return (
        <Card>
            <CardHeader
                title="Preventivo"
                titleTypographyProps={{
                    variant: "body1",
                }}
            />
            <CardContent>
                <Button fullWidth data-testid="quotationsButton" onClick={GoToQuotations}>
                    <QuoteIcon fontSize="large" sx={{ fontSize: "80px" }} />
                </Button>
            </CardContent>
        </Card>
    );
};

export default QuotationsTile;