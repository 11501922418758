import React, { useState, useEffect, useContext } from "react";
import Grid from "@mui/material/Grid";
import JoditEditor, { Jodit } from "jodit-react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CopyIcon from "@mui/icons-material/ContentCopy";
import PdfIcon from "@mui/icons-material/PictureAsPdf";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { ThemeContext } from "../../../theme";
import { IsMasterDoctor, EDITOR_PLACE_OLDER, PLACE_OLDER } from "./DocumentUtil.js";
import { UserContext } from "../../UserContext";
import Tooltip from "@mui/material/Tooltip";

function preparePaste(jodit) {
    jodit.e.on(
        "paste",
        (e) => {
            jodit.e.stopPropagation("paste");
            jodit.s.insertHTML(
                Jodit.modules.Helpers.getDataTransfer(e)
                    .getData(Jodit.constants.TEXT_HTML)
            );
            return false;
        },
        { top: true }
    );
}
Jodit.plugins.add("preparePaste", preparePaste);




export const DocumentEdit = (props) => {
    const userCtx = useContext(UserContext);
    const [light, setLight] = useContext(ThemeContext);
    const [document, setDocument] = useState({
        idDocumentTemplate: undefined,
        idDoctor: undefined,
        documentTemplateType: undefined,
        title: "",
        body: "",
        isMaster: false,
    });
    const [inputValue, setInputValue] = useState("");

    const [config, setConfig] = useState({
        readonly: false,
        toolbar: true,
    });

    useEffect(() => {
        setDocument(props.document);
        setInputValue(props.document.title);
    }, [props.document, props.loading]);

    useEffect(() => {
        return () => {
            setDocument({
                idDocumentTemplate: undefined,
                idDoctor: undefined,
                documentTemplateType: undefined,
                title: "",
                body: "",
                isMaster: false,
            });
            setInputValue("");
        };
    }, []);

    const handleCreateRequestDocument = (requestDocument) => {
        props.onCreateRequestDocument(requestDocument);
    };

    const handleUpdateRequestDocument = (requestDocument) => {
        props.onUpdateRequestDocument(requestDocument);
    };

    const handleDeleteRequestDocument = (requestDocument) => {
        props.onDeleteRequestDocument(requestDocument);
    };

    const handleDuplicateRequestDocument = (requestDocument) => {
        props.onDuplicateRequestDocument(requestDocument);
    };

    const handlePdfRequestDocument = (requestDocument) => {
        props.onPdfRequestDocument(requestDocument);
    };

    const handleChange = (event) => {
        if (event.target) {
            const { id, value } = event.target;
            setInputValue(value);
        }
    };

    const handleChangeCheck = (event) => {
        if (event.target) {
            const { id, checked } = event.target;
            setDocument({ ...document, [id]: checked });
            document.isMaster = checked;
            saveDocument();
        }
    };

    const handleBlur = async (id, event) => {
        setDocument({ ...document, title: inputValue });
        document.title = inputValue;
        saveDocument();
    };

    const handleWYSIWYGBlur = (newTextAreaValue) => {
        document.body = newTextAreaValue;
        setDocument({ ...document, body: newTextAreaValue });
        saveDocument();
    };

    const saveDocument = () => {
        if (
            document.title === "" ||
            document.title === undefined ||
            document.title === null
        ) {
            props.feedbackCallback(
                "warning",
                "Inserire un nome documento per procedere al salvataggio",
                true
            );
            return;
        }
        if (
            document.idDocumentTemplate === undefined ||
            document.idDocumentTemplate === ""
        ) {
            handleCreateRequestDocument(document);
        } else {
            handleUpdateRequestDocument(document);
        }
    };

    const isEditable = () => {
        return (
            !!document &&
            (!document.isMaster || IsMasterDoctor(userCtx.doctor.idDoctor))
        );
    };

    const renderEditor = () => {

       

        if (document !== undefined && document.title !== undefined) {
            return (
                <div>
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            {
                                <TextField
                                    disabled={!isEditable()}
                                    size="small"
                                    id="title"
                                    fullWidth
                                    label="Nome del documento"
                                    variant="filled"
                                    value={inputValue || ""}
                                    onChange={handleChange}
                                    name="title"
                                    onBlur={handleBlur}
                                />
                            }
                        </Grid>
                        <Grid item xs={2}>
                            {IsMasterDoctor(userCtx.doctor.idDoctor) && (
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={document && document.isMaster}
                                            onChange={handleChangeCheck}
                                            data-testid="isMaster"
                                            id="isMaster"
                                        />
                                    }
                                    label="Template Master"
                                />
                            )}
                        </Grid>
                        <Grid
                            item
                            container
                            direction="row"
                            xs={4}
                            justifyContent="flex-end"
                        >
                            <Tooltip title="duplica">
                                <IconButton
                                    data-testid="btn-edit-duplica"
                                    aria-label="duplica"
                                    onClick={() => handleDuplicateRequestDocument(document)}
                                >
                                    <CopyIcon />
                                </IconButton>
                            </Tooltip>
                             <Tooltip title="download pdf">
                                <IconButton
                                    data-testid="btn-edit-pdf"
                                    aria-label="pdf"
                                    onClick={() => handlePdfRequestDocument(document)}
                                >
                                    <PdfIcon />
                                </IconButton>
                            </Tooltip>
                            {isEditable() && (

                                <Tooltip title="elimina">
                                    <IconButton
                                        data-testid="btn-edit-elimina"
                                        aria-label="elimina"
                                        onClick={() => handleDeleteRequestDocument(document)}
                                    >
                                        <DeleteIcon />
                                    </IconButton>
                                </Tooltip>
                            )}
                            <Tooltip title="torna alla lista">
                                <IconButton
                                    data-testid="btn-edit-back"
                                    aria-label="back"
                                    onClick={props.onBackToDocumentsList}
                                >
                                    <ArrowBackIcon />
                                </IconButton>
                            </Tooltip>
                        </Grid>
                    </Grid>
                    <br />

                    <JoditEditor
                        visible={document !== undefined && document.title !== ""}
                        config={{
                            ...config,
                            toolbar: isEditable(),
                            readonly: !isEditable(),
                            theme: light ? "light" : "dark",
                            removeButtons: ['image',
                                'video',
                                'table',
                                'link',],
                            extraButtons: [{
                                iconURL: '/images/head-side-medical.jpg',
                                        name: 'Place Holder',
                                        tooltip: 'Insert Place HOlder',
                                        list:  EDITOR_PLACE_OLDER,
                                        exec: (editor, _, { control }) => {
                                              let value = control.args && control.args[0];
                                              editor.s.insertHTML(PLACE_OLDER[value]);
                                        }
                                    }
                                ],
                        }
                        }
                        onBlur={handleWYSIWYGBlur}
                        value={(document && document.body) || ""}
                        id="body"
                       
                    />

                    <br />
                </div>
            );
        } else {
            return <div></div>;
        }
    };
    return (
        <Card
            sx={{
                boxShadow: 0,
            }}
        >
            <CardContent>
                <Typography gutterBottom variant="subtitle3" component="div">
                    Modifica Documento
                </Typography>
                {renderEditor()}
            </CardContent>
        </Card>
    );
};

export default DocumentEdit;
