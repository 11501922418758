// @flow
import React, { useRef, useState, useEffect, useContext } from "react";
import ReactAgenda from "../Shared/Agenda/ReactAgenda";
import ReactAgendaCtrl from "../Shared/Agenda/ReactAgendaCtrl";
import { getMonday } from "../Shared/Agenda/Helpers";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import authService from "../api-authorization/AuthorizeService";
import ZoomInIcon from "@mui/icons-material/ZoomIn";
import ZoomOutIcon from "@mui/icons-material/ZoomOut";
import EventNoteIcon from "@mui/icons-material/EventNote";
import IconButton from "@mui/material/IconButton";
import ClearIcon from "@mui/icons-material/Clear";
import Divider from "@mui/material/Divider";
import Button from "@mui/material/Button";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import SearchIcon from "@mui/icons-material/Search";
import InputAdornment from "@mui/material/InputAdornment";
import InputLabel from "@mui/material/InputLabel";
import FilledInput from "@mui/material/FilledInput";
import Popper from "@mui/material/Popper";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Grid from "@mui/material/Grid";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { ThemeContext } from "../../theme";
import useMediaQuery from "@mui/material/useMediaQuery";
import "../Shared/Agenda/style.css";
import UserContext from "../UserContext";
import DoctorSelect from "../Shared/DoctorSelect";

require("moment/locale/it.js");

export const Agenda = (props) => {
  const userCtx = useContext(UserContext);
  const [items, setItems] = useState([]);
  const [selected, setSelected] = useState([]);
  const [cellHeight, setCellHeight] = useState(40);
  const [startDate, setStartDate] = useState(getMonday(new Date()));
  const [showModal, setShowModal] = useState(false);
  const [locale, setLocale] = useState("it");
  const [rowsPerHour, setRowsPerHour] = useState(2);
  const [numberOfDays, setNumberOfDays] = useState(7);
  const [searchFilter, setSearchFilter] = useState("");
  const [light, setLight] = useContext(ThemeContext);
  const [searchResults, setSearchResults] = useState([]);
  const [searchAnchorEl, setSearchAnchorEl] = useState(null);
  const [timeNow, setTimeNow] = useState(new Date());
  const [doctors, setDoctors] = useState([]);
  const [selectedDoctor, setSelectedDoctor] = useState({idDoctor: "" });

  const [searchOpen, setSearchOpen] = useState(false);
  const desktop = useMediaQuery("(min-width:1200px)");

    const divRef = useRef(null);
    const [elementTop, setElementTop] = useState(0);

  let colors = {};

  light
    ? (colors = {
        "color-1": "rgba(112, 214, 255,1)",
        "color-2": "rgba(255, 112, 166,1)",
        "color-3": "rgba(255, 151, 112,1)",
        "color-4": "rgba(255, 214, 112,1)",
        "color-5": "rgba(233, 255, 112,1)",
      })
    : (colors = {
        "color-1": "rgba(238, 99, 82,1)",
        "color-2": "rgba(89, 205, 144,1)",
        "color-3": "rgba(63, 167, 214,1)",
        "color-4": "rgba(250, 192, 94,1)",
        "color-5": "rgba(247, 157, 132,1)",
      });

    //change numberOfDays displayed based on monitor size
    useEffect(() => {
      if(!desktop) setNumberOfDays(1);
    }
    ,[desktop]);

  const formatInputData = (data) => {
    let dataArray = data;
    data.forEach((item, index) => {
      dataArray[index].startTime = new Date(item.startTime + "Z"); // Z is for intepreting UTC time
      dataArray[index].endTime = new Date(item.endTime + "Z"); // Z is for intepreting UTC time
      dataArray[index].classes = item.style;
    });
    setItems(dataArray);
  };

  const formatOutputData = (item) => {
    const outputItem = {
      idAppointment: item.idAppointment,
      idPatient: item.idPatient || "",
      idDoctor: item.idDoctor || "",
      startTime: item.startTime.toUTCString(),
      endTime: item.endTime.toUTCString(),
      style: item.classes,
      description: item.description,
      name: item.name || "",
      surname: item.surname || "",
    };
    return outputItem;
  };

  //update timeNow every 5 seconds
  useEffect(() => {
    const interval = setInterval(() => {
      setTimeNow(new Date());
    }, 5000);
    return () => clearInterval(interval);
  }, []);

  const getItems = async () => {
    const token = await authService.getAccessTokenIfValid();
    let startRequestDate = new Date();
    startRequestDate.setTime(
      startDate.getTime() - numberOfDays * 24 * 60 * 60 * 1000 * 2
    ); // 2 periods before
    let endRequestDate = new Date();
    endRequestDate.setTime(
      startDate.getTime() + numberOfDays * 24 * 60 * 60 * 1000 * 2
    ); // 2 periods after
    const response = await fetch(
      `/api/appointment/from/${startRequestDate.toUTCString()}/to/${endRequestDate.toUTCString()}`,
      {
        headers: !token ? {} : { Authorization: `Bearer ${token}` },
      }
    );
    const data = await response.json();
    formatInputData(data);
  };

  useEffect(() => {
    //get appointments at startup
      getItems();

      const handleResize = () => {
          if (divRef != null && divRef.current != null) {

              const rect = divRef.current.getBoundingClientRect();
              setElementTop(rect.top);

          }
      };
      // Aggiungi un listener per gestire gli eventuali ridimensionamenti della finestra
      window.addEventListener('resize', handleResize);

      // Esegui la funzione handleResize una volta per ottenere la posizione iniziale
      handleResize();

      return () => {
          window.removeEventListener('resize', handleResize);
      }
  }, []);

  useEffect(() => {
    //get appointments at change of date
    getItems();
  }, [startDate]);

  const searchAppointments = async () => {
    setSearchOpen(true);
    const token = await authService.getAccessTokenIfValid();
    const response = await fetch(`/api/appointment/search/${searchFilter}`, {
      headers: !token ? {} : { Authorization: `Bearer ${token}` },
    });
    if (response.status === 200 && response.ok) {
      const data = await response.json();
      setSearchResults(data);
    } else {
      setSearchResults([]);
      setSearchOpen(false);
      //TODO: show error message
    }
  };

  const handleSearchClick = (event) => {
    setSearchAnchorEl(document.getElementById("search-input"));
    //setSearchAnchorEl(event.currentTarget.parentNode.parentNode);
    searchAppointments();
  };

  const goToAppontment = (startTime) => {
    handleViewChange(startTime);
    closeSearch();
  };

  const closeSearch = () => {
    setSearchOpen(false);
    setSearchAnchorEl(null);
  };

  const handleItemEdit = (item, openModal) => {
    if (item && openModal === true) {
      setSelected([item]);
      return _openModal();
    }
  };

  const handleCellSelection = (item, openModal) => {
    if (selected && selected[0] === item) {
      return _openModal();
    }
    setSelected([item]);
  };

  const zoomIn = () => {
    var num = cellHeight + 15;
    setCellHeight(num);
  };
  const zoomOut = () => {
    var num = cellHeight - 15;
    if (num > 0) {
      setCellHeight(num);
    }
  };

  const handleViewChange = (date) => {
    switch (numberOfDays) {
      case 1:
        setStartDate(date);
        break;
      case 7:
        setStartDate(getMonday(date));
        break;
      case 5:
        date.setDate(date.getDate() + 2);
        setStartDate(getMonday(date));
        break;
      case 30:
        date.setDate(date.getDate() + 2);
        setStartDate(new Date(date.getFullYear(), date.getMonth(), 1));
        break;
      default:
        setStartDate(date);
    }
  };

  const handleDateRangeChange = (startDate, endDate) => {
    handleViewChange(startDate);
  };

  const handleRangeSelection = (selected) => {
    setSelected(selected);
    _openModal();
  };

  const _openModal = () => {
    setShowModal(true);
  };

  const _closeModal = (e) => {
    if (e) {
      e.stopPropagation();
      e.preventDefault();
    }
    setShowModal(false);
  };

  const searchFilterChange = (event) => {
    setSearchFilter(event.target.value);
  };

  const handleItemChange = async (items, item) => {
    const outputItem = formatOutputData(item);
    if (await updateDB(outputItem, "/api/appointment/", false)) {
      setItems(items);
    } //TODO: else error handling
  };

  const handleItemSize = async (items, item) => {
    const outputItem = formatOutputData(item);
    if (await updateDB(outputItem, "/api/appointment/", false)) {
      setItems(items);
    } //TODO: else error handling
  };

  const removeEvent = async (items, item) => {
    let result = await updateDB(
      item.idAppointment,
      "/api/appointment/delete",
      true
    );
    if (result) {
      setItems(items);
    } //TODO: else error handling
  };

  const addNewEvent = async (items, newItem) => {
    const outputItem = formatOutputData(newItem);
    const idAppointment = await updateDB(
      outputItem,
      "/api/appointment/new",
      false
    );
    if (idAppointment) {
      let itemIndex = items.findIndex(
        (item) => item.idAppointment === newItem.idAppointment
      );
      items[itemIndex].idAppointment = idAppointment;
      setItems(items);
      setShowModal(false);
      setSelected([]);
    } //TODO: else error handling
    _closeModal();
  };

  const editEvent = async (items, item) => {
    const outputItem = formatOutputData(item);
    if (await updateDB(outputItem, "/api/appointment/", false)) {
      setItems(items);
      setShowModal(false);
      setSelected([]);
    } //TODO: else error handling
    _closeModal();
  };

  const updateDB = async (dataToBeTrans, path, isDelete) => {
    const token = await authService.getAccessTokenIfValid();
    const requestOptions = {
      method: "POST",
      headers: !token
        ? { "Content-Type": "application/json" }
        : {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            Accept: "application/json",
          },
      body: JSON.stringify(dataToBeTrans),
    };
    return fetch(path, requestOptions).then(async (response) => {
      const data = await response.json();
      // check for error response
      if (!response.ok) {
        // get error message from body or default to response statusText
        const error = (data && data.message) || response.statusText;
        console.log(Promise.reject(error));
        return false;
      } else {
        if (data && !isDelete) {
          return data.idAppointment;
        }
        return true;
      }
    });
  };

  const changeView = (event) => {
    switch (event.target.value) {
      case 1:
        break;
      case 7:
        setStartDate(getMonday(startDate));
        break;
      case 30:
        setStartDate(
          new Date(startDate.getFullYear(), startDate.getMonth(), 1)
        );
        break;
      case 5:
        setStartDate(getMonday(startDate));
        break;
      default:
        break;
    }
    setNumberOfDays(event.target.value);
  };

  const searchKeyPressed = (event) => {
    if (event.key === "Enter") {
      handleSearchClick(event);
    }
  };

 


  const renderSearchResults = () => {
    if (searchResults.length > 0) {
      return (
        <ClickAwayListener onClickAway={closeSearch}>
          <Card>
            <List dense disablePadding>
              {searchResults.map((resultItem) => (
                <ListItem
                  button
                  key={resultItem.idAppointment}
                  onClick={() => goToAppontment(new Date(resultItem.startTime))}
                >
                  <ListItemText
                    primary={
                      resultItem.description +
                      " " +
                      (resultItem.name ?? "") +
                      " " +
                      (resultItem.surname ?? "")
                    }
                    secondary={new Date(
                      resultItem.startTime
                    ).toLocaleDateString(locale, {
                      weekday: "long",
                      year: "numeric",
                      month: "long",
                      day: "numeric",
                    })}
                  />
                </ListItem>
              ))}
            </List>
          </Card>
        </ClickAwayListener>
      );
    }
  };

  return (
    <div className="content-expanded">
      <Card>
        <CardHeader
          subheader={
            <Typography variant="body1" color="text.primary" textAlign="center">
              {timeNow.toLocaleDateString(locale, {
                weekday: "long",
                year: "numeric",
                month: "long",
                day: "numeric",
              })}{" "}
              - {timeNow.getHours()}:
              {(timeNow.getMinutes() < 10 ? "0" : "") + timeNow.getMinutes()}
            </Typography>
          }
        />
              <CardContent
                  ref={divRef}
                  sx={{
                      height: `calc(100vh - ${elementTop}px)`
                  }}
              >
          <Grid
            container
            spacing={2}
            justifyContent="space-between"
            className="control-header"
            pt={0}
          >
            <Grid className="control-search" pt={0} item xs={12} sm={4} md={4}>
              <FormControl sx={{ m: 1, width: "25ch" }} variant="filled">
                <InputLabel htmlFor="filled-adornment-search">Cerca</InputLabel>
                <FilledInput
                  size="small"
                  id="search-input"
                  value={searchFilter}
                  onChange={searchFilterChange}
                  onKeyPress={searchKeyPressed}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        color="primary"
                        aria-label="search-button"
                        onClick={() => setSearchFilter("")}
                        edge="end"
                      >
                        <ClearIcon fontSize="small" />
                        <Divider orientation="vertical" flexItem />
                      </IconButton>
                      <IconButton
                        aria-label="search-button"
                        onClick={handleSearchClick}
                        edge="end"
                      >
                        <SearchIcon />
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </FormControl>
              <Popper
                id="search-popper"
                open={searchOpen}
                anchorEl={searchAnchorEl}
                placement="bottom-start"
              >
                {renderSearchResults()}
              </Popper>
            </Grid>
            <Grid
              className="control-doctor-select"
              item
              container
              pt={0}
              xs={12}
              sm={12}
              md={4}
              xl={4}
              justifyContent="center"
            >
              {userCtx.roles && userCtx.roles.indexOf("Secretary") !== -1 && props.doctor && (
                <Grid item xs={12} sm={12} md={6} xl={6} sx={{ m: 1, width: "25ch" }}>
                  <p style={{textAlign: "center", marginTop: "10px"}}>{props.doctor.title? props.doctor.title : ""} {props.doctor.name? props.doctor.name: ""} {props.doctor.surname? props.doctor.surname: ""}</p>
                </Grid>
              )}
            </Grid>

            <Grid
              className="control-buttons"
              pt={0}
              item
              xs={12}
              sm={12}
              md={4}
            >
              <Button onClick={() => handleViewChange(new Date())}>Oggi</Button>
              <Tooltip title="Zoom In">
                <IconButton onClick={zoomIn}>
                  <ZoomInIcon className="button-icon" />
                </IconButton>
              </Tooltip>
              <Tooltip title="Zoom Out">
                <IconButton onClick={zoomOut}>
                  <ZoomOutIcon className="button-icon" />
                </IconButton>
              </Tooltip>
              <Tooltip title="Aggiungi Appuntamento">
                <IconButton onClick={_openModal}>
                  <EventNoteIcon className="button-icon" />
                </IconButton>
              </Tooltip>
              <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
                <Select
                  labelId="demo-simple-select-standard-label"
                  id="demo-simple-select-standard"
                  value={numberOfDays}
                  onChange={changeView}
                >
                  <MenuItem value={7}>Settimana</MenuItem>
                  <MenuItem value={5}>Lavorativi</MenuItem>
                  <MenuItem value={1}>Giorno</MenuItem>
                  <MenuItem value={30}>Mese</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>

          <ReactAgenda
            startDate={startDate}
            startAtTime={0}
            endAtTime={23}
            cellHeight={cellHeight}
            timezone="Europe/Rome"
            locale="it"
            items={(selectedDoctor === null || selectedDoctor === undefined || selectedDoctor.idDoctor === "") ?  items: items.filter(item => item.idDoctor === selectedDoctor.idDoctor)}
            numberOfDays={numberOfDays}
            headFormat={"ddd DD MMM"}
            rowsPerHour={rowsPerHour}
            autoscrollToHour={8}
            itemColors={colors}
            helper={true}
            view="calendar"
            autoScale={false}
            fixedHeader={true}
            onRangeSelection={handleRangeSelection}
            onChangeEvent={handleItemChange}
            onChangeDuration={handleItemSize}
            onItemEdit={handleItemEdit}
            onCellSelect={handleCellSelection}
            onItemRemove={removeEvent}
            onDateRangeChange={handleDateRangeChange}
          />
        </CardContent>
      </Card>
      {showModal ? (
        <Dialog open={showModal} onClose={_closeModal}>
          <DialogContent>
            <ReactAgendaCtrl
              selectedIdDoctor={(selectedDoctor && selectedDoctor.idDoctor)? selectedDoctor.idDoctor : ""}
              items={items}
              itemColors={colors}
              selectedCells={selected}
              Addnew={addNewEvent}
              edit={editEvent}
              allowDoctorSelection={userCtx.roles && userCtx.roles.indexOf("Secretary") !== -1}
            />
          </DialogContent>
        </Dialog>
      ) : (
        ""
      )}
    </div>
  );
};

export default Agenda;
