import React, { useState } from "react";

export const UserContext = React.createContext({
    doctor: {
        idDoctor: "",
        idCompany: "",
        title: "",
        name: "",
        surname: "",
        address: "",
        zipCode: "",
        city: "",
        province: "",
        country: "",
        number: "",
        fiscalCode: "",
        email: "",
        pec: "",
        phone: "",
        mobile: "",
        iban: "",
        swift: ""
    },
    updateDoctor: () => { },
    roles: [],
    updateRoles: () => { },
    license: {
        idSubscription: "",
        idCompany: "",
        type: "",
        startDate: "",
        endDate: "",
        expired: false,
        status: 1
    },
    updateLicense: () => { },
    isValidLicense: false,
    updateValidLicense: () => { },
    idUser: "",
    updateIdUser: () => { },
    idCompany: "",
    updateIdCompany: () => { },
    isFirstLogin: true,
    updateIsFirstLogin: () => { },
});

export const UserContextProvider = (props) => {
    const [doctor, setDoctor] = useState({
        idDoctor: "",
        idCompany: "",
        title: "",
        name: "",
        surname: "",
        address: "",
        zipCode: "",
        city: "",
        province: "",
        country: "",
        number: "",
        fiscalCode: "",
        email: "",
        pec: "",
        phone: "",
        mobile: "",
        iban: "",
        swift: ""
    });

    const [roles, setRoles] = useState([]);

    const [license, setLicense] = useState({
        idSubscription: "",
        idCompany: "",
        type: "",
        startDate: "",
        endDate: "",
        expired: false,
        status: 1
    });

    const [isValidLicense, setIsValidLicense] = useState(false);
    const [idUser, setIdUser] = useState("");
    const [idCompany, setIdCompany] = useState("");
    const [isFirstLogin, setIsFirstLogin] = useState(true);

    const UpdateDoctor = (doctor) => {
        setDoctor(doctor);
    };

    const UpdateRoles = (roles) => {
        setRoles(roles);
    };

    const UpdateLicense = (license) => {
        setLicense(license);
    };

    const UpdateValidLicense = (status) => {
        setIsValidLicense(status);
    };

    const UpdateIdUser = (id) => {
        setIdUser(id);
    }

    const UpdateIdCompany = (id) => {
        setIdCompany(id);
    }

    const UpdateIsFirstLogin = (isFirst) => {
        setIsFirstLogin(isFirst);
    }

    return (
        <UserContext.Provider value={{
            doctor: doctor,
            updateDoctor: UpdateDoctor,
            roles: roles,
            updateRoles: UpdateRoles,
            license: license,
            updateLicense: UpdateLicense,
            isValidLicense: isValidLicense,
            updateValidLicense: UpdateValidLicense,
            idUser: idUser,
            updateIdUser: UpdateIdUser,
            idCompany: idCompany,
            updateIdCompany: UpdateIdCompany,
            isFirstLogin: isFirstLogin,
            updateIsFirstLogin: UpdateIsFirstLogin
        }}>
            {props.children}
        </UserContext.Provider>
    );
};

export default UserContext;
