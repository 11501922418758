import React, { useEffect, useState, useContext } from "react";
import authService from "../../api-authorization/AuthorizeService";
import { UserContext } from "../../UserContext";
import Loading from "../../Shared/Loading";
import ConfirmDialog from "../../Shared/ConfirmDialog";

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";

import * as YpatientsConsts from "../../Shared/Constants";
import * as YpatientsUtilities from "../../Shared/Utilities";

import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";

import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';

import Switch from "@mui/material/Switch";
import Stack from "@mui/material/Stack";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';

import { Tooltip } from '@mui/material';
import { IconButton } from "@mui/material";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import DeleteIcon from "@mui/icons-material/Delete";
import PostAddIcon from '@mui/icons-material/PostAdd';
import DownloadIcon from '@mui/icons-material/Download';
import VaccinesIcon from '@mui/icons-material/Vaccines';
import GradingIcon from '@mui/icons-material/Grading';
import ForwardToInboxIcon from '@mui/icons-material/ForwardToInbox';
import Divider from '@mui/material/Divider';
import { createTheme } from '@mui/material/styles';
import { downloadPatientInvoice, sendPatientInvoice } from "./InvoiceManager.js";
import InvoiceRow from "./InvoiceRow";



export const Invoice = (props) => {
    const userCtx = useContext(UserContext);
    const [patient, setPatient] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [listTaxRates, setListTaxRates] = useState([]);
    const [listPaymentMode, setListPaymentMode] = useState([]);
    const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
    const [confirmPublishDialogOpen, setConfirmPublishDialogOpen] = useState(false);
    const [useDoctor, setUseDoctor] = useState(true);
    const [lastPublishingDate, setLastPublishingDate] = useState("");
    const [openCheckDialog, setOpenCheckDialog] = useState(false);
    const [checkInvoiceResult, setCheckInvoiceResult] = useState("");
    const [treatmentsToBeInvoiced, setTreatmentsToBeInvoiced] = useState([]);

    const [companyData, setCompanyData] = useState({
        idCompany: YpatientsConsts.emptyGuid,
        companyName: "",
        address: "",
        zipCode: "",
        city: "",
        province: "",
        country: "",
        vatNumber: "",
        fiscalCode: "",
        email: "",
        pec: "",
        healthCenter: false,
        einvoicingCode: "",
        iban: "",
        swift: ""
    });


    const handleOpenCheckDialog = () => {
        setOpenCheckDialog(true);
    };

    const handleCloseCheckDialog = () => {
        setOpenCheckDialog(false);
    };

    const theme = createTheme();


    const [invoice, setInvoice] = useState({
        idPatientInvoice: "",
        idCompany: "",
        idVisit: "",
        idPatient: "",
        patientName: "",
        patientSurname: "",
        patientFiscalCode: "",
        patientAddress: "",
        patientCity: "",
        patientZipCode: "",
        patientEmail: "",
        number: "",
        date: "",
        dueDate: "",
        amount: "",
        taxes: "",
        total: "",
        paid: false,
        paidDate: "",
        idPaymentMode: "",
        draft: true
    });


    const [invoiceRows, setInvoiceRows] = useState([]);

    useEffect(() => {
        setPatient(
            {
                idPatient: invoice.idPatient,
                name: invoice.patientName,
                surname: invoice.patientSurname,
                fiscalCode: invoice.patientFiscalCode,
                address: invoice.patientAddress,
                city: invoice.patientCity,
                zipCode: invoice.patientZipCode
            }
        );
    }, [invoice]);

    const LoadTaxRates = async () => {
        const token = await authService.getAccessTokenIfValid();
        const getUrl = "/api/patientinvoices/taxrates";
        const response = await fetch(getUrl, {
            headers: !token ? {} : { Authorization: `Bearer ${token}` }
        });

        if (response.ok) {
            const data = await response.json();
            if (data) {
                setListTaxRates(data);
            } else {
                props.feedbackCallback("error", "Errore durante la lettura delle aliquote IVA", true);
            }
        } else {
            const error = await response.text();
            props.feedbackCallback("error", error, true);
        }
    };

    const LoadPaymentMode = async () => {
        const token = await authService.getAccessTokenIfValid();
        const getUrl = "/api/patientinvoices/paymentmode";
        const response = await fetch(getUrl, {
            headers: !token ? {} : { Authorization: `Bearer ${token}` }
        });

        if (response.ok) {
            const data = await response.json();
            if (data) {
                setListPaymentMode(data);
            } else {
                props.feedbackCallback("error", "Errore durante la lettura dei metodi di pagamento", true);
            }
        } else {
            const error = await response.text();
            props.feedbackCallback("error", error, true);
        }

    };

    const loadCompany = async () => {
        const token = await authService.getAccessTokenIfValid();
        const response = await fetch("/api/company", {
            headers: !token ? {} : { Authorization: `Bearer ${token}` },
        });

        if (response.ok) {
            const data = await response.json();
            if (data) {
                setCompanyData(data);
            } else {
                props.feedbackCallback("error", "Errore durante la lettura delle informazioni sulla società", true);
            }
        } else {
            const error = await response.text();
            props.feedbackCallback("error", error, true);
        }
    };

    const LoadInvoice = async (idPatientInvoice, loadRows) => {
        const token = await authService.getAccessTokenIfValid();
        const getUrl = "/api/patientinvoices/invoice/" + idPatientInvoice;
        const response = await fetch(getUrl, {
            headers: !token ? {} : { Authorization: `Bearer ${token}` }
        });

        if (response.ok) {
            const data = await response.json();
            if (data) {
                setInvoice(data);
                if (loadRows) {
                    LoadInvoiceRows(idPatientInvoice);
                }
            } else {
                props.feedbackCallback("error", "Errore durante la lettura delle fatture", true);
            }
        } else {
            const error = await response.text();
            props.feedbackCallback("error", error, true);
        }
    };

    const LoadLastPublishingDate = async () => {
        const token = await authService.getAccessTokenIfValid();
        const getUrl = "/api/patientinvoices/invoice/lastpublishingdate/" + userCtx.idCompany;
        const response = await fetch(getUrl, {
            headers: !token ? {} : { Authorization: `Bearer ${token}` }
        });

        if (response.ok) {
            const data = await response.json();
            if (data) {
                setLastPublishingDate(data);
            } else {
                props.feedbackCallback("error", "Errore durante la lettura dei parametri di fatturazione (data di pubblicazione)", true);
            }
        } else {
            const error = await response.text();
            props.feedbackCallback("error", error, true);
        }
    };

    const EvaluateMinDate = () => {
        if (!invoice.draft) {
            return new Date(invoice.date);
        }

        return new Date(lastPublishingDate);


    };

    const CheckValidInvoice = () => {
        let isValid = true;
        let message = "";
        if ((new Date(invoice.date) < new Date(lastPublishingDate)) || (invoice.date === "")) {
            isValid = false;
            message += "Data di emissione fattura non valida; selezionare una data corretta."
        }

        if ((invoice.dueDate === null) || (new Date(invoice.dueDate) < new Date(invoice.date)) || (invoice.dueDate === "")) {
            isValid = false;
            message += "Data di scadenza fattura non valida; selezionare una data coerente con la data di emissione."
        }

        if ((invoice.idPaymentMode === null) || (invoice.idPaymentMode === "") || (invoice.idPaymentMode === undefined)) {
            isValid = false;
            message += "Impostare il metodo di pagamento."
        }

        if (invoice.amount === "") {
            isValid = false;
            message += "Importo della fattura non valido, immettere almeno una riga.";
        }

        if (invoiceRows.length === 0) {
            isValid = false;
            message += "Corpo fattura non presente, immettere almeno una riga.";
        }

        if (!isValid) {
            setCheckInvoiceResult(message);
        }

        return isValid;

    };

    const LoadInvoiceRows = async (idPatientInvoice) => {
        const token = await authService.getAccessTokenIfValid();
        const getUrl = "/api/patientinvoices/invoice/rows/" + idPatientInvoice;
        const response = await fetch(getUrl, {
            headers: !token ? {} : { Authorization: `Bearer ${token}` }
        });

        if (response.ok) {
            const data = await response.json();
            if (data) {
        
                setInvoiceRows(data);
            } else {
                props.feedbackCallback("error", "Errore durante la lettura delle righe della fattura", true);
            }
        } else {
            const error = await response.text();
            props.feedbackCallback("error", error, true);
        }
    };


    const UpdateInvoice = async (postData) => {
        const token = await authService.getAccessTokenIfValid();
        let postUrl = "/api/patientinvoices/invoice/update";

        const response = await fetch(postUrl, {
            method: 'POST',
            body: JSON.stringify(postData),
            headers: !token ? {} : {
                Authorization: `Bearer ${token}`, 'Content-Type': 'application/json'
            }
        });

        if (response.ok) {
            const data = await response.json();
            if (data) {
                props.feedbackCallback("success", "Dati salvati con successo", true);
                LoadInvoice(invoice.idPatientInvoice);
            }
        } else {
            const error = await response.text();
            props.feedbackCallback("error", error, true);
        }
    }

    const DeleteInvoice = async () => {
        const token = await authService.getAccessTokenIfValid();
        const getUrl = '/api/patientinvoices/invoice/delete/' + invoice.idPatientInvoice;
        const response = await fetch(getUrl, {
            headers: !token ? {} : { Authorization: `Bearer ${token}` }
        });

        if (response.ok) {
            const data = await response.json();
            if (data) {
                props.goToListCallback();
            } else {
                props.feedbackCallback("error", "Errore durante l'eliminazione della fattura", true);
            }
        } else {
            const error = await response.text();
            props.feedbackCallback("error", error, true);
        }
    };

    const PublishInvoice = async () => {
        const token = await authService.getAccessTokenIfValid();
        let postUrl = "/api/patientinvoices/invoice/publish";
        const response = await fetch(postUrl, {
            method: 'POST',
            body: JSON.stringify(invoice),
            headers: !token ? {} : {
                Authorization: `Bearer ${token}`, 'Content-Type': 'application/json'
            }
        });

        if (response.ok) {
            const data = await response.json();
            if (data) {
                props.feedbackCallback("success", "Dati salvati con successo", true);
                props.goToListCallback();
            }
        } else {
            const error = await response.text();
            props.feedbackCallback("error", error, true);
        }
    }

    

    const getCurrentCompanyData = async () => {
        let data;
        const token = await authService.getAccessTokenIfValid();


        const response = await fetch("/api/company", {
            headers: !token ? {} : { Authorization: `Bearer ${token}` },
        });

        if (response.ok) {
            data = await response.json();
        } else {
            const error = await response.text();
            props.feedbackCallback("error", error, true);
        }
        return data;
    }

 
    const sendInvoiceCallBack = (result) => {
        if (result) {
            props.feedbackCallback("success", "Fattura inviata con successo", true);
        }
        else {
            props.feedbackCallback("error", "Fattura non inviata ", true);
        }
    }


    const LoadElements = async () => {
        if (props.idPatientInvoice !== undefined && props.idPatientInvoice !== YpatientsConsts.emptyGuid) {
            setIsLoading(true);
            await loadCompany();
            await LoadPaymentMode();
            await LoadTaxRates();
            await LoadLastPublishingDate();
            await LoadInvoice(props.idPatientInvoice, true);
            setIsLoading(false);
        }
    }

    useEffect(() => {
        LoadElements();
    }, []);


    const handleChange = (event) => {
        let postData;
        const { id, value } = event.target;
        if (id) {
            if (id === "paid") {
                postData = { ...invoice, paid: event.target.checked };
                UpdateInvoice(postData);
            } else {
                if (value) {
                    setInvoice({
                        ...invoice,
                        [id]: value,
                    });

                } else {
                    setInvoice({
                        ...invoice,
                        [id]: "",
                    });
                }
            }
        }
    };

    const handleChangePaymentMode = (event) => {
        let paymentMode = event.target.value;
        let dueDate = GetDueDateAccordingPaymentMode(invoice.date, paymentMode);

        setInvoice({ ...invoice, idPaymentMode: paymentMode, dueDate: dueDate });
        let postData = { ...invoice, idPaymentMode: paymentMode, dueDate: dueDate };
        UpdateInvoice(postData);
    };

    const GetDueDateAccordingPaymentMode = (invoiceDate, paymentMode) => {
        let dueDate = invoice.dueDate;

        if (paymentMode === "B30") {
            dueDate = YpatientsUtilities.GetDateValue(YpatientsUtilities.AddDaysToDate(invoiceDate, 30));
        }

        if (paymentMode === "B60") {
            dueDate = YpatientsUtilities.GetDateValue(YpatientsUtilities.AddDaysToDate(invoiceDate, 60));
        }

        if (paymentMode === "B90") {
            dueDate = YpatientsUtilities.GetDateValue(YpatientsUtilities.AddDaysToDate(invoiceDate, 90));
        }

        return dueDate;
    };

    const handleDatePickerChange = (newValue) => {
        if (newValue) {
            let normalizedDate = YpatientsUtilities.GetDateValue(newValue);
            if (normalizedDate !== undefined) {
                let dueDate = GetDueDateAccordingPaymentMode(normalizedDate, invoice.idPaymentMode);
                if (new Date(dueDate) < new Date(normalizedDate)) {
                    setInvoice({ ...invoice, date: normalizedDate, dueDate: normalizedDate });
                } else {
                    setInvoice({ ...invoice, date: normalizedDate, dueDate: dueDate });
                }
            }
        }
    };

    const handleDatePickerAccept = (newValue) => {
        let postData = invoice;
        if (newValue) {
            let normalizedDate = YpatientsUtilities.GetDateValue(newValue);
            if (normalizedDate !== undefined) {
                let dueDate = GetDueDateAccordingPaymentMode(normalizedDate, invoice.idPaymentMode);
                if (new Date(dueDate) < new Date(normalizedDate)) {
                    postData = { ...invoice, date: normalizedDate, dueDate: normalizedDate };
                } else {
                    postData = { ...invoice, date: normalizedDate, dueDate: dueDate };
                }
                UpdateInvoice(postData);
            }
        }
    };

    const handleDueDatePickerChange = (newValue) => {
        if (newValue) {
            let normalizedDate = YpatientsUtilities.GetDateValue(newValue);
            if (normalizedDate !== undefined) {
                setInvoice({ ...invoice, dueDate: normalizedDate });
            }
        }
    };

    const handleDueDatePickerAccept = (newValue) => {
        let postData = invoice;
        if (newValue) {
            postData = { ...invoice, dueDate: YpatientsUtilities.GetDateValue(newValue) };
            UpdateInvoice(postData);
        }
    };

    const handlePaidDatePickerChange = (newValue) => {
        if (newValue) {
            let normalizedDate = YpatientsUtilities.GetDateValue(newValue);
            if (normalizedDate !== undefined) {
                setInvoice({ ...invoice, paidDate: normalizedDate });
            }
        }
    };

    const handlePaidDatePickerAccept = (newValue) => {
        let postData = invoice;
        if (newValue) {
            postData = { ...invoice, paidDate: YpatientsUtilities.GetDateValue(newValue) };
            UpdateInvoice(postData);
        }
    };

    const handleBlur = () => {
        UpdateInvoice(invoice);
    };

    const RefreshDataCallback = () => {
        LoadInvoice(props.idPatientInvoice, true);
    };

    const handleAddNewRow = () => {
            const emptyRow = {
                idInvoiceRow: YpatientsConsts.emptyGuid,
                idPatientInvoice: props.idPatientInvoice,
                description: "",
                amount: 0,
                taxes: 0,
                idTaxRate: ""
            };

        setInvoiceRows([...invoiceRows, emptyRow]);
    };

    const handlePublishInvoice = () => {
        if (CheckValidInvoice()) {
            setConfirmPublishDialogOpen(true);
        } else {
            handleOpenCheckDialog();
        }
    };

   

    useEffect(() => {
        const getTreatmentsToBeInvoiced = async () => {
            let token = await authService.getAccessTokenIfValid();
            fetch("/api/treatment/byVisit/" + invoice.idVisit, {
              method: "GET",
              headers: !token
                ? { "Content-Type": "application/json" }
                : {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "application/json",
                    Accept: "application/json",
                  },
            }).then(async (response) => {
              const data = await response.json();
              // check for error response
              if (!response.ok) {
                // get error message from body or default to response statusText
                const error = (data && data.message) || response.statusText;
                props.feedbackCallback(
                  "error",
                  "Errore nel caricamento dei trattamenti",
                  true
                );
                return Promise.reject(error);
              } else {
                if (data) {
                  setTreatmentsToBeInvoiced(data);
                }
              }
            });
        };
        if(invoice.idVisit && invoice.idVisit !== YpatientsConsts.emptyGuid) {
            getTreatmentsToBeInvoiced();
        }
    }, [invoice.idVisit]);
        
    const getRowsFromTreatments =  () => {
        let rowsToBeImported = [];
        if (treatmentsToBeInvoiced && treatmentsToBeInvoiced.length > 0) {
            treatmentsToBeInvoiced.forEach((treatment) => { 
                rowsToBeImported.push(
                    {
                        idInvoiceRow: YpatientsConsts.emptyGuid,
                        idPatientInvoice: props.idPatientInvoice,
                        description: treatment.treatmentTypeTitle,
                        deductible: true,
                        amount: treatment.price,
                        taxes: 0,
                        idTaxRate: "000",
                        forceSave: true //this is a temporary field to force saving the row
                    }
                );
            });
        }
        return rowsToBeImported;
    }


    const handleImportRow = async () => {
        //add new row
        setInvoiceRows([...invoiceRows, ...getRowsFromTreatments()]);
    };


    const handleDownloadInvoice = async () => {
        let holder = await  getCurrentCompanyData();
        
        if (!(await downloadPatientInvoice(props.idPatientInvoice, holder, props.feedbackCallback))) {
            props.feedbackCallback("error", "Fattura non disponibile", true);
        }
    };

    const handleSendToPatient = async () => {
        let holder = await getCurrentCompanyData();
        let result = await sendPatientInvoice(props.idPatientInvoice, holder, props.feedbackCallback, sendInvoiceCallBack);
        if (!result) {
            props.feedbackCallback("error", "Fattura non disponibile", true);
        }
    };

    const handleDeleteRequest = () => {
        setConfirmDialogOpen(true);
    };

    const handleDeleteConfirmation = (confirmation) => {
        if (confirmation) {
            DeleteInvoice();
        }
        setConfirmDialogOpen(false);
    };

    const handlePublishConfirmation = (confirmation) => {
        if (confirmation) {
            PublishInvoice();
        }
        setConfirmPublishDialogOpen(false);
    };

    const handleGoToTheList = () => {
        props.goToListCallback(props.pageIndex);
    }

    const renderCompanyData = () => {
        return (
            <>
                <Typography>
                    
                    {companyData.companyName} <br />
                    {companyData.address} <br />
                    {companyData.zipCode} {companyData.city} {companyData.province} <br />
                    P.IVA: {companyData.vatNumber} <br />
                    C.F.: {companyData.fiscalCode} <br />
                    {companyData.email} <br />
                </Typography>
            </>
        );
    };

    const renderPatientData = () => {
        return (
            <Typography>
                <br />
                <strong>PAZIENTE</strong> <br />
                {invoice.patientName} {invoice.patientSurname} <br />
                {invoice.patientAddress} {invoice.patientZipCode} {invoice.patientCity} <br />
                C.F. {invoice.patientFiscalCode}
            </Typography>
        );
    };

    const renderListRows = () => {
        return (
            <List
                sx={{
                    width: "100%",
                    position: "relative",
                    overflow: "auto",
                    "& ul": { padding: 0 },
                }}
            >
                {invoiceRows.map((myRow, index) => (
                    <ListItem key={myRow.idInvoiceRow + index}>
                        <Typography>{index + 1}&nbsp;&nbsp;</Typography>
                        <InvoiceRow idPatientInvoice={props.idPatientInvoice} invoiceRow={myRow} feedbackCallback={props.feedbackCallback} refreshDataCallback={RefreshDataCallback} postedInvoice={!invoice.draft} taxRates={listTaxRates}/>
                    </ListItem>
                ))}
            </List>
        );
    };

    return (
        <>
            {isLoading && <Loading message="Lettura fattura in corso...." />}
            {!isLoading &&
                <Box p={5}>
                    <Grid container spacing={2} id="box">
                        <Grid item xs={9} md={9}>
                            <Card p={5}>
                                <CardHeader
                                    title="Intestazione fattura"
                                    titleTypographyProps={{
                                        variant: "h6",
                                    }}
                                />
                                <CardContent>
                                    <Grid container spacing={2}>
                                        <Grid item xs={4}>
                                            <TextField
                                                fullWidth
                                                data-testid="number"
                                                id="number"
                                                key="number"
                                                name="number"
                                                label="Numero"
                                                variant="filled"
                                                size="small"
                                                value={invoice.number}
                                                disabled

                                            />
                                        </Grid>
                                        <Grid item xs={4}>
                                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                <DatePicker
                                                    size="small"
                                                    key="date"
                                                    id="date"
                                                    name="date"
                                                    label="Data di emissione"
                                                    inputFormat="dd/MM/yyyy"
                                                    disabled={!invoice.draft}
                                                    minDate={EvaluateMinDate()}
                                                    value={invoice.date}
                                                    onChange={handleDatePickerChange}
                                                    onAccept={handleDatePickerAccept}
                                                    desktopModeMediaQuery={theme.breakpoints.up('sm')}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            size="small"
                                                            fullWidth
                                                            variant="filled"
                                                            id="txt-date"
                                                        />
                                                    )}
                                                />
                                            </LocalizationProvider>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                <DatePicker
                                                    size="small"
                                                    key="duedate"
                                                    id="duedate"
                                                    name="duedate"
                                                    label="Data di scadenza"
                                                    inputFormat="dd/MM/yyyy"
                                                    disabled={!invoice.draft}
                                                    minDate={new Date(invoice.date)}
                                                    value={invoice.dueDate}
                                                    onChange={handleDueDatePickerChange}
                                                    onAccept={handleDueDatePickerAccept}
                                                    desktopModeMediaQuery={theme.breakpoints.up('sm')}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            size="small"
                                                            fullWidth
                                                            variant="filled"
                                                            id="txt-duedate"
                                                        />
                                                    )}
                                                />
                                            </LocalizationProvider>
                                        </Grid>

                                        <Grid item xs={4}>
                                            <TextField
                                                fullWidth
                                                disabled
                                                data-testid="invoice-amount"
                                                id="amount"
                                                key="amount"
                                                name="amount"
                                                label="Importo netto (&#8364;)"
                                                variant="filled"
                                                size="small"
                                                value={YpatientsUtilities.GetAmountDecimalFormat(invoice.amount)}
                                            />
                                        </Grid>
                                        <Grid item xs={4}>
                                            <TextField
                                                fullWidth
                                                disabled
                                                data-testid="taxes"
                                                id="taxes"
                                                key="taxes"
                                                name="taxes"
                                                label="Tasse / Iva (&#8364;)"
                                                variant="filled"
                                                size="small"
                                                value={YpatientsUtilities.GetAmountDecimalFormat(invoice.taxes)}
                                            />
                                        </Grid>
                                        <Grid item xs={4}>
                                            <TextField
                                                fullWidth
                                                disabled
                                                data-testid="total"
                                                id="total"
                                                key="total"
                                                name="total"
                                                label="Importo (&#8364;)"
                                                variant="filled"
                                                size="small"
                                                value={YpatientsUtilities.GetAmountDecimalFormat(invoice.total)}
                                            />
                                        </Grid>
                                        <Grid item xs={4}>
                                            <FormControl sx={{ m: 1, minWidth: 300 }} size="small">
                                                <InputLabel>Metodo Pagamento</InputLabel>
                                                <Select
                                                    labelId="paymentmode-label"
                                                    id="paymentmode"
                                                    data-testid="paymentmode"
                                                    value={invoice.idPaymentMode? invoice.idPaymentMode : ""}
                                                    label="Metodo Pagamento"
                                                    disabled={!invoice.draft}
                                                    onChange={handleChangePaymentMode}
                                                >

                                                    {listPaymentMode.map((myMode) => {
                                                        return (
                                                            <MenuItem key={myMode.idPaymentMode} value={myMode.idPaymentMode}>
                                                                {myMode.description}
                                                            </MenuItem>
                                                        );
                                                    })}



                                                </Select>
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <Typography>Stato pagamento</Typography>
                                            <Stack direction="row" spacing={1} alignItems="center">
                                                <Typography>Non pagata</Typography>
                                                <Switch
                                                    data-testid="paid"
                                                    id="paid"
                                                    key="paid"
                                                    name="paid"
                                                    variant="filled"
                                                    size="small"
                                                    checked={invoice.paid}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    disabled={invoice.draft}
                                                />
                                                <Typography>Pagata</Typography>
                                            </Stack>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                <DatePicker
                                                    size="small"
                                                    key="paiddate"
                                                    id="paiddate"
                                                    name="paiddate"
                                                    label="Data di pagamento"
                                                    inputFormat="dd/MM/yyyy"
                                                    disabled={!invoice.paid}
                                                    value={invoice.paidDate}
                                                    onChange={handlePaidDatePickerChange}
                                                    onAccept={handlePaidDatePickerAccept}
                                                    desktopModeMediaQuery={theme.breakpoints.up('sm')}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            size="small"
                                                            fullWidth
                                                            variant="filled"
                                                            id="txt-paidDate"
                                                            {...params}
                                                        />
                                                    )}
                                                />
                                            </LocalizationProvider>
                                        </Grid>
                                    </Grid>
                                </CardContent>
                            </Card>
                            <p>&nbsp;</p>
                            <Card>
                                <CardHeader
                                    title="Corpo fattura"
                                    titleTypographyProps={{
                                        variant: "h6",
                                    }}
                                />
                                <CardContent>
                                    {renderListRows()}
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item xs={3} md={3}>
                            <Card p={5}>
                                <CardContent>
                                    <Box display="flex">
                                        {renderCompanyData()}
                                    </Box>
                                    <Divider />
                                    <Box display="flex">
                                        {renderPatientData()}
                                    </Box>
                                </CardContent>
                            </Card>
                            <p>&nbsp;</p>
                            <Box sx={{
                                display: 'flex',
                                alignItems: 'center',
                                width: 'fit-content',
                                border: (theme) => `1px solid ${theme.palette.divider}`,
                                borderRadius: 1,
                                bgcolor: 'background.paper',
                                color: 'text.secondary',
                                '& svg': {
                                    m: 0.8,
                                },
                                '& hr': {
                                    mx: 0.5,
                                },
                            }}>
                                <Tooltip title="Aggiungi nuova riga">
                                    <span>
                                        <IconButton edge="end" aria-label="add" onClick={handleAddNewRow} disabled={!invoice.draft} data-testid="buttonAddRow">
                                            <PostAddIcon />
                                        </IconButton>
                                    </span>
                                </Tooltip>
                                <Tooltip title="Importa riga da trattamento">
                                    <span>
                                        <IconButton edge="end" aria-label="import" onClick={handleImportRow} disabled={!invoice.draft} data-testid="buttonImportRow">
                                            <VaccinesIcon />
                                        </IconButton>
                                    </span>
                                </Tooltip>
                                <Divider orientation="vertical" variant="middle" flexItem />
                                <Tooltip title="Registra la fattura">
                                    <span>
                                        <IconButton edge="end" aria-label="publish" onClick={handlePublishInvoice} disabled={!invoice.draft} data-testid="buttonPublishInvoice">
                                            <GradingIcon />
                                        </IconButton>
                                    </span>
                                </Tooltip>
                                <Divider orientation="vertical" variant="middle" flexItem />
                                <Tooltip title="Scarica la fattura in formato PDF">
                                    <IconButton edge="end" aria-label="download" onClick={handleDownloadInvoice} data-testid="buttonDownloadInvoice">
                                        <DownloadIcon />
                                    </IconButton>
                                </Tooltip>
                                <Tooltip title="Invia per email al paziente">
                                    <IconButton edge="end" aria-label="download" onClick={handleSendToPatient} data-testid="buttonSendInvoice">
                                        <ForwardToInboxIcon />
                                    </IconButton>
                                </Tooltip>
                                <Divider orientation="vertical" variant="middle" flexItem />
                                <Tooltip title="Elimina la fattura">
                                    <span>
                                        <IconButton edge="end" aria-label="delete" onClick={handleDeleteRequest} disabled={!invoice.draft} data-testid="buttonDeleteInvoice">
                                            <DeleteIcon />
                                        </IconButton>
                                    </span>
                                </Tooltip>
                                <Divider orientation="vertical" variant="middle" flexItem />
                                <Tooltip title="Torna alla lista delle fatture">
                                    <span>
                                        <IconButton edge="end" aria-label="back" onClick={handleGoToTheList} data-testid="buttonGoToList">
                                            <ArrowBackIcon />
                                        </IconButton>
                                    </span>
                                </Tooltip>
                            </Box>

                        </Grid>
                    </Grid>
                    <ConfirmDialog
                        open={confirmDialogOpen}
                        answerCallback={handleDeleteConfirmation}
                        title="Attenzione - richiesta cancellazione!"
                        text="Si è richiesta la cancellazione della fattura. L'azione è irreversibile. Procedere con l'operazione?"
                        agreeText="Elimina"
                        disagreeText="Annulla"
                    />
                    <ConfirmDialog
                        open={confirmPublishDialogOpen}
                        answerCallback={handlePublishConfirmation}
                        title="Attenzione - richiesta pubblicazione!"
                        text="Si è richiesta la pubblicazione della fattura. L'azione è irreversibile e non sarà più possibile modificare la fattura. Procedere con l'operazione?"
                        agreeText="Pubblica"
                        disagreeText="Annulla"
                    />
                    <Dialog
                        open={openCheckDialog}
                        onClose={handleCloseCheckDialog}
                    >
                        <DialogTitle>Attenzione: dati fattura non validi</DialogTitle>
                        <DialogContent dividers>
                            <Typography>
                                Verificare i dati della fattura: {checkInvoiceResult}
                            </Typography>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleCloseCheckDialog} autoFocus >
                                Ok
                            </Button>
                        </DialogActions>
                    </Dialog>
                </Box>
            }
        </>
    );
};

export default Invoice;
