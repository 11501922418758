import { format } from 'date-fns';

//Formatting
export const GetDateItalianFormat = (date) => {
    if (date && date!=="") {
        const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
        return new Date(date).toLocaleDateString("it-IT", options);
    }
    return "";
};

export const GetAmountEuroFormat = (amount) => {
    if (amount && amount !== "") {
        return "€ " + Number(amount).toLocaleString("it-IT", { minimumFractionDigits: 2 });
    }
    return "";
};
export const GetAmountEuroFormat2 = (amount) => {
    if (amount!=undefined && amount !== "" && !isNaN(amount)) {
        return amount.toFixed(2).replace('.', ',') + " €";
    }
    return "";
};
export const GetAmountDecimalFormat = (amount) => {
    if (amount && amount !== "") {
        return Number(amount).toLocaleString("it-IT", { minimumFractionDigits: 2 });
    }
    return "";
};

export const GetAmountToDecimal = (amount) => {
    if (amount && amount !== "") {
        amount = amount.replace('.', '');
        amount = amount.replace(',', '.');
        return amount;
    }
    return "";
};

// Date & Time
export const GetDateValue = (sourceDateValue) => {
    try {
        if (!sourceDateValue) throw "Invalid date";

        let myDate = new Date(sourceDateValue);

        if (myDate.getFullYear() <= 1900) {
            throw "Invalid date";
        }

        return format(sourceDateValue, "yyyy-MM-dd");
    } catch (error) {
            console.error(error);
            return undefined;
        }

};

export const GetDateTimeValueForFile = (sourceDateValue) => {
    try {
        if (!sourceDateValue) throw "Invalid date";

        let myDate = new Date(sourceDateValue);

        if (myDate.getFullYear() <= 1900) {
            throw "Invalid date";
        }

        return format(sourceDateValue, "yyyyMMddhhmmss");
    } catch {
        return undefined;
    }

};

export const DiffTwoDatesInDays = (firstDate, secondDate) => {
    const date1 = new Date(firstDate);
    const date2 = new Date(secondDate);

    const diffInDays = Math.floor((date1 - date2) / (1000 * 60 * 60 * 24));
    return diffInDays;
};

export const AddDaysToDate = (date, days) => {
    if ((date !== "") && (date !== undefined) && (date != null)) {
        const newDate = new Date(date);
        newDate.setDate(newDate.getDate() + days);
        return newDate;
    }
    return "";
};


//Validation

export const IsValidPrice = (amountString) => {
    const re = /^(0|[1-9]\d*)(\.\d{1,2})?$/;
    return re.test(amountString);
}

export const IsValidPhoneNumber = (phoneNumberString) => {
    const re = /^(\+[0-9]{1,3}\x20{0,1}){0,1}[0-9]{4,14}$/;
    return re.test(phoneNumberString);
}

export const IsValidPassword = (password) => {
    const re = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/;
    return re.test(password);
}

export const priceStringToNumberString = (priceString) => {
    if (priceString && priceString!=="") {
        return priceString.replace(",", ".");
    }
    return 0;
}

export const BooleanToString = (boolValue) => {
    if (boolValue) {
        return "Sì";
    } else {
        return "No";
    }

    return "-";
}

export const headerHeight = () => {
    let headerElement = document.querySelector('header');
    if (headerElement) {
        const height = headerElement.clientHeight;
        return height;
    }
    return 0;
}