import React from "react";
import { createTheme, responsiveFontSizes } from "@mui/material";

export const ThemeContext = React.createContext();

// Light palette

// #F0FFF3
// #C6F1E7
// #70ACB1
// #59606D
// rgb(240, 255, 243)
// rgb(198, 241, 231)
// rgb(112, 172, 177)
// rgb(89, 96, 109)

//Dark Palette
// #222222
// #045757
// #044343
// #E4E4E4
// rgb(34, 34, 34)
// rgb(4, 87, 87)
// rgb(4, 67, 67)
// rgb(228, 228, 228)

const scrollBar = {
  track: "#00A0E0",
  thumb: "#EE8317",
  active: "#EE8317",
};

export const themeLight = responsiveFontSizes(createTheme({
  components: {
    MuiFormLabel: {
      styleOverrides: {
        root: {
          color: "#70ACB1",
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: "#70ACB1",
        },
      },
    },
    MuiCardHeader: {
      styleOverrides: {
        root: {
          backgroundColor: "#5CA0BD",
          padding: "5px 15px",
          textTransform: "uppercase"
        },
      },
    },
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          scrollbarColor: `${scrollBar.thumb} ${scrollBar.track}`,

          "&::-webkit-scrollbar, & *::-webkit-scrollbar": {
            backgroundColor: scrollBar.track,
          },

          "&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb": {
            borderRadius: 8,
            backgroundColor: scrollBar.thumb,
            minHeight: 24,
            border: `10px solid ${scrollBar.track}`,
          },

          "&::-webkit-scrollbar-thumb:focus, & *::-webkit-scrollbar-thumb:focus":
            {
              backgroundColor: scrollBar.active,
            },

          "&::-webkit-scrollbar-thumb:active, & *::-webkit-scrollbar-thumb:active":
            {
              backgroundColor: scrollBar.active,
            },

          "&::-webkit-scrollbar-thumb:hover, & *::-webkit-scrollbar-thumb:hover":
            {
              backgroundColor: scrollBar.active,
            },

          "&::-webkit-scrollbar-corner, & *::-webkit-scrollbar-corner": {
            backgroundColor: scrollBar.track,
          },
        },
      },
    },
  },
  palette: {
    primary: {
      main: "#00A0E0",
      contrastText: '#FFFFFF'
    },
    secondary: {
      main: "#EE8317",
    },
    text: {
      primary: "#59606D",
      secondary: "#EE8317",
    },
    background: {
      default: "#EFF9FE",
      paper: "#E1F4FE",
    },
  },
  typography: {
    subtitle3: {
      fontSize: '1.5rem',
      color: "#59606D",
      textTransform: "uppercase",
      textAlign: "center",
      borderBottom: "1px solid #70ACB1",
    }
  },
}));


export const themeDark = responsiveFontSizes(createTheme({
  components: {
    MuiCardHeader: {
      styleOverrides: {
        root: {
          backgroundColor: "#004561",
          padding: "5px 15px",
          textTransform: "uppercase"
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          color: "#d7d7d7 ",
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: "#d7d7d7 ",
        },
      },
    },
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          scrollbarColor: `${scrollBar.thumb} ${scrollBar.track}`,
  
          "&::-webkit-scrollbar, & *::-webkit-scrollbar": {
            backgroundColor: scrollBar.track,
          },
  
          "&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb": {
            borderRadius: 8,
            backgroundColor: scrollBar.thumb,
            minHeight: 24,
            border: `10px solid ${scrollBar.track}`,
          },
  
          "&::-webkit-scrollbar-thumb:focus, & *::-webkit-scrollbar-thumb:focus":
            {
              backgroundColor: scrollBar.active,
            },
  
          "&::-webkit-scrollbar-thumb:active, & *::-webkit-scrollbar-thumb:active":
            {
              backgroundColor: scrollBar.active,
            },
  
          "&::-webkit-scrollbar-thumb:hover, & *::-webkit-scrollbar-thumb:hover":
            {
              backgroundColor: scrollBar.active,
            },
  
          "&::-webkit-scrollbar-corner, & *::-webkit-scrollbar-corner": {
            backgroundColor: scrollBar.track,
          },
        },
      },
    },
  },
  
  palette: {
    primary: {
      // main: "#044343",
      main: "#00A0E0",
      contrastText: '#FFFFFF'
    },
    secondary: {
      main: "#EE8317",
    },
    text: {
      primary: "#E4E4E4",
      secondary: "#EE8317",
    },
    background: {
      default: "#383c3d", 
      //"#263238",
      paper: "#263238",
      darker: "#f2f6f8",
    },
  },
  typography: {
    subtitle3: {
      fontSize: '1.5rem',
      color: "#E4E4E4",
      textTransform: "uppercase",
      textAlign: "center",
      borderBottom: "1px solid #E4E4E4",
    }
  },
}));
