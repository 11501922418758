import React, { useState, useEffect, useContext } from "react";
import { Loading } from "../Shared/Loading.js";
import authService from "../api-authorization/AuthorizeService";
import TextField from "@mui/material/TextField";
import { useParams, useHistory } from "react-router-dom";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Grid from "@mui/material/Grid";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { PersonalPicture } from "./PersonalPicture/PersonalPicture";
import { CountriesSelect } from "../Shared/CountriesStates/CountriesSelect.js";
import { ProvincesSelect } from "../Shared/CountriesStates/ProvincesSelect.js";
import UserContext from "../UserContext.js";

export const PatientPersonalDetails = (props) => {
    const userCtx = useContext(UserContext);
    const history = useHistory();
    const { id } = useParams();
    const [idPatient, setidPatient] = useState(id);
    const [patient, setPatient] = useState({
        idPatient: "",
        idDoctor: userCtx.doctor.idDoctor,
        name: "",
        surname: "",
        address: "",
        zipCode: "",
        city: "",
        province: "",
        country: "",
        birthDate: "",
        birthPlace: "",
        gender: "",
        fiscalCode: "",
        healthCard: "",
        familyDoctor: "",
        email: "",
        phone: "",
        mobile: "",
    });
    const [loading, setLoading] = useState(true);
    const [errorMessage, setErrorMessage] = useState();

    const handleGender = (event) => {
        setPatient({
            ...patient,
            gender: event.target.value,
        });
    };

    const handleCountry = (newvalue) => {
        if (patient.country !== newvalue) {
            handleProvince("");
        }
        setPatient({
            ...patient,
            ["country"]: newvalue,
            country: newvalue,
        });
        patient.country = newvalue;
    };

    const handleProvince = (newvalue) => {
        setPatient({
            ...patient,
            ["province"]: newvalue,
            province: newvalue,
        });
        patient.province = newvalue;
    };

    const getPatientCountry = () => {
        return patient["country"];
    };
    const getPatientProvince = () => {
        return patient["province"];
    };

    const handleChange = (event) => {
        const { id, value } = event.target;
        if (id) {
            if (value) {
                setPatient({
                    ...patient,
                    [id]: value,
                });
            } else {
                setPatient({
                    ...patient,
                    [id]: "",
                });
            }
        }
    };

    const handleDatePickerChange = (newValue) => {
        setPatient({
            ...patient,
            birthDate: newValue,
        });
    };

    const savePatientData = async (value) => {
        let path = "/api/patients/";
        let newPatient = true;
        let postData;
        if (idPatient) {
            //path to request update of patient details
            path = "/api/patients/" + idPatient;
            newPatient = false;
        }
        if (value) {
            postData = { ...patient, birthDate: value }; //value is the date selected in the datepicker. It come from onAccept
        } else {
            postData = patient; //call through handleBlur. value is null
        }
        let token = await authService.getAccessTokenIfValid();
        const requestOptions = {
            method: "POST",
            headers: !token
                ? {}
                : {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "application/json",
                    Accept: "application/json",
                },
            body: JSON.stringify(postData),
        };
        fetch(path, requestOptions).then(async (response) => {
            const data = await response.json();
            setLoading(false);
            // check for error response
            if (!response.ok) {
                // get error message from body or default to response statusText
                const error = (data && data.message) || response.statusText;
                props.feedbackCallback("error", "Error", true);
                return Promise.reject(error);
            } else {
                if (data) {
                    setPatient(data);
                    setidPatient(data.idPatient);
                    props.feedbackCallback("success", "Dati salvati con successo!", true);
                    if (newPatient) {
                        props.setNewId(data.idPatient);
                    }
                }
            }
        });
    };

    const handleBlur = () => {
        savePatientData();
    };

    useEffect(() => {
        props.setPatient(patient);
    }, [patient, props]);

    useEffect(() => {
        //Async function to retrieve Patient details from API endpoint patients/idPatient
        const getPatientInfo = async () => {
            const token = await authService.getAccessTokenIfValid();
            fetch("/api/patients/" + idPatient, {
                headers: !token ? {} : { Authorization: "Bearer " + token },
            })
                .then(async (response) => {
                    const data = await response.json();
                    setLoading(false);
                    // check for error response
                    if (!response.ok) {
                        // get error message from body or default to response statusText
                        const error = (data && data.message) || response.statusText;
                        return Promise.reject(error);
                    } else {
                        if (data) {
                            setPatient(data);
                        }
                    }
                })
                .catch((error) => {
                    if (error) {
                        setErrorMessage(
                            "Unable to retrieve patient's data: " + error.toString()
                        );
                        console.error("There was an error!", error);
                    }
                });
        };

        if (idPatient) {
            getPatientInfo();
        } else {
            setLoading(false);
        }
        return;
    }, [idPatient]);

    const fieldsInColumn = () => {
        let keys = Object.keys(patient);
        let patientFullName = patient.name + " " + patient.surname;

        return (
            <>
                <Card sx={{ marginBottom: 2 }}>
                    <CardHeader
                        title="Anagrafica"
                        data-testid="section-title"
                        titleTypographyProps={{
                            variant: "body1",
                        }}
                    />
                    <CardContent>
                        <PersonalPicture
                            idPatient={idPatient}
                            patientName={patientFullName}
                            feedbackCallback={props.feedbackCallback}
                        />
                        <Grid container spacing={2}>
                            <Grid key="name" item md={3} xs={12}>
                                <TextField
                                    fullWidth
                                    id="name"
                                    key="name"
                                    name="name"
                                    label="Nome"
                                    variant="filled"
                                    size="small"
                                    value={patient["name"] || ""}
                                    data-testid={"name"}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    InputLabelProps={{
                                        style: { color: 'primary' },
                                    }}
                                    
                                ></TextField>
                            </Grid>
                            <Grid key="surname" item md={3} xs={12}>
                                <TextField
                                    fullWidth
                                    id="surname"
                                    key="surname"
                                    name="surname"
                                    label="Cognome"
                                    variant="filled"
                                    size="small"
                                    value={patient["surname"] || ""}
                                    data-testid={"surname"}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                ></TextField>
                            </Grid>
                            <Grid key="birthDate" item md={3} xs={12}>
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <DesktopDatePicker
                                        key="birthDate"
                                        id="birthDate"
                                        name="birthDate"
                                        label="Data di nascita"
                                        inputFormat="dd/MM/yyyy"
                                        value={patient["birthDate"] || "01/01/1980"}
                                        onAccept={savePatientData}
                                        onChange={handleDatePickerChange}
                                        renderInput={(params) => (
                                            <TextField
                                                data-testid="birthDate"
                                                fullWidth
                                                variant="filled"
                                                id="visit-date"
                                                size="small"
                                                {...params}
                                            />
                                        )}
                                    />
                                </LocalizationProvider>
                            </Grid>
                            <Grid key="gender" item md={3} xs={12}>
                                <FormControl
                                    fullWidth
                                    variant="filled"
                                    id="gender"
                                >
                                    <InputLabel id="gender">Sesso</InputLabel>
                                    <Select
                                        fullWidth
                                        id="gender"
                                        key="gender"
                                        name="gender"
                                        labelId="gender"
                                        value={patient["gender"]}
                                        label="Sesso"
                                        variant="filled"
                                        size="small"
                                        onChange={handleGender}
                                        onBlur={handleBlur}
                                        data-testid={"gender"}
                                    >
                                        <MenuItem value="F">F</MenuItem>
                                        <MenuItem value="M">M</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid key="birthPlace" item md={3} xs={12}>
                                <TextField
                                    fullWidth
                                    id="birthPlace"
                                    key="birthPlace"
                                    name="birthPlace"
                                    label="Luogo di nascita"
                                    variant="filled"
                                    size="small"
                                    value={patient["birthPlace"] || ""}
                                    data-testid={"birthPlace"}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                ></TextField>
                            </Grid>
                            <Grid key="address" item md={3} xs={12}>
                                <TextField
                                    fullWidth
                                    id="address"
                                    key="address"
                                    name="address"
                                    label="Indirizzo"
                                    variant="filled"
                                    size="small"
                                    value={patient["address"] || ""}
                                    data-testid={"address"}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                ></TextField>
                            </Grid>
                            <Grid key="zipCode" item md={3} xs={12}>
                                <TextField
                                    fullWidth
                                    id="zipCode"
                                    key="zipCode"
                                    name="zipCode"
                                    label="CAP"
                                    variant="filled"
                                    size="small"
                                    value={patient["zipCode"] || ""}
                                    data-testid={"zipCode"}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                ></TextField>
                            </Grid>
                            <Grid key="city" item md={3} xs={12}>
                                <TextField
                                    fullWidth
                                    id="city"
                                    key="city"
                                    name="city"
                                    label="Città"
                                    variant="filled"
                                    size="small"
                                    value={patient["city"] || ""}
                                    data-testid={"city"}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                ></TextField>
                            </Grid>

                            <Grid key="country" item md={3} xs={12}>
                                <CountriesSelect
                                    handleChange={handleCountry}
                                    handleCountry={getPatientCountry}
                                    handleBlur={handleBlur}
                                />
                            </Grid>
                            <Grid key="province" item md={3} xs={12}>
                                <ProvincesSelect
                                    handleChange={handleProvince}
                                    handleCountry={getPatientCountry}
                                    handleProvince={getPatientProvince}
                                    handleBlur={handleBlur}
                                />
                            </Grid>
                            <Grid key="fiscalCode" item md={3} xs={12}>
                                <TextField
                                    fullWidth
                                    id="fiscalCode"
                                    key="fiscalCode"
                                    name="fiscalCode"
                                    label="Codice Fiscale"
                                    variant="filled"
                                    size="small"
                                    value={patient["fiscalCode"] || ""}
                                    data-testid={"fiscalCode"}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                ></TextField>
                            </Grid>
                            <Grid key="healthCard" item md={3} xs={12}>
                                <TextField
                                    fullWidth
                                    id="healthCard"
                                    key="healthCard"
                                    name="healthCard"
                                    label="Tessera Sanitaria"
                                    variant="filled"
                                    size="small"
                                    value={patient["healthCard"] || ""}
                                    data-testid={"healthCard"}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                ></TextField>
                            </Grid>
                            <Grid key="familyDoctor" item md={3} xs={12}>
                                <TextField
                                    fullWidth
                                    id="familyDoctor"
                                    key="familyDoctor"
                                    name="familyDoctor"
                                    label="Medico curante"
                                    variant="filled"
                                    size="small"
                                    value={patient["familyDoctor"] || ""}
                                    data-testid={"familyDoctor"}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                ></TextField>
                            </Grid>
                            <Grid key="email" item md={3} xs={12}>
                                <TextField
                                    fullWidth
                                    id="email"
                                    key="email"
                                    name="email"
                                    label="E-mail"
                                    variant="filled"
                                    size="small"
                                    value={patient["email"] || ""}
                                    data-testid={"email"}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                ></TextField>
                            </Grid>
                            <Grid key="phone" item md={3} xs={12}>
                                <TextField
                                    fullWidth
                                    id="phone"
                                    key="phone"
                                    name="phone"
                                    label="Numero di telefono"
                                    variant="filled"
                                    size="small"
                                    value={patient["phone"] || ""}
                                    data-testid={"phone"}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                ></TextField>
                            </Grid>
                            <Grid key="mobile" md={3} item xs={12}>
                                <TextField
                                    fullWidth
                                    id="mobile"
                                    key="mobile"
                                    name="mobile"
                                    label="Cellulare"
                                    variant="filled"
                                    size="small"
                                    value={patient["mobile"] || ""}
                                    data-testid={"mobile"}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                ></TextField>
                            </Grid>
       
                        </Grid>
                    </CardContent>
                </Card>
            </>
        );
    };

  

    let contents = loading ? (
        <div className="w-100 d-flex justify-content-center pb-3">
            <Loading />
        </div>
    ) : (
        
            <div>
                <form>{fieldsInColumn()}</form>
            </div>
       
    );


    return <div>{contents}</div>;
};

export default PatientPersonalDetails;
