import React, { useEffect, useState } from "react";
import authService from "../api-authorization/AuthorizeService";
import FeedbackAlert from "../Shared/FeedbackAlert";
import Loading from '../Shared/Loading';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';


export const BankResponse = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [isResultOk, setIsResultOk] = useState(true);
    const [errorMessage, setErrorMessage] = useState('');

    const search = window.location.search;
    const bankParams = new URLSearchParams(search);

    const [paymentResultData, setPaymentResultData] = useState({
        outcome: bankParams.get("esito"),
        amount: bankParams.get("importo"),
        currency: bankParams.get("divisa"),
        ypatientsTransactionCode: bankParams.get("codTrans"),
        date: bankParams.get("data"),
        time: bankParams.get("orario"),
        macCode: bankParams.get("mac"),
        bankTransactionCode: bankParams.get("codAut"),
        bankResultCode: bankParams.get("codiceEsito"),
        bankResultMessage: bankParams.get("messaggio")
    });

    const [feedback, setFeedback] = useState({
        severity: "success",
        text: "Success",
        open: false,
    });

    const alertClosed = () => {
        setFeedback({ ...feedback, open: false });
    };

    const feedbackCallback = (severity, text, open) => {
        setFeedback({ severity: severity, text: text, open: open });
    };

    useEffect(() => {
        ReadBankResponse();
    }, []);


    const ReadBankResponse = async () => {
        setIsLoading(true);
        const token = await authService.getAccessTokenIfValid();
        const postUrl = '/api/payment/gobank/response';
        const response = await fetch(postUrl, {
            method: 'POST',
            body: JSON.stringify(paymentResultData),
            headers: !token ? {} : {
                Authorization: `Bearer ${token}`, 'Content-Type': 'application/json' }
        });

        if (response.ok) {
            const data = await response.text();
            if (paymentResultData.outcome !== "OK") {
                setIsResultOk(false);
                if (paymentResultData.outcome === "ANNULLO") {
                    setErrorMessage("La transazione \u00E8 stata annullata dall'utente");
                } else {
                    setErrorMessage("La banca ha risposto con " + data);
                }
            }
        } else {
            setIsResultOk(false);
            const error = await response.text();
            feedbackCallback("error", error, true);
        }
        setIsLoading(false);
    }

    return (
        <div>
            <Card>
                <CardHeader
                    title="Esito pagamento"
                    data-testid="section-title"
                    titleTypographyProps={{
                        variant: "h7"
                    }}
                />
                <CardContent>
                    {isLoading && <Loading />}
                    {!isLoading && isResultOk &&
                        <div>
                            Il pagamento &egrave; stato accettato!<br />
                            Ritorna alla pagina delle <a href="/Account/settings/subscription">sottoscrizioni</a> <br />
                            Visualizza lo stato delle <a href="/Account/settings/billing">fatture</a><br />
                            Ritorna alla <a href="/">home page</a> <br />
                        </div>
                    }
                    {!isLoading && !isResultOk &&
                        <div>
                            Il pagamento non &egrave; andato a buon fine, si sono verificati degli errori.<br />
                            {errorMessage}
                        </div>
                    }
                </CardContent>
            </Card>
            <FeedbackAlert
                severity={feedback.severity}
                text={feedback.text}
                open={feedback.open}
                closedCallback={alertClosed}
            />
        </div>
    );
};

export default BankResponse;
