import React, {useEffect, useState} from "react";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";


export const FeedbackAlert = (props) => {
    const [open, setOpen] = useState(props.open);
    const [duration, setDuration] = useState(2000);

  useEffect(() =>{
      setOpen(props.open);
      if (props.severity === "success") {
          setDuration(2000);
      } else {
          setDuration(20000);
      }
},[props.open, props.severity])

  const handleClose = () => {
    props.closedCallback();
    setOpen(false);
  };

  return (
    <Snackbar
    open={open}
    autoHideDuration={duration}
    onClose={handleClose}
    anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
  >
    <Alert
      onClose={handleClose}
      severity={props.severity}
      sx={{ width: "100%" }}
      elevation={6}
      variant="filled"
    >
      {props.text}
    </Alert>
  </Snackbar>
  );
};
export default FeedbackAlert;