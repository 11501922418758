import { Autocomplete, TextField, FormControl, FormLabel, FormGroup, FormControlLabel, Checkbox, InputLabel } from "@mui/material";

export default function QuestionRenderer(props) {

  const SingleLineTextQuestion = () => {
  return (
    <>
    <InputLabel>{props.question.description}</InputLabel>
    <Autocomplete
      size="small"
      fullWidth
      id={props.question.idCustomDataField}
      freeSolo
      autoComplete
      clearOnEscape
      selectOnFocus
      handleHomeEndKeys
      disableClearable
      value={props.question.answer}
      inputValue={props.question.answer}
      onInputChange={props.handleAnswerChange}
      onChange={props.handleAnswerChange}
      onBlur={props.handleQuestionBlur}
      getOptionLabel={(option) => option || ""}
      options={props.question.answerOptions.map(
        (option) => option.optionDescription
      )}
      renderInput={(params) => (
        <TextField
          size="small"
          {...params}
          hiddenLabel
          variant="filled"
          fullWidth
        />
      )}
    />
    </>
  );
};

const MultiLineTextQuestion = () => {
  return (
    <>
    <InputLabel>{props.question.description}</InputLabel>
    <Autocomplete
      size="small"
      fullWidth
      id={props.question.idCustomDataField}
      freeSolo
      autoComplete
      clearOnEscape
      selectOnFocus
      handleHomeEndKeys
      disableClearable
      value={props.question.answer}
      inputValue={props.question.answer}
      onInputChange={props.handleAnswerChange}
      onChange={props.handleAnswerChange}
      onBlur={props.handleQuestionBlur}
      getOptionLabel={(option) => option || ""}
      options={props.question.answerOptions.map(
        (option) => option.optionDescription
      )}
      renderInput={(params) => (
        <TextField
          size="small"
          {...params}
          hiddenLabel
          variant="filled"
          fullWidth
          multiline
          minRows="5"
        />
      )}
    />
    </>
  );
};


const MultipleChoiceQuestion = () => {
  return (
    <FormControl
      component="fieldset"
      variant="standard"
      onBlur={props.handleQuestionBlur}
      data-testid={props.question.idCustomDataField}
    >
      <FormLabel component="legend">{props.question.description}</FormLabel>
      <FormGroup row>
        {props.question.answerOptions.map((option, index) => {
          return (
            <FormControlLabel
              key={option.idCustomDataOption}
              control={
                <Checkbox
                  id={option.idCustomDataOption}
                  checked={props.checked[option.idCustomDataOption] || false}
                  onChange={props.handleOptionChange}
                  name={option.optionDescription}
                  value={option.optionDescription}
                />
              }
              label={option.optionDescription}
            />
          );
        })}
      </FormGroup>
    </FormControl>
  );
};

const Title = () => {
  switch (props.question.size){
    case 3:
      return (
        <div className="text-start overflow-hidden">
            <p className="sm-heading-title">
              {props.question.description}
            </p>
          </div>
      );
    case 6:
      return (
        <div className="text-start overflow-hidden">
            <h7 className="sm-heading-title">
              {props.question.description}
            </h7>
          </div>
      );
    case 12:
      return (
        <div className="text-center overflow-hidden">
            <h5 className="heading-title lined-text">
              {props.question.description}
            </h5>
          </div>
      );
    default:
      return (
        <div className="text-center overflow-hidden">
            <h5 className="heading-title lined-text">
              {props.question.description}
            </h5>
          </div>
      );
  }
}

const Filler = () => {
  return (
    <div>
    </div>
  );
}

switch (props.question.dataType) {
  case "txt":
    return SingleLineTextQuestion();

  case "mlt":
    return MultipleChoiceQuestion();

  case "tit":
    return Title();
  
  case "flr":
    return Filler();
  
  case "mtx":
    return MultiLineTextQuestion();

  default:
    return Filler();
}

};
