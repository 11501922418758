import React from "react";
import Camera from "./Camera";
import { useParams } from "react-router-dom";
import Stack from "@mui/material/Stack";
import FeedbackAlert from "../../Shared/FeedbackAlert";
import Loading from "../../Shared/Loading";

export const RemoteImageCapture = () => {
  const idPatient = useParams().idPatient;
  const idVisit = useParams().idVisit;
  const token = useParams().token;
  const [feedback, setFeedback] = React.useState({severity: "success", text: "Immagine salvata correttamente", open: false});
  const [loading, setLoading] = React.useState(false);

  const feedbackCallback = (type, message, show) => {
    setFeedback({severity: type, text: message, open: show});
  };

  const returnClick = () => {
    setFeedback({severity: "success", text: "Immagine salvata correttamente", open: true});
  };

  const alertClosed = () => {
    setFeedback({ ...feedback, open: false });
};
 const handleReadingStatus = (status) => {
    setLoading(status);
  }

 const LoadingMessage = () => {
  return(loading? <Loading message="Salvataggio in corso" /> : <></>);
};

  return (
    <Stack alignItems="center">
      <h1>Acquisizione remota</h1>
      <Camera
        idPatient={idPatient}
        idVisit={idVisit}
        token={token}
        feedbackCallback={feedbackCallback}
        returnClick={returnClick}
        buttonSize="large"
        busyReading={handleReadingStatus}
      />
      {LoadingMessage()}
      <FeedbackAlert severity={feedback.severity} text={feedback.text} open={feedback.open} closedCallback={alertClosed}/>
    </Stack>
  );
};

export default RemoteImageCapture;
