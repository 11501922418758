import React, { useState, useContext, useEffect } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import CardContent from "@mui/material/CardContent";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import UserContext from "../UserContext";
import * as YpatientsConsts from "../Shared/Constants";
import authService from "../api-authorization/AuthorizeService";
import Loading from "../Shared/Loading";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";


export const range = (start, end) => {
  return Array(end - start + 1)
    .fill()
    .map((_, idx) => start + idx);
};

export const RevenuesReport = (props) => {
  const [idPatientInvoice, setIdPatientInvoice] = useState(
    YpatientsConsts.emptyGuid
  );
  const [pageIndex, setPageIndex] = useState(0);
  const userCtx = useContext(UserContext);
  const [dateRange, setDateRange] = useState("CM"); //initialised to current month

  const emptyInvoice = {
    idPatientInvoice: "",
    idCompany: "",
    idVisit: "",
    idPatient: "",
    patientName: "",
    patientSurname: "",
    patientFiscalCode: "",
    patientAddress: "",
    patientCity: "",
    patientZipCode: "",
    patientEmail: "",
    number: "",
    date: "",
    dueDate: "",
    amount: "",
    taxes: "",
    total: "",
    paid: "",
    paidDate: "",
    draft: "",
  };

  const [patientInvoices, setPatientInvoices] = useState([emptyInvoice]);

  const GetInvoicesList = async () => {
    let getUrl = "";
    let getUrlIsOk = true;

    if (
      userCtx.doctor !== undefined &&
      userCtx.doctor.idDoctor !== YpatientsConsts.emptyGuid &&
      userCtx.doctor.idDoctor !== ""
    ) {
      getUrl =
        "/api/patientinvoices/invoice/listbydoctor/" + userCtx.doctor.idDoctor;
    } else {
      if (
        props.idCompany !== undefined &&
        props.idCompany !== "" &&
        props.idCompany !== YpatientsConsts.emptyGuid
      ) {
        getUrl =
          "/api/patientinvoices/invoice/listbycompany/" + props.idCompany;
      } else {
        getUrlIsOk = false;
      }
    }

    if (getUrlIsOk) {
      switch (dateRange) {
        case "CM": //current month
          getUrl +=
            "/from/" +
            new Date().getFullYear() +
            "-" +
            (new Date().getMonth() + 1) +
            "-01/to/" +
            new Date().getFullYear() +
            "-" +
            (new Date().getMonth() + 1) +
            "-" +
            new Date(
              new Date().getFullYear(),
              new Date().getMonth() + 1,
              0
            ).getDate();
          break;
        case "LM": //last month
          getUrl +=
            "/from/" +
            new Date(
              new Date().getFullYear(),
              new Date().getMonth() - 1,
              1
            ).getFullYear() +
            "-" +
            (new Date(
              new Date().getFullYear(),
              new Date().getMonth() - 1,
              1
            ).getMonth() +
              1) +
            "-01/to/" +
            new Date(
              new Date().getFullYear(),
              new Date().getMonth() - 1,
              1
            ).getFullYear() +
            "-" +
            (new Date(
              new Date().getFullYear(),
              new Date().getMonth() - 1,
              1
            ).getMonth() +
              1) +
            "-" +
            new Date(
              new Date(
                new Date().getFullYear(),
                new Date().getMonth() - 1,
                1
              ).getFullYear(),
              new Date(
                new Date().getFullYear(),
                new Date().getMonth() - 1,
                1
              ).getMonth() + 1,
              0
            ).getDate();
          break;
        case "CY": //current year
          getUrl +=
            "/from/" +
            new Date().getFullYear() +
            "-01-01/to/" +
            new Date().getFullYear() +
            "-12-31";
          break;
        case "LY": //last year
          getUrl +=
            "/from/" +
            (new Date().getFullYear() - 1) +
            "-01-01/to/" +
            (new Date().getFullYear() - 1) +
            "-12-31";
          break;
        case "5Y": //last 5 years
          getUrl +=
            "/from/" +
            (new Date().getFullYear() - 5) +
            "-01-01/to/" +
            new Date().getFullYear() +
            "-12-31";
          break;
        default:
          break;
      }

      const token = await authService.getAccessTokenIfValid();
      const response = await fetch(getUrl, {
        headers: !token ? {} : { Authorization: `Bearer ${token}` },
      });

      if (response.ok) {
        const data = await response.json();
        if (data) {
          setPatientInvoices(data);
        } else {
          props.feedbackCallback(
            "error",
            "Errore durante la lettura delle fatture",
            true
          );
        }
      } else {
        const error = await response.text();
        props.feedbackCallback("error", error, true);
      }
    }
  };

  useEffect(() => {
    GetInvoicesList();
  }, [props.idCompany, userCtx.doctor.idDoctor, dateRange]);

  ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
  );

  //Chart
  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: false,
        text: "Fatturato",
      },
    },
  };

  const monthLabels = [
    "Gennaio",
    "Febbraio",
    "Marzo",
    "Aprile",
    "Maggio",
    "Giugno",
    "Luglio",
    "Agosto",
    "Settembre",
    "Ottobre",
    "Novembre",
    "Dicembre",
  ];

  const yearLabels = range(
    new Date().getFullYear() - 5,
    new Date().getFullYear()
  ).map((x) => x.toString());

  const getDaysInMonth = (year, month) => {
    return new Date(year, month + 1, 0).getDate(); //+1 because month is 0 based
  };

  const getDayLabels = (year, month) => {
    return range(1, getDaysInMonth(year, month)).map((x) => x.toString());
  };

  const dayLabels = range(1, 31).map((x) => x.toString());

  const [data, setData] = useState({
    labels: getDayLabels(new Date().getFullYear(), new Date().getMonth()),
    datasets: [
      {
        label: "Fatturato",
        data: dayLabels.map((label, index) =>
          patientInvoices
            .filter((x) => new Date(x.date).getDate() - 1 === index)
            .reduce((a, b) => a + (b.total || 0), 0)
        ),
        backgroundColor: "rgba(255, 77, 166, 0.5)",
      },
    ],
  });

  const updateChart = () => {
    switch (dateRange) {
      case "CM": //current month
        setData({
          labels: getDayLabels(new Date().getFullYear(), new Date().getMonth()),
          datasets: [
            {
              ...data.datasets[0],
              label:
                monthLabels[new Date().getMonth()] +
                " " +
                new Date().getFullYear(),
              data: dayLabels.map((label, index) =>
                patientInvoices
                  .filter((x) => new Date(x.date).getDate() - 1 === index)
                  .reduce((a, b) => a + (b.total || 0), 0)
              ),
            },
          ],
        });
        break;
      case "LM": //last month
        setData({
          labels: dayLabels,
          datasets: [
            {
              ...data.datasets[0],
              label:
                monthLabels[new Date().getMonth() - 1] +
                " " +
                new Date().getFullYear(),
              data: dayLabels.map((label, index) =>
                patientInvoices
                  .filter((x) => new Date(x.date).getDate() - 1 === index)
                  .reduce((a, b) => a + (b.total || 0), 0)
              ),
            },
          ],
        });
        break;
      case "CY": //current year
        setData({
          labels: monthLabels,
          datasets: [
            {
              ...data.datasets[0],
              label: new Date().getFullYear(),
              data: monthLabels.map((label, index) =>
                patientInvoices
                  .filter((x) => new Date(x.date).getMonth() === index)
                  .reduce((a, b) => a + (b.total || 0), 0)
              ),
            },
          ],
        });
        break;
      case "LY": //last year
        setData({
          labels: monthLabels,
          datasets: [
            {
              ...data.datasets[0],
              label: new Date().getFullYear() - 1,
              data: monthLabels.map((label, index) =>
                patientInvoices
                  .filter((x) => new Date(x.date).getMonth() === index)
                  .reduce((a, b) => a + (b.total || 0), 0)
              ),
            },
          ],
        });
        break;
      case "5Y": //last 5 years
        setData({
          labels: yearLabels,
          datasets: [
            {
              ...data.datasets[0],
              label:
                new Date().getFullYear() - 5 + " - " + new Date().getFullYear(),
              data: yearLabels.map((label, index) =>
                patientInvoices
                  .filter(
                    (x) =>
                      yearLabels.indexOf(
                        new Date(x.date).getFullYear().toString()
                      ) === index
                  )
                  .reduce((a, b) => a + (b.total || 0), 0)
              ),
            },
          ],
        });
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    updateChart();
  }, [patientInvoices]);

  const changeRange = (event) => {
    setDateRange(event.target.value);
  };

  const renderLabel = (label) => {
    switch (dateRange) {
      case "CM": //current month
        let month = new Date().getMonth() + 1;
        if (month < 1) {
          month = 12;
        }
        return (
          (label < 10 ? "" + label : label) +
          "/" +
          (month < 10 ? "0" + month : month)
        );

      case "LM": //last month
        let lastMonth = new Date().getMonth();
        if (lastMonth < 1) {
          lastMonth = 12;
        }
        return (
          (label < 10 ? "" + label : label) +
          "/" +
          (lastMonth < 10 ? "0" + lastMonth : lastMonth)
        );
      case "CY": //current year
        return label;
      case "LY": //last year
        return label;
      case "5Y": //last 5 years
        return label;
      default:
        break;
    }
  };

  return (
    <Card
      sx={{
        boxShadow: 0,
        minHeight: "80%",
      }}
    >
      <CardContent>
        <Typography gutterBottom variant="subtitle3" component="div">
          Ricavi
        </Typography>
        {userCtx.doctor.idDoctor !== "" ? (
          <>
            <Grid
              container
              height="100%"
              justifyContent={{
                xs: "center",
                sm: "center",
                md: "center",
                lg: "center",
                xl: "space-around"
              }}
            >
              <Grid item sm={12} md={12} lg={12} xl={2}>
                <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
                  <Select
                    labelId="demo-simple-select-standard-label"
                    id="demo-simple-select-standard"
                    value={dateRange}
                    onChange={changeRange}
                  >
                    <MenuItem value="CM">Mese corrente</MenuItem>
                    <MenuItem value="LM">Mese precedente</MenuItem>
                    <MenuItem value="CY">Anno corrente</MenuItem>
                    <MenuItem value="LY">Anno precedente</MenuItem>
                    <MenuItem value="5Y">5 Anni</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item sm={12} md={12} lg={12} xl={10} sx={{position: "relative", height: "50vh"}}>
                <Bar data={data} options={options} />
              </Grid>
            </Grid>
            <Paper sx={{ p: 2, margin: "auto", flexGrow: 1 }}>
              <Grid
                container
                spacing={0}
                columns={data.labels.length}
                justifyContent="center"
              >
                {data.labels.map((label, index) => (
                  <Grid
                    item
                    xs={data.labels.length}
                    sm={data.labels.length}
                    md={data.labels.length}
                    lg={data.labels.length}
                    xl={1}
                    justifyContent="center"
                  >
                    <Stack
                      spacing={{ sm: 5, lg: 0 }}
                      direction={{ xs: "row", sm: "row", md: "row", lg: "row", xl: "column" }}
                      sx={{ border: "1px grey", borderStyle: "solid" }}
                      justifyContent={{
                        xs: "space-around",
                        sm: "space-around",
                        md: "space-around",
                        lg: "space-around",
                        xl: "center"
                      }}
                    >
                      <Box
                        p={{ xs: 2, sm: 2, md: 4, lg: 4, xl: 0 }}
                        textAlign={{
                          xs: "left",
                          sm: "left",
                          md: "left",
                          lg: "left",
                          xl: "center"
                        }}
                        flexGrow={1}
                      >
                        <Typography variant="caption">
                          {renderLabel(label)}
                        </Typography>
                      </Box>
                      <Box
                        p={{ xs: 2, sm: 2, md: 4, lg: 4, xl: 0 }}
                        textAlign={{
                          xs: "right",
                          sm: "right",
                          md: "right",
                          lg: "right",
                          xl: "center"
                        }}
                        flexGrow={1}
                      >
                        <Typography variant="caption">
                          {data.datasets[0].data[index] + " €"}
                        </Typography>
                      </Box>
                    </Stack>
                  </Grid>
                ))}
              </Grid>
            </Paper>
          </>
        ) : (
          <Loading />
        )}
      </CardContent>
    </Card>
  );
};

export default RevenuesReport;
