
export function ImageToBase64 (image, callback)  {
let image1 = new Image();
image1.crossOrigin = "Anonymous";
image1.src = image.url;
image1.onload = function () {
  let canvas = document.createElement("canvas");
  let ctx = canvas.getContext("2d");
  let dataURL;
  canvas.height = image1.height;
  canvas.width = image1.width;
  ctx.drawImage(image1, 0, 0);
  dataURL = canvas.toDataURL("image/jpg");
  callback(dataURL);
};
};