import React from "react";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import "../../custom.css";

export const Welcome = (props) => {

    return (
        <Grid container spacing={2} className="vh-100">
            <Grid
                item
                container
                xs={12}
                md={3}
                justifyContent="center"
                alignItems="center"
                spacing={2}
                direction="column"
            >
                <Grid item xs={2} md={2} className="w-100"></Grid>
            </Grid>
            <Grid item xs={12} md={9} className="vh-100">
                <Typography variant="h2" gutterBottom data-testid="welcome-title">
                    Benvenuto in Mosaycon
                </Typography>
                <Typography variant="h5" gutterBottom>
                    Grazie per esserti registrato! <br />Per completare il processo di prima
                    registrazione richiedi al tuo amministratore di acquistare una licenza.
                </Typography>
            </Grid>
        </Grid>
    );
};

export default Welcome;
