import React, { useState } from "react";
import { Route, Redirect } from "react-router";
import { Layout } from "./components/Layout";
import { Home } from "./components/Home/Home";
import { LandingPage } from "./components/website/LandingPage";
import { PatientFile } from "./components/PatientFile/PatientFile";
import AuthorizeRoute from "./components/api-authorization/AuthorizeRoute";
import ApiAuthorizationRoutes from "./components/api-authorization/ApiAuthorizationRoutes";
import { ApplicationPaths } from "./components/api-authorization/ApiAuthorizationConstants";
import { Register } from "./components/Account/Register";
import { Login } from "./components/Account/Login";
import { ForgotPassword } from "./components/Account/ForgotPassword";
import { UserContextProvider } from "./components/UserContext";
import CssBaseline from "@mui/material/CssBaseline";
import { themeLight, themeDark, ThemeContext } from "./theme";
import { ThemeProvider } from "@mui/material";
import { SettingsContext } from "./components/Settings/SettingsContext";
import RemoteImageCapture from "./components/PatientFile/ImagesManagement/RemoteImageCapture";
import { BankResponse } from './components/Account/BankResponse';
import AccountSettings from "./components/Account/AccountSettings";
import Warehouse from "./components/Warehouse/Warehouse";
import Reports from "./components/Reports/Reports";
import useMediaQuery from '@mui/material/useMediaQuery';
import "./custom.css";
import Quotation from "./components/Quotations/Quotation";

export const App = () => {

    const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
    const displayName = App.name;
    const [light, setLight] = useState(!prefersDarkMode);
    const [settings, setSettings] = useState({
        editMode: false,
    });

    React.useMemo(
        () => setLight(!prefersDarkMode),
        [prefersDarkMode],
    );


    return (
        <>
            <ThemeContext.Provider value={[light, setLight]}>
                <SettingsContext.Provider value={[settings, setSettings]}>
                    <UserContextProvider>
                        <ThemeProvider theme={light ? themeLight : themeDark}>
                            <CssBaseline />
                            <Layout>
                                <Route exact path="/" component={LandingPage} />
                                <AuthorizeRoute exact path="/home" component={Home} />
                                <Route exact path="/ClientApp">
                                    <Redirect to="/" />
                                </Route>
                                <AuthorizeRoute
                                    path="/Account/Settings"
                                    component={AccountSettings}
                                />
                                <AuthorizeRoute path="/patient/:id" component={PatientFile} />
                                <AuthorizeRoute exact path="/patient" component={PatientFile} />
                                <AuthorizeRoute path="/warehouse" component={Warehouse} />
                                <AuthorizeRoute path="/reports" component={Reports} />
                                <Route exact path="/Account/register" component={Register} />
                                <Route exact path="/Account/login" component={Login} /> 
                                <Route exact path="/Account/forgotpassword" component={ForgotPassword} /> 
                                <Route
                                    exact
                                    path="/ClientApp/Account/register"
                                    component={Register}
                                />
                              
                                <Route
                                    exact
                                    path="/ClientApp/Account/login"
                                    component={Login}
                                />
                                <Route
                                    path={ApplicationPaths.ApiAuthorizationPrefix}
                                    component={ApiAuthorizationRoutes}
                                />
                                <AuthorizeRoute
                                    exact
                                    path="/RemoteImageCapture/:idPatient/:idVisit"
                                    component={RemoteImageCapture}
                                />
                                <AuthorizeRoute
                                    exact
                                    path="/Account/BankResponse"
                                    component={BankResponse}
                                />
                                <AuthorizeRoute path="/quotation" component={Quotation} />
                            </Layout>
                        </ThemeProvider>
                    </UserContextProvider>
                </SettingsContext.Provider>
            </ThemeContext.Provider>
        </>
    );
};

export default App;
