import React, { useRef, useState } from "react";

import ImageQRCode from "./ImageQRCode";

import { ImagesList } from "./ImagesList";
import { Camera } from "./Camera";
import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialIcon from '@mui/material/SpeedDialIcon';
import SpeedDialAction from '@mui/material/SpeedDialAction';
import "./ImagesSection.css";


export const ImagesSection = (props) => {
  const IdPatient = props.idPatient;
  const IdVisit = props.idVisit;

  const myRef = useRef();

  const [cameraIsVisible, setCameraVisible] = useState(false);
  const [streamFromCamera, setStreamFromCamera] = useState("undefined");

  const AddImageOnClick = () => {
    setCameraVisible(true);
  };

  const HandleStopCamera = (stream) => {
    setStreamFromCamera(stream);
  };

  const CloseCameraOnClick = () => {
    if (streamFromCamera !== "undefined") {
      streamFromCamera.getTracks().forEach((t) => t.stop());
    }
    setCameraVisible(false);
  };

  const CloseCameraAndRefreshListOnClick = (image) => {
    CloseCameraOnClick();
    if (image != null) {
      myRef.current.GetImages(image, false);
    }
  };

  const CloseQRCodeAndRefreshListOnClick = () => {
      myRef.current.GetImages("", true);
  };

  return (
    <div>
      <ImagesList
        ref={myRef}
        idPatient={IdPatient}
        idVisit={IdVisit}
        feedbackCallback={props.feedbackCallback}
      />
       <SpeedDial
        ariaLabel="SpeedDial basic example"
        sx={{ position: 'fixed', bottom: 50, right: 50 }}
        direction="up"
        icon={<SpeedDialIcon />}
        color="primary"
      >
        <SpeedDialAction
          key="AddImage"
          icon={<Camera
            idPatient={IdPatient}
            idVisit={IdVisit}
            feedbackCallback={props.feedbackCallback}
            returnClick={CloseCameraAndRefreshListOnClick}
            setCameraOff={(stream) => HandleStopCamera(stream)}
            buttonSize="small"
            busyReading={() => {}}
          />}
          tooltipTitle="Aggiungi immagine"
          onClick={AddImageOnClick}
        />
        <SpeedDialAction
          key="AddImageQR"
          icon={<ImageQRCode
            idPatient={props.idPatient}
            idVisit={props.idVisit}
            onClose={CloseQRCodeAndRefreshListOnClick}
          />}
          tooltipTitle="Acquisisci immagine da altro dispositivo"
          onClick={AddImageOnClick}
        />
      </SpeedDial>
    </div>
  );
};
