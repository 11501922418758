import React, { useState, useEffect } from "react";
import { Parser } from 'html-to-react'
import Typography from "@mui/material/Typography";


export const HtmlViewer = (props) => {

    const [htmlFile, setHtmlFile] = useState("");

    useEffect(() => {
        const getHtmlFile = async () => {


                const response = await fetch("/api/file/local/" + props.fileName, {
                    headers: {}
                });

                if (response.ok) {
                    const data = await response.text(); // return a string
                    if (data) {
                        setHtmlFile(data);
                        if (props.htmlCallback) {
                            props.htmlCallback(data);
                        }

                    } else {
                        props.feedbackCallback("error", "Errore durante la lettura del file", true);
                    }

                } else {
                    const error = await response.text();
                    console.log(error);
                    props.feedbackCallback("error", error, true);
                }
        }

        getHtmlFile();
    }, [props.fileName]);


   
    return (
        <Typography component="div" sx={{ flexGrow: 1, display: { xs: 'block', sm: 'block' } }}>
            {Parser().parse(htmlFile)}
        </Typography>
    );
};

export default HtmlViewer;

