import React, { useState, useEffect, useContext } from "react";
import authService from "../api-authorization/AuthorizeService";
import { Loading } from "../Shared/Loading";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import CardActions from "@mui/material/CardActions";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Fab from "@mui/material/Fab";
import AddIcon from "@mui/icons-material/Add";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import ListItemSecondaryAction from "@mui/material/ListItemSecondaryAction";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import UserContext from "../UserContext";

export const RequestCard = (props) => {
  const userCtx = useContext(UserContext);
  const placeHolderGuid = "00000000-0000-0000-0000-000000000000";
  const placeHolderDate = "2022-01-01";
  const [typeInputValue, setTypeInputValue] = useState("");
  const [requestType, setRequestType] = useState("");
  const [requestTypes, setRequestTypes] = useState([]);
  const [idPatient, setIdPatient] = useState(props.idPatient);
  const [requests, setRequests] = useState([]);
  const [currentRequest, setCurrentRequest] = useState(null);
  const [newRequest, setNewRequest] = useState({
    idRequest: placeHolderGuid,
    idPatient: props.idPatient,
    idRequestType: placeHolderGuid,
    description: "",
    date: placeHolderDate,
  });
  const [loading, setLoading] = useState(true);
  const [expanded, setExpanded] = useState(false);

  const handleRequestChange = (event) => {
    const selectedIdRequest = event.currentTarget.id;
    setExpanded(selectedIdRequest === expanded ? null : selectedIdRequest);
    setCurrentRequest(
      selectedIdRequest !== expanded
        ? {
            idPatient: idPatient,
            idRequest: selectedIdRequest,
            idRequestType: requests.filter(
                    (req) => req.idRequest === selectedIdRequest
                )[0].idRequestType,
            description: requests.filter(
              (req) => req.idRequest === selectedIdRequest
            )[0].description,
            date: requests.filter(
              (req) => req.idRequest === selectedIdRequest
            )[0].date,
          }
        : null
    );
  };

  const handleRequestDelete = async (selectedRequestId, event) => {
    const token = await authService.getAccessTokenIfValid();
    let path = "/api/request/delete/" + idPatient;
    const requestOptions = {
      method: "POST",
      body: JSON.stringify(
        requests.filter((req) => req.idRequest === selectedRequestId)[0]
      ),
      headers: !token
        ? {}
        : {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
    };
    fetch(path, requestOptions).then((response) => {
      if (response.ok) {
        setRequests(
          requests.filter((req) => req.idRequest !== selectedRequestId)
        );
        props.feedbackCallback("success", "Richiesta eliminata", true);
      } else {
        const error = response.text();
        console.log(error);
        props.feedbackCallback(
          "error",
          "Impossibile eliminare richiesta. Contiene delle visite?",
          true
        );
      }
    });
  };

  useEffect(() => {
    props.setRequest(currentRequest);
  }, [currentRequest]);

  useEffect(() => {
    setIdPatient(props.idPatient);
  }, [props.idPatient]);

  const getRequestTypes = async () => {
    const token = await authService.getAccessTokenIfValid();
    const response = await fetch("/api/request/type/bydoctor/" + userCtx.doctor.idDoctor, {
      headers: !token ? {} : { Authorization: `Bearer ${token}` },
    });
    const data = await response.json();
    setRequestTypes(data);
  };

  useEffect(() => {
    getRequestTypes();
  }, []);

  const submitUpdate = async (event) => {
    const token = await authService.getAccessTokenIfValid();
    let requestIndex = requests.findIndex(
      (req) => req.idRequest + "-description" === event.target.id
    );
    const requestOptions = {
      method: "POST",
      headers: !token
        ? { "Content-Type": "application/json" }
        : {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            Accept: "application/json",
          },
      body: JSON.stringify(requests[requestIndex]),
    };
    let path = "/api/request/" + idPatient;
    fetch(path, requestOptions).then(async (response) => {
      props.feedbackCallback("success", "Dati salvati con successo!", true);
    });
  };

  const addRequest = async () => {
    const token = await authService.getAccessTokenIfValid();
    if (newRequest.idRequestType !== placeHolderGuid) {
      const requestOptions = {
        method: "POST",
        headers: !token
          ? { "Content-Type": "application/json" }
          : {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
              Accept: "application/json",
            },
        body: JSON.stringify(newRequest),
      };
      let path = "/api/request/new/" + idPatient;
      fetch(path, requestOptions).then(async (response) => {
        if(!response.ok) {
          props.feedbackCallback("error", "Impossibile aggiungere richiesta", true);
          return;
        }
        const data = await response.json();
        setRequests([...requests, data]);
        setCurrentRequest({ ...newRequest, idRequest: data.idRequest });
        setNewRequest({ ...newRequest, description: "", date: null });
        setExpanded(data.idRequest);
        props.feedbackCallback("success", "Dati salvati con successo!", true);
      });
    }
  };

  useEffect(() => {
    const getRequests = async () => {
      const token = await authService.getAccessTokenIfValid();
      fetch("/api/request/" + idPatient, {
        headers: !token ? {} : { Authorization: "Bearer " + token },
      })
        .then(async (response) => {
          const data = await response.json();
          setLoading(false);
          // check for error response
          if (!response.ok) {
            // get error message from body or default to response statusText
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
          } else {
            if (data) {
              setRequests(data);
            }
          }
        })
        .catch((error) => {
          if (error) {
            console.error("There was an error!", error);
          }
        });
    };
    if (idPatient) {
      getRequests();
    } else {
      setLoading(false);
    }
    return;
  }, [idPatient]);

  const renderList = () => {
    if (loading) {
      return <Loading />;
    }
    return (
      <List>
        {requests.map((request) => {
          let filterType = requestTypes.find((type) => type.idRequestType === request.idRequestType);
          let filter = filterType && filterType.title ? filterType.title.toLowerCase() : "";

          //if there is typeInputValue, filter the list
          if (
            typeInputValue === "" ||
            typeInputValue === null ||
            typeInputValue === undefined ||
            (typeInputValue && (filter === typeInputValue.toLowerCase()))
          ) {
            return (
              <ListItem
                key={request.idRequest}
                id={request.idRequest}
                data-testid={request.idRequest}
                divider
                selected={expanded === request.idRequest}
                dense
                secondaryAction={
                  <IconButton
                    edge="end"
                    aria-label="delete"
                    onClick={(event) =>
                      handleRequestDelete(request.idRequest, event)
                    }
                    data-testid={request.idRequest + "-delete"}
                  >
                    <DeleteIcon />
                  </IconButton>
                }
              >
                <ListItemButton
                  id={request.idRequest}
                  onClick={handleRequestChange}
                  key={request.idRequest}
                  data-testid={request.idRequest + "-button"}
                >
                  <ListItemText
                    primary={
                      requestTypes.find(
                        (x) => x.idRequestType === request.idRequestType
                      )
                        ? requestTypes.find(
                            (x) => x.idRequestType === request.idRequestType
                          ).title
                        : null
                    }
                    secondary={new Date(request.date).toLocaleDateString(
                      "it-IT"
                    )}
                  />
                </ListItemButton>
              </ListItem>
            );
          } else {
            return null;
          }
        })}
      </List>
    );
  };

  return (
    <Card>
      <CardHeader
        title="Percorsi terapeutici"
        data-testid="cardTitle"
        titleTypographyProps={{
          variant: "body1",
        }}
      />
      <CardContent>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Typography variant="body1">Aggiungi nuovo</Typography>
          </Grid>
          <Grid item xs={10}>
            <Autocomplete
              data-testid="requestType-selector"
              required
              autoComplete
              clearOnEscape
              selectOnFocus
              clearOnBlur
              handleHomeEndKeys
              disableClearable
              onFocus={getRequestTypes}
              inputValue={typeInputValue}
              isOptionEqualToValue={(option, value) =>
                option.idRequestType === value.idRequestType
              }
              value={requestType || null}
              options={requestTypes}
              onInputChange={(event, newValue) => {
                setTypeInputValue(newValue);
              }}
              onChange={(event, value) => {
                setRequestType(value);
                setNewRequest({
                  ...newRequest,
                  idRequestType: value.idRequestType,
                  date: new Date().toJSON(),
                });
              }}
              getOptionLabel={(option) => {
                return option && option.title ? option.title : "";
              }}
              renderOption={(props, option) => (
                <Box
                  component="li"
                  sx={{ display: "flex", alignItems: "flex-end" }}
                  {...props}
                  key={option.idRequestType}
                >
                  <div className="d-flex justify-content-between w-100">
                    <div>{option.title}</div>
                  </div>
                </Box>
              )}
              id="requestTypeSearch"
              renderInput={(params) => (
                <TextField
                  data-testid="requestTypeInputField"
                  required
                  {...params}
                  label="Tipologia"
                  variant="filled"
                  size="small"
                />
              )}
            />
          </Grid>
          <Grid item xs={2}>
              <IconButton data-testid="Add-Request" onClick={addRequest} disabled={newRequest.idRequestType === placeHolderGuid || idPatient === undefined} color="inherit">
                <AddIcon />
              </IconButton>
          </Grid>
          <Grid item xs={12}>
            {renderList()}
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default RequestCard;
