import React, { useState } from "react";
import authService from "../../api-authorization/AuthorizeService";


import IconButton from "@mui/material/IconButton";
import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";
import "./Camera.css";

export const Camera = (props) => {
  const IdPatient = props.idPatient;
  const IdVisit = props.idVisit;
  const [image, setImage] = useState(null);
  

  const readImage = (event) => {
    props.busyReading(true);
    const [file] = event.target.files;
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = async () => {
      setImage(reader.result);
      const json = JSON.stringify(reader.result);
      const token =  await authService.getAccessTokenIfValid();
      const postUrl =
        "/api/filepatient/uploadfromcamera/" + IdPatient + "/" + IdVisit;
      const response = await fetch(postUrl, {
        method: "POST",
        body: json,
          headers: !token ? {} : {
            Authorization: `Bearer ${token}`, 'Content-Type': 'application/json' },
      });
        props.busyReading(false);

      if (response.ok) {
        const data = await response.json();
        props.returnClick(data);
      } else {
        const error = await response.text();
        console.log(error);
        props.feedbackCallback("error", "Errore nel caricamento", true);
        }
        // In any case clean the input control
        document.getElementById("inputFile").value = "";
    };
  };

  const RenderIcon = () => {
    if (props.buttonSize === "large") {
      return <AddPhotoAlternateIcon fontSize="large" color="action" />;
    }
    return <AddPhotoAlternateIcon size="small" />;
  };

  return (
    <>
        <IconButton variant="contained" component="label">
          {RenderIcon()}
          <input
            alt="Add Image"
            id="inputFile"
            type="file"
            hidden
            accept="image/*"
            onChange={readImage}
          />
        </IconButton>
    </>
  );
};

export default Camera;
