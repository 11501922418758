import React, { useEffect, useState, useContext, useRef } from "react";
import { useHistory } from "react-router-dom";
import {
  motion,
  useScroll,
  useSpring,
  useTransform,
  MotionValue,
  AnimatePresence,
} from "framer-motion";
import Loading from "../Shared/Loading";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import UserContext from "../UserContext";
import logo from "../../logo.png";
import homeScreen from "../../home_screen.png";
import "./LandingPage.css";

export const LandingPage = (props) => {
  const userCtx = useContext(UserContext);
  const [loading, setLoading] = useState(true);
  const [showVideo, setShowVideo] = useState(false);
  const [videoURL, setVideoURL] = useState("");

  const { scrollYProgress } = useScroll();
  const history = useHistory();

  const scaleX = useSpring(scrollYProgress, {
    stiffness: 100,
    damping: 30,
    restDelta: 0.001,
  });

  // useEffect(() => {
  //   const getVideoFile = async () => {
  //     const response = await fetch("/api/file/local/physicalFile/intro.mp4");

  //     if (response.ok) {
  //       const blob = await response.blob(); // return a blob
  //       const url = URL.createObjectURL(blob);
  //       if (url) {
  //         setVideoURL(url);
  //       } else {
  //         console.log("Errore durante la lettura del file video");
  //       }
  //     } else {
  //       const error = await response.text();
  //       console.log(error);
  //     }
  //   };

  //   getVideoFile();
  // }, []);

  const GoToRegisterPage = () => {
    history.push({
      pathname: "/account/register",
    });
  };

  //redirect to home if already logged in
  useEffect(() => {
    if (userCtx.idUser !== "") {
      history.push({
        pathname: "/home",
      });
    } 
  }, [userCtx.idUser]);

  //when video ends go back to top
  const handleVideoEnd = () => {
    //wait one second to let the video end
    setTimeout(() => {
      setShowVideo(false);
      window.scrollTo(0, 0);
    }, 2000);
  };

  const textes = [
    "Vuoi un unico strumento per gestire:",
    "appuntamenti",
    "cartelle cliniche",
    "fatture",
    "prescrizioni",
    "e molto altro ancora?",
  ];

  function useParallax(value, distance) {
    return useTransform(value, [0, 1], [-distance, distance]);
  }

  function Text({ id }) {
    const ref = useRef(null);
    const { scrollYProgress } = useScroll({ target: ref });
    const y = useParallax(scrollYProgress, 300);

    return (
      <div ref={ref}>
        <motion.h1
          animate={{ y: 100, opacity: 1 }}
          transition={{ ease: "easeOut", duration: 1 }}
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1, scale: 2 }}
          className="landing-big-text"
        >
          {textes[id]}
        </motion.h1>
      </div>
    );
  }

  const show = {
    opacity: 1,
  };

  const hide = {
    opacity: 0,
    transitionEnd: {
      display: "none",
    },
  };

  return (
    <>
      {/* {!showVideo ? ( */}
        {/* <> */}
          <Grid container height="100vh" className="top-banner">
            <Grid container item xs={6} height="50vh">
              <Grid item xs={12} >
                <motion.img
                  className="logo"
                  animate={{ opacity: 1, duration: 2, scale: 1, y: 100 }}
                  initial={{ opacity: 0, scale: 0.5 }}
                  src={logo}
                  alt="logo"
                />
                <motion.h2
                  className="left-banner-text"
                  animate={{ opacity: 1, duration: 4, y: 100 }}
                >
                  Ambulatorio intelligente
                </motion.h2>
              </Grid>
              <Grid item xs={12} >
                <motion.div
                  className="try-button"
                  animate={{ opacity: 1, duration: 1, scale: 1.1 }}
                  transition={{ ease: "linear", 
                  duration: 2, 
                  repeat: Infinity,
                  repeatType: "reverse", 
                  type: "spring",
                  damping: 10,
                  mass: 0.75,
                  stiffness: 100, }}
                >
                  <Button variant="contained" onClick={GoToRegisterPage}>Provalo ora</Button>
                </motion.div>
              </Grid>
            </Grid>
            <Grid item container xs={6} height="50vh" className="right-banner-text">
              <Grid item xs={12}>
              <motion.img
                  animate={{ opacity: 1, duration: 2, scale: 1.2, x: 50}}
                  initial={{ opacity: 0, scale: 0.5 }}
                  className="home-img" 
                  src={homeScreen} 
                  alt="home screen"  
                  width="100%"
                />
        
              </Grid>
              <Grid item xs={12}>
                <motion.h1
                  animate={{ opacity: 1, duration: 2 }}
                >
                  Cura i tuoi pazienti, <br /> Mosaycon si occupa del resto
                </motion.h1>
              </Grid>
              <Grid item xs={12}>
                <motion.h2
                  animate={{ opacity: 1, duration: 4 }}
                >
                  Il software che semplifica la gestione del tuo ambulatorio
                </motion.h2>
              </Grid>
            </Grid>
          </Grid>
          {/* {[0, 1, 2, 3, 4].map((text) => (
            <Text id={text} />
          ))}
          <motion.div
            animate={{ y: 100, opacity: 1 }}
            transition={{ ease: "easeOut", duration: 1 }}
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1, scale: 2 }}
            className="landing-big-text"
          >
            <Button variant="contained" onClick={() => setShowVideo(true)}>
              {" "}
              Show me{" "}
            </Button>
          </motion.div>
        </>
      ) : (
        videoURL && (
          <AnimatePresence>
            <motion.div
              className="landing-big-text"
              animate={showVideo ? show : hide}
            >
              <video
                autoPlay
                id="myVideo"
                height="100%"
                onEnded={handleVideoEnd}
              >
                <source src={videoURL} type="video/mp4" />
              </video>
            </motion.div>
          </AnimatePresence>
        )
      )} */}
    </>
  );
};

export default LandingPage;
