import React, { useEffect, useState, useContext } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import { PatientContext } from "../PatientContext";
import "./Prescription.css";
import { UserContext } from "../../UserContext";
import { GetDateItalianFormat } from "../../Shared/Utilities";


export const Prescription = React.forwardRef((props, ref) => {
    const userCtx = useContext(UserContext);
    const [patient, setPatient] = useContext(PatientContext);
    const [prescription, setPrescription] = useState(props.prescription);
    const [description, setDescription] = useState(props.prescription.description);

    const handleChange = (event) => {
        setDescription(event.target.value);
    }

    const handleBlur = (event) => {
        setDescription(event.target.value);
        let newPrescription = { ...prescription, description: event.target.value };
        setPrescription(newPrescription);
        props.onPrescriptionUpdate(newPrescription);
    };

    useEffect(() => {
        setPrescription(props.prescription);
        setDescription(props.prescription.description);
    }, [props.prescription]);

    const renderDoctorData = () => {
        return (
            <>
                <Typography align="center" variant="p" className="prnt-black">
                    STUDIO MEDICO <br />
                    {userCtx.doctor.title} {userCtx.doctor.name} {userCtx.doctor.surname} <br />
                    MEDICO CHIRURGO <br />
                    {userCtx.doctor.address} <br />
                    {userCtx.doctor.zipCode} {userCtx.doctor.city} <br />
                    Tel. {userCtx.doctor.phone} <br />
                    Mobile {userCtx.doctor.mobile}
                </Typography>
            </>
        );
    };

    return (
        <Box ref={ref} p={5} >
            <Grid container spacing={2} id="box">
                <Grid item xs={9} md={9}>
                    <Box display="flex" justifyContent="flex-start">
                        <p className="prnt-black h4">
                            {patient.name} {patient.surname} -
                            C.F. {patient.fiscalCode}
                        </p>
                    </Box>
                    <Box display="flex" justifyContent="flex-start">
                        <p className="prnt-black h6">
                            {GetDateItalianFormat(new Date(prescription.date))}
                        </p>
                    </Box>
                    <TextField
                        id="description"
                        className="text-wrapper"
                        variant="standard"
                        fullWidth
                        color="secondary"
                        InputProps={{ disableUnderline: true, classes: { input: "prnt-black" } }}
                        multiline
                        rows={50}
                        value={description}
                        onChange={handleChange}
                        onBlur={handleBlur}
                    ></TextField>
                </Grid>
                <Grid item xs={3} md={3} id="doctor">
                    <Box display="flex" justifyContent="center" >
                        {renderDoctorData()}
                    </Box>
                </Grid>
            </Grid>
        </Box>
    );
});

export default Prescription;
