import React, { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import authService from "../api-authorization/AuthorizeService";
import Loading from '../Shared/Loading';

export const BankGateway = (props) => {
    const [paymentData, setPaymentData] = useState({
        alias: "",
        amount: "",
        currency: "",
        transactionCode: "",
        urlResult_OK: "",
        urlResult_KO: "",
        macCode: "",
        languageId: "",
        url_BankSystem: ""
    });

    const amount = props.Amount;
    const transactionCode = props.TransactionCode;

    useEffect(() => {
        const GetBankParameters = async () => {

            const token = await authService.getAccessTokenIfValid();
            const getUrl = '/api/payment/gobank/request'
            const paymentRequestData = {
                amount: amount,
                ypatientsTransactionCode: transactionCode
            };

            const response = await fetch(getUrl, {
                method: 'POST',
                body: JSON.stringify(paymentRequestData),
                headers: !token ? {} : {
                    Authorization: `Bearer ${token}`, 'Content-Type': 'application/json' }
            });
    
            if (response.ok) {
                const data = await response.json();
                setPaymentData(data);
                // document.getElementById("bankForm").submit(); 
    
            } else {
                const error = await response.text();
                props.feedbackCallback("error", error, true);
            }
        };

        GetBankParameters();
    }, [amount, transactionCode]);

    useEffect(() => {
        if (paymentData.url_BankSystem !== "") {
            document.getElementById("bankForm").submit();
        }
    }, [paymentData.url_BankSystem]);

    return (
        < >
            <Loading message="Sto contattando la banca...."/>
            <form id="bankForm" method="post" action={paymentData.url_BankSystem }>
                <TextField
                    sx={{ display: { xl: 'none', xs: 'none' } }} 
                    id="alias"
                    key="alias"
                    name="alias"
                    value={paymentData.alias} />
                <TextField id="importo"
                    sx={{ display: { xl: 'none', xs: 'none' } }} 
                    key="importo"
                    name="importo"
                    value={paymentData.amount} />
                <TextField id="divisa"
                    sx={{ display: { xl: 'none', xs: 'none' } }} 
                    key="divisa"
                    name="divisa"
                    value={paymentData.currency} />
                <TextField id="codTrans"
                    sx={{ display: { xl: 'none', xs: 'none' } }} 
                    key="codTrans"
                    name="codTrans"
                    value={paymentData.transactionCode} />
                <TextField id="url"
                    sx={{ display: { xl: 'none', xs: 'none' } }} 
                    key="url"
                    name="url"
                    value={paymentData.urlResult_OK} />
                <TextField id="url_back"
                    sx={{ display: { xl: 'none', xs: 'none' } }} 
                    key="url_back"
                    name="url_back"
                    value={paymentData.urlResult_KO} />
                <TextField id="mac"
                    sx={{ display: { xl: 'none', xs: 'none' } }} 
                    key="mac"
                    name="mac"
                    value={paymentData.macCode} />
                <TextField id="languageId"
                    sx={{ display: { xl: 'none', xs: 'none' } }} 
                    key="languageId"
                    name="languageId"
                    value={paymentData.languageId} />
            </form>
        </>
    );
};

export default BankGateway;
