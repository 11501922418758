import React, { useState, useContext, useEffect } from "react";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import CropFreeIcon from "@mui/icons-material/CropFree";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import FilledInput from "@mui/material/FilledInput";
import InputAdornment from "@mui/material/InputAdornment";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import Checkbox from "@mui/material/Checkbox";
import Divider from "@mui/material/Divider";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Autocomplete from "@mui/material/Autocomplete";
import Tooltip from "@mui/material/Tooltip";
import { priceStringToNumberString } from "../../Shared/Utilities";
import authService from "../../api-authorization/AuthorizeService";
import ConfirmDialog from "../../Shared/ConfirmDialog";
import AddDrugDialog from "./AddDrugDialog";
import { ListItemSecondaryAction } from "@mui/material";

export const Treatment = (props) => {
    const [loading, setLoading] = useState(true);
    const [treatment, setTreatment] = useState(props.treatment);
    const [treatmentType, setTreatmentType] = useState(null);
    const [treatmentTypeInputValue, setTreatmentTypeInputValue] = useState("");
    const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
    const [addDrugDialogOpen, setAddDrugDialogOpen] = useState(false);
    const [drugs, setDrugs] = useState([]);
    const [suggestedDrugs, setSuggestedDrugs] = useState([]);

    const treatmentStatus = {
        new: 1,
        finished: 2,
        canceled: -1,
    };

    useEffect(() => {
        const getTreatmentsDrugs = async () => {
            const token = await authService.getAccessTokenIfValid();
            const response = await fetch(
                `/api/drug/byTreatment/${treatment.idTreatment}`,
                {
                    headers: !token ? {} : { Authorization: `Bearer ${token}` },
                }
            );
            const data = await response.json();
            let currentDrugs = [...drugs];
            data.forEach((element) => {
                if (
                    currentDrugs.find((x) => x.idDrug === element.idDrug) === undefined
                ) {
                    currentDrugs.push(element);
                }
            });
            setDrugs(currentDrugs);
        };
        if (treatment != null && treatment.idTreatment != null) {
            getTreatmentsDrugs();
        }
    }, []);

    useEffect(() => {
        if (
            treatmentType != null &&
            treatmentType !== undefined &&
            treatmentType.idTreatmentType !== undefined
        ) {
            setTreatment((t) => ({
                ...t,
                idTreatmentType: treatmentType.idTreatmentType,
            }));
        }
    }, [treatmentType]);

    useEffect(() => {
        if (treatment.idTreatmentType && props.treatmentTypes) {
            setTreatmentType(
                props.treatmentTypes.find(
                    (x) => x.idTreatmentType === treatment.idTreatmentType
                )
            );
        }
    }, [props.treatmentTypes, treatment.idTreatmentType]);

    const handleAddDrugDialogClose = (drug) => {
        setAddDrugDialogOpen(false);
    };

    const handleDialogAnswer = (selectedDrugs) => {
        setDrugs(drugs.concat(selectedDrugs));
        updateDrugsStock(selectedDrugs);
        setAddDrugDialogOpen(false);
    };

    const handleChange = (event) => {
        const { id, value } = event.target;
        if (id === "price") {
            setTreatment({ ...treatment, price: priceStringToNumberString(value) });
            props.setTreatment({ ...treatment, [id]: value });
        } else {
            setTreatment({ ...treatment, [id]: value });
            props.setTreatment({ ...treatment, [id]: value });
        }
    };

    const handleBlur = async () => {
        saveTreatment(treatment);
    };

    const setExecuted = async () => {
        if (treatment.idTreatment == null) {
            await saveTreatment(treatment);
        }
        await saveTreatment({ ...treatment, status: treatmentStatus.finished });
    };

    const handleDeleteDrug = async (drug) => {
        drug.idTreatment = "";
        drug.status = 1;
        const token = await authService.getAccessTokenIfValid();
        const response = await fetch("/api/drug/item/update", {
            method: "POST",
            headers: !token
                ? {}
                : {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "application/json",
                },
            body: JSON.stringify(drug),
        });
        if (!response.ok) {
            const error = response.statusText;
            props.feedbackCallback("error", "Errore nella rimoszione del farmaco", true);
            return Promise.reject(error);
        }
        setDrugs(drugs.filter((x) => x.idDrug !== drug.idDrug));
        props.feedbackCallback("success", "Farmaco eliminato con successo", true);
    };


    const updateDrugsStock = async (selectedDrugs) => {
        let usedDrugs = selectedDrugs.map((drug) => {
            drug.idTreatment = treatment.idTreatment;
            drug.status = -1;
            return drug;
        });

        const token = await authService.getAccessTokenIfValid();
        let path = "/api/drug/items/update";

        const requestOptions = {
            method: "POST",
            headers: !token
                ? { "Content-Type": "application/json" }
                : {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "application/json",
                },
            body: JSON.stringify(usedDrugs),
        };
        fetch(path, requestOptions).then(async (response) => {
            // check for error response
            if (!response.ok) {
                // get error message from body or default to response statusText
                const error = response.statusText;
                props.feedbackCallback("error", "Errore nel salvataggio", true);
                return Promise.reject(error);
            }

            props.feedbackCallback("success", "Dati salvati con successo", true);

        });
    };

    const saveTreatment = async (newTreatment) => {
        let token = await authService.getAccessTokenIfValid();
        let path = "/api/treatment/update";
        if (treatment.idTreatment === undefined) {
            path = "/api/treatment/create";
        }
        const requestOptions = {
            method: "POST",
            headers: !token
                ? { "Content-Type": "application/json" }
                : {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "application/json",
                },
            body: JSON.stringify(newTreatment),
        };
        fetch(path, requestOptions).then(async (response) => {
            const data = await response.json();
            // check for error response
            if (!response.ok) {
                // get error message from body or default to response statusText
                const error = (data && data.message) || response.statusText;
                props.feedbackCallback("error", "Errore nel salvataggio", true);
                return Promise.reject(error);
            } else {
                if (data) {
                    setTreatment(data);
                    if (treatment.idTreatment === undefined) {
                        props.setTreatment(data, true); //new treatment
                    } else {
                        props.setTreatment(data, false); //update treatment
                    }
                    props.feedbackCallback("success", "Dati salvati con successo", true);
                }
            }
        });
    };

    const handleDeleteConfirmation = async (confirmation) => {
        if (confirmation) {
            await props.onDelete(treatment, true);
        }
        setConfirmDialogOpen(false);
    };

    const handleDelete = async () => {
        if (treatment.status === treatmentStatus.finished || drugs.length > 0) {
            setConfirmDialogOpen(true);
            return;
        }
        await props.onDelete(treatment, false);
    };

    const renderDrugs = () => {
        if (drugs && drugs.length > 0) {
            return drugs.map((drug, index) => {
                return (
                    <ListItem dense key={index} disablePadding>
                        <ListItemButton role={undefined} onClick={() => { }} dense>
                            <ListItemText
                                id={drug.idDrugType}
                                primary={drug.name}
                                secondary={drug.sticker}
                            />
                        </ListItemButton>
                        <ListItemSecondaryAction>
                            <IconButton
                                edge="end"
                                aria-label="delete"
                                onClick={() => {
                                    let currentDrugs = [...drugs];
                                    currentDrugs.splice(index, 1);
                                    setDrugs(currentDrugs);
                                    handleDeleteDrug(drug);
                                }}
                            >
                                <DeleteIcon />
                            </IconButton>
                        </ListItemSecondaryAction>
                    </ListItem>
                );
            });
        } else {
            return (
                <ListItem dense key={0}>
                    <ListItemText primary="Nessun farmaco associato" />
                </ListItem>
            );
        }
    };

    const renderTools = () => {
        if (treatmentType && treatmentType.tools) {
            return treatmentType.tools.map((tool, index) => {
                return (
                    <ListItem dense key={index}>
                        <ListItemText primary={tool.name} />
                    </ListItem>
                );
            });
        } else {
            return (
                <ListItem dense key={0}>
                    <ListItemText primary="Nessuno strumento associato" />
                </ListItem>
            );
        }
    };

    return (
        <Card className="shadow bg-turquoise">
            <CardContent>
                <Grid container spacing={2} alignContent="center">
                    <Grid item xs={12} md={3} justifyContent="flex-start">
                        <InputLabel htmlFor="treatmentTypeSearch">Tipologia di intervento</InputLabel>
                        <Autocomplete
                            disabled={
                                treatment && treatment.status === treatmentStatus.finished
                            }
                            fullWidth
                            required
                            autoComplete
                            selectOnFocus
                            handleHomeEndKeys
                            disableClearable
                            inputValue={treatmentTypeInputValue}
                            isOptionEqualToValue={(option, value) =>
                                option.idTreatmentType === value.idTreatmentType
                            }
                            value={treatmentType || null}
                            options={props.treatmentTypes}
                            onInputChange={(event, newValue) => {
                                setTreatmentTypeInputValue(newValue);
                            }}
                            onChange={(event, value) => {
                                setTreatmentType(value);
                                saveTreatment({
                                    ...treatment,
                                    idTreatmentType: value.idTreatmentType,
                                    price: value.price,
                                });
                            }}
                            getOptionDisabled={(option) => { return option.idTreatmentType == undefined }}
                            getOptionLabel={(option) => {
                                return option.title;
                            }}
                            renderOption={(props, option) => (
                                <Box
                                    component="li"
                                    sx={{ display: "flex", alignItems: "flex-end" }}
                                    {...props}
                                    key={option.idTreatmentType}
                                >
                                    <div className="d-flex justify-content-between w-100">
                                        <div>{option.title}</div>
                                    </div>
                                </Box>
                            )}
                            id="treatmentTypeSearch"
                            renderInput={(params) => (
                                <TextField
                                    hiddenLabel
                                    size="small"
                                    id="treatmentTypeSearch"
                                    data-testid={
                                        props.treatment.idTreatment
                                            ? "typeInputField-" + props.treatment.idTreatment
                                            : "typeInputField"
                                    }
                                    required
                                    {...params}
                                    variant="filled"
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12} md={3} >
                        <Typography variant="subtitle">
                            <Grid container spacing={2} alignContent="center">
                                <Grid item xs={10}>
                                    Farmaci
                                </Grid>
                                {treatment.status !== treatmentStatus.finished &&
                                    treatmentType &&
                                    treatment.idTreatment && (
                                        <Grid item xs={2}>
                                            <IconButton
                                                edge="end"
                                                aria-label="add-drug"
                                                onClick={() => {
                                                    setAddDrugDialogOpen(true);
                                                }}
                                                color="inherit"
                                            >
                                                <AddIcon size="small" />
                                            </IconButton>
                                        </Grid>
                                    )}
                            </Grid>
                        </Typography>
                        <Divider />
                        <List dense={true} disablePadding={true}>
                            {renderDrugs()}
                        </List>
                        <Divider />
                    </Grid>
                    <Grid item xs={12} md={4}>

                        <InputLabel htmlFor="price">Note</InputLabel>
                        <FormControl
                            fullWidth
                            variant="filled"
                            disabled={!treatmentType}
                            hiddenLabel
                            id="notes"
                        >
                            <FilledInput
                                data-testid={
                                    props.treatment.idTreatment
                                        ? "notesInputField-" + props.treatment.idTreatment
                                        : "notesInputField"
                                }
                                minRows={4}
                                multiline
                                id="notes"
                                value={treatment.notes || ""}
                                onChange={handleChange}
                                onBlur={handleBlur}
                            />
                        </FormControl>
                    </Grid>
                    <Grid
                        item
                        container
                        xs={2}
                        md={2}
                        direction="column"
                        justifyContent="space-between"
                        alignItems="center"
                    >
                        <Grid item xs={6}>
                            <InputLabel htmlFor="price">Prezzo</InputLabel>
                            <FormControl
                                fullWidth
                                variant="filled"
                                disabled={
                                    !treatmentType ||
                                    treatment.status === treatmentStatus.finished
                                }
                                hiddenLabel
                            >
                                <FilledInput
                                    data-testid={
                                        props.treatment.idTreatment
                                            ? "priceInputField-" + props.treatment.idTreatment
                                            : "priceInputField"
                                    }
                                    size="small"
                                    id="price"
                                    value={(treatment && treatment.price) || ""}
                                    onChange={handleChange}

                                    startAdornment={
                                        <InputAdornment position="start">€</InputAdornment>
                                    }

                                    onBlur={handleBlur}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={2}>
                            <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={4}>

                                <Tooltip title="Premere per congelare lo stato">
                                    <Button
                                        disabled={
                                            (treatment && treatment.status === treatmentStatus.finished) || !treatmentType
                                        }
                                        variant="contained"
                                        onClick={setExecuted}
                                        aria-label="executed"
                                        align="center"
                                    >
                                        {treatment && treatment.status === treatmentStatus.finished
                                            ? "ESEGUITO"
                                            : "ESEGUI"}
                                    </Button>
                                </Tooltip>



                                <Tooltip title="Elimina il trattamento">
                                    <IconButton onClick={handleDelete} aria-label="delete" align="center" data-testid="treatmentDeleteIcon">
                                        <DeleteIcon />
                                    </IconButton>
                                </Tooltip>

                            </Stack>
                        </Grid>
                    </Grid>
                </Grid>
            </CardContent>
            <ConfirmDialog
                open={confirmDialogOpen}
                answerCallback={handleDeleteConfirmation}
                title="Attenzione - richiesta cancellazione!"
                text="Si è richiesta l'eliminazione del trattamento'. I farmaci verranno ricaricati in magazzino. L'azione è irreversibile. Procedere con l'operazione?"
                agreeText="Elimina"
                disagreeText="Annulla"
            />
            <AddDrugDialog
                open={addDrugDialogOpen}
                answerCallback={handleDialogAnswer}
                onClose={handleAddDrugDialogClose}
                treatment={treatment}
                feedbackCallback={props.feedbackCallback}
            />
        </Card>
    );
};

export default Treatment;
