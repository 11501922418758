import React from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import { getCountry, getCountriesSort, getCountryName } from "./Data.js";

export const CountriesSelect = (props) => {
  const getPatientCountry = () => {
    let _currentCountry = props.handleCountry();
    return _currentCountry;
  };

  const handleChange = (event, value) => {
    handleUpdateCountry(value && value.iso3 ? value.iso3 : "");
  };

  const handleBlur = () => {
    if (props.handleBlur) {
      props.handleBlur();
    }
  };

  const handleUpdateCountry = (newcod) => {
    if (props.handleChange) {
      props.handleChange(newcod);
    }
  };
  const getLanguage = () => {
    return "it";
  };

  const getOptions = () => {
    let _options = getCountriesSort(getLanguage());
    return !_options ? [{ name: "...", iso2: "", iso3: "", id: 0 }] : _options;
  };

  const getValue = () => {
    let _value = getCountry(getPatientCountry());
    return !_value ? { name: "...", iso2: "", iso3: "", id: 0 } : _value;
  };
  return (
    <Autocomplete
      id="countrySelect"
      options={getOptions()}
      autoHighlight
      getOptionLabel={(option) => getCountryName(option, getLanguage()) || ""}
      defaultValue={{ name: "...", iso2: "", iso3: "", id: 0 }}
      value={getValue()}
      renderOption={(props, option) => (
        <Box
          component="li"
          sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
          {...props}
          key={option["iso3"]}
        >
          <img
            loading="lazy"
            width="20"
            src={
              option
                ? `https://flagcdn.com/w20/${option["iso2"].toLowerCase()}.png`
                : ""
            }
            srcSet={
              option
                ? `https://flagcdn.com/w40/${option[
                    "iso2"
                  ].toLowerCase()}.png 2x`
                : ""
            }
            alt=""
          />
          {getCountryName(option, getLanguage())}
        </Box>
      )}
      renderInput={(params) => (
        <TextField
          fullWidth
          {...params}
          label="Nazione"
          inputProps={{
            ...params.inputProps,
            autoComplete: "new-password", // disable autocomplete and autofill
          }}
          variant="filled"
          size="small"
          data-testid={"country"}
        />
      )}
      onChange={handleChange}
      onBlur={handleBlur}
      variant="filled"
      size="small"
    />
  );
};
export default CountriesSelect;
