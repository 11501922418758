import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import authService from "../api-authorization/AuthorizeService";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Button from "@mui/material/Button";
import PatientSelect from "../Shared/PatientSelect.js";
import { Add, FileOpen } from "@mui/icons-material";

export const PatientsTile = () => {
  const history = useHistory();
  const [patient, setPatient] = useState();

  const GoToPatientFile = (event) => {
    event.preventDefault();
    if (patient.idPatient) {
      history.push({
        pathname: "/patient/" + patient.idPatient,
        state: { patient: patient },
      });
    }
  };

  const GoToNewPatientFile = () => {
    history.push({
      pathname: "/patient/",
      state: { patient: patient },
    });
  };


  return (
    <Card>
      <form onSubmit={GoToPatientFile}>
        <CardHeader
          title="Pazienti"
          titleTypographyProps={{
            variant: "body1",
          }}
        />
        <CardContent>
        <PatientSelect onPatientSelected={(selectedPatient) => {setPatient(selectedPatient)}} required={true}/>
        </CardContent>
        <CardActions>
          <Grid container spacing={2} justifyContent="center" alignItems="center">
            <Grid  item>
              <Button
                startIcon={<FileOpen />}
                type="submit"
                data-testid="openPatientButton"
                color="primary"
                variant="contained"
              >
                Apri scheda
              </Button>
            </Grid>
            <Grid  item>
              <Button
                startIcon={<Add />}
                data-testid="newPatientButton"
                onClick={GoToNewPatientFile}
                color="primary"
                variant="contained"
              >
                Nuovo paziente
              </Button>
            </Grid>
          </Grid>
        </CardActions>
      </form>
    </Card>
  );
};

export default PatientsTile;
