import React from "react";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import WarningIcon from '@mui/icons-material/Warning';
import "../../../custom.css";

export const ExpiredLicense = (props) => {

    return (
        <Grid container spacing={2}>
            <Grid
                item
                container
                xs={12}
                md={3}
                justifyContent="center"
                alignItems="center"
                spacing={2}
                direction="column"
            >
                <Grid item xs={2} md={2} className="w-100"></Grid>
            </Grid>
            <Grid item xs={12} md={9} className="vh-100">
                <Typography variant="h2" gutterBottom data-testid="welcome-title">
                    <WarningIcon fontSize="large" /> Attenzione
                </Typography>
                <Typography variant="h5" gutterBottom>
                    Non hai una regolare licenza attiva oppure la tua licenza di utilizzo del sistema è scaduta. <br />
                    Richiedi al tuo amministratore di acquistare una nuova licenza o rinnovare l'attuale.
                </Typography>
            </Grid>
        </Grid>
    );
};

export default ExpiredLicense;
