import authService from "../api-authorization/AuthorizeService";
import { emptyGuid } from "./Constants";

export const ImportRequestTypesOfSpecialization = async (specializationCode, idToDoctor, idCompany) => {
    let result = false;

    try {
        let idFromDoctor = await getTempleIdDoctorOfSpecialization(specializationCode);
        if (idFromDoctor === undefined) {
            return false;
        }
        result = await ImportRequestTypesFromDoctorToDoctor(idFromDoctor, idToDoctor, idCompany);    

        // Continua con il codice qui
    } catch (error) {
        console.error("Errore nella chiamata:", error);
    }
    return result;
};

export const ImportRequestTypesFromDoctorToDoctor = async (idFromDoctor, idToDoctor, idCompany) => {
    let requestTypesList = await getRequestTypes(idFromDoctor);
    let result = true;
    for (var requestType of requestTypesList) {
        result  = ( await ImportRequestTypeToDoctor(requestType, idToDoctor, idCompany) ) && result;
    }
    return result;
};

export const ImportRequestTypeToDoctor = async (requestType, idToDoctor, idCompany) => {
    let newRequestType = {
        idRequestType: emptyGuid,
        title: requestType.title,
        description: requestType.description,
        idDoctor: idToDoctor,
        status: "1",
        treatmentTypes: []
    };

    let newIdRequestType = await insertRequestType(newRequestType);
    if (newIdRequestType === undefined) {
        return; 
    }
    newRequestType.idRequestType = newIdRequestType;

    for (let treatmentType of requestType.treatmentTypes) {
        let newTreatmentType = {
            idTreatmentType: emptyGuid,
            idDoctor: idToDoctor,
            idCompany: idCompany,
            idSpecialization: treatmentType.idSpecialization,
            title: treatmentType.title,
            description: treatmentType.description,
            price: treatmentType.price,
            status: "1"
        }

        let newIdTreatmentType = await insertTreatmentType(newTreatmentType);
        if (newIdTreatmentType == undefined) {
            continue;
        }

        newTreatmentType.idTreatmentType = newIdTreatmentType;
        newRequestType.treatmentTypes = [...newRequestType.treatmentTypes, newTreatmentType];
    }
    return await updateRequestType(newRequestType);
}

const getTempleIdDoctorOfSpecialization = async (specializationCode) =>{
    const token = await authService.getAccessTokenIfValid();
    const response = await fetch("/api/specialization/" + specializationCode, {
        headers: !token ? {} : { Authorization: `Bearer ${token}` },
    });
    const data = await response.json();
    if (data !== undefined) {
        return data.idTemplateDoctor
    }
    return data;
}

const getRequestTypes = async (idDoctor) => {
    const token = await authService.getAccessTokenIfValid();
    const response = await fetch("/api/request/type/bydoctor/" + idDoctor, {
        headers: !token ? {} : { Authorization: `Bearer ${token}` },
    });
    const data = await response.json();
    return data;
};

const insertRequestType = async (newRequestType) => {
   
    let token = await authService.getAccessTokenIfValid();
    let path = "/api/request/type";
    let newId ;
    const requestOptions = {
        method: "POST",
        headers: !token
            ? { "Content-Type": "application/json" }
            : {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
                Accept: "application/json",
            },
        body: JSON.stringify(newRequestType),
    };
    await fetch(path, requestOptions).then(async (response) => {
        const data = await response.json();
        // check for error response
        if (!response.ok || data === undefined) {
            // get error message from body or default to response statusText
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        } else {
            newId = data.idRequestType;
        }
    });
    return newId;
}

const insertTreatmentType = async (newTreatmentType) => {
    let token = await authService.getAccessTokenIfValid();
    let path = "/api/treatment/create";
    let newId;
    const requestOptions = {
        method: "POST",
        headers: !token
            ? { "Content-Type": "application/json" }
            : {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
            },
        body: JSON.stringify(newTreatmentType),
    };
    await fetch(path, requestOptions).then(async (response) => {
        const data = await response.json();
        // check for error response
        if (!response.ok || data === undefined) {
            // get error message from body or default to response statusText
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        else {
            newId = data.idTreatmentType;
        }
    });
    return newId;
}

const updateRequestType = async (newRequestType) => {
    
    let token = await authService.getAccessTokenIfValid();
    let path = "/api/request/type/update";
    
    const requestOptions = {
        method: "POST",
        headers: !token
            ? { "Content-Type": "application/json" }
            : {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
                Accept: "application/json",
            },
        body: JSON.stringify(newRequestType),
    };
    await fetch(path, requestOptions).then(async (response) => {
        const data = await response.json();
        // check for error response
        if (!response.ok || data === undefined) {
            // get error message from body or default to response statusText
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        return response.ok
    });

}
