
export  const updateQuestionList = (questions, updatedQuestion, deleteQuestion) => {
    //find question in questions array
    let questionIndex = questions.findIndex(
      (question) =>
        question.idCustomDataField === updatedQuestion.idCustomDataField
    );
    let newQuestions = Array.from(questions, x => ({ ...x }));

    if (deleteQuestion) {
      // delete question
      if (questionIndex >= 0) {
        //should always be true
        newQuestions.splice(questionIndex, 1);
        //fill gap left by deleted question
        newQuestions.forEach((question) => {
          if (
            question.positionIndex > updatedQuestion.positionIndex &&
            question.section === updatedQuestion.section
          ) {
            if(question.positionIndex >0 ) question.positionIndex--;
          }
        });
      }
      newQuestions = sortQuestions(newQuestions);
      return newQuestions;
    }

    if (questionIndex >= 0) {
      // update question
      if(updatedQuestion.positionIndex > newQuestions[questionIndex].positionIndex) {
        //question moved up
        newQuestions.forEach((question) => {
          if (
            question.positionIndex === updatedQuestion.positionIndex &&
            question.section === updatedQuestion.section
          ) {
            if(question.positionIndex > 0) question.positionIndex--; //swap position with question at positionIndex
          }
        });
      }

      if(updatedQuestion.positionIndex < newQuestions[questionIndex].positionIndex) {
        //question moved down
        newQuestions.forEach((question) => {
          if (
            question.positionIndex === updatedQuestion.positionIndex &&
            question.section === updatedQuestion.section
          ) {
            question.positionIndex++; //swap position with question at positionIndex
          }
        });
      }
      newQuestions[questionIndex] = updatedQuestion;
      newQuestions = sortQuestions(newQuestions);
      return newQuestions;

    }

    // add question
    // find index of previous question
    let previousIndex = newQuestions.findIndex(
      (question) =>
        question.positionIndex === updatedQuestion.positionIndex - 1 &&
        question.section === updatedQuestion.section
    );
    //make space for new question
    newQuestions.forEach((question) => {
      if (
        question.positionIndex >= updatedQuestion.positionIndex &&
        question.section === updatedQuestion.section
      ) {
        question.positionIndex++;
      }
    });
    newQuestions.splice(previousIndex + 1, 0, updatedQuestion);

    return newQuestions;
  };

  export const sortQuestions = (questionList) => {

    questionList.sort((a, b) => {
      if (a.positionIndex < b.positionIndex) {
        return -1;
      }
      if (a.positionIndex > b.positionIndex) {
        return 1;
      }
      return 0;
    });
    return questionList;
  }