import React, { useState } from "react";
import QRCode from "react-qr-code";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import QrCodeScannerIcon from '@mui/icons-material/QrCodeScanner';
import Tooltip from "@mui/material/Tooltip";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogContentText,
  DialogActions,
} from "@mui/material";

export const ImageQRCode = (props) => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    props.onClose();
  };
  let baseUrl = window.location.href.split("/")[0]+"//"+window.location.href.split("/")[2];

  return (
    <>
      <IconButton onClick={handleOpen} variant="contained">
        <QrCodeScannerIcon />
      </IconButton>
      <Dialog open={open} onClose={handleClose} maxWidth="lg">
        <DialogContent>
          <Stack spacing={2} alignItems="center" p={5}>
            <DialogTitle>Acquisizione remota</DialogTitle>
            <QRCode
              size={256}
              value={`${baseUrl}/RemoteImageCapture/${props.idPatient}/${props.idVisit}`}
              title={`${baseUrl}/RemoteImageCapture/${props.idPatient}/${props.idVisit}`}
            />
            <DialogContentText>
              Inquadra con la fotocamera per accedere all'acquisizione remota
            </DialogContentText>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} aria-label="cancel">
            Termina acquisizione
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ImageQRCode;
