import authService from "../api-authorization/AuthorizeService";


export const sendEmail = async (from, to , subject, body, isBodyHtml, attachments) => {
        let email = {
            from: process.env.REACT_APP_NOREPLY_EMAIL,
            to: to,
            subject: subject,
            body: body,
            isBodyHtml: isBodyHtml,
            attachments: attachments
        };
        const token = await authService.getAccessTokenIfValid();
        const path = '/api/email';
        const requestOptions = {
            method: "POST",
            headers: !token
            ? { "Content-Type": "application/json" }
            : {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
                },
            body: JSON.stringify(email),
        };
        return fetch(path, requestOptions).then(async (response) => {
            const data = await response.json();
            // check for error response
            if (!response.ok) {
              // get error message from body or default to response statusText
              const error = (data && data.message) || response.statusText;
              console.log(Promise.reject(error));
              return false;
            } else {
              if (data) {
                return true;
              }
              return false;
            }
        });
}