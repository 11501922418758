import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import FilledInput from "@mui/material/FilledInput";
import InputAdornment from "@mui/material/InputAdornment";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import IconButton from "@mui/material/IconButton";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import authService from "../api-authorization/AuthorizeService";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import { priceStringToNumberString } from "../Shared/Utilities";
import Typography from "@mui/material/Typography";

export const DrugTypesManager = (props) => {
  const [drugTypes, setDrugTypes] = useState([]);
  const [drugType, setDrugType] = useState(null);

  const [inputValue, setInputValue] = useState("");

  //cleanup state on unmount
  useEffect(() => {
    return () => {
      setDrugType({
        name: "",
        category: "",
        aic: "",
        price: "",
        minQuantity: "",
        status: 1,
      });
      setInputValue("");
      setDrugTypes([]);
    };
  }, []);

  const filter = createFilterOptions();

  useEffect(() => {
    props.setDrugTypeCallback(drugType);
  }, [drugType]);

  const GetDrugTypes = async () => {
    const token = await authService.getAccessTokenIfValid();
    const response = await fetch("/api/drug/type", {
      headers: !token ? {} : { Authorization: `Bearer ${token}` },
    });
    const data = await response.json();
    if (!response.ok) {
      // get error message from body or default to response statusText
      const error = (data && data.message) || response.statusText;
      return Promise.reject(error);
    }
    if (data) {
      setDrugTypes(data);
    }
  };

  const handleChange = (event) => {
    const { id, value } = event.target;
    setDrugType({ ...drugType, [id]: value });
    if (id === "price") {
      setDrugType({ ...drugType, [id]: priceStringToNumberString(value) });
    }
  };

  const handleNew = () => {
    setDrugType({
      name: inputValue,
      category: "",
      aic: "",
      price: "",
      minQuantity: "",
      status: 1,
    });
  };

  const handleStatusChange = (event) => {
    setDrugType({ ...drugType, status: event.target.value });
  };

  const handleDelete = async () => {
    const token = await authService.getAccessTokenIfValid();
    const response = await fetch("/api/drug/type/delete", {
      method: "POST",
      headers: !token
        ? {}
        : {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            Accept: "application/json",
          },
      body: JSON.stringify(drugType),
    });
    if (!response.ok) {
      props.feedbackCallback(
        "error",
        "Impossibile eliminare il farmaco. Verificare se è in uso.",
        true
      );
      // get error message from body or default to response statusText
      const error = response.statusText;
      return Promise.reject(error);
    }
      props.feedbackCallback(
        "success",
        "Tipo di farmaco eliminato con successo",
        true
      );
      setDrugTypes(
        drugTypes.filter((x) => x.idDrugType !== drugType.idDrugType)
      );
      setDrugType(null);
      setInputValue("");
  };

  const handleBlur = async (id, event) => {
    let token = await authService.getAccessTokenIfValid();
    let path = "/api/drug/type/update";
    if (drugType.idDrugType === undefined || drugType.idDrugType === "") {
      path = "/api/drug/type";
    }
    const requestOptions = {
      method: "POST",
      headers: !token
        ? { "Content-Type": "application/json" }
        : {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            Accept: "application/json",
          },
      body: JSON.stringify(drugType),
    };
    fetch(path, requestOptions).then(async (response) => {
      const data = await response.json();
      // check for error response
      if (!response.ok) {
        // get error message from body or default to response statusText
        const error = (data && data.message) || response.statusText;
        props.feedbackCallback("error", "Errore nel salvataggio", true);
        return Promise.reject(error);
      } else {
        if (data) {
          setDrugType(data);
          props.feedbackCallback("success", "Dati salvati con successo", true);
        }
      }
    });
  };

  return (
    <Card
      sx={{
        boxShadow: 0,
      }}
    >
      <CardContent>
        <Typography gutterBottom variant="subtitle3" component="div">
          Farmaci
        </Typography>
        <Grid container spacing={2}>
          <Grid item container xs={12} spacing={2}>
            <Grid item xs>
              <Autocomplete
                autoHighlight
                autoSelect
                autoComplete
                clearOnEscape
                clearOnBlur
                selectOnFocus
                handleHomeEndKeys
                inputValue={inputValue}
                value={drugType || null}
                options={drugTypes}
                isOptionEqualToValue={(option, value) =>
                  option.idDrugType === value.idDrugType
                }
                onFocus={GetDrugTypes}
                onInputChange={(event, newValue) => {
                  setInputValue(newValue);
                }}
                onChange={(event, value) => {
                  setDrugType(value);
                }}
                getOptionLabel={(option) => {
                  return option.name;
                }}
                filterOptions={(options, params) => {
                  const filtered = filter(options, params);

                  const { inputValue } = params;
                  // Suggest the creation of a new value
                  const isExisting = options.some(
                    (option) => inputValue === option.name
                  );
                  if (inputValue !== "" && !isExisting) {
                    filtered.push({
                      inputValue,
                      name: inputValue,
                    });
                  }

                  return filtered;
                }}
                renderOption={(props, option) => (
                  <Box
                    component="li"
                    sx={{ display: "flex", alignItems: "flex-end" }}
                    {...props}
                    key={option.idDrugType}
                  >
                    <div className="d-flex justify-content-between w-100">
                      <div>{option.name}</div>
                    </div>
                  </Box>
                )}
                id="drugTypeSearch"
                renderInput={(params) => (
                  <TextField
                    size="small"
                    data-testid="drugTypeInputField"
                    {...params}
                    label="Cerca per nome"
                    variant="filled"
                  />
                )}
              />
            </Grid>
            <Grid item container xs={1} alignItems="center">
              <Grid item>
                <IconButton
                  data-testid="newDrugTypeButton"
                  onClick={handleNew}
                  color="inherit"
                  aria-label="add"
                  size="small"
                >
                  <AddIcon />
                </IconButton>
              </Grid>
            </Grid>
            {drugType && drugType.idDrugType && (
            <Grid item container xs={1} alignItems="center">
              <Grid item>
                <IconButton
                  data-testid="deleteDrugTypeButton"
                  onClick={handleDelete}
                  color="inherit"
                  aria-label="delete"
                  size="small"
                >
                  <DeleteIcon />
                </IconButton>
              </Grid>
            </Grid>
            )}
          </Grid>
          {drugType && (
            <Grid item container xs={12} spacing={2}>
          <Grid item xs={6}>
            <TextField
              size="small"
              id="name"
              fullWidth
              label="Nome"
              variant="filled"
              value={(drugType && drugType.name) || ""}
              onChange={handleChange}
              name="name"
              onBlur={handleBlur}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              size="small"
              id="category"
              fullWidth
              label="Categoria"
              variant="filled"
              value={(drugType && drugType.category) || ""}
              onChange={handleChange}
              name="category"
              onBlur={handleBlur}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              size="small"
              id="aic"
              fullWidth
              label="Codice AIC"
              variant="filled"
              value={(drugType && drugType.aic) || ""}
              onChange={handleChange}
              name="aic"
              onBlur={handleBlur}
            />
          </Grid>

          <Grid item xs={6}>
            <FormControl
              fullWidth
              variant="filled"
              disabled={!drugType || (drugType && drugType.name === "")}
            >
              <InputLabel htmlFor="price">Prezzo</InputLabel>
              <FilledInput
                size="small"
                id="price"
                value={(drugType && drugType.price) || ""}
                onChange={handleChange}
                startAdornment={
                  <InputAdornment position="start">€</InputAdornment>
                }
                label="Prezzo"
                onBlur={handleBlur}
              />
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <TextField
              size="small"
              id="minQuantity"
              fullWidth
              label="Quantità minima"
              variant="filled"
              type="number"
              value={(drugType && drugType.minQuantity) || ""}
              onChange={handleChange}
              name="minQuantity"
              onBlur={handleBlur}
            />
          </Grid>
          </Grid>
          )}
        </Grid>

        
      </CardContent>
    </Card>
  );
};

export default DrugTypesManager;
