import { useState, useEffect, useContext } from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import InputLabel from "@mui/material/InputLabel";
import IconButton from "@mui/material/IconButton";
import FilledInput from "@mui/material/FilledInput";
import InputAdornment from "@mui/material/InputAdornment";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import authService from "../api-authorization/AuthorizeService";
import Button from "@mui/material/Button";
import { IsValidPassword } from "../Shared/Utilities.js";
import Loading from "../Shared/Loading";
import UsersList from "./UsersList";
import Typography from "@mui/material/Typography";
import UserContext from "../UserContext";

import * as YpatientsConsts from "../Shared/Constants";

export const UsersManager = (props) => {
    const userCtx = useContext(UserContext);
    const [isAddingNewUser, setIsAddingNewUser] = useState(false);
    const [newUser, setNewUser] = useState({
        email: "",
        phoneNumber: "",
        password: "",
        roles: [],
    });

    const [confirmPassword, setConfirmPassword] = useState("");
    const [confirmPasswordError, setConfirmPasswordError] = useState("");
    const [passwordError, setPasswordError] = useState("");
    const [newPasswordError, setNewPasswordError] = useState("");
    const [showPassword, setShowPassword] = useState(false);

    const cleanForm = () => {
        setNewUser({
            email: "",
            password: "",
            phoneNumber: "",
            roles: [],
        });
        setConfirmPassword("");
        setConfirmPasswordError("");
        setPasswordError("");
        setNewPasswordError("");
    };

    //cleanup state on unmount
    useEffect(() => {
        return () => {
            cleanForm();
        }
    }, []);

    const handleSave = async () => {
        //validation of input
        //password format is invalid
        if (!IsValidPassword(newUser.password)) {
            setNewPasswordError(
                "La password deve avere minimo 8 caratteri, contenere almeno una lettera maiuscola, una lettera minuscola, un numero ed un carattere speciale"
            );
            return;
        }
        //confim password is not the same as new password
        if (newUser.password !== confirmPassword) {
            setConfirmPasswordError("Le password non corrispondono");
            return;
        }
        setIsAddingNewUser(true);
        let token = await authService.getAccessTokenIfValid();
        const path = "/api/account/register";
        const requestOptions = {
            method: "POST",
            headers: !token
                ? { "Content-Type": "application/json" }
                : {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "application/json",
                    Accept: "application/json",
                },
            body: JSON.stringify(newUser),
        };

        fetch(path, requestOptions).then(async (response) => {
            // check for error response
            if (!response.ok) {
                const data = await response.json();
                // get error message from body or default to response statusText
                const error = data || response.statusText;
                props.feedbackCallback(
                    "error",
                    "Errore nella registrazione dell'utente: " + error,
                    true
                );
                return Promise.reject(error);
            } else {

                props.feedbackCallback(
                    "success",
                    "Utente registrato con successo",
                    true
                );

                cleanForm();
            }
            setIsAddingNewUser(false);
        });
    };

    const handleChangePassword = (event) => {
        setNewUser({ ...newUser, password: event.target.value });
        setPasswordError("");
    };

    const handleChangeConfirmPassword = (event) => {
        setConfirmPassword(event.target.value);
        setConfirmPasswordError("");
    };

    const handleChangePhoneNumber = (event) => {
        setNewUser({ ...newUser, phoneNumber: event.target.value });
    };

    const handleChangeUsername = (event) => {
        setNewUser({ ...newUser, email: event.target.value });
    };

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    return (
        <Grid container spacing={2}>
            <Grid item xs={12} lg={7}>
                {isAddingNewUser && <Loading message="Aggiornamento utenti..." />}
                {!isAddingNewUser &&
                    <UsersList feedbackCallback={props.feedbackCallback} />}
            </Grid>
            <Grid item xs={12} lg={5}>
                <Card
                    sx={{
                        boxShadow: 0
                    }}
                >
                    <CardContent>
                        <Typography gutterBottom variant="subtitle3" component="div">
                            Aggiungi Utente
                        </Typography>
                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <TextField
                                    fullWidth
                                    id="username"
                                    variant="filled"
                                    label="E-mail"
                                    type="email"
                                    onChange={handleChangeUsername}
                                    value={newUser.email}
                                />
                            </Grid>

                            <Grid item xs={6}>
                                <FormControl variant="filled" fullWidth>
                                    <InputLabel htmlFor="new-password">Password</InputLabel>
                                    <FilledInput
                                        id="new-password"
                                        type={showPassword ? "text" : "password"}
                                        value={newUser.password}
                                        onChange={handleChangePassword}
                                        error={newPasswordError !== ""}
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={handleClickShowPassword}
                                                    onMouseDown={handleMouseDownPassword}
                                                    edge="end"
                                                >
                                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                                </IconButton>
                                            </InputAdornment>
                                        }
                                        label="newPassword"
                                    />
                                    <FormHelperText id="newPassword-helper-text" color="danger">
                                        {newPasswordError}
                                    </FormHelperText>
                                </FormControl>
                            </Grid>

                            <Grid item xs={6}>
                                <TextField
                                    fullWidth
                                    id="phoneNumber"
                                    variant="filled"
                                    label="Telefono"
                                    type="phoneNumber"
                                    value={newUser.phoneNumber}
                                    onChange={handleChangePhoneNumber}
                                />
                            </Grid>

                            <Grid item xs={6}>
                                <FormControl variant="filled" fullWidth>
                                    <InputLabel htmlFor="confirm-password">
                                        Conferma password
                                    </InputLabel>
                                    <FilledInput
                                        id="confirm-password"
                                        type={showPassword ? "text" : "password"}
                                        value={confirmPassword}
                                        onChange={handleChangeConfirmPassword}
                                        error={confirmPasswordError !== ""}
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={handleClickShowPassword}
                                                    onMouseDown={handleMouseDownPassword}
                                                    edge="end"
                                                >
                                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                                </IconButton>
                                            </InputAdornment>
                                        }
                                        label="confirmPassword"
                                    />
                                    <FormHelperText
                                        id="confirmPassword-helper-text"
                                        color="danger"
                                    >
                                        {confirmPasswordError}
                                    </FormHelperText>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} textAlign='center'>
                                <Button
                                    onClick={handleSave}
                                    disabled={userCtx.idCompany === YpatientsConsts.emptyGuid || userCtx.idCompany === ""}
                                >Registra nuovo utente</Button>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
    );
};

export default UsersManager;
