import React, { useState,  useEffect } from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";

export const SearchRequestType = (props) => {
    const [requestsType, setRequestsType] = useState([]);
    const handleAddRequest = async (_request) => {
        props.AddRequest(_request);
    }

    useEffect(() => {

    }, []);

    useEffect(() => {
        setRequestsType(props.requestsType);
    }, [props.requestsType]);

        return (
            <>
                <Autocomplete
                    id="requestSelect"
                    options={requestsType}
                    autoHighlight
                    getOptionLabel={(option) => option.title || ""}
                    defaultValue={""}
                    value={null}
                    renderOption={(props, option) => (
                        <Box
                            component="li"
                            sx={{ display: "flex", alignItems: "flex-end" }}
                            {...props}
                            key={option.title}
                        >
                            <div className="d-flex justify-content-between w-100">
                                <div>{option.title}</div>
                            </div>
                        </Box>
                    )}
                    renderInput={(params) => (
                        <TextField
                            size="small"
                            id="requestTypeSearch"
                            data-testid="typeInputField"
                            label="Aggiungi percorso"
                            {...params}
                            variant="filled"
                        />
                    )}
                    onChange={(event, value) => {
                        handleAddRequest(value);
                    }
                    }
                    variant="filled"
                    size="small"
                />

            </>
        );
    };


export default SearchRequestType;