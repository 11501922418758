import React, { useState, useContext } from "react";
import { useHistory } from "react-router-dom";
import ConstructionIcon from '@mui/icons-material/Construction';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Button from "@mui/material/Button";
import UserContext from "../UserContext";
import * as YpatientsConsts from "../Shared/Constants";
import authService from "../api-authorization/AuthorizeService";
import { ImportRequestTypesOfSpecialization } from "../Shared/RequestUtil.js";
import { StartTrial } from "../Shared/TrialUtil.js";

export const TrialTile = (props) => {
    const history = useHistory();
    const userCtx = useContext(UserContext);

    const specializationCode = "m_est";

    const StartTrialLicense = async (event) => {
        props.loading(true);
        if (await StartTrial(userCtx)) {
            await ImportRequestTypesOfSpecialization(specializationCode, userCtx.doctor.idDoctor, userCtx.idCompany);
        }
        //ricarica home
        props.loading(false);
    };
   
    return (
        <>{userCtx.license.idSubscription == '' && ( 
        <Card>
            <CardHeader
                title="Prova gratuita"
                titleTypographyProps={{
                    variant: "body1",
                }}
            />
            <CardContent>
                <Button fullWidth data-testid="trialButton" onClick={StartTrialLicense}>
                    <PlayCircleOutlineIcon fontSize="large" sx={{ fontSize: "80px" }} />
                    
                </Button>
            </CardContent>
        </Card>
    )}
    </>
    );
};

export default TrialTile;