import React, { useState, useContext, useEffect } from "react";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import authService from "../../api-authorization/AuthorizeService";
import Treatment from "./Treatment";
import { Button } from "@mui/material";
import Loading from "../../Shared/Loading";
import Typography from "@mui/material/Typography";


export const TreatmentsSection = (props) => {
  const [loading, setLoading] = useState(true);
  const [treatments, setTreatments] = useState([]);
  const [treatmentTypes, setTreatmentTypes] = useState([]);
  

  useEffect(() => {
    const getTreatments = async () => {
      let token = await authService.getAccessTokenIfValid();
      fetch("/api/treatment/byVisit/" + props.idVisit, {
        method: "GET",
        headers: !token
          ? { "Content-Type": "application/json" }
          : {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
              Accept: "application/json",
            },
      }).then(async (response) => {
        const data = await response.json();
        // check for error response
        if (!response.ok) {
          // get error message from body or default to response statusText
          const error = (data && data.message) || response.statusText;
          props.feedbackCallback(
            "error",
            "Errore nel caricamento dei trattamenti",
            true
          );
          return Promise.reject(error);
        } else {
          if (data) {
            setTreatments(data);
            setLoading(false);
          }
        }
      });
    };
    const getTreatmentTypes = async () => {
      let token = await authService.getAccessTokenIfValid();
      fetch("/api/treatment/type", {
        method: "GET",
        headers: !token
          ? { "Content-Type": "application/json" }
          : {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
              Accept: "application/json",
            },
      }).then(async (response) => {
        const data = await response.json();
        // check for error response
        if (!response.ok) {
          // get error message from body or default to response statusText
          const error = (data && data.message) || response.statusText;
          props.feedbackCallback(
            "error",
            "Errore nel caricamento dei trattamenti",
            true
          );
          return Promise.reject(error);
        } else {
            if (data) {
                let _data = sortWithRequestTypeId(data, props.idRequestType);
                setTreatmentTypes(_data);
            }
        }
      });
    };
    getTreatmentTypes();
    getTreatments();
  }, []);

  const setTreatment = (treatment, isNew) => {
    if (isNew) {
      setTreatments(
        treatments.map((t) => (t.idTreatment === undefined ? treatment : t))
      );
      return;
    }
    setTreatments(
      treatments.map((t) =>
        t.idTreatment === treatment.idTreatment ? treatment : t
      )
    );
  };

  const addTreatment = () => {
    setTreatments([
      ...treatments,
      {
        idTreatment: undefined,
        notes: "",
        price: "",
        status: 1,
        idVisit: props.idVisit,
      },
    ]);
  };



  const deleteTreatment = async (treatment, force) => {
    if (treatment.idTreatment !== undefined) {
      
      let token = await authService.getAccessTokenIfValid();
      let path = "/api/treatment/delete";
      if (force) {
        path = "/api/treatment/delete/force";
      }
      const requestOptions = {
        method: "POST",
        headers: !token
          ? { "Content-Type": "application/json" }
          : {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
              Accept: "application/json",
            },
        body: JSON.stringify(treatment),
      };
      fetch(path, requestOptions).then(async (response) => {
        // check for error response
        if (!response.ok) {
          // get error message from body or default to response statusText
          props.feedbackCallback(
            "error",
            "Errore nell'eliminazione del trattamento",
            true
          );
          return;
        }
        const data = await response.json();
        if (data) {
          setTreatments(treatments.filter((t) => t.idTreatment !== treatment.idTreatment));
        }
        props.feedbackCallback(
          "success",
          "Trattamento eliminato con successo",
          true
        );
      });
    }
    else {
      setTreatments(treatments.filter((t) => t.idTreatment === undefined));
    }
  };

    const sortWithRequestTypeId = (treatmentTypes, idRequestType) => {
        if (idRequestType == undefined) {
            return treatmentTypes;
        }
        const treatmentsWithIdRequestType = treatmentTypes.filter((treatment) => {
            return treatment.requestTypes.some((request) => request.idRequestType == idRequestType);
        });
       
        const treatmentsWithoutIdRequestType = treatmentTypes.filter(treatment => {
            return !treatmentsWithIdRequestType.some(treatmentWithIdRequestType => {
                return treatment.idTreatmentType === treatmentWithIdRequestType.idTreatmentType;
            });
        });

        const requestTreatmentLabel = { idTreatment: undefined, title: '--- trattamenti del percorso corrente ---' }
        const otherTreatmentLabel = { idTreatment: undefined, title: '--- altri trattamenti ---' }

        if (treatmentsWithIdRequestType.length > 0 && treatmentsWithoutIdRequestType.length > 0) {
            return [requestTreatmentLabel, ...treatmentsWithIdRequestType, otherTreatmentLabel, ...treatmentsWithoutIdRequestType];
        }
        else {
            return [...treatmentsWithIdRequestType, ...treatmentsWithoutIdRequestType];
        }
    }
  const renderTreatments = () => {
    return treatments.map((treatment) => {
      return (
        <Grid
          item
          xs={12}
          key={treatment.idTreatment ? treatment.idTreatment : "new"}
        >
          <Treatment
            treatmentTypes={treatmentTypes}
            treatment={treatment}
            setTreatment={setTreatment}
            feedbackCallback={props.feedbackCallback}
            onDelete={deleteTreatment}
          />
        </Grid>
      );
    });
  };

  return (
    <>
        <Typography gutterBottom variant="subtitle3" component="div">
          Interventi
        </Typography>
        <Grid container spacing={2} justifyContent="flex-start" p={2}>
          {loading ? (
            <Loading message="Caricamento interventi" />
          ) : (
            renderTreatments()
          )}
          <Grid
            item
            container
            xs={12}
            md={12}
            alignContent="center"
            justifyContent="center"
          >
            <Grid>
              <Button variant="contained" onClick={addTreatment}>
                Aggiungi intervento
              </Button>
            </Grid>
          </Grid>
        </Grid>
    </>
  );
};

export default TreatmentsSection;
