import React, { useState, useContext, useEffect } from "react";
import { Route, Routes, Router, Link, matchPath, useLocation, useHistory, Redirect } from "react-router-dom";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import Divider from "@mui/material/Divider";
import CardHeader from "@mui/material/CardHeader";
import FeedbackAlert from "../Shared/FeedbackAlert";
import Box from "@mui/material/Box";
import CardContent from "@mui/material/CardContent";
import DrugsManager from "./DrugsManager";
import ToolsManager from "./ToolsManager";
import TreatmentTypesManager from "./TreatmentTypesManager";
import RequestTypesManager from "./RequestTypesManager";
import DocumentsManager from "../Shared/Document/DocumentsManager";
import ExpiredLicense from "../Shared/Licenses/ExpiredLicense";
import UserContext from "../UserContext";

export const Warehouse = (props) => {
    const userCtx = useContext(UserContext);
    const history = useHistory();
    const [value, setValue] = useState(history.location.pathname);

    const [feedback, setFeedback] = useState({
        severity: "success",
        text: "Success",
        open: false,
    });

    //cleanup state on unmount
    useEffect(() => {
        return () => {
            setFeedback({ ...feedback, open: false });
        }
    }, []);

    useEffect(() => {
        setValue(history.location.pathname);
    }, [history.location.pathname]);

    const alertClosed = () => {
        setFeedback({ ...feedback, open: false });
    };

    const feedbackCallback = (severity, text, open) => {
        setFeedback({ severity: severity, text: text, open: open });
    };


    return (
        <>
            {userCtx.isValidLicense && userCtx.roles.includes("Doctor") &&
                <Router history={history}>
                    <Box p={2}>
                        <Card>
                            <CardHeader title="Gestione inventario" />
                            <CardContent
                                sx={{
                                    flexGrow: 2,
                                    display: "flex",
                                    height: "90%",
                                }}
                            >
                                <Grid container spacing={1}>
                                    <Grid item xs={2}>
                                        <Tabs
                                            value={value}
                                            aria-label="account settings tabs"
                                            textColor="inherit"
                                            indicatorColor="primary"
                                            orientation="vertical"
                                        >
                                            <Tab
                                                label="Percorsi terapeutici"
                                                id="requests"
                                                value="/warehouse/requests"
                                                to="/warehouse/requests"
                                                component={Link}
                                            />
                                            <Tab
                                                label="Interventi"
                                                id="treatments"
                                                value="/warehouse/treatments"
                                                to="/warehouse/treatments"
                                                component={Link}
                                            />
                                            <Tab
                                                label="Farmaci / Medical devices"
                                                id="drugs"
                                                value="/warehouse/drugs"
                                                to="/warehouse/drugs"
                                                component={Link}
                                            />
                                            <Tab
                                                label="Strumenti"
                                                id="tools"
                                                value="/warehouse/tools"
                                                to="/warehouse/tools"
                                                component={Link}
                                            />
                                            <Tab
                                                label="Template Documenti"
                                                id="documents"
                                                value="/warehouse/documents"
                                                to="/warehouse/documents"
                                                component={Link}
                                            />
                                            <Tab label="" icon={<Divider className="w-100" />} disabled />
                                        </Tabs>
                                    </Grid>
                                    <Grid item xs={10}>
                                        {/* Tabs handled by react-router-dom */}
                                        <Route exact path="/warehouse">
                                            <Redirect to="/warehouse/requests" />
                                        </Route>
                                        <Route path="/warehouse/requests">
                                            <RequestTypesManager
                                                feedbackCallback={feedbackCallback}
                                            />
                                        </Route>
                                        <Route path="/warehouse/treatments">
                                            <TreatmentTypesManager
                                                feedbackCallback={feedbackCallback}
                                            />
                                        </Route>
                                        <Route path="/warehouse/drugs">
                                            <DrugsManager feedbackCallback={feedbackCallback} />
                                        </Route>
                                        <Route path="/warehouse/tools">
                                            <ToolsManager
                                                feedbackCallback={feedbackCallback}
                                            />
                                        </Route>
                                        <Route path="/warehouse/documents">
                                            <DocumentsManager
                                                feedbackCallback={feedbackCallback}
                                            />
                                        </Route>
                                        <FeedbackAlert
                                            severity={feedback.severity}
                                            text={feedback.text}
                                            open={feedback.open}
                                            closedCallback={alertClosed}
                                        />
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                    </Box>
                </Router>
            }
            {(!userCtx.isValidLicense || !userCtx.roles.includes("Doctor")) &&
                <Grid container spacing={2} p={2} className="vh-100">
                    <ExpiredLicense />
                </Grid>
            }
        </>
    );
};

export default Warehouse;
