import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import TextField from "@mui/material/TextField";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import CardActions from "@mui/material/CardActions";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import isEmail from "validator/lib/isEmail";
import { IsValidPassword } from "../Shared/Utilities.js";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import FilledInput from "@mui/material/FilledInput";
import InputAdornment from "@mui/material/InputAdornment";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Visibility from "@mui/icons-material/Visibility";
import IconButton from "@mui/material/IconButton";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import Container from "@mui/material/Container";
import { Loading } from "../Shared/Loading.js";
import HtmlViewer from "../Shared/HtmlViewer.js";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import FeedbackAlert from "../Shared/FeedbackAlert.js";
import Tooltip from "@mui/material/Tooltip";
import Stack from "@mui/material/Stack";

export const Register = () => {
  const history = useHistory();
  const [newUser, setNewUser] = useState({
    email: "",
    password: "",
    confirmPassword: "",
    phoneNumber: "",
    termconditionText: "",
    privacyText: "",
  });

  const [isValidEmail, setIsValidEmail] = useState(true);
  const [errorMessageEmail, setErrorMessageEmail] = useState("mail non valida");
  const [isValidPW, setIsValidPW] = useState(true);
  const [matchPassword, setMatchPassword] = useState(true);
  const [open, setOpen] = useState(false);

  const [isEmptyEmail, setIsEmptyEmail] = useState(true);
  const [isEmptyPassword, setIsEmptyPassword] = useState(true);
  const [isEmptyConfirmPassword, setIsEmptyConfirmPassword] = useState(true);
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [privacyPolicyAccepted, setPrivacyPolicyAccepted] = useState(false);
  const [privacyPolicyViewed, setPrivacyPolicyViewed] = useState(false);
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [termsViewed, setTermsViewed] = useState(false);
  const [openTerms, setOpenTerms] = useState(false);
  const [openPrivacyPolicy, setOpenPrivacyPolicy] = useState(false);
  const [feedback, setFeedback] = useState({
    severity: "success",
    text: "Success",
    open: false,
  });

  const supportEmail = process.env.REACT_APP_SUPPORT_EMAIL;

  //cleanup state on unmount
  useEffect(() => {
    return () => {
      setNewUser({
        id: "",
        email: "",
        password: "",
        confirmPassword: "",
        phoneNumber: "",
        termconditionText: "",
        privacyText: "",
      });
      setIsValidEmail(true);
      setErrorMessageEmail("mail non valida");
      setIsValidPW(true);
      setMatchPassword(true);
      setIsEmptyEmail(true);
      setIsEmptyPassword(true);
      setIsEmptyConfirmPassword(true);
      setShowPassword(false);
      setLoading(false);
    };
  }, []);

  const alertClosed = () => {
    setFeedback({ ...feedback, open: false });
  };

  const handleClose = () => {
    setOpen(false);
    GoToLoginPage();
  };

  const GoToLoginPage = () => {
    history.push({
      pathname: "/Account/login",
    });
  };

  const feedbackCallback = (severity, text, open) => {
    setFeedback({ severity: severity, text: text, open: open });
  };

  const handlePhoneChange = (event) => {
    const { id, value } = event.target;
    if (id) {
      if (value) {
        setNewUser({
          ...newUser,
          phoneNumber: value,
        });
      } else {
        setNewUser({
          ...newUser,
          phoneNumber: "",
        });
      }
    }
  };

  const handleEmailChange = (event) => {
    const { id, value } = event.target;
    if (id) {
      if (value) {
        if (isEmail(value)) {
          setIsValidEmail(true);
          setErrorMessageEmail("");
          setIsEmptyEmail(false);
          setNewUser({
            ...newUser,
            email: value,
          });
        } else {
          setIsEmptyEmail(false);
          setIsValidEmail(false);
          setErrorMessageEmail("mail non valida");
        }
      } else {
        setIsEmptyEmail(true);
        setNewUser({
          ...newUser,
          email: "",
        });
      }
    }
  };

  const handlePasswordChange = (event) => {
    const { id, value } = event.target;
    if (id) {
      if (value) {
        if (IsValidPassword(value)) {
          setIsEmptyPassword(false);
          setIsValidPW(true);
          if (
            newUser.confirmPassword === "" ||
            newUser.confirmPassword === value
          ) {
            setMatchPassword(true);
          } else {
            setMatchPassword(false);
          }

          setNewUser({
            ...newUser,
            password: value,
          });
        } else {
          setIsEmptyPassword(false);
          setIsValidPW(false);
        }
      } else {
        setIsEmptyPassword(true);
        setNewUser({
          ...newUser,
          password: "",
        });
      }
    }
  };

  const handleConfirmPasswordChange = (event) => {
    const { id, value } = event.target;
    if (id) {
      if (value != "") {
        if (newUser.password === value) {
          setIsEmptyConfirmPassword(false);
          setMatchPassword(true);
          setNewUser({
            ...newUser,
            confirmPassword: value,
          });
        } else {
          setIsEmptyConfirmPassword(false);
          setMatchPassword(false);
        }
      } else {
        setMatchPassword(true);
        setIsEmptyConfirmPassword(true);
        setNewUser({
          ...newUser,
          confirmPassword: "",
        });
      }
    }
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const RegisterSubmit = async (event) => {
    setLoading(true);
    event.preventDefault();
    let path = "/api/account/register";
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(newUser),
    };

    fetch(path, requestOptions).then(async (response) => {
      setLoading(false);
      // check for error response
      if (!response.ok) {
        // get error message from body or default to response statusText
        const errorMes = await response.text();

        setIsValidEmail(false);
        setErrorMessageEmail(errorMes);
      } else {
        const data = await response.json();
          if (data) {
          setOpen(true);
        }
      }
    });
  };


  const handlePrivacyPolicyClosed = () => {
    setOpenPrivacyPolicy(false);
    setPrivacyPolicyViewed(true);
  };

  const handlePrivacyPolicyAccepted = () => {
    setOpenPrivacyPolicy(false);
    setPrivacyPolicyViewed(true);
    setPrivacyPolicyAccepted(true);
  };

  const handleTermsClosed = () => {
    setOpenTerms(false);
    setTermsViewed(true);
  };

  const handleTermsAccepted = () => {
    setOpenTerms(false);
    setTermsViewed(true);
    setTermsAccepted(true);
  };
  const termHtmlCallback = (html) => {
    setNewUser({
      ...newUser,
      termconditionText: html,
    });
  };
  const privacyHtmlCallback = (html) => {
    setNewUser({
      ...newUser,
      privacyText: html,
    });
  };

  var cantRegister =
    isValidEmail === false ||
    isValidPW === false ||
    matchPassword === false ||
    isEmptyEmail ||
    isEmptyPassword ||
    isEmptyConfirmPassword ||
    !termsViewed ||
    !privacyPolicyViewed ||
    !termsAccepted ||
    !privacyPolicyAccepted;

  return (
    <Container>
      <Grid container spacing={2} p={6}>
        <Grid item xs={8}>
          <section>
            <h1>Nuovo utente?</h1>
            <h2>Crea un account per iniziare</h2>
            <hr />
            <p>
              Mosaycon è un'applicazione per la gestione di ambulatori medici.
              Registrati per scoprire tutte le funzionalità. Potrai attivare
              immediatamente una licenza di prova, senza impegno.
            </p>
            <p>
              Serve aiuto? Scrivi a{" "}
              <a href={`mailto:${supportEmail}`}>
                {supportEmail}
              </a>
              .
            </p>
            <small>
              I nostri tecnici ti risponderanno con una soluzione o ti
              chiameranno per aiutarti.
            </small>{" "}
          </section>
        </Grid>
        <Grid item xs={4}>
          <Card>
            <Dialog
              open={open}
              onClose={handleClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">
                {"Registrazione avvenuta con successo"}
              </DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  Abbiamo solo bisogno di verificare che l'indirizzo email
                  inserito sia corretto. Controlla la tua email per completare
                  la registrazione, ti abbiamo inviato un'email con un link di
                  conferma.
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleClose}>Ok</Button>
              </DialogActions>
            </Dialog>
            <CardHeader
              title="Dati di registrazione"
              titleTypographyProps={{
                variant: "h6",
              }}
            />
            <CardContent>
              <Grid key="name" item xs={12}>
                <TextField
                  sx={{
                    mb: 2,
                  }}
                  fullWidth
                  id="email"
                  key="email"
                  name="email"
                  label="E-mail *"
                  variant="filled"
                  size="small"
                  error={isValidEmail === false}
                  helperText={isValidEmail === false ? errorMessageEmail : ""}
                  onChange={handleEmailChange}
                ></TextField>
                <FormControl
                  variant="filled"
                  fullWidth
                  sx={{
                    mb: 2,
                  }}
                >
                  <InputLabel htmlFor="password">Password *</InputLabel>
                  <FilledInput
                    id="password"
                    type={showPassword ? "text" : "password"}
                    key="password"
                    name="password"
                    label="password"
                    variant="filled"
                    size="small"
                    error={isValidPW === false}
                    onChange={handlePasswordChange}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                  <FormHelperText
                    id="Password-helper-text"
                    color="error"
                    error={isValidPW === false}
                  >
                    {isValidPW
                      ? ""
                      : "La password deve avere minimo 8 caratteri, contenere almeno una lettera maiuscola, una lettera minuscola, un numero e un carattere speciale"}
                  </FormHelperText>
                </FormControl>
                <FormControl
                  variant="filled"
                  fullWidth
                  sx={{
                    mb: 2,
                  }}
                >
                  <InputLabel htmlFor="confirmPassword">
                    Conferma Password *
                  </InputLabel>
                  <FilledInput
                    id="confirmPassword"
                    type={showPassword ? "text" : "password"}
                    key="confirmPassword"
                    name="confirmPassword"
                    variant="filled"
                    size="small"
                    onChange={handleConfirmPasswordChange}
                    error={matchPassword === false}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                  <FormHelperText
                    id="Password-helper-text"
                    color="error"
                    error={matchPassword === false}
                  >
                    {matchPassword ? "" : "le password non coincidono"}
                  </FormHelperText>
                </FormControl>
                <TextField
                  sx={{
                    mb: 2,
                  }}
                  fullWidth
                  id="phoneNumber"
                  key="phoneNumber"
                  name="phoneNumber"
                  label="Phone Number"
                  variant="filled"
                  size="small"
                  onChange={handlePhoneChange}
                ></TextField>
              </Grid>
              <Grid key="terms" item xs={12}>
                <Typography variant="body2" align="justify">
                  Prima di registrarsi è necessario leggere ed accettere i
                  termini e condizioni d'uso e l'informativa sulla privacy.
                </Typography>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={termsAccepted}
                      data-testid="terms-check"
                    />
                  }
                  onChange={() => {
                    if (!termsViewed) {
                      setOpenTerms(true);
                    } else {
                      setTermsAccepted(!termsAccepted);
                    }
                  }}
                  label={
                    <Typography variant="body2">
                      Accetto i&nbsp;
                      <Link
                        component="button"
                        variant="body2"
                        color="inherit"
                        data-testid="terms-link"
                        onClick={() => {
                          setOpenTerms(true);
                        }}
                      >
                        termini e le condizioni.
                      </Link>
                    </Typography>
                  }
                ></FormControlLabel>
              </Grid>
              <Grid key="privacy" item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={privacyPolicyAccepted}
                      data-testid="privacy-check"
                    />
                  }
                  onChange={() => {
                    if (!privacyPolicyViewed) {
                      setOpenPrivacyPolicy(true);
                    } else {
                      setPrivacyPolicyAccepted(!privacyPolicyAccepted);
                    }
                  }}
                  label={
                    <Typography variant="body2">
                      Accetto&nbsp;
                      <Link
                        data-testid="privacy-link"
                        component="button"
                        variant="body2"
                        color="inherit"
                        onClick={() => {
                          setOpenPrivacyPolicy(true);
                        }}
                      >
                        l'informativa sulla Privacy.
                      </Link>
                    </Typography>
                  }
                />
              </Grid>
              <Dialog
                open={openTerms}
                onClose={handleTermsClosed}
                aria-labelledby="form-dialog-title"
              >
                <DialogTitle id="form-dialog-title">
                  Termini e condizioni
                </DialogTitle>
                <DialogContent>
                  <HtmlViewer
                    fileName="terms.html"
                    feedbackCallback={feedbackCallback}
                    htmlCallback={termHtmlCallback}
                  />
                </DialogContent>
                <DialogActions>
                  <Button
                    onClick={handleTermsAccepted}
                    color="primary"
                    data-testid="accept-button"
                  >
                    Accetto
                  </Button>
                </DialogActions>
              </Dialog>
              <Dialog
                open={openPrivacyPolicy}
                onClose={handlePrivacyPolicyClosed}
                aria-labelledby="form-dialog-title"
              >
                <DialogTitle id="form-dialog-title">
                  Informativa sulla privacy
                </DialogTitle>
                <DialogContent>
                  <HtmlViewer
                    fileName="privacy.html"
                    feedbackCallback={feedbackCallback}
                    htmlCallback={privacyHtmlCallback}
                  />
                </DialogContent>
                <DialogActions>
                  <Button
                    onClick={handlePrivacyPolicyAccepted}
                    color="primary"
                    data-testid="accept-button"
                  >
                    Accetto
                  </Button>
                </DialogActions>
              </Dialog>
            </CardContent>
            <CardActions>
              <div className="w-100 d-flex justify-content-center pb-3">
                <Stack direction="column" spacing={2} textAlign="center">
                  {loading ? (
                    <Loading />
                  ) : (
                    <Tooltip title={cantRegister ? "Completare i campi obbligatori per registrarsi" : "Clicca qui per registrarti" }  placement="top" followCursor={true}> 
                      <span>
                        <Button
                          sx={{
                            m: 0,
                          }}
                          type="button"
                          onClick={RegisterSubmit}
                          data-testid="registerButton"
                          color="primary"
                          variant="contained"
                          disabled={cantRegister}
                        >
                          Registra
                        </Button>
                      </span>
                    </Tooltip>
                  )}
                  <Typography variant="body2">
                    Già registrato? &nbsp;
                    <Link
                      data-testid="goToLoginButton"
                      component="button"
                      variant="body2"
                      color="inherit"
                      onClick={GoToLoginPage}
                    >
                      Accedi
                    </Link>
                  </Typography>
                  {/* <Button
                                    sx={{
                                        m: 1,
                                    }}
                                    data-testid="goToLoginButton"
                                    onClick={GoToLoginPage}
                                    color="primary"
                                    variant="contained"
                                >
                                    Sono già registrato
                                </Button> */}
                </Stack>
              </div>
            </CardActions>
          </Card>
        </Grid>
      </Grid>
      <FeedbackAlert
        severity={feedback.severity}
        text={feedback.text}
        open={feedback.open}
        closedCallback={alertClosed}
      />
    </Container>
  );
};

export default Register;
