import React, { useEffect, useState, useContext } from "react";
import authService from "../../api-authorization/AuthorizeService";
import ConfirmDialog from "../../Shared/ConfirmDialog";

import * as YpatientsUtilities from "../../Shared/Utilities";
import * as YpatientsConsts from "../../Shared/Constants";

import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';

import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import { Tooltip } from '@mui/material';
import { IconButton } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import Typography from "@mui/material/Typography";
import Switch from "@mui/material/Switch";
import Stack from "@mui/material/Stack";

export const InvoiceRow = (props) => {
    const [isValid, setIsValid] = useState({
        isValidAmount: true,
        isValidTaxRates: true
    });
    const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
    const [invoiceRowToDelete, setInvoiceRowToDelete] = useState(YpatientsConsts.emptyGuid);
    const [selectedTaxRate, setSelectedTaxRate] = useState("");

    const [myInvoiceRow, setMyInvoiceRow] = useState({
        idInvoiceRow: YpatientsConsts.emptyGuid,
        idPatientInvoice: "",
        description: "",
        amount: 0,
        taxes: 0,
        idTaxRate: "",
        deductible: false
    });

    useEffect(() => {
        setMyInvoiceRow(props.invoiceRow);
        if (props.invoiceRow.idTaxRate) {
            setSelectedTaxRate(props.invoiceRow.idTaxRate);
        }
    }, []);


    const DeleteRowInvoice = async (idRowInvoice) => {
        const token = await authService.getAccessTokenIfValid();
        const getUrl = '/api/patientinvoices/invoicerow/delete/' + idRowInvoice;
        const response = await fetch(getUrl, {
            headers: !token ? {} : { Authorization: `Bearer ${token}` }
        });

        if (response.ok) {
            const data = await response.json();
            if (data) {
                props.refreshDataCallback(props.idPatientInvoice);
            } else {
                props.feedbackCallback("error", "Errore durante l'eliminazione della riga fattura", true);
            }
        } else {
            const error = await response.text();
            props.feedbackCallback("error", error, true);
        }
    };


    const UpdateNewRowInvoice = async (invoiceRowData, refreshInvoiceData) => {
        if ((isValid.isValidAmount && isValid.isValidTaxRates)) {
            let action = "update";
            let postUrl = "/api/patientinvoices/invoicerow/update";
            if (myInvoiceRow.idInvoiceRow === YpatientsConsts.emptyGuid) {
                postUrl = "/api/patientinvoices/invoicerow/new";
                action = "new";
            }

            const token = await authService.getAccessTokenIfValid();
            const response = await fetch(postUrl, {
                method: 'POST',
                body: JSON.stringify(invoiceRowData),
                headers: !token ? {} : {
                    Authorization: `Bearer ${token}`, 'Content-Type': 'application/json'
                }
            });

            if (response.ok) {
                const data = await response.json();

                if (action === "new") {
                    setMyInvoiceRow(data);
                }

                props.feedbackCallback("success", "Dati salvati con successo", true);

                if (refreshInvoiceData) {
                    props.refreshDataCallback(props.idPatientInvoice);
                }

            } else {
                const error = await response.text();
                props.feedbackCallback("error", error, true);
            }
        }
    };

    const handleBlur = (event) => {
        const { id } = event.target;
        if (id === "description") {
            UpdateNewRowInvoice(myInvoiceRow, false);
        }

        if (id === "amount") {
            UpdateNewRowInvoice(myInvoiceRow, true);
        }
    };

    useEffect(() => {
        if (myInvoiceRow.forceSave) {
            delete myInvoiceRow.forceSave;
            UpdateNewRowInvoice(myInvoiceRow, true);
            setMyInvoiceRow({ ...myInvoiceRow});
        }
    }, [myInvoiceRow]);

    const handleChange = (event) => {
        let postData;
        const { id, value } = event.target;
        if (id) {
            if (value) {
                switch (id) {
                    case "deductible":
                        postData = { ...myInvoiceRow, deductible: event.target.checked };
                        setMyInvoiceRow({ ...myInvoiceRow, deductible: event.target.checked });
                        UpdateNewRowInvoice(postData, false);
                        break;
                    case "amount":
                        let validAmount = YpatientsUtilities.IsValidPrice(value);
                        setIsValid({ ...isValid, isValidAmount: validAmount });
                        let tempValue = YpatientsUtilities.priceStringToNumberString(value);
                        CalculateTaxes(tempValue, selectedTaxRate);
                        break;
                    default:
                        setMyInvoiceRow({
                            ...myInvoiceRow,
                            [id]: value,
                        });
                }
            } else {
                if (id === "amount") {
                    CalculateTaxes("", selectedTaxRate);
                }
                setMyInvoiceRow({
                    ...myInvoiceRow,
                    [id]: "",
                });
            }
        }
    };


    const CalculateTaxes = (amount, taxRateValue, forceUpdate) => {
        let tempAmount = amount;
        if (amount === null || amount === undefined || amount === "") {
            tempAmount = 0;
        }
        let selectedTaxRates = props.taxRates.filter((t) => t.idTaxRate === taxRateValue);
        let taxesValue = 0;
        if (selectedTaxRates.length === 1) {
            taxesValue = tempAmount * selectedTaxRates[0].value / 100;
        }

        setMyInvoiceRow({
            ...myInvoiceRow,
            taxes: taxesValue, idTaxRate: taxRateValue, amount: amount
        });

        setSelectedTaxRate(taxRateValue);

        if (forceUpdate) {
            myInvoiceRow.taxes = taxesValue;
            myInvoiceRow.idTaxRate = taxRateValue;
            myInvoiceRow.amount = amount;
            UpdateNewRowInvoice(myInvoiceRow, true);
        }
    };

    const handleChangeTaxes = (event) => {
        setIsValid({ ...isValid, isValidTaxRates: true });
        CalculateTaxes(myInvoiceRow.amount, event.target.value, true);
       
    };

    const handleDeleteRequest = (event) => {
        setInvoiceRowToDelete(event.currentTarget.id);
        setConfirmDialogOpen(true);
    };


    const handleDeleteConfirmation = (confirmation) => {
        if ((confirmation) && (invoiceRowToDelete !== YpatientsConsts.emptyGuid)) {
            DeleteRowInvoice(invoiceRowToDelete);
        }
        setConfirmDialogOpen(false);
    };


    return (
        <>
            <Grid container spacing={2} id="box">
                <Grid item xs={3} md={3}>
                    <TextField
                        fullWidth
                        disabled={props.postedInvoice}
                        data-testid="description"
                        id="description"
                        key="description"
                        name="description"
                        label="Descrizione"
                        variant="filled"
                        size="small"
                        value={myInvoiceRow.description || ""}
                        onChange={handleChange}
                        onBlur={handleBlur}

                    />
                </Grid>
                <Grid item xs={2} md={2}>
                    <TextField
                        fullWidth
                        disabled={props.postedInvoice}
                        data-testid="amount"
                        id="amount"
                        key="amount"
                        name="amount"
                        label="Importo netto (&#8364;)"
                        variant="filled"
                        size="small"
                        value={myInvoiceRow.amount}
                        error={!isValid.isValidAmount}
                        helperText={!isValid.isValidAmount ? "Immettere un numero valido" : ""}
                        onChange={handleChange}
                        onBlur={handleBlur}
                    />
                </Grid>
                <Grid item xs={2} md={2}>
                    <TextField
                        fullWidth
                        disabled
                        data-testid="taxes-value"
                        id="taxes"
                        key="taxes"
                        name="taxes"
                        label="Tasse / IVA (&#8364;)"
                        variant="filled"
                        size="small"
                        value={YpatientsUtilities.GetAmountDecimalFormat(myInvoiceRow.taxes)}
                    />
                </Grid>
                <Grid item xs={2} md={2}>
                    <FormControl sx={{ m: 1, minWidth: 130 }} size="small">
                        <InputLabel>Aliquota IVA</InputLabel>
                        <Select
                            disabled={props.postedInvoice}
                            labelId="tax-rates-label"
                            id="tax-rates"
                            data-testid="tax-rates"
                            value={myInvoiceRow.idTaxRate || ''}
                            label="Aliquota IVA"
                            onChange={handleChangeTaxes}
                        >

                            {props.taxRates.map((myRate) => {
                                return (
                                    <MenuItem key={myRate.idTaxRate} name={myRate.value} value={myRate.idTaxRate} data-testid="tax-rate-option">
                                        {myRate.description}
                                    </MenuItem>
                                );
                            })}
                        </Select>
                        <FormHelperText>{!isValid.isValidTaxRates ? "Selezionare un valore" : "" }</FormHelperText>
                    </FormControl>
                </Grid>
                <Grid item xs={2} md={2}>
                    <Typography>Detraibile</Typography>
                    <Stack direction="row" spacing={1} alignItems="center">
                        <Typography>No</Typography>
                        <Switch
                            data-testid="deductible"
                            id="deductible"
                            key="deductible"
                            name="deductible"
                            variant="filled"
                            size="small"
                            checked={myInvoiceRow.deductible || false}
                            onChange={handleChange}
                            disabled={props.postedInvoice}
                        />
                        <Typography>Sì</Typography>
                    </Stack>
                </Grid>
                <Grid item xs={1} md={1}>
                    <Tooltip title="Elimina la riga">
                        <span>
                            <IconButton edge="end" aria-label="delete"
                                onClick={handleDeleteRequest}
                                data-testid="buttonDelete"
                                name="buttonDelete"
                                id={myInvoiceRow.idInvoiceRow}
                                disabled={myInvoiceRow.idInvoiceRow === YpatientsConsts.emptyGuid || props.postedInvoice}>
                                <DeleteIcon />
                            </IconButton>
                        </span>
                    </Tooltip>
                </Grid>

            </Grid>
            <div>
                <ConfirmDialog
                    open={confirmDialogOpen}
                    answerCallback={handleDeleteConfirmation}
                    title="Attenzione!"
                    text="Si è richiesta la cancellazione della riga fattura. L'azione è irreversibile. Procedere con l'operazione?"
                    agreeText="Elimina"
                    disagreeText="Annulla"
                />
            </div>
        </>
    );
};

export default InvoiceRow;
