import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import ClearIcon from "@mui/icons-material/Clear";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import authService from "../api-authorization/AuthorizeService";
import TextField from "@mui/material/TextField";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import {
  priceStringToNumberString,
  GetDateItalianFormat,
  GetDateValue,
} from "../Shared/Utilities";
import Button from "@mui/material/Button";
import CropFreeIcon from "@mui/icons-material/CropFree";
import Typography from "@mui/material/Typography";
import TablePagination from "@mui/material/TablePagination";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

export const DrugsInventory = (props) => {
  const [drug, setDrug] = useState({
    idDrugType: props.drugType ? props.drugType.idDrugType : null,
    lot: "",
    sticker: "",
    expirationDate: "",
    price: props.drugType ? props.drugType.price : null,
    status: 1,
  });
  const [drugs, setDrugs] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [selected, setSelected] = useState([]);

  const columns = [
    { id: "name", label: "Codice", numeric: false },
    { id: "lot", label: "Lotto", numeric: false },
    { id: "quantity", label: "Quantità per confezione", numeric: true },
    { id: "price", label: "Prezzo (€)", numeric: true },
    { id: "expirationDate", label: "Scadenza", numeric: false },
    { id: "actions", label: "Azioni", numeric: false },
  ];

  let selectedIdForEdit = null;

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  //cleanup state on unmount
  useEffect(() => {
    return () => {
      setDrug({
        idDrugType: props.drugType.idDrugType,
        lot: "",
        sticker: "",
        expirationDate: null,
        price: props.drugType.price,
        status: 1,
      });
      setDrugs([]);
      setRowsPerPage(10);
      setPage(0);
    };
  }, []);

  const GetAvailableDrugs = async () => {
    if (
      props.drugType &&
      props.drugType.idDrugType &&
      props.drugType.idDrugType !== ""
    ) {
      const token = await authService.getAccessTokenIfValid();
      const response = await fetch(
        "/api/drug/doctor/type/" + props.drugType.idDrugType + "/available",
        {
          headers: !token ? {} : { Authorization: `Bearer ${token}` },
        }
      );
      const data = await response.json();
      if (!response.ok) {
        // get error message from body or default to response statusText
        const error = (data && data.message) || response.statusText;
        return Promise.reject(error);
      }
      if (data) {
        if (props.drugType && props.drugType.idDrugType !== "") {
          setDrugs(
            data.filter((d) => d.idDrugType === props.drugType.idDrugType)
          );
          return;
        }
        setDrugs(data);
      }
    }
  };

  useEffect(() => {
    if (props.drugType != null) {
      GetAvailableDrugs();
    } else {
      setDrugs([]);
    }

    setDrug({
      idDrugType: props.drugType ? props.drugType.idDrugType : null,
      lot: "",
      sticker: "",
      expirationDate: null,
      price: props.drugType ? props.drugType.price : null,
      status: 1,
    });
  }, [props.drugType]);

  const UpdateDrug = async (updatedDrug) => {
    if (
      updatedDrug == null ||
      updatedDrug.sticker === "" ||
      updatedDrug.sticker === undefined ||
      updatedDrug.sticker === null
    ) {
      props.feedbackCallback(
        "warning",
        "Inserire il codice bollino per procedere al salvataggio",
        true
      );
      return;
    }
    let token = await authService.getAccessTokenIfValid();
    let path = "/api/drug/item/update";
    if (updatedDrug.idDrug === undefined || updatedDrug.idDrug === "") {
      path = "/api/drug/item";
      selectedIdForEdit = "new";
    }
    const requestOptions = {
      method: "POST",
      headers: !token
        ? { "Content-Type": "application/json" }
        : {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            Accept: "application/json",
          },
      body: JSON.stringify(updatedDrug),
    };
    fetch(path, requestOptions).then(async (response) => {
      const data = await response.json();
      // check for error response
      if (!response.ok) {
        // get error message from body or default to response statusText
        const error = (data && data.message) || response.statusText;
        props.feedbackCallback("error", "Errore nel salvataggio", true);
        return Promise.reject(error);
      } else {
        if (data) {
          if (drug.idDrug === undefined || drug.idDrug === "") {
            //we are updating the newly created drug which is the first of the list
            let newDrugs = [...drugs];
            newDrugs[0] = data;
            setDrugs(newDrugs);
          }
          if (selectedIdForEdit === data.idDrug || selectedIdForEdit === "new") { //only update if the updated drug is the same as the one we are currently editing
            setDrug(data);
          }
          //update drugs list
          let updatedDrugs = drugs;
          let index = updatedDrugs.findIndex((d) => d.idDrug === data.idDrug);
          if (index !== -1) {
            updatedDrugs[index] = data;
            setDrugs(updatedDrugs);
          }
          props.feedbackCallback("success", "Dati salvati con successo", true);
        }
      }
    });
  };

  const handleBlur = async (updatedDrug, event) => {
    UpdateDrug(updatedDrug);
  };

  const handleAdd = () => {
    const newDrug = {
      idDrugType: props.drugType.idDrugType,
      lot: "",
      sticker: "",
      expirationDate: null,
      price: props.drugType.price,
      status: 1,
    };
    let newDrugs = drugs;
    newDrugs.unshift(newDrug);
    setDrugs(newDrugs);
    setDrug(newDrug);
  };

  const handleEdit = (drugToEdit, event) => {
    if ( drugToEdit.idDrug === drug.idDrug) {
      setDrug({
        idDrugType: props.drugType ? props.drugType.idDrugType : null,
        lot: "",
        sticker: "",
        expirationDate: "",
        price: props.drugType ? props.drugType.price : null,
        status: 1,
      });
      return;
    }
    setDrug(drugToEdit);
  };

  const handleExpirationDatePickerAccept = (newValue) => {
    let postData = drug;
    if (newValue) {
      postData = { ...drug, expirationDate: GetDateValue(newValue) };
      UpdateDrug(postData);
    }
  };

  const handleExpirationDatePickerChange = (newValue) => {
    if (newValue) {
      let normalizedDate = GetDateValue(newValue);
      if (normalizedDate !== undefined) {
        setDrug({ ...drug, expirationDate: normalizedDate });
        setDrugs(
          drugs.map((d) => {
            if (d.idDrug === drug.idDrug) {
              return { ...d, expirationDate: normalizedDate };
            }
            return d;
          })
        );
      }
    }
  };

  const handleDelete = async (drugToDelete, event) => {
    if (drugToDelete && drugToDelete.idDrug) {
      let token = await authService.getAccessTokenIfValid();
      fetch("/api/drug/item/delete", {
        method: "POST",
        headers: !token
          ? { "Content-Type": "application/json" }
          : {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
              Accept: "application/json",
            },
        body: JSON.stringify(drugToDelete),
      })
        .then((response) => {
          if (!response.ok) {
            // get error message from body or default to response statusText
            const error = response.statusText;
            props.feedbackCallback(
              "error",
              "Impossibile eliminare il farmaco",
              true
            );
            return Promise.reject(error);
          }
          GetAvailableDrugs();
          setDrug({
            idDrugType: props.drugType.idDrugType,
            lot: "",
            sticker: "",
            expirationDate: null,
            price: props.drugType.price,
            status: 1,
          });
        })
        .catch((error) => {
          console.error("There was an error!", error);
        });
    }
  };

  const handleChange = (changedDrug, event) => {
    const { id, value } = event.target;
    setDrug({
      ...changedDrug,
      idDrugType: props.drugType.idDrugType,
      [id]: value,
    });
    setDrugs(
      drugs.map((currentDrug) =>
        currentDrug.idDrug === changedDrug.idDrug
          ? { ...currentDrug, [id]: value }
          : currentDrug
      )
    );
    if (id === "price") {
      setDrug({ ...changedDrug, [id]: priceStringToNumberString(value) });
      setDrugs(
        drugs.map((currentDrug) =>
          currentDrug.idDrug === changedDrug.idDrug
            ? { ...currentDrug, [id]: priceStringToNumberString(value) }
            : currentDrug
        )
      );
    }
  };

  useEffect(() => {
    if (props.onSelect) {
      let selectedDrugs = selected.map((id) =>
        drugs.find((x) => x.idDrug === id)
      );
      props.onSelect(selectedDrugs);
    }
  }, [selected]);

  const handleClick = (event, idDrug) => {
    const selectedIndex = selected.indexOf(idDrug);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, idDrug);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
    selectedIdForEdit = idDrug;
  };

  const isSelected = (idDrug) => selected.indexOf(idDrug) !== -1;

  const handleSelectAllClick = (event, drugsToSelect) => {
    if (event.target.checked) {
      const newSelected = drugsToSelect.map((n) => n.idDrug);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const renderTable = () => {
    let drugsToShow = [];
    if (drugs.length > rowsPerPage) {
      //select subscriptions to show
      const firstPageIndex = page * rowsPerPage;
      const lastPageIndex = firstPageIndex + rowsPerPage;
      drugsToShow = drugs.slice(firstPageIndex, lastPageIndex);
    } else {
      drugsToShow = [...drugs];
    }
    return (
      <>
        <TableContainer>
          <Table
            sx={{ minWidth: 650 }}
            size="small"
            aria-label="table of drugs"
          >
            <TableHead>
              <TableRow>
                {props.onSelect ? (
                  <TableCell padding="checkbox">
                    <Checkbox
                      data-testid="select-all"
                      checked={
                        drugsToShow.length > 0 &&
                        selected.length === drugsToShow.length
                      }
                      onChange={(event) => {
                        handleSelectAllClick(event, drugsToShow);
                      }}
                      inputProps={{
                        "aria-label": "select all desserts",
                      }}
                    />
                  </TableCell>
                ) : null}
                {columns.map((headCell) => (
                  <TableCell
                    key={headCell.id}
                    align="center"
                    padding={headCell.disablePadding ? "none" : "normal"}
                  >
                    {headCell.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>

            <TableBody>
              {drugsToShow.map((row) => {
                const isItemSelected = isSelected(row.idDrug);
                return (
                  <>
                    {drug.idDrug !== row.idDrug ? (
                      <TableRow
                      key={row.idDrug}
                      hover 
                      data-testid={"drug-row-" + row.idDrug}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                      onClick={(e) => handleEdit(row, e)}
                      selected={isItemSelected}
                      >
                        {props.onSelect ? (
                          <TableCell padding="checkbox">
                            <Checkbox
                              data-testid= {row.idDrug + "-select"}
                              checked={isItemSelected}
                              onChange={(event) => handleClick(event, row.idDrug)}
                              inputProps={{
                                "aria-labelledby":
                                  row.idDrug + "-stickerButton",
                              }}
                              id="checkbox"
                            />
                          </TableCell>
                        ) : null}
                        <TableCell align="center">
                          {/* <Button
                            id={row.idDrug + "-stickerButton"}
                            data-testid={row.idDrug + "-stickerButton"}
                            onClick={(e) => handleEdit(row, e)}
                          > */}
                            {row.sticker}
                          {/* </Button> */}
                        </TableCell>
                        <TableCell align="center">
                          {/* <Button
                            id={row.idDrug + "-lotButton"}
                            data-testid={row.idDrug + "-lotButton"}
                            onClick={(e) => handleEdit(row, e)}
                          > */}
                            {row.lot}
                          {/* </Button> */}
                        </TableCell>
                        <TableCell align="center">
                          {/* <Button
                            id={row.idDrug + "-quantityButton"}
                            data-testid={row.idDrug + "-quantityButton"}
                            onClick={(e) => handleEdit(row, e)}
                          > */}
                            {row.quantity}
                          {/* </Button> */}
                        </TableCell>
                        <TableCell align="center">
                          {/* <Button
                            id={row.idDrug + "-priceButton"}
                            data-testid={row.idDrug + "-priceButton"}
                            onClick={(e) => handleEdit(row, e)}
                          > */}
                            {row.price}
                          {/* </Button> */}
                        </TableCell>
                        <TableCell align="center">
                          {/* <Button onClick={(e) => handleEdit(row, e)}> */}
                            {GetDateItalianFormat(row.expirationDate)}
                          {/* </Button> */}
                        </TableCell>
                        <TableCell align="center">
                      <Grid container spacing={2} justifyContent="center">
                        <Grid item xs={3}>
                        <IconButton
                            data-testid={
                              row.idDrug
                                ? row.idDrug + "-editButton"
                                : "newEditButton"
                            }
                            onClick={(e) => handleEdit(row, e)}
                            aria-label="edit"
                            size="large"
                            id="editButton"
                          >
                            {drug.idDrug !== row.idDrug ? (
                              <EditIcon />
                            ) : (
                              <ClearIcon />
                            )}
                          </IconButton>
                        </Grid>
                        <Grid item xs={3}>
                        <IconButton
                            data-testid={
                              row.idDrug
                                ? row.idDrug + "-deleteButton"
                                : "newEditButton"
                            }
                            onClick={(e) => handleDelete(row, e)}
                            aria-label="delete"
                            size="large"
                          >
                            <DeleteIcon />
                          </IconButton>
                        </Grid>
                      </Grid>
                    </TableCell>
                      </TableRow>
                    ) : (
                      <TableRow
                      key={row.idDrug}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                      selected={isItemSelected}
                      >
                        {props.onSelect ? (
                          <TableCell padding="checkbox">
                            <Checkbox
                              data-testid= {row.idDrug + "-select"}
                              checked={isItemSelected}
                              onChange={(event) => handleClick(event, row.idDrug)}
                              inputProps={{
                                "aria-labelledby":
                                  row.idDrug + "-stickerButton",
                              }}
                              id="checkbox"
                            />
                          </TableCell>
                        ) : null}
                        <TableCell align="center">
                          <TextField
                            data-testid={
                              row.idDrug
                                ? row.idDrug + "-stickerInput"
                                : "newStickerInput"
                            }
                            variant="filled"
                            hiddenLabel
                            fullWidth
                            margin="dense"
                            InputProps={{
                              disableUnderline: true,
                              style: { fontSize: 13 },
                            }}
                            id="sticker"
                            value={row.sticker}
                            onChange={(e) => handleChange(row, e)}
                            onBlur={(e) => handleBlur(row, e)}
                            size="small"
                          ></TextField>
                        </TableCell>
                        <TableCell align="center">
                          <TextField
                            data-testid={
                              row.idDrug
                                ? row.idDrug + "-lotInput"
                                : "newLotInput"
                            }
                            variant="filled"
                            hiddenLabel
                            fullWidth
                            margin="dense"
                            InputProps={{
                              disableUnderline: true,
                              style: { fontSize: 13 },
                            }}
                            id="lot"
                            value={row.lot}
                            onChange={(e) => handleChange(row, e)}
                            onBlur={(e) => handleBlur(row, e)}
                            size="small"
                          ></TextField>
                        </TableCell>
                        <TableCell align="center">
                          <TextField
                            data-testid={
                              row.idDrug
                                ? row.idDrug + "-quantityInput"
                                : "newQuantityInput"
                            }
                            variant="filled"
                            hiddenLabel
                            fullWidth
                            margin="dense"
                            InputProps={{
                              disableUnderline: true,
                              style: { fontSize: 13 },
                            }}
                            id="quantity"
                            value={row.quantity}
                            onChange={(e) => handleChange(row, e)}
                            onBlur={(e) => handleBlur(row, e)}
                            size="small"
                          ></TextField>
                        </TableCell>
                        <TableCell align="center">
                          <TextField
                            data-testid={
                              row.idDrug
                                ? row.idDrug + "-priceInput"
                                : "newPriceInput"
                            }
                            variant="filled"
                            hiddenLabel
                            fullWidth
                            margin="dense"
                            InputProps={{
                              disableUnderline: true,
                              style: { fontSize: 13 },
                            }}
                            id="price"
                            value={row.price}
                            onChange={(e) => handleChange(row, e)}
                            onBlur={(e) => handleBlur(row, e)}
                            size="small"
                          ></TextField>
                        </TableCell>
                        <TableCell align="center">
                          <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DatePicker
                              size="small"
                              key="expirationDate"
                              id="expirationDate"
                              name="expirationDate"
                              inputFormat="dd/MM/yyyy"
                              value={(row && row.expirationDate) || null}
                              onAccept={handleExpirationDatePickerAccept}
                              onChange={handleExpirationDatePickerChange}
                              renderInput={(params) => (
                                <TextField
                                  size="small"
                                  data-testid={
                                    row.idDrug
                                      ? row.idDrug + "-expirationDateInput"
                                      : "newExpirationDateInput"
                                  }
                                  margin="dense"
                                  fullWidth
                                  hiddenLabel
                                  variant="filled"
                                  id="drug-expirationDate"
                                  InputProps={{
                                    disableUnderline: true,
                                    style: { fontSize: 13 },
                                  }}
                                  {...params}
                                />
                              )}
                            />
                          </LocalizationProvider>
                        </TableCell>
                    <TableCell align="center">
                      <Grid container spacing={2} justifyContent="center">
                        <Grid item xs={3}>
                        <IconButton
                            data-testid={
                              row.idDrug
                                ? row.idDrug + "-editButton"
                                : "newEditButton"
                            }
                            onClick={(e) => handleEdit(row, e)}
                            aria-label="edit"
                            size="large"
                            id="editButton"
                          >
                            {drug.idDrug !== row.idDrug ? (
                              <EditIcon />
                            ) : (
                              <ClearIcon />
                            )}
                          </IconButton>
                        </Grid>
                        <Grid item xs={3}>
                        <IconButton
                            data-testid={
                              row.idDrug
                                ? row.idDrug + "-deleteButton"
                                : "newEditButton"
                            }
                            onClick={(e) => handleDelete(row, e)}
                            aria-label="delete"
                            size="large"
                          >
                            <DeleteIcon />
                          </IconButton>
                        </Grid>
                      </Grid>
                    </TableCell>
                  </TableRow>
                  )}
                  </>
                  );})}

            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25, 50]}
          component="div"
          count={drugs.length}
          labelRowsPerPage="Righe per pagina"
          page={page}
          onPageChange={handlePageChange}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </>
    );
  };

  return (
    <Card
      sx={{
        boxShadow: 0,
      }}
    >
      <CardContent>
        <Typography gutterBottom variant="subtitle3" component="div">
          <Grid container spacing={1} justifyContent="flex-end">
            <Grid item xs={10}>
              {props.drugType ? (
                <>
                  Disponibilità: {drugs.filter((d) => d.status === 1).length}{" "}
                </>
              ) : (
                <>Selezionare un farmaco per visualizzare la disponibilità</>
              )}
            </Grid>
            {props.drugType && (
              <Grid item xs={1}>
                <IconButton
                  data-testid="addDrugButton"
                  onClick={handleAdd}
                  aria-label="add"
                  size="small"
                  color="inherit"
                >
                  <AddIcon />
                </IconButton>
              </Grid>
            )}
            <Grid item xs={1}>
              <IconButton
                data-testid="scanDragButton"
                onClick={() => {}}
                aria-label="scan"
                size="small"
              >
                <CropFreeIcon />
              </IconButton>
            </Grid>
          </Grid>
        </Typography>

        {drugs.filter((d) => d.status === 1).length > 0 ? (
          renderTable()
        ) : (
          <Typography align="center" gutterBottom component="div">
            Nessun farmaco disponibile
          </Typography>
        )}
      </CardContent>
    </Card>
  );
};

export default DrugsInventory;
