import React, { useState, useEffect, useContext } from "react";
import authService from "../../api-authorization/AuthorizeService";

import { IconButton } from "@mui/material";
import { Tooltip } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import DescriptionIcon from "@mui/icons-material/Description";
import DownloadIcon from "@mui/icons-material/Download";
import ContentPasteOffIcon from "@mui/icons-material/ContentPasteOff";
import ForwardToInboxIcon from "@mui/icons-material/ForwardToInbox";
import Fab from "@mui/material/Fab";

import Checkbox from "@mui/material/Checkbox";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TablePagination from "@mui/material/TablePagination";

import * as YpatientsConsts from "../../Shared/Constants";
import * as YpatientsUtilities from "../../Shared/Utilities";
import ConfirmDialog from "../../Shared/ConfirmDialog";
import { Loading } from "../../Shared/Loading";

import { downloadPatientInvoice, sendPatientInvoice, downloadPdfInvoicesZip } from "./InvoiceManager.js";
import "./Invoice.css";


export const InvoicesList = (props) => {

    const [isLoading, setIsLoading] = useState(false);
    const emptyInvoice = {
        idPatientInvoice: "",
        idCompany: "",
        idVisit: "",
        idPatient: "",
        patientName: "",
        patientSurname: "",
        patientFiscalCode: "",
        patientAddress: "",
        patientCity: "",
        patientZipCode: "",
        patientEmail: "",
        number: "",
        date: "",
        dueDate: "",
        amount: "",
        taxes: "",
        total: "",
        paid: "",
        paidDate: "",
        draft: "",
    };


    const [patientInvoices, setPatientInvoices] = useState([emptyInvoice]);

    const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
    const [invoiceToDelete, setInvoiceToDelete] = useState(
        YpatientsConsts.emptyGuid
    );

    const [page, setPage] = useState(props.pageIndex);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [selected, setSelected] = useState([]);
    const [useDoctor, setUseDoctor] = useState(true);
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const GetInvoicesList = async () => {
        let getUrl = "";
        let getUrlIsOk = true;

        if (
            props.idVisit !== YpatientsConsts.emptyGuid &&
            props.idVisit !== undefined
        ) {
            getUrl = "/api/patientinvoices/invoice/listbyvisit/" + props.idVisit;
        } else {
            if (props.doctor !== undefined && props.doctor.idDoctor !== YpatientsConsts.emptyGuid) {
                getUrl = "/api/patientinvoices/invoice/listbydoctor/" + props.doctor.idDoctor;
            } else {
                if (props.idCompany !== YpatientsConsts.emptyGuid) {
                    getUrl =
                        "/api/patientinvoices/invoice/listbycompany/" + props.idCompany;
                } else {
                    getUrlIsOk = false;
                }
            }
        }

        if (getUrlIsOk) {
            setIsLoading(true);
            const token = await authService.getAccessTokenIfValid();
            const response = await fetch(getUrl, {
                headers: !token ? {} : { Authorization: `Bearer ${token}` },
            });

            if (response.ok) {
                const data = await response.json();
                if (data) {
                    setPatientInvoices(data);
                    setIsLoading(false);
                } else {
                    props.feedbackCallback(
                        "error",
                        "Errore durante la lettura delle fatture",
                        true
                    );
                }
            } else {
                const error = await response.text();
                props.feedbackCallback("error", error, true);
            }
        } else {
            props.feedbackCallback(
                "error",
                "Errore di creazione lista fatture, chiamata non valida",
                true
            );
        }
    };

    useEffect(() => {
        GetInvoicesList();
    }, []);

    const AddNewInvoice = async () => {
        emptyInvoice.idPatientInvoice = YpatientsConsts.emptyGuid;
        emptyInvoice.amount = 0;
        emptyInvoice.date = YpatientsUtilities.GetDateValue(new Date());
        emptyInvoice.draft = true;
        emptyInvoice.idCompany = props.idCompany;
        emptyInvoice.idVisit = props.idVisit;
        emptyInvoice.paid = false;
        emptyInvoice.taxes = 0;
        emptyInvoice.total = 0;

        const token = await authService.getAccessTokenIfValid();
        const postUrl = "/api/patientinvoices/invoice/new";
        const response = await fetch(postUrl, {
            method: "POST",
            body: JSON.stringify(emptyInvoice),
            headers: !token
                ? {}
                : {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "application/json",
                },
        });

        if (response.ok) {
            const data = await response.json();
            if (data) {
                props.feedbackCallback("success", "Dati salvati con successo", true);
                props.openInvoiceCallback(data, 0);
            }
        } else {
            const error = await response.text();
            props.feedbackCallback("error", error, true);
        }
    };

    const getCurrentCompanyData = async () => {
        let data;
        const token = await authService.getAccessTokenIfValid();
        const response = await fetch("/api/company", {
            headers: !token ? {} : { Authorization: `Bearer ${token}` },
        });

        if (response.ok) {
            data = await response.json();
        } else {
            const error = await response.text();
            props.feedbackCallback("error", error, true);
        }
        return data;
    };

   

    const sendInvoiceCallBack = (result) => {
        if (result) {
            props.feedbackCallback("success", "Fattura inviata con successo", true);
        } else {
            props.feedbackCallback("error", "Fattura non inviata ", true);
        }
    };

    const handleOpenInvoice = (event) => {
        if (event.currentTarget) {
            props.openInvoiceCallback(event.currentTarget.id, page);
        } else {
            props.feedbackCallback("error", "Fattura non disponibile", true);
        }
    };

   const handleDownloadInvoice = async (event) => {
        let target = event.currentTarget;
        let holder = await getCurrentCompanyData();
        if (target != null) { 
            if (!(await downloadPatientInvoice(target.id, holder, props.feedbackCallback))) {
                props.feedbackCallback("error", "Fattura non disponibile", true);
            }
            props.feedbackCallback("success", "Stampa fattura generata con successo", true);
        } else {
            props.feedbackCallback("error", "Fattura non disponibile", true);
        }    
    };

    const handleSendToPatient = async (event) => {
        let target = event.currentTarget;
        let holder = await getCurrentCompanyData();
        if (target) {
            let result = await sendPatientInvoice(target.id, holder, props.feedbackCallback, sendInvoiceCallBack);
            if (!result) {
                //feedback is inside sendInvoice
            }
        } else {
            props.feedbackCallback("error", "Fattura non disponibile", true);
        }
    };

    const DeleteInvoice = async (idPatientInvoice) => {
        const token = await authService.getAccessTokenIfValid();
        const getUrl = "/api/patientinvoices/invoice/delete/" + idPatientInvoice;
        const response = await fetch(getUrl, {
            headers: !token ? {} : { Authorization: `Bearer ${token}` },
        });

        if (response.ok) {
            const data = await response.json();
            if (data) {
                GetInvoicesList();
            } else {
                props.feedbackCallback(
                    "error",
                    "Errore durante l'eliminazione della fattura",
                    true
                );
            }
        } else {
            const error = await response.text();
            props.feedbackCallback("error", error, true);
        }
    };

    const handleDeleteRequest = (event) => {
        setInvoiceToDelete(event.currentTarget.id);
        setConfirmDialogOpen(true);
    };

    const handleDeleteConfirmation = (confirmation) => {
        if (confirmation) {
            DeleteInvoice(invoiceToDelete);
        }
        setConfirmDialogOpen(false);
    };

    const renderAddButton = () => {
        return (
            <div className="w-100 d-flex justify-content-end pt-3 pe-3">
                <Tooltip title="Aggiungi una fattura">
                    <Fab
                        id="add-new"
                        sx={{
                            bgcolor: "#17252A",
                            color: "#00A0E0",
                        }}
                        size="small"
                        aria-label="add"
                        onClick={AddNewInvoice}
                        color="inherit"
                    >
                        <AddIcon />
                    </Fab>
                </Tooltip>
            </div>
        );
    };

    const DownloadSelectedInvoices = async () => {
        let holder = await getCurrentCompanyData();

        if (selected.length > 1) {
            await downloadPdfInvoicesZip(selected,holder);
        } else {
            await downloadPatientInvoice(selected[0], holder);
        }
    };

    const renderDownloadButton = () => {
        return (
            <div className="w-100 d-flex justify-content-center pt-3">
                <Tooltip title="Scarica le fatture selezionate">
                    <IconButton
                        id="download-selected"
                        size="large"
                        aria-label="download-selected"
                        onClick={DownloadSelectedInvoices}
                        data-testid="download-selected"
                    >
                        <DownloadIcon fontSize="inherit" />
                    </IconButton>
                </Tooltip>
            </div>
        );
    };

    //selection management
    const handleClick = (event, idPatientInvoice) => {
        const selectedIndex = selected.indexOf(idPatientInvoice);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, idPatientInvoice);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1)
            );
        }

        setSelected(newSelected);
    };

    const isSelected = (idPatientInvoice) => selected.indexOf(idPatientInvoice) !== -1;

    const handleSelectAllClick = (event, invoicesToSelect) => {
        if (event.target.checked) {
            const newSelected = invoicesToSelect.map((n) => n.idPatientInvoice);
            setSelected(newSelected);
            return;
        }
        setSelected([]);
    };

    const renderInvoiceList = () => {
        if (patientInvoices.length > 0) {
            const tableColumns = [
                { id: "number", label: "Numero", numeric: false },
                { id: "date", label: "Data emissione", numeric: false },
                { id: "amount", label: "Importo", numeric: false },
                { id: "dueDate", label: "Data scadenza", numeric: false },
                { id: "draft", label: "Registrata", numeric: false },
                { id: "paid", label: "Pagata", numeric: false },
                { id: "action", label: "Azioni", numeric: false },
            ];
            if (props.scope === "doctor" || props.scope === "company") {
                tableColumns.unshift({
                    id: "patient",
                    label: "Paziente",
                    numeric: false,
                });
            }

            let invoicesToRender =
                rowsPerPage > 0
                    ? patientInvoices
                        .sort((a, b) => {
                            return b.number.localeCompare(a.number);
                        })
                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    : patientInvoices.sort((a, b) => {
                        return b.number.localeCompare(a.number);
                    });

            return (
                <>
                    <TableContainer className="table-padding-30">
                        <Table
                            sx={{ minWidth: 650 }}
                            size="small"
                            aria-label="a dense table"
                        >
                            <TableHead>
                                <TableRow>
                                    <TableCell padding="checkbox">
                                        <Checkbox
                                            color="primary"
                                            data-testid="select-all"
                                            checked={
                                                invoicesToRender.length > 0 &&
                                                selected.length === invoicesToRender.length
                                            }
                                            onChange={(event) => {
                                                handleSelectAllClick(event, invoicesToRender);
                                            }}
                                            inputProps={{
                                                "aria-label": "select all desserts",
                                            }}
                                        />
                                    </TableCell>
                                    {tableColumns.map((headCell) => (
                                        <TableCell
                                            key={headCell.id}
                                            align={headCell.id === "amount" ? "right" : "center"}
                                            padding={headCell.disablePadding ? "none" : "normal"}
                                        >
                                            {headCell.label}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>

                            <TableBody>
                                {invoicesToRender.map((invoice) => {
                                    const isItemSelected = isSelected(invoice.idPatientInvoice);
                                    return (
                                        <TableRow
                                            hover
                                            key={invoice.idPatientInvoice}
                                            sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                                        >
                                            <TableCell padding="checkbox">
                                                <Checkbox
                                                    data-testid={invoice.idPatientInvoice + "-select"}
                                                    color="primary"
                                                    checked={isItemSelected}
                                                    onChange={(event) =>
                                                        handleClick(event, invoice.idPatientInvoice)
                                                    }
                                                    inputProps={{
                                                        "aria-labelledby":
                                                            invoice.idPatientInvoice + "-stickerButton",
                                                    }}
                                                />
                                            </TableCell>
                                            {props.scope === "doctor" || props.scope === "company" ? (
                                                <TableCell align="center">
                                                    {invoice.patientSurname} {invoice.patientName}
                                                </TableCell>
                                            ) : null}
                                            <TableCell align="center">{invoice.number}</TableCell>
                                            <TableCell align="center">
                                                {YpatientsUtilities.GetDateItalianFormat(invoice.date)}
                                            </TableCell>
                                            <TableCell align="right">
                                                {YpatientsUtilities.GetAmountEuroFormat(invoice.total)}
                                            </TableCell>
                                            <TableCell align="center">
                                                {YpatientsUtilities.GetDateItalianFormat(
                                                    invoice.dueDate
                                                )}
                                            </TableCell>
                                            <TableCell align="center">
                                                {YpatientsUtilities.BooleanToString(!invoice.draft)}
                                            </TableCell>
                                            <TableCell align="center">
                                                {YpatientsUtilities.BooleanToString(invoice.paid)}
                                            </TableCell>
                                            <TableCell align="center">
                                                <Tooltip title="Visualizza fattura">
                                                    <IconButton
                                                        edge="end"
                                                        aria-label="open"
                                                        onClick={handleOpenInvoice}
                                                        id={invoice.idPatientInvoice}
                                                        data-testid={`buttonOpenInvoice-${invoice.idPatientInvoice}`}
                                                    >
                                                        <DescriptionIcon />
                                                    </IconButton>
                                                </Tooltip>
                                                <Tooltip title="Scarica la fattura in formato PDF">
                                                    <IconButton
                                                        edge="end"
                                                        aria-label="download"
                                                        onClick={handleDownloadInvoice}
                                                        id={invoice.idPatientInvoice}
                                                        data-testid={`buttonDownloadInvoice-${invoice.idPatientInvoice}`}
                                                    >
                                                        <DownloadIcon />
                                                    </IconButton>
                                                </Tooltip>
                                                <Tooltip title="Invia per email al paziente">
                                                    <IconButton
                                                        edge="end"
                                                        aria-label="download"
                                                        onClick={handleSendToPatient}
                                                        id={invoice.idPatientInvoice}
                                                        data-testid={`buttonSendInvoice-${invoice.idPatientInvoice}`}
                                                    >
                                                        <ForwardToInboxIcon />
                                                    </IconButton>
                                                </Tooltip>
                                                <Tooltip title="Elimina la fattura">
                                                    <span>
                                                        <IconButton
                                                            edge="end"
                                                            aria-label="delete"
                                                            onClick={handleDeleteRequest}
                                                            id={invoice.idPatientInvoice}
                                                            disabled={!invoice.draft}
                                                            data-testid={`buttonDeleteInvoice-${invoice.idPatientInvoice}`}
                                                        >
                                                            <DeleteIcon />
                                                        </IconButton>
                                                    </span>
                                                </Tooltip>
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25]}
                        component="div"
                        count={patientInvoices.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        labelRowsPerPage="Righe per pagina"
                    />
                </>
            );
        } else {
            return (
                <div>
                    <div className="d-flex justify-content-center">
                        Fatture non presenti
                    </div>
                    <div className="w-100 d-flex justify-content-center">
                        <ContentPasteOffIcon
                            className="empty-placeholder-icon my-5"
                            alt="Fatture non disponibili"
                            fontSize="large"
                        />
                    </div>
                </div>
            );
        }
    };

    return (
        <>
            {isLoading && <Loading message="Lettura fatture in corso...." />}
            {!isLoading && (
                <div>
                    {props.scope !== "doctor" && props.scope !== "company"
                        ? renderAddButton()
                        : null}
                    {renderInvoiceList()}
                    {selected.length > 0 ? renderDownloadButton() : null}
                    <ConfirmDialog
                        open={confirmDialogOpen}
                        answerCallback={handleDeleteConfirmation}
                        title="Attenzione - richiesta cancellazione!"
                        text="Si è richiesta la cancellazione della fattura. L'azione è irreversibile. Procedere con l'operazione?"
                        agreeText="Elimina"
                        disagreeText="Annulla"
                    />
                </div>
            )}
        </>
    );
};

export default InvoicesList;
