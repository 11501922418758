import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";
import { GetDateItalianFormat } from "../Utilities.js";

export const MASTER_DOCTOR_ID = "00000000-0000-0000-0000-000000000000";

export const IsMasterDoctor = (masterId) => {
    return masterId === MASTER_DOCTOR_ID;
}
export const PLACE_OLDER = {
    TEMPLATE_BODY: "#BODY#",
    PAZIENTE_NAME: "#PAZIENTE_NOME#",
    PAZIENTE_SURNAME: "#PAZIENTE_COGNOME#",
    PAZIENTE_ZIPCODE: "#PAZIENTE_CAP#",
    PAZIENTE_CITY: "#PAZIENTE_CITTA#",
    PAZIENTE_PROVINCE: "#PAZIENTE_PROVINCIA#",
    PAZIENTE_ADDRESS: "#PAZIENTE_INDIRIZZO#",
    PAZIENTE_COUNTRY: "#PAZIENTE_NAZIONE#",
    PAZIENTE_BIRTHDATE: "#PAZIENTE_DATANASCITA#",
    PAZIENTE_BIRTHPLACE: "#PAZIENTE_LUOGONASCITA#",
    PAZIENTE_GENDER: "#PAZIENTE_GENDER#",
    PAZIENTE_FISCALCOD: "#PAZIENTE_CODICEFISCALE#",
    PAZIENTE_HEALTHCARD: "#PAZIENTE_TESSERASANITARIA#",
    PAZIENTE_FAMILYDOCTOR:"#PAZIENTE_MEDICODIFAMIGLIA#",
    PAZIENTE_EMAIL: "#PAZIENTE_EMAIL#",
    PAZIENTE_PHONE: "#PAZIENTE_TELEFONO#",
    PAZIENTE_MOBILE: "#PAZIENTE_CELLULARE#",

};
export const EDITOR_PLACE_OLDER = {
    PAZIENTE_NAME: "Nome",
    PAZIENTE_SURNAME: "Cognome",
    PAZIENTE_ZIPCODE: "CAP",
    PAZIENTE_CITY: "Città",
    PAZIENTE_PROVINCE: "Provincia",
    PAZIENTE_ADDRESS: "Indirizzo",
    PAZIENTE_COUNTRY: "Nazione",
    PAZIENTE_BIRTHDATE: "Data di Nascita",
    PAZIENTE_BIRTHPLACE: "Luogo di Nascita",
    PAZIENTE_GENDER: "Genere",
    PAZIENTE_FISCALCOD: "Codicefiscale",
    PAZIENTE_HEALTHCARD: "Tessera Sanitaria",
    PAZIENTE_FAMILYDOCTOR: "Medico di Famiglia",
    PAZIENTE_EMAIL: "Email",
    PAZIENTE_PHONE: "Telefono",
    PAZIENTE_MOBILE: "Cellulare",

};

const pdf_settings = { orientation: "p", unit: "pt", format: "a4", x: 10, y: 10, width: 595, scale: 1 };
const margins = {
    top: 40,
    right: 50,
    bottom: 50,
    left: 50
};
const defaultDocumentTemplate = "defaultDocumentTemplate";
const getTemplateHtml = (filename) => {
    //disabling eslint
    // eslint-disable-next-line import/no-webpack-loader-syntax
    let template_file = require("raw-loader!./" + filename + ".html");
    if (!process.env.NODE_ENV || process.env.NODE_ENV === 'test') {
        template_file = require("./" + filename + ".html");
    }
    return template_file.default;
}

const replaceAll = (template, replaces) => {

    let _template = template;
    for (let replace in replaces) {
        _template = _template.replaceAll(replace.key, replace.value);
    }
    return _template;
}
const addBodyToTemplate = (html, _template) => {

    if (_template.includes(PLACE_OLDER.TEMPLATE_BODY)) {
        _template = _template.replaceAll(PLACE_OLDER.TEMPLATE_BODY, html);
    }
    return _template;
}
const addPatientInformation = (html, patient) => {
    
    let _html = html.replaceAll(PLACE_OLDER.PAZIENTE_NAME, patient.name);
    _html = _html.replaceAll(PLACE_OLDER.PAZIENTE_SURNAME, patient.surname);
    _html = _html.replaceAll(PLACE_OLDER.PAZIENTE_ADDRESS, patient.address);
    _html = _html.replaceAll(PLACE_OLDER.PAZIENTE_ZIPCODE, patient.zipCode);
    _html = _html.replaceAll(PLACE_OLDER.PAZIENTE_CITY, patient.city);
    _html = _html.replaceAll(PLACE_OLDER.PAZIENTE_PROVINCE, patient.province);
    _html = _html.replaceAll(PLACE_OLDER.PAZIENTE_COUNTRY, patient.country);
    _html = _html.replaceAll(PLACE_OLDER.PAZIENTE_BIRTHDATE, GetDateItalianFormat(patient.birthDate));
    _html = _html.replaceAll(PLACE_OLDER.PAZIENTE_BIRTHPLACE, patient.birthPlace);
    _html = _html.replaceAll(PLACE_OLDER.PAZIENTE_GENDER, patient.gender);
    _html = _html.replaceAll(PLACE_OLDER.PAZIENTE_FISCALCOD, patient.fiscalCode);
    _html = _html.replaceAll(PLACE_OLDER.PAZIENTE_HEALTHCARD, patient.healthCard);
    _html = _html.replaceAll(PLACE_OLDER.PAZIENTE_FAMILYDOCTOR, patient.familyDoctor);
    _html = _html.replaceAll(PLACE_OLDER.PAZIENTE_EMAIL, patient.email);
    _html = _html.replaceAll(PLACE_OLDER.PAZIENTE_PHONE, patient.phone);
    _html = _html.replaceAll(PLACE_OLDER.PAZIENTE_MOBILE, patient.mobile);
 
    return _html;
}
export const createDocumentPdf = (html, patient, template, filename) => {
    let _html = html;
    let _template = "";
    if (!!template && template !== '') {
        _template = getTemplateHtml(template);
    }
    else {
        _template = getTemplateHtml(defaultDocumentTemplate);
    }
    _html = addBodyToTemplate(html, _template);
    if (!!patient) {
        _html = addPatientInformation(_html, patient);
    }
    buildPdf(_html, filename);
}

const addFooters = doc => {
    const pageCount = doc.internal.getNumberOfPages()

    doc.setFont("helvetica", "italic")
    doc.setFontSize(8)
    for (let i = 1; i <= pageCount; i++) {
        doc.setPage(i)
        let line_y = doc.internal.pageSize.height - margins.bottom + 5;
        doc.line(0, line_y, doc.internal.pageSize.width, line_y);
        doc.text("Pagina " + String(i) + " di " + String(pageCount), doc.internal.pageSize.width - margins.right, doc.internal.pageSize.height - 30, {
            align: "right"
        })
    }
}
const buildPdf = (html, filename) => {

    let pdf = new jsPDF(pdf_settings.orientation, pdf_settings.unit, pdf_settings.format);

    window.html2canvas = html2canvas

    pdf.html(
        html, {
        autoPaging: "text",
        margin: [margins.top, margins.right, margins.bottom, margins.left],
        callback: function (doc) {
            addFooters(doc);
            doc.save(filename);
            },
        x: pdf_settings.x,
        y: pdf_settings.y,
        width: pdf_settings.width,
        html2canvas: { scale: pdf_settings.scale }
    });
}
