import React, { useState, useEffect } from "react";
import { Loading } from "../../Shared/Loading";

import Stack from "@mui/material/Stack";
import Grid from "@mui/material/Grid";
import { TransformWrapper, TransformComponent } from "@pronestor/react-zoom-pan-pinch";
import html2canvas from "html2canvas";
import "./ImageCompare.css";
import { IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import DownloadIcon from "@mui/icons-material/Download";
import VerticalAlignCenterIcon from "@mui/icons-material/VerticalAlignCenter";
import { ImageToBase64 } from "./ImageUtils";

export const ImageCompare = (props) => {
  const IdPatient = props.idPatient;
  const [direction, setDirection] = useState("row");
  const [imagesSrc, setImagesSrc] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    for (let i = 0; i < props.images.length; i++) {
      ImageToBase64(props.images[i], (dataURL) => {
        setImagesSrc((current) => [...current, dataURL]);
      });
    }
  }, [props.images]);

  useEffect(() => {
    if (imagesSrc.length === props.images.length) {
      setLoading(false);
    }
  }, [imagesSrc, props.images.length]);

  const captureCompare = async () => {
    const compareContainer = document.querySelector("#compare-container");
    const canvas = await html2canvas(compareContainer, {
      allowTaint: true,
      windowWidth: compareContainer.offsetWidth,
      windowHeight: compareContainer.offsetHeight,
    });
    const link = document.createElement("a");
    link.download = "compare.jpg";
    link.href = canvas.toDataURL("image/jpeg");
    link.click();
    link.remove();
  };

  return (
    <div className="compare-main-container">
      <Stack spacing={1} direction="row" justifyContent="flex-end">
        <IconButton variant="contained" onClick={() => setDirection("row")}>
          <VerticalAlignCenterIcon className="rotate-icon" />
        </IconButton>

        <IconButton variant="contained" onClick={() => setDirection("column")}>
          <VerticalAlignCenterIcon />
        </IconButton>

        <IconButton variant="contained" onClick={captureCompare}>
          <DownloadIcon />
        </IconButton>

        <IconButton
          onClick={props.onClose}
        >
          <CloseIcon />
        </IconButton>
      </Stack>

      {loading ? (
        <Loading message="Caricamento immagini" />
      ) : (
        <Grid
          container
          spacing={0}
          justifyContent="center"
          data-testid="compare-container"
          id="compare-container"
          className="compare-container"
          alignItems="center"
        >
          {props.images.map((image, index) => (
            <Grid item xs={direction === "row" ? 6 : 12}>
              <div
                data-testid="compare-image-container"
                className={
                  direction === "row"
                    ? "horizontal-wrapper-container"
                    : "vertical-wrapper-container"
                }
              >
                <TransformWrapper
                  data-testid="compare-image-wrapper"
                  defaultScale={1}
                  minScale={0.2}
                  centerZoomedOut={true}
                  className={
                    direction === "row"
                      ? "horizontal-wrapper"
                      : "vertical-wrapper"
                  }
                >
                  <TransformComponent>
                    <img
                      className="imageCompare-img"
                      id={"image" + index}
                      src={imagesSrc[index]}
                      alt={image.name}
                      width="100%"
                      height="100%"
                      style={{
                        maxHeight: "100%",
                        maxWidth: "100%",
                      }}
                    />
                  </TransformComponent>
                </TransformWrapper>
              </div>
            </Grid>
          ))}
        </Grid>
      )}
    </div>
  );
};

export default ImageCompare;
