import React, {
  useRef,
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from "react";
import authService from "../../api-authorization/AuthorizeService";
import { Loading } from "../../Shared/Loading";
import { ImageItem } from "./ImageItem";
import NoPhotographyIcon from "@mui/icons-material/NoPhotography";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import Fab  from "@mui/material/Fab";
import CompareIcon from "@mui/icons-material/Compare";
import Dialog from "@mui/material/Dialog";
import ImageCompare from "./ImageCompare";


export const ImagesList = forwardRef((props, ref) => {
  const IdPatient = props.idPatient;
  const IdVisit = props.idVisit;
  const [images, setImages] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [imagesRange, setImagesRange] = useState(1);
  const [rangeIsLoaded, setRangeIsLoaded] = useState(false);
  const [imagesToCompare, setImagesToCompare] = useState([]);
  const [openCompareDialog, setOpenCompareDialog] = useState(false);
  const [canAddToCompare, setCanAddToCompare] = useState(true);

  let imageBatchSize = 8;

  useImperativeHandle(ref, () => ({
    GetImages(image, reload) {
      if (reload) {
        GetImages(1);
      } else {
        GetNewImages(image);
      }
      setCanAddToCompare(true);
    },
  }));

  useEffect(() => {
    GetImages(imagesRange);
  }, [imagesRange]);

  useEffect(() => {
    const handleScroll = (event) => {
      let downloadThreshold = 25 * imagesRange;
      let currentRange = imagesRange;
      if (window.scrollY > downloadThreshold) {
        if (rangeIsLoaded) {
          setImagesRange(currentRange + 1);
        }
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [imagesRange, rangeIsLoaded]);

  const renderImages = (image) => {
    setIsLoading(true);
    let index = images.indexOf(image);
    if (index > -1) {
      images.splice(index, 1);
      setImages(images);
    }
    setIsLoading(false);
  };

  const GetNewImages = async (image) => {
    setIsLoading(true);
    images.splice(0, 0, image);
    setImages(images);
    setIsLoading(false);
  };

  const GetImages = async (imagesRange) => {
    setImagesRange(imagesRange);
    if (imagesRange === 1) {
      setIsLoading(true);
    }
    setRangeIsLoaded(false);
    const token = await authService.getAccessTokenIfValid();
    const getUrl =
      "/api/filepatient/images/" +
      IdPatient +
      "/" +
      imageBatchSize +
      "/" +
      imagesRange;
    const response = await fetch(getUrl, {
      headers: !token
        ? {}
        : {
            Authorization: `Bearer ${token}`,
          },
    });

    if (response.ok) {
      const data = await response.json();

      if (data.length > 0) {
        if (imagesRange > 1) {
          images.push.apply(images, data);
          let unique = images.filter(
            (x, i) => images.map((img) => img.idFile).indexOf(x.idFile) === i
          );
          setImages(unique);
        } else {
          setImages(data);
        }
        setRangeIsLoaded(true);
      } else {
        if (imagesRange === 1) {
          setImages(data);
        }
      }

      setIsLoading(false);
    } else {
      const error = await response.text();
      props.feedbackCallback("error", error, true);
    }
  };

  const handleCompare = (image) => {
      let index = imagesToCompare.indexOf(image);
      if (index > -1) {
        setImagesToCompare(current => [...current.filter(x => x.idFile !== image.idFile)]);
        setCanAddToCompare(true);
      } else {
        if(imagesToCompare.length < 2) {
          if(imagesToCompare.length !== 0) {
            setCanAddToCompare(false);
          }
          setImagesToCompare(current => [...current, image]);
        }
      }
  }

  const handleClose = () => {
    setOpenCompareDialog(false);
  };

  return (
    <div>
      <div className="row gutters">
        {isLoading && <Loading />}
        {!isLoading && images.length === 0 && (
          <div className="w-100 d-flex justify-content-center">
            <NoPhotographyIcon
              className="empty-placeholder-icon my-5"
              alt="Images not found!"
              fontSize="large"
            />
          </div>
        )}
        {!isLoading && images.length > 0 && (
          <>
          {!canAddToCompare && (
          <Fab style={{"position": "fixed", "right": "50px","marginTop":"10px"}} color="primary" aria-label="add" className="compare-button" onClick={() => {setOpenCompareDialog(true);}}>
            <CompareIcon />
          </Fab>
          )}
          <ImageList variant="masonry" cols={4} gap={0}>
            {images.map((img) => (
              <ImageListItem key={img.idFile}>
              <ImageItem
                key={img.idFile}
                idPatient={IdPatient}
                image={img}
                feedbackCallback={props.feedbackCallback}
                getImagesCallback={renderImages}
                compareCallback={handleCompare}
                canAddToCompare={canAddToCompare}
              />
              </ImageListItem>
            ))}
          </ImageList>
          <Dialog open={openCompareDialog} fullScreen>
            <ImageCompare images={imagesToCompare} onClose={handleClose}/>
          </Dialog>
          </>
        )}
        
      </div>
    </div>
  );
});
