import React, { useState, useEffect } from "react";
import authService from "../../api-authorization/AuthorizeService";
import Avatar from "@mui/material/Avatar";
import ArticleIcon from "@mui/icons-material/Article";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Fab from "@mui/material/Fab";
import DocumentScannerIcon from "@mui/icons-material/DocumentScanner";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import { emptyGuid } from "../../Shared/Constants";
import { IconButton } from "@mui/material";
import ConfirmDialog from "../../Shared/ConfirmDialog";

export const PrescriptionsList = (props) => {
  const [prescriptions, setPrescriptions] = useState(props.prescriptions);
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [prescriptionToDelete, setPrescriptionToDelete] = useState(null);

  useEffect(() => {
    setPrescriptions(props.prescriptions);
  }, [props.prescriptions]);

  const GoToPrescription = (event) => {
    if (event.currentTarget) {
      let selectedPrescription = prescriptions.filter(
        (p) => p.idPrescription === event.currentTarget.id
      );
      props.setPrescription(selectedPrescription[0]);
    } else {
      console.log("Prescription not available yet.");
    }
  };

  const GoToNewPrescription = () => {
    if (props.idVisit) {
      props.setPrescription({
        idPrescription: emptyGuid,
        idVisit: props.idVisit,
        date: props.visitDate,
        description: "",
      });
    }
  };

  const DeletePrescription = async (prescription) => {
    let path = `/api/prescription/delete`;
    const token = await authService.getAccessTokenIfValid();
    const requestOptions = {
      method: "POST",
      headers: !token
        ? { "Content-Type": "application/json" }
        : {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
      body: JSON.stringify(prescription),
    };
    fetch(path, requestOptions).then(async (response) => {
      const data = await response.json();
      // check for error response
      if (!response.ok) {
        // get error message from body or default to response statusText
        const error = (data && data.message) || response.statusText;
        return Promise.reject(error);
      } else {
        if (data) {
          props.onPrescriptionDelete(prescription, true);
        }
      }
    });
  };

  const handleDeleteRequest = (event) => {
    let selectedPrescription = prescriptions.filter(
      (p) => p.idPrescription === event.currentTarget.id
    );
    setPrescriptionToDelete(selectedPrescription[0]);
  };

  useEffect(() => {
    if (prescriptionToDelete) {
      setConfirmDialogOpen(true);
    }
  }, [prescriptionToDelete]);

  const handleDeleteConfirmation = (confirmation) => {
    if (confirmation) {
      DeletePrescription(prescriptionToDelete);
    }
    setConfirmDialogOpen(false);
  };

  const renderAddButton = () => {
    if (props.idVisit) {
      return (
        <>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <div className="w-100 d-flex justify-content-end pt-3 pe-3">
                <Fab
                  id="add-new"
                  sx={{
                    bgcolor: "#17252A",
                    color: "#00A0E0",
                  }}
                  data-testid="FAB-Add"
                  size="small"
                  aria-label="add"
                  onClick={GoToNewPrescription}
                >
                  <AddIcon />
                </Fab>
              </div>
            </Grid>
          </Grid>
        </>
      );
    }
  };

  const renderTable = () => {
    if (
      props.idVisit &&
      props.prescriptions &&
      props.prescriptions.length > 0
    ) {
      let itemList = props.prescriptions.map((prescription) => {
        return (
          <React.Fragment key={prescription.idPrescription}>
            <ListItem
              secondaryAction={
                <IconButton
                  edge="end"
                  aria-label="delete"
                  onClick={handleDeleteRequest}
                  id={prescription.idPrescription}
                >
                  <DeleteIcon />
                </IconButton>
              }
              disablePadding
            >
              <ListItemButton
                id={prescription.idPrescription}
                onClick={GoToPrescription}
              >
                <ListItemAvatar>
                  <Avatar>
                    <ArticleIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={prescription.description.substring(0, 20)}
                  secondary={new Date(prescription.date).toLocaleDateString(
                    "it-IT"
                  )}
                />
              </ListItemButton>
            </ListItem>
            <Divider />
          </React.Fragment>
        );
      });
      return itemList;
    } else {
      return (
        <div className="w-100 d-flex justify-content-center">
          <DocumentScannerIcon
            className="empty-placeholder-icon my-5"
            fontSize="large"
          />
        </div>
      );
    }
  };

  return (
    <>
      {renderAddButton()}
      <List
        sx={{
          width: "100%",
          position: "relative",
          overflow: "auto",
          maxHeight: 300,
          "& ul": { padding: 0 },
        }}
      >
        {renderTable()}
      </List>
      <ConfirmDialog
        open={confirmDialogOpen}
        answerCallback={handleDeleteConfirmation}
        title="Attenzione!"
        text="Si è richiesta la cancellazione della prescrizione. L'azione è irreversibile. Procedere con l'operazione?"
        agreeText="Elimina"
        disagreeText="Annulla"
      />
    </>
  );
};

export default PrescriptionsList;
