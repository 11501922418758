import React, { useState, useEffect } from "react";
import authService from "../api-authorization/AuthorizeService";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import VisitsList from "./VisitsList";
import Visit from "./Visit/Visit";
import { VisitContext } from "./VisitContext";

export const VisitsCard = (props) => {
    const [loading, setLoading] = useState(true);
    const [visits, setVisits] = useState([]);
    const [openVisit, setOpenVisit] = useState(null);
    const [forceReload, setForceReload] = useState(false);

    useEffect(() => {
        if(null === openVisit) setForceReload(true);
    },[openVisit]);

    const setOpenIdVisit = (idVisit) => {
        setOpenVisit({"idVisit": idVisit});
    };


    useEffect(() => {
        let isMounted = true;
        const getVisits = async () => {
            const token = await authService.getAccessTokenIfValid();
            fetch("/api/visit/" + props.request.idRequest, {
                headers: !token ? {} : { Authorization: "Bearer " + token },
            })
                .then(async (response) => {
                    const data = await response.json();
                    if (isMounted) {
                        setLoading(false);
                    }
                    // check for error response
                    if (!response.ok) {
                        // get error message from body or default to response statusText
                        const error = (data && data.message) || response.statusText;
                        return Promise.reject(error);
                    } else {
                        if (data) {
                            if (isMounted) {
                                setVisits(data);
                            }
                        }
                    }
                })
                .catch((error) => {
                    if (error) {
                        console.error("There was an error!", error);
                    }
                });
        };
        if (props.request) {
            getVisits();
        } else {
            setLoading(false);
        }
        //No visit is selected
        setOpenVisit(null);
        setForceReload(false);
        return () => {
            isMounted = false;
        };
    }, [props.request, forceReload]);

    const renderTitle = () => {
        if (props.request) {
            if (openVisit != null) {
                return (
                    <div>Scheda visita</div>
                )
            } else {
                return (
                    <div>
                        Visite per la richiesta del{" "}
                        {new Date(props.request.date).toLocaleDateString("it-IT")}
                    </div>
                );
            }
        } else {
            return <div>Lista Visite</div>;
        }
    };

    const renderCardContent = () => {
        if (openVisit != null) {
            return (
                <Visit
                    idRequest={props.request ? props.request.idRequest : null}
                    idPatient={props.request ? props.request.idPatient : null}
                    idRequestType={props.request ? props.request.idRequestType : null}
                    patient={props.patient}
                    idVisit={openVisit.idVisit}
                    setVisit={setOpenVisit}
                    feedbackCallback={props.feedbackCallback}
                />
            );
        } else {
            return (
                <VisitsList
                    visits={visits}
                    setIdVisit={setOpenIdVisit}
                    request={props.request}
                />
            );
        }
    };

  return (
    <Card className="h-100">
      <CardHeader
        title={renderTitle()}
        data-testid="cardTitle"
        titleTypographyProps={{
          variant: "body1",
        }}
      />
      <CardContent className="h-100">
        <VisitContext.Provider value={[openVisit, setOpenVisit]}>
            {renderCardContent()}
        </VisitContext.Provider>
        </CardContent>
    </Card>
  );
};

export default VisitsCard;
