import React, { useState, useContext, useEffect } from "react";
import Grid from "@mui/material/Grid";
import authService from "../../api-authorization/AuthorizeService";
import Select from "@mui/material/Select";
import Switch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";
import MenuItem from "@mui/material/MenuItem";
import Loading from "../../Shared/Loading";
import Typography from "@mui/material/Typography";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TablePagination from "@mui/material/TablePagination";
import { GetDateItalianFormat } from "../../Shared/Utilities.js";

export const TreatmentsList = (props) => {
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [page, setPage] = useState(0);
  const [loading, setLoading] = useState(true);
  const [treatments, setTreatments] = useState([]);
  const [seeAll, setSeeAll] = useState(false);

  useEffect(() => {
    const getTreatments = async () => {
      let token = await authService.getAccessTokenIfValid();
      let fetchRequest = "/api/treatment/byRequest/" + props.idRequest;
      if(seeAll){
        fetchRequest = "/api/treatment/byPatient/" + props.idPatient;
      }
      fetch(fetchRequest, {
        method: "GET",
        headers: !token
          ? { "Content-Type": "application/json" }
          : {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
              Accept: "application/json",
            },
      }).then(async (response) => {
        const data = await response.json();
        // check for error response
        if (!response.ok) {
          // get error message from body or default to response statusText
          const error = (data && data.message) || response.statusText;
          props.feedbackCallback(
            "error",
            "Errore nel caricamento degli interventi",
            true
          );
          return Promise.reject(error);
        } else {
          if (data) {
            setTreatments(data.filter((t) => t.idVisit !== props.idVisit));
            setLoading(false);
          }
        }
      });
    };
    getTreatments();
  }, [seeAll]);

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleRequestFilterChange = (event) => {
    setSeeAll(event.target.checked);
  };

  const renderTreatmentsList = () => {
    const tableColumns = [
      { id: "date", label: "Data", numeric: false },
      { id: "treatmentTypeTitle", label: "Tipologia", numeric: false },
      { id: "notes", label: "Note", numeric: false },
    ];
    let treatmentsToShow = [];
    if (treatments.length > rowsPerPage) {
      //select subscriptions to show
      const firstPageIndex = page * rowsPerPage;
      const lastPageIndex = firstPageIndex + rowsPerPage;
      treatmentsToShow = treatments.slice(firstPageIndex, lastPageIndex);
    } else {
      treatmentsToShow = [...treatments];
    }

    return (
      // Show treatments list only if there are treatments to show
      <>
        {treatments.length > 0 ? (
          <>
            <TableContainer>
              <Table
                sx={{ minWidth: 650 }}
                size="small"
                aria-label="a dense treatment table"
              >
                <TableHead>
                  <TableRow>
                    {tableColumns.map((headCell) => (
                      <TableCell
                        key={headCell.id}
                        align="left"
                        padding={headCell.disablePadding ? "none" : "normal"}
                      >
                        {headCell.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>

                <TableBody>
                  {treatmentsToShow.map((treatment) => (
                    <TableRow
                      hover
                      key={treatment.idTreatment}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell align="left">
                        {GetDateItalianFormat(treatment.date)}
                      </TableCell>
                      <TableCell align="left">
                        {treatment.treatmentTypeTitle}
                      </TableCell>
                      <TableCell align="left">{treatment.notes}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              labelRowsPerPage="Righe per pagina"
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={treatments.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handlePageChange}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </>
        ) : (
          <p style={{ textAlign: "center", width: "inherit" }}>
            {seeAll? "Nessun intervento trovato" : "Nessun intervento trovato per il percorso terapeutico"}
          </p>
        )}
      </>
    );
  };

  return (
    <>
      <Typography
        gutterBottom
        variant="subtitle3"
        component="div"
        data-testid="treatments-history"
      >
        <Grid container alignItems="center" justifyContent="center">
  <Grid item xs={6}>
    <Typography align="left">Storico</Typography>
  </Grid>
  <Grid item xs={6} container justifyContent="flex-end">
  <FormControlLabel
          labelPlacement="start"
          control={
            <Switch
              checked={seeAll}
              onChange={handleRequestFilterChange}
              inputProps={{ "aria-label": "limited-to-request" }}
            />
          }
          label="vedi tutti"
        />
  </Grid>
</Grid>
        
        
      </Typography>
      <Grid container spacing={2} justifyContent="flex-start" p={2}>
        {loading ? (
          <Loading message="Caricamento storico interventi" />
        ) : (
          renderTreatmentsList()
        )}
      </Grid>
    </>
  );
};

export default TreatmentsList;
