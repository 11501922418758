import React, { useRef, useState, useContext, useEffect } from "react";
import {Route, Routes, Router, Link, matchPath, useLocation, useHistory, Redirect} from "react-router-dom";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import Divider from "@mui/material/Divider";
import CardHeader from "@mui/material/CardHeader";
import FeedbackAlert from "../Shared/FeedbackAlert";
import Box from "@mui/material/Box";
import CardContent from "@mui/material/CardContent";
import InvoicesReport from "./InvoicesReport";
import RevenuesReport from "./RevenuesReport"
import ExpiredLicense from "../Shared/Licenses/ExpiredLicense";
import UserContext from "../UserContext";
import AddressBookList from "../Shared/AddressBook/AddressBookList";


export const Reports = (props) => {
    const userCtx = useContext(UserContext);
    const history = useHistory();
    const [value, setValue] = useState(history.location.pathname);

    const divRef = useRef(null);
    const [elementTop, setElementTop] = useState(0);


    const [feedback, setFeedback] = useState({
        severity: "success",
        text: "Success",
        open: false,
    });


    //cleanup state on unmount
    useEffect(() => {
        const handleResize = () => {
        if (divRef != null && divRef.current != null) {
           
                const rect = divRef.current.getBoundingClientRect();
                setElementTop(rect.top);
           
        }
        };
        // Aggiungi un listener per gestire gli eventuali ridimensionamenti della finestra
        window.addEventListener('resize', handleResize);

        // Esegui la funzione handleResize una volta per ottenere la posizione iniziale
        handleResize();



        return () => {
            setFeedback({ ...feedback, open: false });
            window.removeEventListener('resize', handleResize);
        }
    }, []);

    useEffect(() => {
        setValue(history.location.pathname);
    }, [history.location.pathname]);

    const alertClosed = () => {
        setFeedback({ ...feedback, open: false });
    };

    const feedbackCallback = (severity, text, open) => {
        setFeedback({ severity: severity, text: text, open: open });
    };

    

    return (
        <>
            {userCtx.isValidLicense && (userCtx.roles.includes("Doctor") || userCtx.roles.includes("Secretary"))&&
                <Router history={history}>
                    <Box p={2}>
                        <Card>
                            <CardHeader title="Reportistica" />
                            <CardContent
                                sx={{
                                    flexGrow: 2,
                                    display: "flex",
                                    height: `calc(100vh - ${elementTop}px -32px)`
                                }}
                                ref={divRef} 
                            >
                                <Grid container spacing={1}>
                                    <Grid item xs={2}>
                                        <Tabs
                                            value={value}
                                            aria-label="reports tabs"
                                            textColor="inherit"
                                            indicatorColor="primary"
                                            orientation="vertical"
                                        >
                                            <Tab
                                                label="Fatture pazienti"
                                                id="requests"
                                                value="/reports/invoices"
                                                to="/reports/invoices"
                                                component={Link}
                                            />
                                            <Tab
                                                label="Ricavi"
                                                id="revenues"
                                                value="/reports/revenues"
                                                to="/reports/revenues"
                                                component={Link}
                                            />
                                            <Tab
                                                label="Rubrica"
                                                id="addressbook"
                                                value="/reports/addressbook"
                                                to="/reports/addressbook"
                                                component={Link}
                                            />
                                            <Tab label="" icon={<Divider className="w-100" />} disabled />
                                        </Tabs>
                                    </Grid>
                                    <Grid item xs={10}>
                                        {/* Tabs handled by react-router-dom */}
                                        <Route exact path="/reports">
                                            <Redirect to="/reports/invoices" />
                                        </Route>
                                        <Route path="/reports/invoices">
                                            <InvoicesReport
                                                feedbackCallback={feedbackCallback}
                                            />
                                        </Route>
                                        <Route path="/reports/revenues">
                                            <RevenuesReport
                                                feedbackCallback={feedbackCallback}
                                            />
                                        </Route>
                                        <Route path="/reports/addressbook">
                                            <AddressBookList idDoctor={userCtx.doctor.idDoctor}/>
                                        </Route>
                                        <FeedbackAlert
                                            severity={feedback.severity}
                                            text={feedback.text}
                                            open={feedback.open}
                                            closedCallback={alertClosed}
                                        />
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                    </Box>
                </Router>
            }
            {(!userCtx.isValidLicense || (!userCtx.roles.includes("Doctor") && !userCtx.roles.includes("Secretary"))) &&
                <Grid container spacing={2} p={2} className="vh-100">
                    <ExpiredLicense />
                </Grid>
            }
        </>
    );
};

export default Reports;
