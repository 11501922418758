import _Countries from './countries_states.json'

export function getCountries() {
    return _Countries;
}

export function getCountriesSort(lang) {
    return getCountries().sort(function (a, b) {
        return getCountryName(a,lang).localeCompare(getCountryName(b,lang));
    });
}

export function getCountry(iso3){

    for (var i = 0; i < _Countries.length; i++) {
        if (_Countries[i]["iso3"] === iso3) {
            return _Countries[i];
        }
    }
    return _Countries[0];
}

export function getCountryName(option,lang){
    if (!option || option === undefined) {
        return "";
    }
    if (option["translations"]) {
        if (option["translations"][lang]) {
            return option["translations"][lang];
        }
    }
    return option["name"];
}

export function getProvincesForCountry(countryIso3){

    let _states = [];

    let empty_country = getCountry("");
    if (empty_country) {
        let empty_states = empty_country["states"];
        if (empty_states) {
            for (let i = 0; i < empty_states.length; i++) {
                let _s = empty_states[i];
                _states.push(_s);
            }
        }
    }
    
    if (countryIso3) {
        let _country = getCountry(countryIso3);
        if (_country) {
            if (countryIso3 === "ITA") {
                let _all = _country["states"];
                if (_all) {
                    for (let i = 0; i < _all.length; i++) {
                        if (_all[i]["type"] !== "region" && _all[i]["type"] !== "autonomous region") {
                            let _s = _all[i];
                            _states.push(_s);
                        }
                    }
                }
            }
            else {
                let _all = _country["states"];

                if (_all) {
                    for (let i = 0; i < _all.length; i++) {
                        let _s = _all[i];
                        _states.push(_s);
                    }
                }
            }
        }
    }

    return _states;
}

export function getProvince(countryCode, provinceCode){

    let _provinces = getProvincesForCountry(countryCode);
    if (_provinces) {
        for (let i = 0; i < _provinces.length; i++) {
            if (_provinces[i]["state_code"] === provinceCode) {

                return _provinces[i];
            }
        }
    }
    let emptyProvince = getProvincesForCountry("")
    return emptyProvince[0];
}
