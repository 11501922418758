import { useEffect, useState } from "react";
import authService from "../../api-authorization/AuthorizeService";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Stack from "@mui/material/Stack";
import AddIcon from "@mui/icons-material/Add";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Tooltip from "@mui/material/Tooltip";
import DeleteIcon from "@mui/icons-material/Delete";

export default function QuestionDialog(props) {
  const [open, setOpen] = useState(props.edit ? props.open : false);
  const [questionType, setQuestionType] = useState(
    props.edit ? props.question.dataType : "txt"
  );
  const [question, setQuestion] = useState(
    props.edit
      ? props.question
      : {
          idCustomDataField: "00000000-0000-0000-0000-000000000000",
          description: "",
          dataType: questionType,
          section: props.section,
          positionIndex: props.positionIndex,
          isGeneralData: false,
          size: 3,
          answerOptions: [],
          answer: "",
        }
  );

  useEffect(() => {
    setQuestionType(props.edit ? props.question.dataType : "txt");
  }, [props.edit, props.question]);

  useEffect(() => {
    if (props.edit) setOpen(props.open);
  }, [props.open, props.edit]);

  useEffect(() => {
    if (props.edit) {
      setQuestion(props.question);
    } else {
      //reset status when closed or changed
      setQuestion({
        idCustomDataField: "00000000-0000-0000-0000-000000000000",
        description: "",
        dataType: "txt",
        section: props.section,
        positionIndex: props.positionIndex,
        isGeneralData: false,
        size: 3,
        answerOptions: [],
        answer: "",
      });
      setQuestionType("txt");
    }
  }, [open, props.section, props.positionIndex, props.question, props.edit]);

  const handleTypeChange = (event) => {
    setQuestionType(event.target.value);
    if (event.target.value === "mlt") {
      setQuestion({
        ...question,
        dataType: event.target.value,
        answerOptions: [
          {
            idCustomDataOption: "00000000-0000-0000-0000-000000000000",
            section: props.section,
            optionDescription: "",
          },
        ],
      });
      return;
    }
    setQuestion({
      ...question,
      dataType: event.target.value,
      answerOptions: [],
    });
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    if (props.onClose) {
      props.onClose();
    }
    setOpen(false);
  };

  const handleIsGeneralDataChange = (event) => {
    setQuestion({ ...question, isGeneralData: event.target.checked });
  };

  const handleDescriptionChange = (event) => {
    setQuestion({ ...question, description: event.target.value });
  };

  const handleSizeChange = (event) => {
    setQuestion({ ...question, size: event.target.value });
  };
  const handleOptionDescriptionChange = (event) => {
    const { id, value } = event.target;
    const optionIndex = id.slice(id.indexOf("-") + 1, id.length);

    let options = question.answerOptions;
    if (props.edit) {
      options[optionIndex] = {
        ...options[optionIndex],
        optionDescription: value,
        positionIndex: optionIndex,
      };
    } else {
      options[optionIndex] = {
        idCustomDataOption: "00000000-0000-0000-0000-000000000000",
        optionDescription: value,
        positionIndex: optionIndex,
      };
    }
    setQuestion({
      ...question,
      answerOptions: options,
    });
  };

  const handleDeleteOption = (event) => {
    const { id } = event.currentTarget;
    const optionIndex = id.slice(id.indexOf("-") + 1, id.length);

    let options = question.answerOptions;
    options.splice(optionIndex, 1);
    setQuestion({
      ...question,
      answerOptions: options,
    });
  }

  const handleAddOption = (event) => {
    const { id, value } = event.target;
    setQuestion({
      ...question,
      answerOptions: [
        ...question.answerOptions,
        {
          idCustomDataOption: "00000000-0000-0000-0000-000000000000",
          optionDescription: value,
        },
      ],
    });
  };

  const handleSubmit = async () => {
    let path = "/api/question/new";
    if (props.edit) {
      path = "/api/question/update";
    }
    const token = await authService.getAccessTokenIfValid();
    const requestOptions = {
      method: "POST",
      headers: !token
        ? { "Content-Type": "application/json" }
        : {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
      body: JSON.stringify(question),
    };
    fetch(path, requestOptions).then(async (response) => {
      const data = await response.json();
      // check for error response
      if (!response.ok) {
        // get error message from body or default to response statusText
        const error = (data && data.message) || response.statusText;
        return Promise.reject(error);
      } else {
        if (data) {
          setQuestion(data);
          props.updateQuestionsCallback(data, false);
          setOpen(false);
          handleClose();
          props.savedCallback();
        }
      }
    });
  };


  const QuestionIsGeneralSelector = () => {
    return (
      <Tooltip title="Selezionare se il campo ha risposta singola per tutte le visite. 
          Non selezionare se richiede una risposta diversa per ogni visita.">
      <FormControl width="100%">
        <FormLabel id="questionGeneralData"> 
        </FormLabel>
        <FormControlLabel
          control={
            <Checkbox
              checked={question.isGeneralData}
              onChange={handleIsGeneralDataChange}
              disabled={props.edit}
            />
          }
          label="Campo universale"
          labelPlacement="top"
        />
      </FormControl>
      </Tooltip>
    );
  };

  const QuestionTypeSelector = () => {
    return (
      <FormControl width="100%">
        <FormLabel id="questionTypeSelector">
          Tipologia
        </FormLabel>
        <RadioGroup
          row
          aria-labelledby="questionTypeSelector"
          name="position"
          onChange={handleTypeChange}
          value={props.edit ? props.question.dataType : questionType}
        >
          <FormControlLabel
            value="txt"
            control={<Radio />}
            label="Testo libero"
            labelPlacement="top"
            disabled={props.edit}
          />
          <FormControlLabel
            value="mtx"
            control={<Radio />}
            label="Aera di testo"
            labelPlacement="top"
            disabled={props.edit}
          />
          <FormControlLabel
            value="mlt"
            control={<Radio />}
            label="Risposta multipla"
            labelPlacement="top"
            disabled={props.edit}
          />
          <FormControlLabel
            value="tit"
            control={<Radio />}
            label="Titolo"
            labelPlacement="top"
            disabled={props.edit}
          />
          <FormControlLabel
            value="flr"
            control={<Radio />}
            label="Riempimento"
            labelPlacement="top"
            disabled={props.edit}
          />
        </RadioGroup>
      </FormControl>
    );
  };

  const QuestionSizeSelector = () => {
    return (
      <FormControl width="100%">
        <FormLabel id="questionSizeSelector">
          Dimensione
        </FormLabel>
        <RadioGroup
          row
          aria-labelledby="questionSizeSelector"
          name="position"
          onChange={handleSizeChange}
          value={question.size}
        >
          <FormControlLabel
            value={3}
            control={<Radio />}
            label="Small"
            labelPlacement="top"
          />
          <FormControlLabel
            value={6}
            control={<Radio />}
            label="Medium"
            labelPlacement="top"
          />
          <FormControlLabel
            value={12}
            control={<Radio />}
            label="Large"
            labelPlacement="top"
          />
        </RadioGroup>
      </FormControl>
    );
  };

  const compareOptions = (a, b) => {
    if (a.positionIndex < b.positionIndex) {
      return -1;
    }
    if (a.positionIndex > b.positionIndex) {
      return 1;
    }
    return 0;
  };

  const renderOptions = () => {
    return question.answerOptions.sort(compareOptions).map((option, index) => {
      return (
        <ListItem key={`Option-${index}`}>
          <TextField
            margin="dense"
            id={`Option-${index}`}
            label={`Opzione ${index + 1}`}
            type="text"
            fullWidth
            variant="standard"
            value={question.answerOptions[index].optionDescription}
            onChange={handleOptionDescriptionChange}
          />
          <IconButton onClick={handleDeleteOption} id={`Option-${index}`}><DeleteIcon /></IconButton>
        </ListItem>
      );
    });
  };

  const QuestionForm = () => {
    switch(questionType) {

    case "txt":  
      return (
        <div>
          <TextField
            autoFocus
            margin="dense"
            id="text-description"
            label="Descrizione o domanda"
            type="text"
            fullWidth
            variant="standard"
            value={question.description}
            onChange={handleDescriptionChange}
          />
          <List dense={true}>{renderOptions()}</List>
          <IconButton size="small" aria-label="add" onClick={handleAddOption} color="inherit">
            <AddIcon fontSize="small" />
          </IconButton>
        </div>
      );

    case "mtx":
      return (
        <div>
          <TextField
            autoFocus
            margin="dense"
            id="text-description"
            label="Descrizione o domanda"
            type="text"
            fullWidth
            variant="standard"
            value={question.description}
            onChange={handleDescriptionChange}
          />
          <List dense={true}>{renderOptions()}</List>
          <IconButton size="small" aria-label="add" onClick={handleAddOption} color="inherit">
            <AddIcon fontSize="small" />
          </IconButton>
        </div>
      );  

    case "mlt": 
      return (
        <div>
          <TextField
            autoFocus
            margin="dense"
            id="multi-description"
            label="Descrizione o domanda"
            type="text"
            fullWidth
            variant="standard"
            value={question.description}
            onChange={handleDescriptionChange}
          />
          <List dense={true}>{renderOptions()}</List>
          <IconButton size="small" aria-label="add" onClick={handleAddOption} color="inherit">
            <AddIcon fontSize="small" />
          </IconButton>
        </div>
      );
    

    case "tit":
      return (
        <TextField
          autoFocus
          margin="dense"
          id="title-description"
          label="Titolo"
          type="text"
          fullWidth
          variant="standard"
          value={question.description}
          onChange={handleDescriptionChange}
        />
      );
    
    default:
      return(
        <div></div>
      )
    };
  };
  

  const AddButton = () => {
    return props.edit ? (
      <></>
    ) : (
      <IconButton
        data-testid="FAB-Add"
        size="small"
        aria-label="add"
        onClick={handleClickOpen}
        color="inherit"
      >
        <AddIcon fontSize="small" />
      </IconButton>
    );
  };

  return (
    <>
      {AddButton()}
      <Dialog open={open} onClose={handleClose} fullWidth={true} maxWidth="lg">
        <DialogTitle>{props.edit ? "Modifica" : "Nuovo"} campo</DialogTitle>
        <DialogContent>
          <DialogContentText></DialogContentText>
          <Stack direction="row" justifyContent="space-around" spacing={2}>
            {QuestionIsGeneralSelector()}
            {QuestionTypeSelector()}
            {QuestionSizeSelector()}
          </Stack>
          {QuestionForm()}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} aria-label="cancel">
            Annulla
          </Button>
          <Button onClick={handleSubmit} aria-label="save">
            Salva
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
