import React, { useState, useEffect } from "react";
import authService from "../api-authorization/AuthorizeService";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import { GetDateItalianFormat } from "../Shared/Utilities.js";

export const PatientSelect = (props) => {
  const [patients, setPatients] = useState([]);
  const [patient, setPatient] = useState(props.patient? {
    idPatient: props.patient.idPatient ?? undefined,
    name: props.patient.name ?? undefined,
    surname: props.patient.surname ?? undefined,
  }: null);
  const [inputValue, setInputValue] = useState("");

  useEffect(() => {
    if (props.patient && props.patient.idPatient !== undefined && patients.length > 0) {
        //set patient based on props.patient.idPatient
        setPatient(patients.find((p) => p.idPatient === props.patient.idPatient));

    }
  }, [patients]);


  //request to get the list of patients of current doctor
  const getMyPatients = async () => {
    const token = await authService.getAccessTokenIfValid();
    const response = await fetch("/api/patients", {
      headers: !token ? {} : { Authorization: `Bearer ${token}` },
    });
    const data = await response.json();
    setPatients(data);
  };

  useEffect(() => {
    getMyPatients();
  }, []);

  return (
    <Autocomplete
      size="small"
      required
      autoComplete
      clearOnEscape
      selectOnFocus
      clearOnBlur
      handleHomeEndKeys
      inputValue={inputValue}
      value={patient || null}
      options={props.doctorIdFilter? patients.filter((p) => p.doctorId === props.doctorIdFilter): patients}
      onFocus={getMyPatients}
      onInputChange={(event, newValue) => {
        setInputValue(newValue);
      }}
      onChange={(event, value) => {
        setPatient(value);
        props.onPatientSelected(value);
      }}
      getOptionLabel={(option) => option.surname + " " + option.name}
      renderOption={(props, option) => (
        <Box
          component="li"
          sx={{ display: "flex", alignItems: "flex-end" }}
          {...props}
          key={option.idPatient}
        >
          <div className="d-flex justify-content-between w-100">
            <div>
              {option.surname} {option.name}
            </div>
            <div>{GetDateItalianFormat(option.birthDate)}</div>
          </div>
        </Box>
      )}
      id="patientSearch"
      renderInput={(params) => (
        <TextField
          size="small"
          data-testid="patientInputField"
          required={props.required}
          {...params}
          label="Seleziona un paziente"
          variant="filled"
        />
      )}
    />
  );
};

export default PatientSelect;
