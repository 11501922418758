import { useState, useEffect, useContext } from "react";
import Grid from "@mui/material/Grid";
import DocumentsList from "./DocumentsList";
import DocumentEdit from "./DocumentEdit";
import authService from "../../api-authorization/AuthorizeService";
import { createDocumentPdf, MASTER_DOCTOR_ID } from "./DocumentUtil";
import { UserContext } from "../../UserContext";

export const DocumentsManager = (props) => {
    const userCtx = useContext(UserContext);

    const [loading, setLoading] = useState(true);
   
    const [documents, setDocuments] = useState([]);
    const [masterDocuments, setMasterDocuments] = useState([]);
    const [documentsAll, setDocumentsAll] = useState([]);
    const [document, setDocument] = useState(undefined);

    const EditRequestDocument = (editDocument) => {
        setDocument(editDocument);
    };

    const CreateRequestDocument = async (updateDocument) => {
        let token = await authService.getAccessTokenIfValid();
        let path = "/api/document/createTemplate";

        const requestOptions = {
            method: "POST",
            headers: !token
                ? { "Content-Type": "application/json" }
                : {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "application/json",
                    Accept: "application/json",
                },
            body: JSON.stringify(updateDocument),
        };
        fetch(path, requestOptions).then(async (response) => {
            const data = await response.json();
            // check for error response
            if (!response.ok) {
                // get error message from body or default to response statusText
                const error = (data && data.message) || response.statusText;
                props.feedbackCallback("error", "Errore nel salvataggio", true);
                return Promise.reject(error);
            } else {
                if (data) {
                    if (data.isMaster) {
                        setMasterDocuments([...masterDocuments, data]);
                    } else {
                        setDocuments([...documents, data]);
                    }
                    setDocumentsAll([...documentsAll, data]);
                    setDocument(data);

                    props.feedbackCallback(
                        "success",
                        "Documento creato con successo",
                        true
                    );
                }
            }
        });
    };

    const UpdateRequestDocument = async (updateDocument) => {
        let token = await authService.getAccessTokenIfValid();
        let path = "/api/document/updateTemplate";

        const requestOptions = {
            method: "POST",
            headers: !token
                ? { "Content-Type": "application/json" }
                : {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "application/json",
                    Accept: "application/json",
                },
            body: JSON.stringify(updateDocument),
        };
        fetch(path, requestOptions).then(async (response) => {
            const data = await response.json();
            // check for error response
            if (!response.ok) {
                // get error message from body or default to response statusText
                const error = (data && data.message) || response.statusText;
                props.feedbackCallback("error", "Errore nel salvataggio", true);
                return Promise.reject(error);
            } else {
                if (data) {
                    let indexMaster = masterDocuments.findIndex(
                        (x) => x.idDocumentTemplate === document.idDocumentTemplate
                    );
                    let index = documents.findIndex(
                        (x) => x.idDocumentTemplate === document.idDocumentTemplate
                    );
                    let reloadAll =
                        (updateDocument.isMaster && index >= 0) ||
                        (!updateDocument.isMaster && indexMaster > 0);

                    if (reloadAll) {
                        getDocuments();
                    } else {
                        if (data.isMaster) {
                            masterDocuments[indexMaster] = data;
                            setDocuments([...documents]);
                        } else {
                            documents[index] = data;
                            setDocuments([...documents]);
                        }
                    }
                    props.feedbackCallback("success", "Dati salvati con successo", true);
                }
            }
        });
    };

    const DeleteRequestDocument = async (deleteDocument) => {
        const token = await authService.getAccessTokenIfValid();
        const path = "/api/document/deleteTemplate";
        const requestOptions = {
            method: "POST",
            headers: !token
                ? { "Content-Type": "application/json" }
                : {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "application/json",
                    Accept: "application/json",
                },
            body: JSON.stringify(deleteDocument),
        };
        fetch(path, requestOptions).then(async (response) => {
            // check for error response
            if (!response.ok) {
                const data = await response.json();
                // get error message from body or default to response statusText
                const error = (data && data.message) || response.statusText;
                props.feedbackCallback(
                    "error",
                    "Impossibile eliminare. Documento in uso?",
                    true
                );
                return Promise.reject(error);
            } else {
                props.feedbackCallback("success", "Documento eliminato", true);
                if (!deleteDocument.isMaster) {
                    const newDocuments = documents.filter(
                        (x) => x.idDocumentTemplate !== deleteDocument.idDocumentTemplate
                    );
                    setDocuments(newDocuments);
                } else {
                    const newDocuments = masterDocuments.filter(
                        (x) => x.idDocumentTemplate !== deleteDocument.idDocumentTemplate
                    );
                    setMasterDocuments(newDocuments);
                }
                const newDocuments = documentsAll.filter(
                    (x) => x.idDocumentTemplate !== deleteDocument.idDocumentTemplate
                );
                setDocumentsAll(newDocuments);

                setDocument(undefined);
                props.feedbackCallback(
                    "success",
                    "Documento eliminato con successo",
                    true
                );
            }
        });
    };

    const DuplicateRequestDocument = async (duplicateDocument) => {
        let newDocument = {
            idDocumentTemplate: undefined,
            idDoctor: props.doctor.idDoctor,
            documentTemplateType: duplicateDocument.documentTemplateType,
            title: duplicateDocument.title + "(copia)",
            body: duplicateDocument.body,
            isMaster: false,
        };
        await CreateRequestDocument(newDocument);
    };

    const PdfRequestDocument = async (pdfDocument) => {
        createDocumentPdf(pdfDocument.body, props.patient, null, pdfDocument.title);
    };

    const getDocuments = async () => {
        const token = await authService.getAccessTokenIfValid();
        const response = await fetch(
            "/api/document/byDoctor/" + userCtx.doctor.idDoctor,
            {
                headers: !token ? {} : { Authorization: `Bearer ${token}` },
            }
        );
        const data = await response.json();
        if (!response.ok) {
            // get error message from body or default to response statusText
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }

        const dataMaster = await getMasterDocuments();
        if (data && dataMaster) {
            setDocuments(data);
            setMasterDocuments(dataMaster);
            setDocumentsAll([...data, ...dataMaster]);
            setLoading(false);
        }
    };

    const getMasterDocuments = async () => {
        const token = await authService.getAccessTokenIfValid();
        const response = await fetch(
            "/api/document/byDoctor/" + MASTER_DOCTOR_ID + "/true",
            {
                headers: !token ? {} : { Authorization: `Bearer ${token}` },
            }
        );
        const data = await response.json();
        if (!response.ok) {
            // get error message from body or default to response statusText
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        if (data) {
            return data;
        }
        return {};
    };

    const BackToDocumentsList = () => {
        setDocument(undefined);
    };


    useEffect(() => {
        if (userCtx.doctor === undefined || userCtx.doctor.idDoctor === undefined) {
            //do nothing if idDoctor is not set
            return;
        }

        getDocuments();
    }, [userCtx.doctor]);

    return (
        <Grid container spacing={2}>
            {document === undefined && (
                <Grid item xs={12}>
                    <DocumentsList
                        documents={documents}
                        masterDocuments={masterDocuments}
                        feedbackCallback={props.feedbackCallback}
                        onEditRequestDocument={EditRequestDocument}
                        onDeleteRequestDocument={DeleteRequestDocument}
                        onDuplicateRequestDocument={DuplicateRequestDocument}
                        onPdfRequestDocument={PdfRequestDocument}
                        loading={loading}
                    />
                </Grid>
            )}
            {document !== undefined && (
                <Grid item xs={12}>
                    <DocumentEdit
                        documents={documentsAll}
                        document={document}
                        feedbackCallback={props.feedbackCallback}
                        onCreateRequestDocument={CreateRequestDocument}
                        onUpdateRequestDocument={UpdateRequestDocument}
                        onDeleteRequestDocument={DeleteRequestDocument}
                        onDuplicateRequestDocument={DuplicateRequestDocument}
                        onPdfRequestDocument={PdfRequestDocument}
                        onBackToDocumentsList={BackToDocumentsList}
                    />
                </Grid>
            )}
        </Grid>
    );
};

export default DocumentsManager;
