import { useState, useEffect, useContext } from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import TextField from "@mui/material/TextField";
import authService from "../api-authorization/AuthorizeService";
import isEmail from "validator/lib/isEmail";
import CountriesSelect from "../Shared/CountriesStates/CountriesSelect";
import ProvincesSelect from "../Shared/CountriesStates/ProvincesSelect";
import UserContext from "../UserContext";
import Typography from "@mui/material/Typography";
import * as YpatientsUtilities from "../Shared/Utilities";
import { ImportRequestTypesOfSpecialization } from "../Shared/RequestUtil.js";

export const DoctorProfile = (props) => {
    const userCtx = useContext(UserContext);
    const [localDoctor, setLocalDoctor] = useState({
        idDoctor: "",
        idCompany: "",
        title: "",
        name: "",
        surname: "",
        address: "",
        zipCode: "",
        city: "",
        province: "",
        country: "",
        number: "",
        fiscalCode: "",
        email: "",
        pec: "",
        phone: "",
        mobile: "",
        iban: "",
        swift: ""
    });

    const [isValid, setIsValid] = useState({
        name: true,
        surname: true,
        email: true,
        pec: true,
        phone: true,
        mobile: true,
        address: true,
        city: true,
        zipCode: true,
        fiscalCode: true,
        number: true,
        iban: true,
        swift: true,
    });
    const specializationCode = "m_est";

    useEffect(() => {
        const getDoctor = async () => {
            setLocalDoctor(userCtx.doctor);
        };
        getDoctor();
    }, []);

    const handleCountry = (newvalue) => {
        if (localDoctor.country !== newvalue) {
            handleProvince("");
        }
        setLocalDoctor({
            ...localDoctor,
            country: newvalue,
        });
    };

    const handleProvince = (newvalue) => {
        setLocalDoctor({
            ...localDoctor,
            province: newvalue,
        });
    };

    const getCountry = () => {
        return localDoctor && localDoctor.country ? localDoctor.country : "";
    };
    const getProvince = () => {
        return localDoctor && localDoctor.province ? localDoctor.province : "";
    };

    const handleChange = (event) => {
        const { id, value } = event.target;
        setLocalDoctor({ ...localDoctor, [id]: value, idCompany: userCtx.idCompany });
        setIsValid({ ...isValid, [id]: true });

        if ((id === "email" || id === "pec") && value && !isEmail(value)) {
            setIsValid({ ...isValid, [id]: false });
        }

        if ((id === "phone" || id === "mobile") && !YpatientsUtilities.IsValidPhoneNumber(value)) {
            setIsValid({ ...isValid, [id]: false });
        }
       
    };

    const handleBlur = async () => {
        let token = await authService.getAccessTokenIfValid();
        let path = "/api/user/doctor/update";
        let isNew = false;
        if (localDoctor.idDoctor === "") {
            path = "/api/user/doctor/new/" + userCtx.idUser; // Profile doctor created by a user directly
            isNew = true;
        }
        const requestOptions = {
            method: "POST",
            headers: !token
                ? { "Content-Type": "application/json" }
                : {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "application/json",
                    Accept: "application/json",
                },
            body: JSON.stringify(localDoctor),
        };
        fetch(path, requestOptions).then(async (response) => {
            const data = await response.json();
            // check for error response
            if (!response.ok) {
                // get error message from body or default to response statusText
                const error = (data && data.message) || response.statusText;
                props.feedbackCallback("error", "Errore nel salvataggio", true);
                return Promise.reject(error);
            } else {
                if (data) {
                    setLocalDoctor(data);
                    userCtx.updateDoctor(data);
                    if (isNew) {
                        await ImportRequestTypesOfSpecialization(specializationCode, userCtx.doctor.idDoctor, userCtx.idCompany);
                    }
                    props.feedbackCallback("success", "Dati salvati con successo", true);
                }
            }
        });
    };

    return (
        <>
            <Grid container spacing={2} justifyContent="center">
                <Grid item xs={12}>
                    <Card
                        sx={{
                            boxShadow: 0,
                        }}
                    >
                        <CardContent>
                            <Typography gutterBottom variant="subtitle3" component="div">
                                Profilo del medico
                            </Typography>
                            <Grid container spacing={2}>
                                <Grid item xs={6} md={4} lg={3}>
                                    <TextField
                                        size="small"
                                        fullWidth
                                        id="title"
                                        variant="filled"
                                        label="Titolo"
                                        value={(localDoctor && localDoctor.title) || ""}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                    />
                                </Grid>
                                <Grid item xs={6} md={4} lg={3}>
                                    <TextField
                                        size="small"
                                        fullWidth
                                        id="name"
                                        variant="filled"
                                        label="Nome"
                                        value={(localDoctor && localDoctor.name) || ""}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                    />
                                </Grid>
                                <Grid item xs={6} md={4} lg={3}>
                                    <TextField
                                        size="small"
                                        fullWidth
                                        id="surname"
                                        variant="filled"
                                        label="Cognome"
                                        value={(localDoctor && localDoctor.surname) || ""}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                    />
                                </Grid>
                                <Grid item xs={12} md={4} lg={3}>
                                    <TextField
                                        size="small"
                                        fullWidth
                                        id="address"
                                        variant="filled"
                                        label="Indirizzo"
                                        value={(localDoctor && localDoctor.address) || ""}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                   />
                                </Grid>

                                <Grid item xs={6} md={6} lg={3}>
                                    <TextField
                                        size="small"
                                        fullWidth
                                        id="zipCode"
                                        variant="filled"
                                        label="CAP"
                                        value={(localDoctor && localDoctor.zipCode) || ""}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                    />
                                </Grid>
                                <Grid item xs={6} md={6} lg={3}>
                                    <TextField
                                        size="small"
                                        fullWidth
                                        id="city"
                                        variant="filled"
                                        label="Città"
                                        value={(localDoctor && localDoctor.city) || ""}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                    />
                                </Grid>
                                <Grid key="country" item xs={6} md={6} lg={3}>
                                    <CountriesSelect
                                        handleChange={handleCountry}
                                        handleCountry={getCountry}
                                        handleBlur={handleBlur}
                                    />
                                </Grid>
                                <Grid key="province" item xs={6} md={6} lg={3}>
                                    <ProvincesSelect
                                        handleChange={handleProvince}
                                        handleCountry={getCountry}
                                        handleProvince={getProvince}
                                        handleBlur={handleBlur}
                                    />
                                </Grid>
                                <Grid item xs={6} md={4} lg={3}>
                                    <TextField
                                        size="small"
                                        fullWidth
                                        id="email"
                                        variant="filled"
                                        label="E-mail"
                                        value={(localDoctor && localDoctor.email) || ""}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        error={!isValid.email}
                                        helperText={!isValid.email ? "email non valida" : ""}
                                    />
                                </Grid>
                                <Grid item xs={6} md={4} lg={3}>
                                    <TextField
                                        size="small"
                                        fullWidth
                                        id="pec"
                                        variant="filled"
                                        label="PEC"
                                        value={(localDoctor && localDoctor.pec) || ""}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        error={!isValid.pec}
                                        helperText={!isValid.pec ? "email pec non valida" : ""}
                                    />
                                </Grid>
                                <Grid item xs={6} md={4} lg={3}>
                                    <TextField
                                        size="small"
                                        fullWidth
                                        id="phone"
                                        variant="filled"
                                        label="Telefono"
                                        value={(localDoctor && localDoctor.phone) || ""}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        error={!isValid.phone}
                                        helperText={!isValid.phone ? "numero telefono non valido" : ""}
                                    />
                                </Grid>
                                <Grid item xs={6} md={4} lg={3}>
                                    <TextField
                                        size="small"
                                        fullWidth
                                        id="mobile"
                                        variant="filled"
                                        label="Cellulare"
                                        value={(localDoctor && localDoctor.mobile) || ""}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        error={!isValid.mobile}
                                        helperText={!isValid.mobile ? "numero telefono non valido" : ""}
                                    />
                                </Grid>
                                <Grid item xs={6} md={4} lg={3}>
                                    <TextField
                                        size="small"
                                        fullWidth
                                        id="fiscalCode"
                                        variant="filled"
                                        label="C.F."
                                        value={(localDoctor && localDoctor.fiscalCode) || ""}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                    />
                                </Grid>
                                <Grid item xs={6} md={4} lg={3}>
                                    <TextField
                                        size="small"
                                        fullWidth
                                        id="number"
                                        variant="filled"
                                        label="Numero di iscrizione all'Ordine"
                                        value={(localDoctor && localDoctor.number) || ""}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                    />
                                </Grid>
                                <Grid item xs={6} md={4} lg={3}>
                                    <TextField
                                        size="small"
                                        fullWidth
                                        id="iban"
                                        variant="filled"
                                        label="IBAN"
                                        value={(localDoctor && localDoctor.iban) || ""}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                    />
                                </Grid>
                                <Grid item xs={6} md={4} lg={3}>
                                    <TextField
                                        size="small"
                                        fullWidth
                                        id="swift"
                                        variant="filled"
                                        label="SWIFT"
                                        value={(localDoctor && localDoctor.swift) || ""}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                    />
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </>
    );
};

export default DoctorProfile;
