import React, { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import TextField from "@mui/material/TextField";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import CardActions from "@mui/material/CardActions";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import isEmail from "validator/lib/isEmail";
import { IsValidPassword } from "../Shared/Utilities.js";
import FilledInput from "@mui/material/FilledInput";
import InputAdornment from "@mui/material/InputAdornment";
import { VisibilityOff } from "@mui/icons-material";
import { Visibility } from "@mui/icons-material";
import IconButton from "@mui/material/IconButton";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Link from "@mui/material/Link";
import { Loading } from "../Shared/Loading.js";

import logo from '../../logo.png';
import authService, {AuthorizeService} from "../api-authorization/AuthorizeService";
import { useTheme } from '@mui/material/styles';
import {ThemeContext} from '../../theme';


export const ForgotPassword = () => {
  const theme = useTheme();
  const history = useHistory();
  const [newUser, setNewUser] = useState({
    email: "",
    password: "",
    rememberMe: false,
  });

  const [light, setLight] = useContext(ThemeContext);
  const [isValidEmail, setIsValidEmail] = useState(true);
  const [errorMessageEmail, setErrorMessageEmail] = useState("mail non valida");
  const [isValidPW, setIsValidPW] = useState(true);
  const [open, setOpen] = useState(false);

  const [isEmptyPassword, setIsEmptyPassword] = useState(true);
  const [isEmptyConfirmPassword, setIsEmptyConfirmPassword] = useState(true);
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);

  const supportEmail = process.env.REACT_APP_SUPPORT_EMAIL;
  const infoEmail = process.env.REACT_APP_INFO_EMAIL;


  //cleanup state on unmount
  useEffect(() => {
    return () => {
      setNewUser({
        email: "",
        password: "",
        rememberMe: false,
      });
      setIsValidEmail(true);
      setErrorMessageEmail("mail non valida");
      setIsValidPW(true);
      setIsEmptyPassword(true);
      setIsEmptyConfirmPassword(true);
      setShowPassword(false);
      setLoading(false);
    }
  } ,[]);





  const RestorePassword = () => {
    history.push({
      pathname: "/account/register",
    });
  };


  const handleEmailChange = (event) => {
    const { id, value } = event.target;
    if (id) {
      if (value) {
        if (isEmail(value)) {
          setIsValidEmail(true);
          setErrorMessageEmail("");

          setNewUser({
            ...newUser,
            email: value,
          });
        } else {
          setIsValidEmail(false);
          setErrorMessageEmail("mail non valida");
        }
      } else {
        setNewUser({
          ...newUser,
          email: "",
        });
      }
    }
  };



  const LoginSubmit = async (event) => {
    setLoading(true);

    event.preventDefault();
    let path = "/api/account/password/reset";
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(newUser),
    };
    fetch(path, requestOptions).then(async (response) => {
      setLoading(false);
      history.push({
          pathname: "/authentication/login",
        });
      // check for error response
      if (!response.ok) {
        // get error message from body or default to response statusText
        const errorMes = await response.text();

        setIsValidEmail(false);
        setErrorMessageEmail(errorMes);
      } else
      if (response.redirected) {
        window.location.href = response.url;
      } else {
        const data = await response.json();
        if (data) {
          setOpen(true);
        }
      }
    });
  };

  return (
    <Container>
      <Grid container spacing={2} p={6}>
        <Grid item xs={12} md={8}>
          <section>
            <img src={logo} alt="Mosaycon" />
            <h2>Accedi alla gestione del tuo ambulatorio</h2>
            <hr /> 
            <p align="justify">Mosaycon è un'applicazione per la gestione di ambulatori medici. 
                    Registrati per scoprire tutte le funzionalità. Potrai attivare immediatamente una licenza di prova, senza impegno.</p>
                <p>Per avere più informazioni ed essere contattato dai nostri consulenti puoi scriverci a <a href={`mailto:${infoEmail}`} color="inherit">{infoEmail}</a>.</p>
                <p>Serve aiuto? Scrivi a <a href={`mailto:${supportEmail}`} color="inherit">{supportEmail}</a> o utilizza la chat che trovi integrata nella pagina.</p>
          </section>
        </Grid>
        <Grid item xs={12} md={4}>
          <Card>
            <CardHeader
              title="Password dimenticata"
              titleTypographyProps={{
                variant: "h6",
              }}
            />
            <form onSubmit={LoginSubmit}>
              <CardContent>
                  <TextField
                    sx={{
                      mb: 2,
                    }}
                    fullWidth
                    id="email"
                    key="email"
                    name="email"
                    label="E-mail *"
                    variant="filled"
                    size="small"
                    error={isValidEmail === false}
                    helperText={isValidEmail === false ? errorMessageEmail : ""}
                    onChange={handleEmailChange}
                  ></TextField>
              </CardContent>
              <CardActions>
                <div className="w-100 d-flex justify-content-center pb-3">
                  {loading ? (
                    <Loading />
                  ) : (
                    <Button
                      sx={{
                        m: 1,
                      }}
                      type="submit"
                      data-testid="submitButton"
                      color="primary"
                      variant="contained"
                    >
                      Ripristina password
                    </Button>
                  )}
                </div>
              </CardActions>
            </form>
          </Card>
        </Grid>
      </Grid>
      </Container>
  );
};

export default ForgotPassword
;
