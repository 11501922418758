import React, { useEffect, useState } from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import authService from "../api-authorization/AuthorizeService";
import Pagination from "@mui/material/Pagination";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import ShoppingCartCheckoutIcon from "@mui/icons-material/ShoppingCartCheckout";
import { GetDateItalianFormat } from "../Shared/Utilities.js";
import Typography from '@mui/material/Typography';
import { createPdf } from "../Shared/Invoice/InvoiceUtil.js";
export const InvoiceList = (props) => {
    const pageSize = 5;
    const [page, setPage] = useState(1);
    const [invoices, setInvoices] = useState([]);
    const [company, setCompany] = useState();

  const columns = [
    { id: "number", label: "Numero Fattura", numeric: false },
    { id: "date", label: "Data Fattura", numeric: false },
    { id: "amount", label: "Ammontare", numeric: false },
    { id: "taxes", label: "IVA", numeric: false },
    { id: "state", label: "Stato", numeric: false },
    { id: "action", label: "Azioni", numeric: false },
  ];
  let invoicesDisplayed;
  //cleanup state on unmount
  useEffect(() => {
    return () => {
      setInvoices([]);
      setPage(1);
    };
  }, []);

  const handleChange = (event, value) => {
    setPage(value);
  };

    const getInvoices = async (idcompany) => {
        if (idcompany !== "") {
            const token = await authService.getAccessTokenIfValid();
            const response = await fetch("/api/invoice/" + idcompany, {
                headers: !token ? {} : { Authorization: `Bearer ${token}` },
            });
            const data = await response.json();
            if (data) {
                setInvoices(data);
            }
        }
    };

    const getInvoiceRows = async (idInvoice) => {

        const token = await authService.getAccessTokenIfValid();
        const response = await fetch("/api/invoice/rows/" + idInvoice
            , {
            headers: !token ? {} : { Authorization: `Bearer ${token}` },
        });
        const data = await response.json();
        if (data) {
            return data;
        }
    };
    async function getPdfInvoice(index) {
        let invoiceRows = await getInvoiceRows(invoicesDisplayed[index].idInvoice);
        if (invoiceRows != "undefined") {
            return createPdf(invoicesDisplayed[index], invoiceRows, company);
        }
    }

    useEffect(() => {
        const getCompany = async () => {
            const token = await authService.getAccessTokenIfValid();
            const response = await fetch("/api/company", {
                headers: !token ? {} : { Authorization: `Bearer ${token}` },
            });
            const data = await response.json();
            if (data != undefined) {
                setCompany(data);
                let _idcompany = data.idCompany;
                
                getInvoices(_idcompany);
            }
        };
        getCompany();
    },[props.user]);

  const getStatusString = (idStatus) => {
    switch (idStatus) {
      case 0:
        return "DA PAGARE";
      case 1:
        return "PAGATA";
      case -1:
        return "ELIMINATA";
      default:
        return "";
    }
  };

    const renderTable = () => {
        let invoicesToShow = [];
        if (invoices !== undefined && invoices != null && invoices.length > 0) {
            if (invoices.length > pageSize) {
                //select subscriptions to show
                const firstPageIndex = (page - 1) * pageSize;
                const lastPageIndex = firstPageIndex + pageSize;
                invoicesToShow = invoices.slice(firstPageIndex, lastPageIndex);
            }
            else {
                invoicesToShow = [...invoices];
            }
        }
        else {
            invoicesToShow = [];
        }
        invoicesDisplayed = invoicesToShow;

        return (
            <TableContainer>
                <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
                    <TableHead>
                        <TableRow>
                            {columns.map((headCell) => (
                                <TableCell
                                    key={headCell.id}
                                    align="center"
                                    padding={headCell.disablePadding ? "none" : "normal"}
                                >
                                    {headCell.label}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>

                    <TableBody>
                        {invoicesToShow.map((row,index) => (
                            <TableRow
                                key={row.idInvoice}
                                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                            >
                                <TableCell align="center">{row.number}</TableCell>
                                <TableCell align="center">{GetDateItalianFormat(row.date)}</TableCell>
                                <TableCell align="center">{row.amount ? row.amount.toFixed(2) : ''}</TableCell>
                                <TableCell align="center">{row.taxes ? row.taxes.toFixed(2) : ''}</TableCell>
                                <TableCell align="center">{getStatusString(row.status)}</TableCell>
                                <TableCell align="center"><Button data-testid={"btnPdf_"+index} variant="outlined" size="small" startIcon={<ShoppingCartCheckoutIcon fontSize="small" />} onClick={() => getPdfInvoice(index)}  >Pdf</Button></TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        );
    };

    return (
        <Card
          sx={{ 
            boxShadow: 0 
          }}
        >
            <CardContent>
                <Typography gutterBottom variant="subtitle3" component="div">
                Stato Fatture
                </Typography>                 
                    {renderTable()}
                {invoices.length > pageSize && (
                    <Pagination
                        count={(invoices.length % pageSize) + 1}
                        defaultPage={1}
                        onChange={handleChange}
                    />
                )}
            </CardContent>
        </Card>
    );
};

export default InvoiceList;
