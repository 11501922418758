import React, { useState, useEffect } from "react";
import authService from "../../api-authorization/AuthorizeService";
import * as Utilities from "../../Shared/Utilities";
import { Loading } from "../../Shared/Loading";

import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import CompareIcon from "@mui/icons-material/Compare";
import DeleteIcon from "@mui/icons-material/Delete";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";
import Grid from "@mui/material/Grid";
import { TransformWrapper, TransformComponent } from "@pronestor/react-zoom-pan-pinch";
import "./ImageItem.css";
import { Typography } from "@mui/material";

export const ImageItem = (props) => {
  const image = props.image;
  const propertiesTitle = "Propriet\u00E0 dell'immagine " + image.name;
  const IdPatient = props.idPatient;
  const [openZoomDialog, setOpenZoomDialog] = useState(false);
  const [openAttributesDialog, setOpenAttributesDialog] = useState(false);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [hasChanged, setHasChanged] = useState(false);
  const [hasCallBackChanged, setHasCallBackChanged] = useState(false);
  const [imageProperties, setImageProperties] = useState({
    idImage: image.idFile,
    idPatient: props.idPatient,
    title: image.fileTitle,
    description: "",
    url: image.name,
    imageType: "",
    idVisit: "",
    idTreatment: "",
    lastModified: image.fileLastModified,
  });
  const [compare, setCompare] = useState(false);
  const [canCompare, setCanCompare] = useState(props.canAddToCompare);

  useEffect(() => {
    setCanCompare(props.canAddToCompare);
  }, [props.canAddToCompare]);

  const handleOpenZoomDialogOnClick = () => {
    GetImage(image.name);
    setOpenZoomDialog(true);
  };

  const handleCloseZoomDialogOnClick = () => {
    setOpenZoomDialog(false);
  };

  const handleOpenAttributesDialogOnClick = () => {
    GetImage(image.name);
    setOpenAttributesDialog(true);
  };

  const handleCloseAttributesDialogOnClick = () => {
    if (!hasChanged) {
      if (hasCallBackChanged) {
        setHasCallBackChanged(false);
        setOpenAttributesDialog(false);
      } else {
        setOpenAttributesDialog(false);
      }
    }
  };

  const handleDeleteImageOnClick = () => {
    setOpenConfirmDialog(false);
    DeleteImage(image.idFile, image.name);
  };

  const handleOpenConfirmDialogOnClick = () => {
    setOpenConfirmDialog(true);
  };

  const handleCloseConfirmDialogOnClick = () => {
    setOpenConfirmDialog(false);
  };

  const handleChangeProperties = (event) => {
    const { id, value } = event.target;
    if (id) {
      if (value) {
        setImageProperties({
          ...imageProperties,
          [id]: value,
        });
      } else {
        setImageProperties({
          ...imageProperties,
          [id]: "",
        });
      }
    }
    setHasChanged(true);
    setHasCallBackChanged(true);
  };

  const handleSaveProperties = async () => {
    if (hasChanged) {
      const token = await authService.getAccessTokenIfValid();
      const postUrl = "/api/filepatient/image/update";
      const response = await fetch(postUrl, {
        method: "POST",
        body: JSON.stringify(imageProperties),
        headers: !token
          ? {}
          : {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
      });

      if (response.ok) {
        const data = await response.json();
        if (data) {
          props.feedbackCallback("success", "Dati salvati con successo!", true);
        }
      } else {
        const error = await response.text();
        props.feedbackCallback("error", error, true);
      }
      setHasChanged(false);
    }
  };

  const GetImage = async (imageName) => {
    setIsLoading(true);
    const token = await authService.getAccessTokenIfValid();
    const getUrl = "/api/filepatient/image/" + IdPatient + "/" + imageName;
    const response = await fetch(getUrl, {
      headers: !token
        ? {}
        : {
            Authorization: `Bearer ${token}`,
          },
    });

    if (response.ok) {
      const data = await response.json();
      setImageProperties(data);
    } else {
      const error = await response.text();
      props.feedbackCallback("error", error, true);
    }
    setIsLoading(false);
  };

  const DeleteImage = async (idImage, imageName) => {
    const token = await authService.getAccessTokenIfValid();
    const getUrl =
      "/api/filepatient/image/delete/" +
      IdPatient +
      "/" +
      idImage +
      "/" +
      imageName;
    const response = await fetch(getUrl, {
      headers: !token
        ? {}
        : {
            Authorization: `Bearer ${token}`,
          },
    });

    if (response.ok) {
      const data = await response.json();
      if (data) {
        props.getImagesCallback(image);
      } else {
        props.feedbackCallback(
          "error",
          "Errore durante l'eliminazione dell'immagine",
          true
        );
      }
    } else {
      let error = "Status restituito: " + (await response.status);
      error += " " + (await response.text());
      props.feedbackCallback("error", error, true);
    }
  };

  const handleToggleCompare = () => {
    if( compare || canCompare){
     setCompare(!compare);
     props.compareCallback(image);
    } 
  };

  return (
    <div
      data-testid={image.name}
      className={`container-img-hover ${
        compare ? "container-img-compare" : ""
      }`}
    >
      
      <Button
        fullWidth
        onClick={handleOpenZoomDialogOnClick}
        sx={{ padding: 0 }}
      >
        <LazyLoadImage
          src={image.urlPreview}
          alt={image.name}
          height="100%"
          width="100%"
          className="img-thumbnail img-fluid"
          effect="blur"
        />
      </Button>
      <div className="image-header"/>
      <div className="image-footer">
        <Typography variant="h6">
          {Utilities.GetDateItalianFormat(image.fileLastModified)}
        </Typography>
      </div>
      <Stack
        direction="row"
        spacing={2}
        alignItems="center"
        justifyContent="space-around"
        className="image-header-text"
      >
        <IconButton
          onClick={handleToggleCompare}
          data-testid="toggleCompareButton"
        >
          <CompareIcon fontSize="large" />
        </IconButton>
        <Divider orientation="vertical" flexItem />
        <IconButton
          onClick={handleOpenConfirmDialogOnClick}
          data-testid="openConfirmModal"
        >
          <DeleteIcon fontSize="large" />
        </IconButton>
      </Stack>

      <Dialog
        open={openZoomDialog}
        onClose={handleCloseZoomDialogOnClick}
        fullWidth={true}
        maxWidth="640"
      >
        <DialogContent>
          <Card>
            <CardHeader
              title={image.name}
              data-testid="section-title"
              titleTypographyProps={{
                variant: "h7",
              }}
            />
            <CardContent>
              <Grid container spacing={2} justifyContent="center">
                <Grid item xs="auto">
                  <TransformWrapper 
                    minScale={0.2}
                    centerZoomedOut={true}>
                    <TransformComponent>
                      <img
                        crossOrigin="anonymous"
                        src={image.url}
                        alt={image.name}
                        className="image imageItem-img"
                      />
                    </TransformComponent>
                  </TransformWrapper>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </DialogContent>
        <DialogActions>
          <IconButton
            onClick={handleCloseZoomDialogOnClick}
            data-testid="closeImageZoomModal"
          >
            <CloseIcon />
          </IconButton>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openAttributesDialog}
        onClose={handleCloseAttributesDialogOnClick}
      >
        {isLoading && <Loading message="Loading image..." />}
        {!isLoading && (
          <DialogContent>
            <Card>
              <CardHeader
                title={propertiesTitle}
                data-testid="section-title"
                titleTypographyProps={{
                  variant: "h7",
                }}
              />
              <CardContent>
                <img
                  src={image.urlPreview}
                  alt={image.name}
                  className="img-thumbnail img-fluid image imageItem-img"
                />
                <form>
                  <TextField
                    fullWidth
                    id="title"
                    key="title"
                    name="title"
                    label="Titolo"
                    variant="filled"
                    size="small"
                    value={imageProperties.title || ""}
                    data-testid={"image-title"}
                    onChange={handleChangeProperties}
                    onBlur={handleSaveProperties}
                  ></TextField>
                  <TextField
                    fullWidth
                    id="description"
                    key="description"
                    name="description"
                    label="Descrizione"
                    variant="filled"
                    size="small"
                    value={imageProperties.description || ""}
                    data-testid={"image-description"}
                    onChange={handleChangeProperties}
                    onBlur={handleSaveProperties}
                  ></TextField>
                </form>
              </CardContent>
            </Card>
          </DialogContent>
        )}
        <DialogActions>
          <IconButton
            onClick={handleCloseAttributesDialogOnClick}
            data-testid="closeImagePropertiesModal"
          >
            <CloseIcon />
          </IconButton>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openConfirmDialog}
        onClose={handleCloseConfirmDialogOnClick}
      >
        <DialogTitle>{"Conferma eliminazione immagine"}</DialogTitle>
        <DialogContent>
          <DialogContent>
            <p>
              Sicuro di voler eliminare l'immagine selezionata {image.name}?
            </p>
          </DialogContent>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleCloseConfirmDialogOnClick}
            autoFocus
            data-testid="closeConfirmModal"
          >
            No
          </Button>
          <Button onClick={handleDeleteImageOnClick}>{"S\u00EC"}</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
