import React, { useEffect, useState, useContext } from "react";
import authService from "../../api-authorization/AuthorizeService";
import Stack from "@mui/material/Stack";
import Grid from "@mui/material/Grid";
import GeneralInfoCard from "./GeneralInfoCard";
import Loading from "../../Shared/Loading";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Section from "./Section";
import PrescriptionCard from "./PrescriptionCard";
import InvoicesCard from "./InvoicesCard";
import QuestionsImport from "./QuestionsImport";
import * as Consts from "../../Shared/Constants";
import { ImagesSection } from "../ImagesManagement/ImagesSection";
import { updateQuestionList } from "./VisitUtils";
import TreatmentsSection from "./TreatmentsSection";
import TreatmentsList from "./TreatmentsList";
import DocumentsManager from "../../Shared/Document/DocumentsManager";
import UserContext from "../../UserContext";

export const Visit = (props) => {
  const userCtx = useContext(UserContext);
  const [loading, setLoading] = useState(true);
  const [loadingQuestions, setLoadingQuestions] = useState(false);
  const [questions, setQuestions] = useState([]);
  const [alertOpen, setAlertOpen] = useState(false);
  const [ignoreTemplate, setIgnoreTemplate] = useState(false);
  const [forceReload, setForceReload] = useState(false);
  const [activeTab, setActiveTab] = useState(0);

  const handleTabChange = (event, value) => {
    setActiveTab(value);
  };

  useEffect(() => {
    setLoading(true);
  }, [props.idVisit]);

  const updateQuestions = (updatedQuestion, deleteQuestion) => {
    let newQuestions = updateQuestionList(
      questions,
      updatedQuestion,
      deleteQuestion
    );
    setQuestions(newQuestions);
  };

  useEffect(() => {
    const getQuestions = async () => {
      setForceReload(false);
      const token = await authService.getAccessTokenIfValid();
      fetch(
        "/api/question/patient/" + props.idPatient + "/visit/" + props.idVisit,
        {
          headers: !token ? {} : { Authorization: "Bearer " + token },
        }
      )
        .then(async (response) => {
          const data = await response.json();
          setLoading(false);
          setLoadingQuestions(false);
          // check for error response
          if (!response.ok) {
            // get error message from body or default to response statusText
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
          } else {
            if (data) {
              setQuestions(data);
            }
          }
        })
        .catch((error) => {
          if (error) {
            console.error("There was an error!", error);
          }
        });
    };
    if (
      props.idPatient &&
      props.idVisit &&
        Consts.emptyGuid !== props.idVisit &&
        userCtx.roles.includes("Doctor")
    ) {
      getQuestions();
    } else {
      setLoading(false);
    }
    return;
  }, [props.idVisit, props.idPatient, forceReload]);

  const forceQuestionsReload = () => {
    setForceReload(true);
    setLoading(true);
  };

  const ignoreTemplateCallback = (ignore) => {
    setIgnoreTemplate(ignore);
  };

    const renderDoctorsView = () => {
        if (Consts.emptyGuid === props.idVisit) {
            if (!loadingQuestions) setLoadingQuestions(true);
            return <></>;
        } else {
            if (loadingQuestions) {
                return <Loading message="Loading questions..." />;
            } else {
                if (questions.length === 0 && !ignoreTemplate) {
                    return (
                        <div align="center">
                            <QuestionsImport
                                reloadQuestions={forceQuestionsReload}
                                ignoreTemplate={ignoreTemplateCallback}
                                feedbackCallback={props.feedbackCallback}
                            />
                        </div>
                    );
                } else {
                    return (
                        <>
                            <Tabs
                                onChange={handleTabChange}
                                value={activeTab}
                                textColor="inherit"
                                indicatorColor="primary"
                                variant="scrollable"
                                scrollButtons="auto"
                            >
                                <Tab label="Anamnesi" />
                                <Tab label="Esame obiettivo" />
                                <Tab label="Esami clinici" />
                                <Tab label="Diagnosi" />
                                <Tab label="Terapia e Interventi" />
                                <Tab label="Fotografie" />
                                <Tab label="Prescrizioni" />
                                <Tab label="Fatture" />
                                <Tab label="Documenti" />
                            </Tabs>
                            <TabPanel value={activeTab} index={0}>
                                <Section
                                    loading={loading}
                                    questions={
                                        questions
                                            ? questions.filter((q) => q.section === "Anamnesi")
                                            : undefined
                                    }
                                    section="Anamnesi"
                                    savedCallback={() =>
                                        props.feedbackCallback("success", "Dati salvati con successo!", true)
                                    }
                                    updateQuestionsCallback={updateQuestions}
                                />
                            </TabPanel>
                            <TabPanel value={activeTab} index={1}>
                                <Section
                                    loading={loading}
                                    questions={
                                        questions
                                            ? questions.filter((q) => q.section === "Esame obiettivo")
                                            : undefined
                                    }
                                    section="Esame obiettivo"
                                    savedCallback={() =>
                                        props.feedbackCallback("success", "Dati salvati con successo!", true)
                                    }
                                    updateQuestionsCallback={updateQuestions}
                                />
                            </TabPanel>
                            <TabPanel value={activeTab} index={2}>
                                <Section
                                    loading={loading}
                                    questions={
                                        questions
                                            ? questions.filter((q) => q.section === "Esami clinici")
                                            : undefined
                                    }
                                    section="Esami clinici"
                                    savedCallback={() =>
                                        props.feedbackCallback("success", "Dati salvati con successo!", true)
                                    }
                                    updateQuestionsCallback={updateQuestions}
                                />
                            </TabPanel>
                            <TabPanel value={activeTab} index={3}>
                                <Section
                                    loading={loading}
                                    questions={
                                        questions
                                            ? questions.filter((q) => q.section === "Diagnosi")
                                            : undefined
                                    }
                                    section="Diagnosi"
                                    savedCallback={() =>
                                        props.feedbackCallback("success", "Dati salvati con successo!", true)
                                    }
                                    updateQuestionsCallback={updateQuestions}
                                />
                            </TabPanel>
                            <TabPanel value={activeTab} index={4}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} md={6}>
                                        <Section
                                            loading={loading}
                                            questions={
                                                questions
                                                    ? questions.filter((q) => q.section === "Interventi")
                                                    : undefined
                                            }
                                            section="Interventi"
                                            savedCallback={() =>
                                                props.feedbackCallback("success", "Dati salvati con successo!", true)
                                            }
                                            updateQuestionsCallback={updateQuestions}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <TreatmentsList idRequest={props.idRequest} idVisit={props.idVisit} idPatient={props.idPatient}/>
                                    </Grid>
                                </Grid>
                                <TreatmentsSection
                                    feedbackCallback={props.feedbackCallback}
                                    idVisit={props.idVisit}
                                    idRequestType={props.idRequestType}
                                />
                            </TabPanel>
                            <TabPanel value={activeTab} index={5}>
                                <ImagesSection
                                    idPatient={props.idPatient}
                                    idVisit={props.idVisit}
                                    feedbackCallback={props.feedbackCallback}
                                />
                            </TabPanel>
                            <TabPanel value={activeTab} index={6}>
                                <PrescriptionCard
                                    patient={props.patient}
                                    idVisit={props.idVisit}
                                    feedbackCallback={props.feedbackCallback}
                                />
                            </TabPanel>
                            <TabPanel value={activeTab} index={7}>
                                <InvoicesCard
                                    idVisit={props.idVisit}
                                    idCompany={Consts.emptyGuid}
                                    feedbackCallback={props.feedbackCallback}
                                />
                            </TabPanel>
                            <TabPanel value={activeTab} index={8}>
                                <DocumentsManager feedbackCallback={props.feedbackCallback} patient={props.patient} />
                            </TabPanel>
                        </>
                    );
                }
            }
        }
    };
  
    const renderSecretarysView = () => {
      return (
        <>
          <Tabs
            onChange={handleTabChange}
            value={activeTab}
            textColor="inherit"
            indicatorColor="primary"
            variant="scrollable"
            scrollButtons="auto"
          >
            <Tab label="Fatture" />
            <Tab label="Documenti" />
          </Tabs>
          <TabPanel value={activeTab} index={0}>
            <InvoicesCard
              idVisit={props.idVisit}
              idCompany={Consts.emptyGuid}
              feedbackCallback={props.feedbackCallback}
            />
          </TabPanel>
          <TabPanel value={activeTab} index={1}>
            <DocumentsManager
              feedbackCallback={props.feedbackCallback}
              patient={props.patient}
            />
          </TabPanel>
        </>
      );
    };
  
  if (loading) {
    return <Loading color="inherit" message="Caricamento visita" />;
  } else {
    return (
      <div>
        <Stack spacing={2}>
          <GeneralInfoCard
            feedbackCallback={props.feedbackCallback }
            idRequest={props.idRequest}
            idPatient={props.idPatient}
            idVisit={props.idVisit}
            setVisit={props.setVisit}
          />

          {userCtx.roles.includes("Doctor") && renderDoctorsView()}
          {userCtx.roles.includes("Secretary") && renderSecretarysView()}
        </Stack>
      </div>
    );
  }
};

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      className="h-100"
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
      style={{ paddingTop: 5 }}
    >
      {value === index && <>{children}</>}
    </div>
  );
};

export default Visit;
