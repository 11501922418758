import React, { useState, useContext, useEffect } from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import Box from "@mui/material/Box";
import CardContent from "@mui/material/CardContent";
import ExpiredLicense from "../Shared/Licenses/ExpiredLicense";
import UserContext from "../UserContext";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import PatientSelect from "../Shared/PatientSelect.js";
import authService from "../api-authorization/AuthorizeService";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Divider from "@mui/material/Divider";

import { createQuotationPdf, sendQuotationPdf, getCalendarsOfQuotation } from "./QuotationUtil.js";
import * as YpatientsConsts from "../Shared/Constants";
import { v4 as uuidv4 } from 'uuid';
import { GetDateItalianFormat } from "../Shared/Utilities.js";
import QuotationsList from "./QuotationsList";
import QuotationTreatments from "./QuotationTreatments";
import QuotationCalendars from "./QuotationCalendars";
import FeedbackAlert from "../Shared/FeedbackAlert";

import { useHistory } from "react-router-dom";


export const Quotations = (props) => {
    const userCtx = useContext(UserContext);

    
    const quotationDB = {
        idQuotation: '',
        idDoctor: '',
        name: '',
        surname: '',
        phoneNumber: '',
        email: '',
        date: new Date().toJSON(),
        total: 0,
        discount: 0,
        status: 1,
        rows: [],
        preview:'',
    };

    const rowDB = {
        idQuotationRow: YpatientsConsts.emptyGuid,
        request: '',
        treatment: '',
        price: 0,
        quantity: 1,
        total: 0,
        status: 1,
        appointments: []
    };

    const appointmentDB = {
        idQuotationAppointment: YpatientsConsts.emptyGuid,
        idQuotationRow: YpatientsConsts.emptyGuid,
        appTime: new Date().toJSON(),
        status: 1
}

    const [quotations, setQuotations] = useState([]);
    const [quotation, setQuotation] = useState(quotationDB);
    const [localDoctor, setLocalDoctor] = useState({
        idDoctor: "",
        idCompany: "",
        title: "",
        name: "",
        surname: "",
        address: "",
        zipCode: "",
        city: "",
        province: "",
        country: "",
        number: "",
        fiscalCode: "",
        email: "",
        pec: "",
        phone: "",
        mobile: "",
        iban: "",
        swift: ""
    });
   
    const [feedback, setFeedback] = useState({
        severity: "success",
        text: "Success",
        open: false,
    });
    const [treatmentsType, setTreatmentsType] = useState([]);
    const [requestsType, setRequestsType] = useState([]);
    const [isSending, setIsSending] = useState(false);
    const [viewQuotationsList, setViewQuotationsList] = useState(false);
    const [viewQuotationDetail, setViewQuotationDetail] = useState(false);
    const [patient, setPatient] = useState({});
    const [loading, setLoading] = useState(true);
    const [calendars, setCalendars] = useState([]);
    const [treatmentsCalendar, setTreatmentsCalendar] = useState([]);
    const [viewEmailToSend, SetViewEmailToSend] = useState(false);
    const [emailToSend, SetEmailToSend] = useState('');

    const feedbackCallback = (severity, text, open) => {
        setFeedback({ severity: severity, text: text, open: open });
    };

   
    //REQUEST TYPE
    const handleAddRequest = async (_request) => {
        var _treatments = Array();
        _request.treatmentTypes.forEach(function (_treatment) {
            let treatment = buildTreatmentsType(_request.title, _treatment.title, _treatment.price, _treatment.minQuantity);
            _treatments.push(treatment);
        });
        addTreatmens(_treatments);
    }


    //TREATMENTS
    const handleChangeTreatment = (index, treatment) => {

        var _quotation = { ...quotation };
        var array = [..._quotation.rows];

        array[index] = treatment;
        _quotation.rows = array;
        setQuotation(_quotation);

    }
    
    const handleSaveTreatment = async (index) => {
        var array = [...quotation.rows];
        var treatment = array[index];

        if (quotation.idQuotation !== YpatientsConsts.emptyGuid) {
            let treatmentUpdated = await saveTreatment(treatment);
            if (treatmentUpdated !== undefined) {
                let _quotation = { ...quotation };
                _quotation.rows[index] = treatmentUpdated;
                _quotation.total = treatmentsTotal(_quotation) - _quotation.discount;
                if (_quotation.total != quotation.total) {
                    saveQuotation(_quotation);
                }
                else {
                    setQuotation(_quotation);
                }
            }
        }


    }

    const handleDeleteTreatment = async (index) => {
        var _quotation = { ...quotation };

        var array = [...quotation.rows];

        let arrayToDelete = array[index];

        let boolDelete = true;

        if (!arrayToDelete.idQuotationRow.startsWith("LOCAL_")) {
            for (const appointment of arrayToDelete.appointments) {
                if (!appointment.idQuotationAppointment.startsWith("LOCAL_")) {
                    await deleteAppointment(appointment.idQuotationAppointment);
                }
            }
            boolDelete = await deleteTreatment(arrayToDelete.idQuotationRow);
        }
        if (boolDelete) {
            array.splice(index, 1);
            _quotation.rows = array;
            setQuotation(_quotation);
        }
    }

    const handleAddTreatment = async (treatment) => {
        addTreatmens( [buildTreatmentsType('', treatment.title, treatment.price, treatment.minQuantity)]);
    }

    const buildTreatmentsType = (_route, _title, _price, _quantity) => {
        var newTreatment = {
            ...rowDB,
            ['idQuotation']: quotation.idQuotation,
            ['idQuotationRow']: 'LOCAL_' + uuidv4(),
            ['request']: _route,
            ['treatment']: _title,
            ['price']: _price,
            ['quantity']: _quantity || 1,
            ['total']: (_quantity || 1) * _price,
        }
        return newTreatment;
    }

    const addTreatmens = async (arrayTreatment)  => {
        var _quotation = { ...quotation };

        if (_quotation.rows.length === 1 && _quotation.rows[0].treatment === '' && _quotation.rows[0].price == 0) {
            _quotation.rows = [];
        }

        if (quotation.idQuotation !== YpatientsConsts.emptyGuid) {
            let newTreatments = [];
            for (const treatment of arrayTreatment) {
                let newTreatment = await saveTreatment(treatment);
                _quotation.rows.push( newTreatment );
            }
        }
        _quotation.total = treatmentsTotal(_quotation);

        saveQuotation(_quotation);  
    }

    //DISCOUNT
    const handleChangeDiscount = (value) => {
        let _quotation = { ...quotation, 'discount': value };
        setQuotation(_quotation);
    }

    const handleSaveDiscount = () => {
        if (quotation.idQuotation !== YpatientsConsts.emptyGuid) {
            let _quotation = { ...quotation };
            _quotation.total = treatmentsTotal(_quotation);
            saveQuotation(_quotation);
        }
    }


    //CALENDAR
    const loadTreatmentsCalendar = (_calendars) => {
        var array = [];

        quotation.rows.forEach(function (t) {
            if (t.treatment != undefined) {

                let _tc = array.find(tc => tc.name == t.treatment);
                if (_tc != undefined) {
                    _tc.quantity = _tc.quantity + t.quantity;
                }
                else {
                    array.push({ name: t.treatment, quantity: t.quantity, row: t });
                }
            }
        });

        _calendars.forEach(function (element) {
            let _tc = array.find(t => t.name === element.treatment);
            if (_tc != undefined) {
                _tc.quantity = _tc.quantity - 1;
            }
        });

        var newArray = [];
        array.forEach(function (element) {
            if (element.quantity > 0) {
                newArray.push({ title: element.name, row: element.row });
            }
        });

        newArray.sort((a, b) => a.title.localeCompare(b.title));

        setTreatmentsCalendar(newArray);
    }

    const loadCalendar = () => {
        var array = getCalendarsOfQuotation(quotation);

        setCalendars(array);
        return array;
    }

    const handleChangeDataPicker = (value, index) => {
        var array = [...calendars];
        var calendar = array[index];
        calendar.appointment.appTime = value;
        array.sort((a, b) => a.appointment.appTime.getTime() - b.appointment.appTime.getTime());
        setCalendars(array);

        var _quotation = { ...quotation };
        _quotation.rows.forEach(function (row) {
            if (row.idQuotationRow == calendar.appointment.idQuotationRow) {
                row.appointments.forEach(function (appointment) {
                    if (appointment.idQuotationAppointment == calendar.appointment.idQuotationAppointment) {
                        appointment.appTime = value;

                        if (!appointment.idQuotationRow.startsWith("LOCAL_")) {
                            saveAppointment(appointment);
                        }
                    }
                });
            }
        });
        setQuotation(_quotation);
    }

    const handleDeleteCalendar = async (index) => {

        let appointToRemove = calendars[index];

        let boolDelete = true;
        if (quotation.idQuotation !== YpatientsConsts.emptyGuid) {
            boolDelete = await deleteAppointment(appointToRemove.appointment.idQuotationAppointment);
        }

        if (boolDelete) {
            var _quotation = { ...quotation };
            _quotation.rows.forEach(function (row) {
                row.appointments.forEach(function (appointment, aindex) {
                    if (appointment.idQuotationAppointment == appointToRemove.appointment.idQuotationAppointment) {
                        row.appointments.splice(aindex, 1);
                    }
                });
            });
            setQuotation(_quotation);
        }
    }

    const handleAddCalendar = async (idQuotationRow) => {
        var newAppointment = {
            ...appointmentDB,
            ['idQuotationAppointment']: "LOCAL_" + uuidv4(),
            ['idQuotationRow']: idQuotationRow,
            ['appTime']: new Date()
        };

        var _quotation = { ...quotation };
        _quotation.rows.forEach(function (row) {
            if (row.idQuotationRow == idQuotationRow) {
                row.appointments = [...row.appointments, newAppointment];
            }
        });
       

        if (_quotation.idQuotation !== YpatientsConsts.emptyGuid) {
            await saveAppointment(newAppointment);
        }
        else {
            setQuotation(_quotation);
        }
    }

     const viewQuotation = (_quotation) => {
        setViewQuotationsList(false);
        setViewQuotationDetail(true);
        loadQuotation(_quotation);
        if(patient.idPatient == undefined){
            patient.idPatient = _quotation.idPatient;
        }
    }


    //QUOTATIONS LIST
    const handleEditQuotation = (quotation) => {
        viewQuotation(quotation);
    }
    const handleDeleteQuotation = async () => {
        if(await deleteQuotation(quotation.idQuotation)){
            setQuotation(quotationDB);
            setViewQuotationsList(true);
            setLoading(true);
            setViewQuotationDetail(false);
            getQuotations();
        }
    }

    const handleNewQuotation = () => {
        createNewQuotation();
    }
    const loadQuotation = async (value) => {
        let _quotation = { ...value };
        _quotation.rows = await getRows(_quotation.idQuotation);

        for (const row of _quotation.rows) {
            row.appointments = await getAppointments(row.idQuotationRow);
            for (const appointment of row.appointments) {
                appointment.appTime = new Date(appointment.appTime);
            }
        }

        setQuotation(_quotation);
    }

    const closeQuotation = async () => {
        setQuotation(quotationDB);
        setViewQuotationsList(true);
        setLoading(true);
        setViewQuotationDetail(false);
        getQuotations();
    }
    

    const handleAddRow = () => {
        var _quotation = { ...quotation };
        var _newRow = { ...rowDB };
        _newRow.idQuotation = _quotation.idQuotation;

        _quotation.rows = [..._quotation.rows, _newRow];
        saveQuotation(_quotation);


    }


    //PDF


    const handleCreatePdf = async () => {
        createQuotationPdf(userCtx.doctor, quotation,  calendars, patient);
    }
    const sendPdf = async () => {
        setIsSending(true);
        sendQuotationPdf(userCtx.doctor, quotation, calendars, patient, emailToSend, callbackPdf);
    }
    const callbackPdf = (sent) => {

        if (sent) {
            feedbackCallback("success", "Preventivo inviato con successo", true);
        }
        else {
            feedbackCallback("error", "Preventivo non inviato ", true);
        }
        setIsSending(false);
    }

    const treatmentsTotal = (_quotation) => {
        var returnValue = 0;
        for (var i = 0; i < _quotation.rows.length; i++) {
            let treat = quotation.rows[i];
            returnValue = returnValue + parseFloat(treat.total);
        }

        return returnValue - _quotation.discount;
    }

    const alertClosed = () => {
        setFeedback({ ...feedback, open: false });
    };

    useEffect(() => {
        getRequestsType();
        getTreatmentsType();
        return () => {
            setFeedback({ ...feedback, open: false });
        }
    }, []);

    useEffect(() => {
        if (quotation != undefined && quotation.idQuotation != '') {
            loadTreatmentsCalendar(loadCalendar());
            if (!viewQuotationDetail) {
                setViewQuotationDetail(true);
                setViewQuotationsList(false);
            }
        }
        else {
            setViewQuotationDetail(false);
        }
    }, [quotation]);

    useEffect(() => {
        if (patient!=undefined && patient.idPatient != undefined) {
            setQuotations([]);
            setCalendars([]);
            getQuotations();

            setViewQuotationsList(true);
            setViewQuotationDetail(false);
        }
    }, [patient]);

    useEffect(() => {
        const getDoctor = async () => {
            setLocalDoctor(userCtx.doctor);
            setQuotation({ ...quotation, ['idDoctor']: userCtx.doctor.idDoctor });
        };
        getDoctor();
    }, [userCtx]);

    useEffect(() => {
        if(props.quotation !== undefined && props.quotation.idQuotation !== undefined && props.quotation.idQuotation !== '' )
        viewQuotation(props.quotation);
    }, [props.quotation]);



    const createNewQuotation = async () => {
        let newQuotation = { ...quotationDB, idQuotation: YpatientsConsts.emptyGuid };
        if (patient.idPatient != undefined) {
            newQuotation.idPatient = patient.idPatient;
            newQuotation.idDoctor = userCtx.doctor.idDoctor;
            saveQuotation(newQuotation);
        }
    }

    const updatePatient = (selectedPatient) => {
        setPatient(selectedPatient);
    }

    //RICHIESTE AL CONTROLLER

    const getTreatmentsType = async () => {
        if (userCtx.doctor == undefined || userCtx.doctor.idDoctor == '') {
            setTreatmentsType([]);
        }

        const token = await authService.getAccessTokenIfValid();
        const response = await fetch(
            `/api/treatment/type/byDoctor/${userCtx.doctor.idDoctor}`,
            {
                headers: !token ? {} : { Authorization: `Bearer ${token}` },
            }
        );
        const data = await response.json();

        if (Array.isArray(data)) {
            setTreatmentsType(data);
        }
        else {
            setTreatmentsType([]);
        }
    };

    const getRequestsType = async () => {
        if (userCtx.doctor == undefined || userCtx.doctor.idDoctor == '') {
            setRequestsType([]);
        }

        const token = await authService.getAccessTokenIfValid();
        const response = await fetch(
            `/api/request/type/byDoctor/${userCtx.doctor.idDoctor}`,
            {
                headers: !token ? {} : { Authorization: `Bearer ${token}` },
            }
        );
        const data = await response.json();

        if (Array.isArray(data)) {
            setRequestsType(data);
        }
        else {
            setRequestsType([]);
        }
    };

    const getQuotations = async () => {
        if (patient !== undefined && patient.idPatient != '') {
            const token = await authService.getAccessTokenIfValid();
            const response = await fetch(
                `/api/quotation/${patient.idPatient}`,
                {
                    headers: !token ? {} : { Authorization: `Bearer ${token}` },
                }
            );
            const data = await response.json();

            if (Array.isArray(data)) {
    
                setQuotations(data);
            }
            else {
                setQuotations([]);
            }
            setLoading(false);
        }
    };

    const getRows = async (idQuotation) => {
        const token = await authService.getAccessTokenIfValid();
        const response = await fetch(
            '/api/quotation/rows/' + idQuotation,
            {
                headers: !token ? {} : { Authorization: `Bearer ${token}` },
            }
        );
        const data = await response.json();
        if (Array.isArray(data)) {
            return data;
        }
        else {
            return [];
        }
    };

    const getAppointments = async (idRow) => {
        const token = await authService.getAccessTokenIfValid();
        const response = await fetch(
            '/api/quotation/appointments/' + idRow,
            {
                headers: !token ? {} : { Authorization: `Bearer ${token}` },
            }
        );
        const data = await response.json();
        if (Array.isArray(data)) {
            return data;
        }
        else {
            return [];
        }
    };

    const saveQuotation = async (_quotation) => {
        let token = await authService.getAccessTokenIfValid();
        let path = "/api/quotation/save";

        const requestOptions = {
            method: "POST",
            headers: !token
                ? { "Content-Type": "application/json" }
                : {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "application/json",
                    Accept: "application/json",
                },
            body: JSON.stringify(_quotation),
        };

        fetch(path, requestOptions).then(async (response) => {

            if (!response.ok) {
                feedbackCallback("error", "Errore nel salvataggio", true);
                return; 
            } else {
                const _quotation = await response.json();
                if (_quotation) {

                    for (const row of _quotation.rows) {
                        for (const appointment of row.appointments) {
                            appointment.appTime = new Date(appointment.appTime);
                        }
                    }
                    setQuotation(_quotation);

                    feedbackCallback(
                        "success",
                        "Preventivo salvato con successo",
                        true
                    );
                }
            }
        });
    }

    const deleteQuotation = async (idQuotation) => {
        let token = await authService.getAccessTokenIfValid();
        let path = "/api/quotation/delete/" + idQuotation;

        const requestOptions = {
            method: "GET",
            headers: !token
                ? {}
                : {
                    Authorization: `Bearer ${token}`,
                },
        };

        let response = await fetch(path, requestOptions);

        if (!response.ok) {
            feedbackCallback("error", "Errore nell'eliminazione del preventivo", true);
            return false;
        } else {
            feedbackCallback(
                "success",
                "Preventivo cancellato con successo",
                true
            );
            return true;
        }
    }

    const saveTreatment = async (treatment) => {
        let token = await authService.getAccessTokenIfValid();
        let path = "/api/quotation/row/save";
        let newTreatment = { ...treatment };

        if (treatment.idQuotationRow.startsWith("LOCAL_")) {
            newTreatment = { ...treatment, "idQuotationRow": YpatientsConsts.emptyGuid };
        }
        
        const requestOptions = {
            method: "POST",
            headers: !token
                ? { "Content-Type": "application/json" }
                : {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "application/json",
                    Accept: "application/json",
                },
            body: JSON.stringify(newTreatment),
        };
        let response = await fetch(path, requestOptions); 

        if (!response.ok) {

            feedbackCallback("error", "Errore nel salvataggio", true);
            return; 
        } else {
            const data = await response.json();
            if (data) {
                feedbackCallback(
                    "success",
                    "Preventivo salvato con successo",
                    true
                );
                return data;
            }
        }
    }

    const deleteTreatment = async (idQuotationRow) => {
        let token = await authService.getAccessTokenIfValid();
        let path = "/api/quotation/row/delete/" + idQuotationRow;

        const requestOptions = {
            method: "GET",
            headers: !token
                ? {}
                : {
                    Authorization: `Bearer ${token}`,
                },
        };

        let response = await fetch(path, requestOptions);

        if (!response.ok) {
            feedbackCallback("error", "Errore nell'eliminazione del trattamento", true);
            return false; 
        } else {
            feedbackCallback(
                "success",
                "Trattamento cancellato con successo",
                true
            );
            return true;
        }
    }

    const saveAppointment = async (appointment) => {
        let token = await authService.getAccessTokenIfValid();
        let path = "/api/quotation/appointment/save";
        let isNew = false;
        let newAppointment = { ...appointment, "appTime": appointment.appTime.toJSON() };

        if (appointment.idQuotationAppointment.startsWith("LOCAL_")) {
            newAppointment.idQuotationAppointment =  YpatientsConsts.emptyGuid;
            isNew = true;
        }

        const requestOptions = {
            method: "POST",
            headers: !token
                ? { "Content-Type": "application/json" }
                : {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "application/json",
                    Accept: "application/json",
                },
            body: JSON.stringify(newAppointment),
        };
        fetch(path, requestOptions).then(async (response) => {
            if (!response.ok) {
                feedbackCallback("error", "Errore nel salvataggio", true);
                return; 
            } else {
                const data = await response.json();
                if (data) {
                    if (isNew) {
                        let _quotation = { ...quotation };
                        _quotation.rows.forEach(function (row) {
                            if (row.idQuotationRow == appointment.idQuotationRow) {
                                row.appointments.forEach(function (_appointment) {
                                    if (_appointment.idQuotationAppointment == appointment.idQuotationAppointment) {
                                        _appointment.idQuotationAppointment = data.idQuotationAppointment;
                                    }
                                });
                            }
                        });
                        setQuotation(_quotation);
                    }
                    feedbackCallback(
                        "success",
                        "Preventivo salvato con successo",
                        true
                    );
                }
            }
        });
    }

    const deleteAppointment = async (idAppointment) => {
        let token = await authService.getAccessTokenIfValid();
        let path = "/api/quotation/appointment/delete/" + idAppointment;

        const requestOptions = {
            method: "GET",
            headers: !token
                ? {  }
                : {
                    Authorization: `Bearer ${token}`,
                },
        };

        let response = await fetch(path, requestOptions);

        if (!response.ok) {
            feedbackCallback("error", "Errore nell'eliminazione dell'appuntamento", true);
            return false; 
        } else {
            feedbackCallback(
                "success",
                "Appuntamento cancellato con successo",
                true
            );
            return true;
        }
    }

    const handleSendPdf = () => {
        SetEmailToSend(patient.email);
        SetViewEmailToSend(true);
    };
    
    const handleEmailToSendClosed = () => {
        SetViewEmailToSend(false);
    };
    const handleChangeEmail = (event) => {
        const { id, value } = event.target;
        SetEmailToSend(value);
    }
    const closeEmailToSend = () => {
        SetViewEmailToSend(false);
        sendPdf();
    };

    const history = useHistory();

    const GoToPatient = (event) => {
        event.preventDefault();
        history.push({
            pathname: `/patient/${patient.idPatient}`,
        });
    };

    return (
        <>
            <Box p={2}>
                <Typography gutterBottom variant="subtitle3" component="div">
                    PREVENTIVO
                </Typography>
                <Grid container spacing={2}  >
                    <Grid item xs={12} md={3}>
                        <Card>
                            <CardContent>
                                <Box>
                                    <Typography>STUDIO MEDICO</Typography>
                                    <Typography>{localDoctor.title} {localDoctor.name} {localDoctor.surname}</Typography>
                                    <Typography>MEDICO CHIRURGO</Typography>
                                    <Typography>{localDoctor.address} {localDoctor.number}</Typography>
                                    <Typography>{localDoctor.zipCode} {localDoctor.city}</Typography>
                                    <Typography>Tel. {localDoctor.phone}</Typography>
                                    <Typography>Mobile {localDoctor.mobile}</Typography>
                                </Box>
                                <Divider sx={{ borderRadius: 1 }} />
                                <Box marginTop={2} data-testid="patient-select">
                                    <PatientSelect  onPatientSelected={(selectedPatient) => { updatePatient(selectedPatient) }} />
                                    <Divider />
                                    {patient != undefined && patient.idPatient != undefined && (
                                        <Box display="flex" flexDirection="column" justifyContent="center" width="100%" gap={1} mt={2}>
                                            <Button data-testid="editPatientButton" onClick={GoToPatient} variant="outlined" style={{ flexGrow: 1, padding:2 }}>
                                                SCHEDA PAZIENTE
                                            </Button>
                                            <Typography>Data di nascita: {GetDateItalianFormat(patient.birthDate)}</Typography>
                                            <Typography>Email: {patient.email}</Typography>
                                            <Typography>Tel: {patient.phone}</Typography>
                                            <Divider />
                                        </Box>
                                    )}
                                    { viewQuotationDetail && (
                                        <Box display="flex" flexDirection="column" justifyContent="center" width="100%" gap={1} mt={2}>
                                            <Button data-testid="btnEliminaPdf" variant="outlined" onClick={handleDeleteQuotation} style={{ flexGrow: 1 }}>ELIMINA PREVENTIVO</Button>
                                            <Button data-testid="btnCreatePdf" variant="outlined" onClick={handleCreatePdf} style={{ flexGrow: 1 }}>CREA PDF</Button>
                                            <Button id="btnSendPdf" data-testid="btnSendPdf" variant="outlined" onClick={handleSendPdf} style={{ flexGrow: 1 }}>{isSending ? "Invio in corso..." : "INVIA PDF"}</Button>
                                            <Button data-testid="btnCloseQuotation" variant="outlined" onClick={closeQuotation} style={{ flexGrow: 1 }}>CHIUDI PREVENTIVO</Button>
                                        </Box>
                                        )
                                    }
                                </Box>

                                <Dialog
                                    open={viewEmailToSend}
                                    onClose={handleEmailToSendClosed}
                                    aria-labelledby="form-dialog-title"
                                   
                                >
                                    <DialogTitle id="form-dialog-title">
                                        Convalida Email
                                    </DialogTitle>
                                    <DialogContent>
                                        <Grid container direction="column" alignItems="center" spacing={2}>
                                            <Grid item sx={{ width:'300px' }}>
                                                <TextField
                                                    id="sendEmail"
                                                    name="emailToSend"
                                                    size="small"
                                                    onChange={handleChangeEmail}
                                                    value={emailToSend}
                                                    fullWidth
                                                    data-testid="emailToSend"
                                                />
                                            </Grid>
                                            <Grid item>
                                                <Button data-testid="btnConfirmEmail" variant="outlined" onClick={closeEmailToSend} style={{ flexGrow: 1 }}>CONFERMA EMAIL</Button>
                                            </Grid>
                                        </Grid>
                                    </DialogContent>
                                </Dialog>

                            </CardContent>
                        </Card>

                    </Grid>
                    <Grid item container xs={12} md={9} spacing={2} >
   
                        {(viewQuotationsList &&
                            <Grid item xs={12} md={12} >
                                <Card p={2}>
                                    <CardHeader 
                                        title="Preventivi"
                                    ></CardHeader>
                                    <CardContent
                                         sx={{
                                                flexGrow: 2,
                                                display: "flex",
                                                height: "90%",
                                            }}
                                    >
                                        <Grid item container xs={12} md={12} spacing={1} >
                                                    
                                            <QuotationsList quotations={quotations}
                                                    feedbackCallback={feedbackCallback}
                                                    EditQuotation={handleEditQuotation}
                                                    NewQuotation={handleNewQuotation}
                                                    loading={loading} />
                                        </Grid>
                                    </CardContent>
                                </Card>
                            </Grid>
                        )}

                        {(viewQuotationDetail &&
                            <>
                            <Grid item xs={12} md={12} data-testid="quotation-detail" >
                                    <Card p={2}>
                                        <CardHeader title="Trattamenti"></CardHeader>
                                        <CardContent
                                            sx={{
                                                flexGrow: 2,
                                                display: "flex",
                                                height: "90%",
                                            }}
                                    >
                                        <QuotationTreatments
                                        quotationRows={quotation.rows}
                                        discount={quotation.discount}
                                        ChangeTreatment={handleChangeTreatment}
                                        SaveTreatment={handleSaveTreatment}
                                        DeleteTreatment={handleDeleteTreatment}
                                        ChangeDiscount={handleChangeDiscount}
                                        SaveDiscount={handleSaveDiscount}
                                        AddRow={handleAddRow}
                                        requestsType={requestsType}
                                        AddRequest={handleAddRequest}
                                        treatmentsType={treatmentsType}
                                        AddTreatment={handleAddTreatment}
                                     />
                                       
                                        </CardContent>
                                    </Card>
                                </Grid>
                                <Grid item xs={12} md={12}>
                                    <Card >
                                        <CardHeader title="Calendarizzazione"></CardHeader>
                                        <CardContent
                                            sx={{
                                                flexGrow: 2,
                                                display: "flex",
                                                height: "90%",
                                            }}
                                        >
                                        <QuotationCalendars
                                            calendars={calendars}
                                            treatmentsCalendar={treatmentsCalendar}
                                            AddCalendar={handleAddCalendar}
                                            DeleteCalendar={handleDeleteCalendar}
                                            ChangeCalendar={handleChangeDataPicker}
                                        />
                                        
                                        </CardContent>
                                    </Card>
                                </Grid>
                            </>
                        )}
                    </Grid>
                    <FeedbackAlert
                        severity={feedback.severity}
                        text={feedback.text}
                        open={feedback.open}
                        closedCallback={alertClosed}
                    />
                </Grid>
            </Box>


            {(!userCtx.isValidLicense || !(userCtx.roles.includes("Doctor") || userCtx.roles.includes("Secretary"))) &&
                <Grid container spacing={2} p={2} className="vh-100">
                    <ExpiredLicense />
                </Grid>
            }
        </>
    );
};

export default Quotations;
