import authService from "../../api-authorization/AuthorizeService";
import { createPatientPdf, sendPatientPdf, createPatientsHtmlInvoice, buildDownloadMultiplePdf } from "./InvoiceUtil.js";

export const downloadPatientInvoice = async (idPatientInvoice, holder, feedbackCallback) => {
    let invoice, rows,  _patient;
    invoice = await getPatientInvoiceData(idPatientInvoice, feedbackCallback);
    if (invoice) {
        rows = await getPatientInvoiceRowsData(idPatientInvoice, feedbackCallback);
       
        _patient = await getPatientData(invoice);
        createPatientPdf(invoice, rows, holder, _patient);
        return true;
    }
    else {
        return false;
    }
}

const getPatientInvoiceData = async (idPatientInvoice, feedbackCallback) => {
    let data;
    const token = await authService.getAccessTokenIfValid();
    const getUrl = "/api/patientinvoices/invoice/" + idPatientInvoice;
    const response = await fetch(getUrl, {
        headers: !token ? {} : { Authorization: `Bearer ${token}` }
    });

    if (response.ok) {
        data = await response.json();
    } else {
        const error = await response.text();
        feedbackCallback("error", error, true);
    }
    return data;
}

const getPatientInvoiceRowsData = async (idPatientInvoice, feedbackCallback) => {
    let data;
    const token = await authService.getAccessTokenIfValid();
    const getUrl = "/api/patientinvoices/invoice/rows/" + idPatientInvoice;
    const response = await fetch(getUrl, {
        headers: !token ? {} : { Authorization: `Bearer ${token}` }
    });

    if (response.ok) {
        data = await response.json();
    } else {
        const error = await response.text();
        feedbackCallback("error", error, true);
    }
    return data;
}

const getPatientData = (invoice) => {
    return {
        idPatient: invoice.idPatient,
        name: invoice.patientName,
        surname: invoice.patientSurname,
        fiscalCode: invoice.patientFiscalCode,
        address: invoice.patientAddress,
        city: invoice.patientCity,
        zipCode: invoice.patientZipCode,
        email: invoice.patientEmail,
    }
}

export const sendPatientInvoice = async (idPatientInvoice, holder, feedbackCallback, sendInvoiceCallBack) => {
    let invoice, rows,  _patient;
    invoice = await getPatientInvoiceData(idPatientInvoice, feedbackCallback);
    if (invoice) {
        rows = await getPatientInvoiceRowsData(idPatientInvoice, feedbackCallback);
        
        _patient = getPatientData(invoice);
        if (!_patient || !_patient.email) {
            feedbackCallback("error", "Email non disponibile", true);
            return false;
        }
        else {
            await sendPatientPdf(invoice, rows, holder, _patient, sendInvoiceCallBack);
            return true;
        }

    }
    else {
        return false;
    }
}


export const downloadPdfInvoicesZip = async (idPatientInvoices, holder) => {
    let htmls = [];
    let fileNames = [];
    for (let i = 0; i < idPatientInvoices.length; i++) {
        let invoice, rows;
        invoice = await getPatientInvoiceData(idPatientInvoices[i]);
        if (invoice) {
            rows = await getPatientInvoiceRowsData(idPatientInvoices[i]);
          
            let patient = getPatientData(invoice);

            const data = createPatientsHtmlInvoice(invoice, rows, holder, patient);
            htmls.push(data.html);
            fileNames.push(data.fileName);
        } else {
            return null;
        }
    }
    buildDownloadMultiplePdf(htmls, fileNames);
};

