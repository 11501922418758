import React from 'react'
import { useDropzone } from 'react-dropzone'
import authService from '../../api-authorization/AuthorizeService';
import "./PersonalPicture.css";

export const FileUpload = (props) => {
    const IdPatient = props.idPatient;

    const {
        acceptedFiles,
        fileRejections,
        getRootProps,
        getInputProps
    } = useDropzone({
        accept: {
            'image/jpg': [],
            'image/jpeg': [],
            'image/png': []
        },
        onDrop: (acceptedFiles, fileRejections) => {
            storeFileInRepository(acceptedFiles);
            rejectFile(fileRejections);
        },
        maxFiles: 1
    });

    const storeFileInRepository = (images) => {
        images.map(img => {
            uploadFile(img);
        })
    };

    const rejectFile = (files) => {
        files.map(({ file, errors }) => {
            let errorMessage = file.path + ": file non valido.";
            errors.map(e => (errorMessage = errorMessage + " \ " + e.message));
            props.feedbackCallback("error", errorMessage, true);
        }
        )
    };

    const uploadFile = (file) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);

        reader.onloadend = async () => {
            const base64data = reader.result;
            const postUrl = '/api/filepatient/UploadAvatar/' + IdPatient;
            const token = await  authService.getAccessTokenIfValid();
            const response = await fetch(postUrl, {
                method: 'POST',
                body: base64data,
                headers: !token ? {} : {
                    Authorization: `Bearer ${token}` }
            });
            if (response.ok) {
                const data = await response.json();
                if (data) {
                    props.returnClick();
                    props.feedbackCallback("success", "Avatar aggiornato!", true);
                }
            } else {
                const error = await response.text();
                props.feedbackCallback("error", error, true);
            }
        };
    };



    return (
        <section className="fup-container">
            <div className="fup-drag-area">
                <div {...getRootProps({ className: 'dropzone' })}>
                    <input {...getInputProps()} />
                    <div><i className="fas fa-cloud-upload-alt"></i></div>
                    <p className='px-3'>Trascina qui il tuo file o fai click per caricarne uno</p>
                </div>
            </div>
        </section>
    );
}