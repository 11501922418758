import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import AssessmentIcon from '@mui/icons-material/Assessment';
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Button from "@mui/material/Button";


export const ReportsTile = () => {
  const history = useHistory();

  const GoToReports = (event) => {
    event.preventDefault();
    history.push({
      pathname: "/reports/invoices",
    });
  };

  return (
    <Card>
      <CardHeader
          title="Reportistica"
          titleTypographyProps={{
            variant: "body1",
          }}
        />
      <CardContent>
        <Button fullWidth data-testid="reportsButton" onClick={GoToReports}>
          <AssessmentIcon fontSize="large" sx={{ fontSize: "80px" }} />
        </Button>
      </CardContent>
    </Card>
  );
};

export default ReportsTile;