import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import DeleteIcon from "@mui/icons-material/Delete";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TextField from "@mui/material/TextField";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { GetAmountEuroFormat2 } from "../Shared/Utilities.js";
import SearchTreatmentType from "./SearchTreatmentType";
import SearchRequestType from "./SearchRequestType";

export const QuotationTreatments = (props) => {
    const columns = [
        { id: "request", label: "Percoso", numeric: false },
        { id: "treatment", label: "Trattamento", numeric: false },
        { id: "price", label: "Prezzo", numeric: true },
        { id: "quantity", label: "Quantità", numeric: true },
        { id: "total", label: "Totale", numeric: true },
        { id: "actions", label: "Azioni", numeric: false },
    ];
    const [selected, setSelected] = useState(null);
    const [quotationRows, setQuotationRows] = useState([]);
    const [discount, setDiscount] = useState(0);

    const treatmentTotal = (treatment) => {
        return treatment.quantity * treatment.price;
    }
    const treatmentsTotal = () => {
        var returnValue = 0;
        for (var i = 0; i < quotationRows.length; i++) {
            let treat = quotationRows[i];
            returnValue = returnValue + parseFloat(treat.total);
        }

        return returnValue - discount;
    }
    const handleChange = (event) => {
        const { id, value, name, inputMode } = event.target;
        if (id) {
            var array = [...props.quotationRows];
            var treatment = array[id];
            if (inputMode === 'numeric') {
                if (value === '') {
                    treatment[name] = 0;
                }
                else {
                    treatment[name] = parseFloat(value.replace(',', '.').replace('€', ''));
                }
            }
            else {
                treatment[name] = value;
            }
            treatment.total = treatmentTotal(treatment);
            props.ChangeTreatment(id, treatment);

        }
    };

    const handleBlur = async (index) => {
        props.SaveTreatment(index);
    };

    const handleChangeDiscount = (event) => {
        const { id, value, name, inputMode } = event.target;
        const _value = parseFloat(value.replace(',', '.').replace('€', ''));
        setDiscount(_value);
        props.ChangeDiscount(_value);
    };
    const handleBlurDiscount = async () => {

        props.SaveDiscount();
    }

    const handleDelete = async (index) => {
        props.DeleteTreatment(index);
    }
    const handleAddRow = async () => {
        props.AddRow();
    }
    useEffect(() => {
        setQuotationRows(props.quotationRows);
        setDiscount(props.discount);
    }, [props.quotationRows, props.discount]);

    const renderTreatments = () => {
        return (

            <TableContainer data-testid="quotation-treatments">
                <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
                    <TableHead>
                        <TableRow key="head">
                            {columns.map((headCell) => (
                                <TableCell
                                    key={headCell.id}
                                    align="center"
                                    padding={headCell.disablePadding ? "none" : "normal"}
                                >
                                    {headCell.label}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>

                    <TableBody>
                        {quotationRows.map((row, index) =>

                            <TableRow
                                hover
                                key={index}
                                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                                selected={selected === index}
                                onClick={() => setSelected(index)}
                            >
                                <TableCell align="center">
                                    <TextField
                                        id={index + ''}
                                        name="request"
                                        size="small"
                                        onChange={handleChange}
                                        onBlur={() => { handleBlur(index) }}
                                        value={row.request}
                                        variant="filled"
                                        hiddenLabel
                                        InputProps={{
                                            disableUnderline: true,
                                            style: { fontSize: 13 },
                                        }}
                                        fullWidth  
                                    />
                                </TableCell>
                                <TableCell align="center">
                                    <TextField
                                        id={index + ''}
                                        name="treatment"
                                        size="small"
                                        onChange={handleChange}
                                        onBlur={() => { handleBlur(index) }}
                                        value={row.treatment}
                                        variant="filled"
                                        hiddenLabel
                                        InputProps={{
                                            disableUnderline: true,
                                            style: { fontSize: 13 },
                                        }}
                                        fullWidth
                                        data-testid="row-treatment"
                                    />
                                </TableCell>
                                <TableCell align="right" width={150}>
                                    <TextField
                                        id={index + ''}
                                        name="price"
                                        size="small"
                                        value={row.price == 0 ? '' : GetAmountEuroFormat2(row.price)}
                                        inputProps={{ inputMode: 'numeric', pattern: '^([1-9]\d{0,2}(.\d{3})*|0)(,\d{1,2})?€?$', style: { textAlign: 'right' } }}
                                        onChange={handleChange}
                                        onBlur={() => { handleBlur(index) }}
                                        variant="filled"
                                        hiddenLabel
                                        InputProps={{
                                            disableUnderline: true,
                                            style: { fontSize: 13 },
                                        }}
                                    /></TableCell>
                                <TableCell align="center" width={30}>
                                    <TextField
                                        id={index + ''}
                                        name="quantity"
                                        size="small"
                                        value={row.quantity}
                                        inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                                        onChange={handleChange}
                                        onBlur={() => { handleBlur(index) }}
                                        variant="filled"
                                        hiddenLabel
                                        InputProps={{
                                            disableUnderline: true,
                                            style: { fontSize: 13, textAlign: 'right' },
                                        }}
                                    />
                                </TableCell>
                                <TableCell align="right" width={150}>
                                    <TextField
                                        inputProps={{
                                            disableUnderline: true,
                                            min: 0,
                                            style: { fontSize: 13, textAlign: 'right' }
                                        }}
                                        disabled={true}
                                        size="small"
                                        value={GetAmountEuroFormat2(row.total)} />
                                </TableCell>
                                <TableCell align="center" width={50}>
                                    <Tooltip title="Elimina">
                                        <IconButton
                                            onClick={(e) => handleDelete(index)}
                                            data-testid={index + "-deleteButton"}
                                        >
                                            <DeleteIcon />
                                        </IconButton>
                                    </Tooltip>
                                </TableCell>
                            </TableRow>
                        )}
                        <TableRow>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell>
                                <Typography>Sconto: </Typography>
                                <TextField
                                    data-testid="quotation-discount"
                                    id="quotation"
                                    name="discount"
                                    size="small"
                                    value={GetAmountEuroFormat2(discount)}
                                    onChange={handleChangeDiscount}
                                    onBlur={handleBlurDiscount}
                                    inputProps={{
                                        disableUnderline: true,
                                        min: 0,
                                        style: { textAlign: 'right', fontSize: 13, width: '100%' }
                                    }}
                                    variant="filled"
                                    hiddenLabel
                                />
                            </TableCell>
                            <TableCell colSpan={2}>
                                <Typography style={{ fontSize: '14px' }}><b>Totale preventivo: </b></Typography>
                                <TextField
                                    inputProps={{ min: 0, style: { textAlign: 'right' } }}
                                    disabled={true}
                                    size="small"
                                    value={GetAmountEuroFormat2(treatmentsTotal())}
                                />
                            </TableCell>
                            <TableCell></TableCell>
                        </TableRow>


                    </TableBody>
                </Table>
            </TableContainer>

        );
    };

    return(
        <Grid container spacing={1}>
            <Grid item xs={12} md={9}>
                {renderTreatments()}
            </Grid>
            <Grid container item xs={12} md={3} spacing={2} sx={{ "place-content": "flex-start" }} justifyContent="flex-start" alignItems="flex-start" >
                <Grid item p={1} xs={12} data-testid="add-row">
                    <Button data-testid="btnAddRow" variant="outlined" onClick={handleAddRow} style={{ flexGrow: 1, width: '100%' }}>AGGIUNGI RIGA</Button>
                </Grid>
                <Grid item p={1} xs={12} data-testid="request-select">
                    <SearchRequestType
                        requestsType={props.requestsType}
                        AddRequest={props.AddRequest}
                    ></SearchRequestType>
                </Grid>
                <Grid item p={1} xs={12} data-testid="treatment-select">
                    <SearchTreatmentType
                        treatmentsType={props.treatmentsType}
                        AddTreatment={props.AddTreatment}
                    ></SearchTreatmentType>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default QuotationTreatments;