import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";
import { sendEmail } from "../../Shared/emailService";

const pdf_settings = { orientation: 'p', unit: 'px', format: 'a4', x: 10, y: 10, width: 360, scale: 0.7 };

const getHtmlTemplate = () => {

  let template_file;

  if (!process.env.NODE_ENV || process.env.NODE_ENV === 'test') {
      template_file = require("./prescriptionTemplate.html");
  }
  else {
      //disabling eslint
      // eslint-disable-next-line import/no-webpack-loader-syntax
      template_file = require("raw-loader!./prescriptionTemplate.html");
  }

  return template_file.default;
}

const addPatientData = (template, patient) => {
  if (template && patient) {
      template = template.replaceAll('[PatientName]', patient.name + ' ' + patient.surname);
      template = template.replaceAll('[PatientFiscalCode]', patient.fiscalCode);
  }
  return template;
}

const addDate = (template, date) => {
  if (template && date) {
      template = template.replaceAll('[Date]', date);
  }
  return template;
}

const addDoctor = (template, doctor) => {
  if (template && doctor) {
      template = template.replaceAll('[DoctorTitle]', doctor.title);
      template = template.replaceAll('[DoctorName]', doctor.name + ' ' + doctor.surname);
      template = template.replaceAll('[DoctorAddress]', doctor.address);
      template = template.replaceAll('[DoctorZipCode]', doctor.zipCode);
      template = template.replaceAll('[DoctorCity]', doctor.city);
      template = template.replaceAll('[DoctorEmail]', doctor.email);
      template = template.replaceAll('[DoctorPhoneNumber]', doctor.phone);
  }
  return template;
}

const addPrescription = (template, prescription) => {
  if (template && prescription) {
      template = template.replaceAll('[PrescriptionText]', prescription.description);
  }
  return template;
}

const saveCallback = (doc, patient) => {
  doc.output('save', `Prescrizione_${patient.fiscalCode}.pdf`);
}

const emailCallback = async (doc, patient, resultCallback) => {
      let filename = `Prescrizione_${patient.fiscalCode}.pdf`;
      let attachment = doc.output('datauristring');
      attachment = attachment.replace('generated.pdf', filename);
      const attachments = [attachment];
      const emailResult = await sendEmail(
        "noreply@mosaycon.com",
        patient.email,
        "La sua prescrizione",
        "In allegato la sua prescrizione. Grazie. Si prega di non rispondere a questa email.",
        false,
        attachments
      );
      resultCallback(emailResult);
}

const buildPdf = (html, htmlCallback) => {

  var pdf = new jsPDF(pdf_settings.orientation, pdf_settings.unit, pdf_settings.format);

  window.html2canvas = html2canvas;

  pdf.html(
      html, {
      callback: htmlCallback,
      x: pdf_settings.x,
      y: pdf_settings.y,
      width: pdf_settings.width,
      html2canvas: { scale: pdf_settings.scale }
  });

}

const composeTemplate = (prescription, date, patient, doctor) => {
  var _template_html = getHtmlTemplate();

  _template_html = addPatientData(_template_html, patient);
  _template_html = addDate(_template_html, date);
  _template_html = addDoctor(_template_html, doctor);
  _template_html = addPrescription(_template_html, prescription);

  return _template_html;
}


export const downloadPrescriptionPdf = (prescription, date, patient, doctor) => {
  buildPdf(composeTemplate(prescription, date, patient, doctor),  (doc) => saveCallback(doc, patient) );
}

export const emailPrescriptionPdf = (prescription, date, patient, doctor, resultCallback) => {
  let result = false;
  buildPdf(composeTemplate(prescription, date, patient, doctor),  (doc) => emailCallback(doc, patient, resultCallback) );


}
