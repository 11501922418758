import React, { useState, useContext } from "react";


import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import CardContent from "@mui/material/CardContent";
import InvoicesList from "../Shared/Invoice/InvoicesList";
import Invoice from "../Shared/Invoice/Invoice";
import UserContext from "../UserContext";
import * as YpatientsConsts from "../Shared/Constants";
import Loading from "../Shared/Loading";

export const InvoicesReport = (props) => {
    const [idPatientInvoice, setIdPatientInvoice] = useState(YpatientsConsts.emptyGuid);
    const [pageIndex, setPageIndex] = useState(0);
    const userCtx = useContext(UserContext);

    const OpenInvoice = (idPatientInvoice, pageIndex) => {
        setIdPatientInvoice(idPatientInvoice);
        setPageIndex(pageIndex);
    };

    const GoToList = (pageIndex) => {
        setIdPatientInvoice(YpatientsConsts.emptyGuid);
        setPageIndex(pageIndex);
    };


    return (
        <Card
            sx={{
                boxShadow: 0,
            }}
        >
            <CardContent>
                <Typography gutterBottom variant="subtitle3" component="div">
                    Fatture pazienti
                </Typography>
                {idPatientInvoice !== YpatientsConsts.emptyGuid && (
                    <Invoice
                        idPatientInvoice={idPatientInvoice}
                        doctor={userCtx.doctor}
                        feedbackCallback={props.feedbackCallback}
                        goToListCallback={GoToList}
                        pageIndex={pageIndex}
                    />
                )}
                {userCtx.doctor.idDoctor !== "" ? (
                    idPatientInvoice === YpatientsConsts.emptyGuid && (
                        <InvoicesList
                            idCompany={props.idCompany}
                            doctor={userCtx.doctor}
                            feedbackCallback={props.feedbackCallback}
                            openInvoiceCallback={OpenInvoice}
                            pageIndex={pageIndex}
                            scope={"doctor"}
                        />
                    )
                ) : (
                    <Loading />
                )}
            </CardContent>
        </Card>
    );
};

export default InvoicesReport;
