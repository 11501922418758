import moment from "moment";
import React, { useState, useEffect } from "react";
import IconButton from "@mui/material/IconButton";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardActionArea from "@mui/material/CardActionArea";
import CardContent from "@mui/material/CardContent";
import ClearIcon from "@mui/icons-material/Clear";
import EditIcon from "@mui/icons-material/Edit";
import Popper from "@mui/material/Popper";
import "./ReactAgendaItem.css";
import Stack from "@mui/material/Stack";
import Link from '@mui/material/Link';
import {Link as RouterLink} from 'react-router-dom';

export const ReactAgendaItem = (props) =>  {

  const [wrapper, setWrapper] = useState({
          width: "150px",
          marginLeft: "0px",
          zIndex: 5,
          borderLeft: null,
        });
  
  const [open, setOpen] = useState(props.selected);
  const [anchorEl, setAnchorEl] = useState(null);
  let height = ((props.cellHeight * (props.item.endTime.getTime() - props.item.startTime.getTime()) / 1800000)) + "px";


  const updateDimensions = () => {
    var elem = document.getElementById(props.parent);
    if (elem) {
      var nwidh = elem.offsetWidth / 1.4;
      var nmrgl =
        props.padder > 0 ? nwidh / 5 + props.padder * 8 : nwidh / 5;

      return setWrapper({
          width: nwidh + "px",
          marginLeft: nmrgl + "px",
          marginTop: props.padder * 8 + "px",
          zIndex: 5,
      });
    }
  }

  useEffect(() => {
    setTimeout(
      function () {
        updateDimensions();
      },
      50
    );
  }, []);

  useEffect(() => {
    window.addEventListener("resize", updateDimensions);

    updateDimensions();
    return () => {
      window.removeEventListener("resize", updateDimensions);
    }
  }, []);


  const lowerZindex = (e) => {
    let sty = wrapper;

    if (sty.zIndex === 8) {
      var newWrapper = Object.assign({}, sty, { zIndex: 5 });
      return setWrapper(newWrapper);
    }
  }
  const raiseZindex = (e) => {
    let sty = wrapper;

    if (sty.zIndex === 5) {
      var newWrapper = Object.assign({}, sty, { zIndex: 8 });
      return setWrapper(newWrapper);
    }
  }

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setOpen(true);
    if(props.selected) {
      props.setSelected(null);
    } else {
      props.setSelected(props.item.idAppointment);
    }
  }

  const handleEdit = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
    setOpen(false);
    props.setSelected(null);
    props.edit(props.item);
  }

  const handleRemove = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
    setOpen(false);
    props.setSelected(null);
    props.remove(props.item);
  }

  let duratH = moment
      .duration(props.item.duration._milliseconds, "Milliseconds")
      .humanize();
  let duratL = moment(props.item.startTime).format("HH:mm");
  let duratE = moment(props.item.endTime).format("HH:mm");
  let title = props.item.description;
  let person = (props.item.name? props.item.name: "") + " " + (props.item.surname? props.item.surname: "");
  let linkToPatientFile = "/patient/" + props.item.idPatient;

    return (
      <>
        <Popper
          id="popper"
          open={open && props.selected}
          anchorEl={anchorEl}
          placement="top-end"
        >
          <Box
            sx={{
              border: 0.5,
              borderRadius: 1,
              borderColor: "grey.500",
              width: "100%",
              bgcolor: "background.paper",
              display: "flex",
              flexDirection: "row",
            }}
          >
            <Stack direction="column" spacing={1} className="agenda-item-duration-text-container">
              <p className="text-center agenda-item-description-text w-100 d-inline-block ">
                {duratL}  {duratE}
              </p>
            </Stack>
            {props.edit ? (
              <IconButton onClick={handleEdit}>
                <EditIcon fontSize="small" />
              </IconButton>
            ) : (
              ""
            )}
            {props.remove ? (
              <IconButton onClick={handleRemove}>
                <ClearIcon fontSize="small" />
              </IconButton>
            ) : (
              ""
            )}
          </Box>
        </Popper>
        <Card className="agenda-cell-item dark-turquoise"
        sx={{
          backgroundColor: props.backgroundColor, 
          height: height,
          width: (100 / props.itemsInCell) - 5 + "%" ,
          marginLeft: (props.itemIndex*(100 / props.itemsInCell)) + "%"}}>
          <CardActionArea onClick={handleClick} sx={{height: "100%"}}>
            <CardContent sx={{padding:"4px", height: "100%"}}>
              <p className="agenda-item-description-text">{title} <br/>
              <Link className="agenda-item-description-text" component={RouterLink} to={linkToPatientFile} color="inherit"> {person}</Link>
              </p>
            </CardContent>
          </CardActionArea>
        </Card>
      </>
    );
  }



export default ReactAgendaItem;