import React, { useEffect, useState } from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import authService from "../api-authorization/AuthorizeService";
import Pagination from "@mui/material/Pagination";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

import { IconButton } from "@mui/material";
import { Tooltip } from '@mui/material';
import ShoppingCartCheckoutIcon from "@mui/icons-material/ShoppingCartCheckout";
import Typography from "@mui/material/Typography";

export const SubscriptionsList = (props) => {
    const pageSize = 5;
    const [page, setPage] = useState(1);
    const [subscriptions, setSubscriptions] = useState([
        {
            idSubscription: "",
            idCompany: "",
            type: "",
            startDate: "",
            endDate: "",
            expired: "",
            status: "",
        },
    ]);

    //cleanup state on unmount
    useEffect(() => {
        return () => {
            setSubscriptions([]);
            setPage(1);
        };
    }, []);

    const columns = [
        { id: "type", label: "Tipo Licenza", numeric: false },
        { id: "startDate", label: "Data Attivazione", numeric: false },
        { id: "endDate", label: "Data Scadenza", numeric: false },
        { id: "state", label: "Stato", numeric: false },
        { id: "action", label: "Azioni", numeric: false },
    ];

    const handleChange = (event, value) => {
        setPage(value);
    };

    const handleRenewalLicense = (event) => {
        if (event.currentTarget) {
            props.renewalCallBack(event.currentTarget.id);
        } else {
            props.feedbackCallback("error", "Sottoscrizione/Licenza non disponibile", true);
        }
    };

    useEffect(() => {
        const getSubscriptions = async () => {
            const token = await authService.getAccessTokenIfValid();
            const response = await fetch("/api/subscription/" + props.idCompany, {
                headers: !token ? {} : { Authorization: `Bearer ${token}` },
            });
            const data = await response.json();
            if (data) {
                setSubscriptions(data);
            }
        };
        if (props.idCompany !== undefined) {
            getSubscriptions();
        }
    }, [props.idCompany, props.reload]);

    const getStatusString = (subscription) => {
        switch (subscription.status) {
            case 0:
                return "IN USO";
            case 1:
                return "DISPONIBILE";
            case -1:
                return "SCADUTA";
            default:
                return "";
        }
    };

    const getPrice = (subscription) => {
        let price = "";
        if (subscription.expired) {
            switch (subscription.type) {
                case "DOC":
                    price = " € " + props.priceData.doctor;
                    break;
                case "SEC":
                    price = " € " + props.priceData.secretary;
                    break;
                default:
                    price = "";
                    break;
            }
        }
        return price;
    };

    const getTypeString = (subscription) => {
        switch (subscription.type) {
            case "DOC":
                return "Licenza Medico";
            case "SEC":
                return "Licenza Segreteria";
            case "TRI":
                return "Licenza Trial";
            default:
                return "Errore Licenza";
        }
    };

    const renderTable = () => {
        let subscriptionsToShow = [];
        if (subscriptions.length > pageSize) {
            //select subscriptions to show
            const firstPageIndex = (page - 1) * pageSize;
            const lastPageIndex = firstPageIndex + pageSize;
            subscriptionsToShow = subscriptions.slice(firstPageIndex, lastPageIndex);
        } else {
            subscriptionsToShow = [...subscriptions];
        }
        return (
            <TableContainer>
                <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
                    <TableHead>
                        <TableRow>
                            {columns.map((headCell) => (
                                <TableCell
                                    key={headCell.id}
                                    align="center"
                                    padding={headCell.disablePadding ? "none" : "normal"}
                                >
                                    {headCell.label}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>

                    <TableBody>
                        {subscriptionsToShow.map((row) => (
                            <TableRow
                                key={row.idSubscription}
                                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                            >
                                <TableCell align="center">{getTypeString(row)}</TableCell>
                                <TableCell align="center">
                                    {new Date(row.startDate).toLocaleDateString("it-IT")}
                                </TableCell>
                                <TableCell align="center">
                                    {new Date(row.endDate).toLocaleDateString("it-IT")}
                                </TableCell>
                                <TableCell align="center">{getStatusString(row)}</TableCell>
                                <TableCell align="center">
                                    <Tooltip title="Rinnova la licenza">
                                        <span>
                                            <IconButton edge="end" aria-label="open" disabled={!(row.expired && row.type !== 'TRI') || !props.termAcceptCallBack()} onClick={handleRenewalLicense} id={row.idSubscription} data-testid={`buttonRenewal-${row.idSubscription}`}>
                                                <ShoppingCartCheckoutIcon />
                                            </IconButton>
                                        </span>
                                    </Tooltip>
                                    <Typography>
                                        {getPrice(row)}
                                    </Typography>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        );
    };

    return (
        <Card
            sx={{
                boxShadow: 0,
            }}
        >
            <CardContent>
                <Typography gutterBottom variant="subtitle3" component="div">
                    Stato licenze
                </Typography>
                {renderTable()}
                {subscriptions.length > pageSize && (
                    <Pagination
                        count={Math.floor(subscriptions.length / pageSize) + 1}
                        defaultPage={1}
                        onChange={handleChange}
                    />
                )}
            </CardContent>
        </Card>
    );
};

export default SubscriptionsList;
