import React, { useState } from "react";
import authService from "../../api-authorization/AuthorizeService";
import Loading from '../../Shared/Loading';
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";



export const QuestionsImport = (props) => {

  const [loading , setLoading] = useState(false);

  const importTemplate = async () => {
    setLoading(true);
    let isMounted = true;
    const token = await authService.getAccessTokenIfValid();
    fetch("/api/question/import/" + "m_est", { //TODO: change to real template for the current specialization
        headers: !token ? {} : { Authorization: "Bearer " + token },
      })
        .then(async (response) => {
          const data = await response.json();
          if (isMounted) {
            setLoading(false);
          }
          // check for error response
          if (!response.ok) {
            // get error message from body or default to response statusText
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
          } else {
            if (data) {
              if (isMounted) {
                props.reloadQuestions();
              }
            }
            else{
                console.error("There was an error in importing template");
            }
          }
        })
        .catch((error) => {
          if (error) {
            console.error("There was an error!", error);
            props.feedbackCallback("error", "Unable to import template", true);
          }
        });
  }

  const ignoreTemplate = () => {
    props.ignoreTemplate(true);
}

  if (loading) {
    return(
        <Loading/>
    );
  }
  return (
    <Paper>
      <h4>La scheda paziente non contiene domande.</h4>
      <p>
        Si vuole importare il questionario dal templete o creare la scheda
        autonomamente?
      </p>
      <Grid container spacing={4} justifyContent="center" alignItems="center">
        <Grid item xs={5}>
          <p>
            L'operazione di import è possibile solo quando non sono ancora stati
            registrati record paziente. <br />
            Una volta importato il template le domande potranno essere
            modificate ed eliminate. Sarà inoltre possibile aggiungere nuove
            domande.
          </p>
        </Grid>

        <Grid item xs={5}>
          <p>
            La scheda paziente può essere creata da zero inserendo manualmente i
            questionari. I campi dei questionari potranno essere definiti e
            modificati, ordinandoli a piacere.
          </p>
        </Grid>
        <Grid item xs={5}>
          <Button onClick={importTemplate}>Voglio partire dal template esistente</Button>
        </Grid>
        <Grid item xs={5}>
          <Button onClick={ignoreTemplate}>Voglio creare la mia scheda da zero</Button>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default QuestionsImport;
