import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import DeleteIcon from "@mui/icons-material/Delete";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TextField from "@mui/material/TextField";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import SearchTreatmentType from "./SearchTreatmentType";

export const QuotationCalendars = (props) => {

    const [calendars, setCalendars] = useState([]);
    const [treatmentsCalendar, setTreatmentsCalendar] = useState([]);

    const handleAddCalendar = async (value) => {
        props.AddCalendar(value.row.idQuotationRow);
    }
    const handleDeleteCalendar = async (index) => {
        props.DeleteCalendar(index);
    }
    const handleChangeDataPicker = async (value, index) => {
        props.ChangeCalendar(value, index);
    }

    useEffect(() => {
        setCalendars(props.calendars);
    }, [props.calendars]);

    useEffect(() => {
        setTreatmentsCalendar(props.treatmentsCalendar);
    }, [props.treatmentsCalendar]);
    return (
        
        <Grid data-testid="calendar-section" container spacing={1} >
            <Grid item xs={12} md={9}>
                <TableContainer>
                    <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
                        <TableHead>
                            <TableRow key="head">
                                <TableCell
                                    align="center"
                                    padding={"none"}
                                >
                                    Trattamento
                                </TableCell>
                                <TableCell
                                    align="center"
                                    padding={"normal"}
                                >
                                    Appuntamento
                                </TableCell>
                                <TableCell
                                    align="right"
                                    sx={{ width: 100 }}>
                                Azione
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {calendars.map((row, index) =>

                                <TableRow
                                    hover
                                    key={index}
                                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}

                                >
                                    <TableCell align="left">
                                        <Typography variant="body1">
                                            {row.treatment}
                                        </Typography>
                                    </TableCell>
                                    <TableCell align="center" width={200}>
                                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                                            <DesktopDatePicker
                                                key="calendarDate"
                                                id={index + ''}
                                                name="calendar"
                                                hiddenLabel
                                                inputFormat="dd/MM/yyyy"
                                                value={row.appointment.appTime || new Date()}
                                                size="small"
                                                onChange={(val) => { handleChangeDataPicker(val, index) }}
                                                renderInput={(params) => (
                                                    <TextField
                                                        data-testid="date"
                                                        fullWidth
                                                        variant="filled"
                                                        id="visit-date"
                                                        size="small"
                                                        {...params}
                                                    />
                                                )}
                                                variant="filled"
                                                InputProps={{
                                                    disableUnderline: true,
                                                }}
                                            />
                                        </LocalizationProvider>
                                    </TableCell>
                                    <TableCell align="right">
                                        <Tooltip title="Elimina">
                                            <IconButton
                                                onClick={(e) => handleDeleteCalendar(index)}
                                                data-testid={index + "-deleteButton"}
                                            >
                                                <DeleteIcon />
                                            </IconButton>
                                        </Tooltip>
                                    </TableCell>
                                </TableRow>

                            )}


                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
            <Grid data-testid="calendar-select" item xs={12} md={3}>
                
                <SearchTreatmentType
                    treatmentsType={treatmentsCalendar}
                    AddTreatment={handleAddCalendar}
                ></SearchTreatmentType>
            </Grid> 
        </Grid>            
    );
};

export default QuotationCalendars;