import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";
import { GetDateItalianFormat } from "../Utilities.js";
import { sendEmail } from "../emailService";
import { saveAs } from 'file-saver';

const pdf_settings = { orientation: 'p', unit: 'px', format: 'a4', x: 20, y: 20, width: 400, scale: 0.7 };
let JSZip = require("jszip");

const getTemplateHtml = () => {

    let template_file;

    if (!process.env.NODE_ENV || process.env.NODE_ENV === 'test') {
        template_file = require("./invoice_template.html");
    }
    else {
        //disabling eslint
        // eslint-disable-next-line import/no-webpack-loader-syntax
        template_file = require("raw-loader!./invoice_template.html");
    }

    return template_file.default;
}

const getTemplatePatientHtml = () => {

    let template_file;

    if (process.env.NODE_ENV === 'test') {
        template_file = require("./invoice_patient_template.html");
    }
    else {
        //disabling eslint
        // eslint-disable-next-line import/no-webpack-loader-syntax
        template_file = require("raw-loader!./invoice_patient_template.html");
    }

    return template_file.default;
}


export const createPdf = (invoice, rows, company) => {

    var _template_html = getTemplateHtml();

    _template_html = addCompanyClientData(_template_html, company);
    _template_html = addInvoiceData(_template_html, invoice);
    _template_html = addRowsData(_template_html, rows);

    buildPdf(_template_html, getInvoiceFileName(invoice));

}
export const createPatientPdf = (invoice, rows, holder, patient) => {

    var _template_html = getTemplatePatientHtml();

    _template_html = addHolderData(_template_html, holder);
    _template_html = addInvoiceData(_template_html, invoice);
    _template_html = addPatientData(_template_html, patient);
    _template_html = addRowsData(_template_html, rows);
    buildPdf(_template_html, getInvoiceFileName(invoice));
}
export const createPatientsHtmlInvoice = (invoice, rows, holder, patient) => {

        var _template_html = getTemplatePatientHtml();

        _template_html = addHolderData(_template_html, holder);
        _template_html = addInvoiceData(_template_html, invoice);
        _template_html = addPatientData(_template_html, patient);
        _template_html = addRowsData(_template_html, rows);
        return ({ html: _template_html, fileName: getInvoiceFileName(invoice) });
}
export const sendPatientPdf = (invoice, rows, holder, patient, callback) => {

    var _template_html = getTemplatePatientHtml();

    _template_html = addHolderData(_template_html, holder);
    _template_html = addInvoiceData(_template_html, invoice);
    _template_html = addPatientData(_template_html, patient);
    _template_html = addRowsData(_template_html, rows);
    let fileName = getInvoiceFileName(invoice);
    buildPdfAndSend(_template_html, fileName, patient.email, callback);


}

const buildPdf = (html, filename) => {

    var pdf = new jsPDF(pdf_settings.orientation, pdf_settings.unit, pdf_settings.format);

    window.html2canvas = html2canvas;

    pdf.html(
        html, {
        callback: function (doc) {
            doc.save(filename);
        },
        x: pdf_settings.x,
        y: pdf_settings.y,
        width: pdf_settings.width,
        html2canvas: { scale: pdf_settings.scale }
    });
}

export const buildDownloadMultiplePdf = (htmls, filenames) => {
    var zip = new JSZip();
    let fileCounter = 0;

    htmls.forEach((html, index) => {

        var pdf = new jsPDF(pdf_settings.orientation, pdf_settings.unit, pdf_settings.format);

        window.html2canvas = html2canvas;

        pdf.html(
            html, {
            callback: function (doc) {
                zip.file(filenames[index], doc.output('blob'), { createFolders: false });
                fileCounter++;
                if (fileCounter === htmls.length) {
                    zip.generateAsync({ type: 'blob' }).then(function (content) {
                        saveAs(content, 'invoices.zip');
                    });
                };
            },
            x: pdf_settings.x,
            y: pdf_settings.y,
            width: pdf_settings.width,
            html2canvas: { scale: pdf_settings.scale }
        });

    });

}


const buildPdfAndSend = async (html, fileName, email, callback) => {

    var pdf = new jsPDF(pdf_settings.orientation, pdf_settings.unit, pdf_settings.format);

    window.html2canvas = html2canvas;

    pdf.html(
        html, {
        callback: async function (doc) {
            var attachpdf = doc.output('datauristring');


            attachpdf = attachpdf.replace('generated.pdf', fileName);

            const attachments = [attachpdf];
            const emailResult = await sendEmail(
                "noreply@mosaycon.com",
                email,
                "La sua fattura " + fileName,
                "In allegato la sua fattura. Grazie. Si prega di non rispondere a questa email.",
                false,
                attachments
            );
            callback(emailResult);
        },
        x: pdf_settings.x,
        y: pdf_settings.y,
        width: pdf_settings.width,
        html2canvas: { scale: pdf_settings.scale }
    });

}



const addHolderData = (template, holder) => {

    if (template && holder) {
        if (!!holder.title) {
            template = template.replaceAll('[Titolo]', holder.title + '<br />');
        }
        else {
            template = template.replaceAll('[Titolo]', ' ');
        }
        if (!!holder.companyName) {
            template = template.replaceAll('[Nome emettitore]', holder.companyName);
        }
        else {
            template = template.replaceAll('[Nome emettitore]', `${holder.name} ${holder.surname}`);
        }


        template = template.replaceAll('[Indirizzo emettitore]', `${holder.address}<br/> ${holder.zipCode} ${holder.city} (${holder.province})`);
        template = template.replaceAll('[P.IVA emettitore]', holder.fiscalCode);
        if (!!holder.iban) {
            template = template.replaceAll('[Conto corrente (IBAN)]', 'IBAN: ' + holder.iban);
        }
        else {
            template = template.replaceAll('[Conto corrente (IBAN)]', '');
        }

        if (!!holder.swift) {
            template = template.replaceAll('[SWIFT/BIC]', 'SWIFT/BIC: ' + holder.swift);
        }
        else {
            template = template.replaceAll('[SWIFT/BIC]', '');
        }
    }
    return template;
}

const addPatientData = (template, patient) => {
    if (template && patient) {
        template = template.replaceAll('[Nome paziente]', `${patient.name} ${patient.surname}`);
        template = template.replaceAll('[Indirizzo paziente]', `${patient.address} ${patient.city}`);
        template = template.replaceAll('[CF paziente]', patient.fiscalCode);
    }
    return template;
}
const addCompanyClientData = (template, company) => {
    if (template && company) {
        template = template.replaceAll('[Nome cliente]', company.companyName);
        template = template.replaceAll('[Indirizzo cliente]', `${company.address} ${company.city}`);
        template = template.replaceAll('[P.IVA cliente]', company.fiscalCode);
    }
    return template;
}

const addInvoiceData = (template, invoice) => {
    if (template && invoice) {
        template = template.replaceAll('[Data fattura]', GetDateItalianFormat(invoice.date));
        template = template.replaceAll('[Data scadenza]', GetDateItalianFormat(invoice.dueDate));
        template = template.replaceAll('[Numero fattura]', invoice.number);
        template = template.replaceAll('[Prezzo]', invoice.amount.toFixed(2));
        template = template.replaceAll('[IVA]', invoice.taxes.toFixed(2));
        template = template.replaceAll('[Totale]', invoice.total.toFixed(2));
    }
    return template;
}

const addRowsData = (template, rows) => {
    if (template && rows) {
        let indexStartTemplateRow = template.indexOf('<tr id="rowTemplate"');
        let closeTrTemplate = template.indexOf("</tr>", indexStartTemplateRow);
        let rowTemplate = template.substring(indexStartTemplateRow, closeTrTemplate + 5);

        var rowsString = '';
        rows.forEach(function (row) {
            var rowString = rowTemplate.replaceAll(' id="rowTemplate"', '');
            rowString = rowString.replaceAll('[Descrizione Riga]', row.description);
            rowString = rowString.replaceAll('[Prezzo Riga]', row.amount.toFixed(2));
            if (row.taxRateDescription !== undefined) {
                rowString = rowString.replaceAll('[IVA Riga]', row.taxRateDescription);
            }
            else if (row.taxes !== undefined) {
                rowString = rowString.replaceAll('[IVA Riga]', row.taxes.toFixed(2) +" €");
            }
            rowsString = rowsString + rowString;
        });

        template = template.replaceAll('<!-- [ROWS] -->', rowsString);
        template = template.replaceAll(rowTemplate, '');
    }
    return template;
}


function getInvoiceFileName(invoice) {

    if (invoice.date) {
        return `Fattura-${invoice.number.replace("/", "-")}_${invoice.date.substring(0, 10)}.pdf`;
    }
    else {
        return `Fattura-${invoice.number.replace("/", "-")}.pdf`;
    }
}
