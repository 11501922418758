import React, { useEffect, useState, useCallback, useContext } from "react";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Grid from "@mui/material/Grid";
import authService from "../api-authorization/AuthorizeService";
import SubscriptionsList from "./SubscriptionsList";
import { Stack } from "@mui/material";
import Switch from "@mui/material/Switch";
import isEmail from "validator/lib/isEmail";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import Link from "@mui/material/Link";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import HtmlViewer from "../Shared/HtmlViewer.js";
import Loading from "../Shared/Loading";
import { CountriesSelect } from "../Shared/CountriesStates/CountriesSelect";
import { ProvincesSelect } from "../Shared/CountriesStates/ProvincesSelect";
import { Tooltip } from '@mui/material';
import Button from "@mui/material/Button";
import AddCardIcon from '@mui/icons-material/AddCard';
import ConstructionIcon from '@mui/icons-material/Construction';

import * as YpatientsConsts from "../Shared/Constants";
import UserContext from "../UserContext";

import BankGateway from "./BankGateway";
import { StartTrial } from "../Shared/TrialUtil.js";
import { ImportRequestTypesOfSpecialization } from "../Shared/RequestUtil.js";


export const Subscription = (props) => {
    const userCtx = useContext(UserContext);
    const [, updateState] = useState();
    const handleForceupdateMethod = useCallback(() => updateState({}), []);

    const [isLoadingCompany, setIsLoadingCompany] = useState(true);
    const [isLoadingLicenses, setIsLoadingLicenses] = useState(false);
    const [useOfTrialLicense, setUseOfTrialLicense] = useState(false);

    const [isPayment, setIsPayment] = useState(false);
    const [transactionCode, setTransactioCode] = useState("");

    const [price, setPrice] = useState({
        doctor: 0,
        secretary: 0,
    });

    const [totalPrice, setTotalPrice] = useState(0);

    const [subscriptionData, setSubscriptionData] = useState({
        docSubscription: 0,
        secSubscription: 0
    });

    const [companyData, setCompanyData] = useState({
        idCompany: YpatientsConsts.emptyGuid,
        companyName: "",
        address: "",
        zipCode: "",
        city: "",
        province: "",
        country: "",
        vatNumber: "",
        fiscalCode: "",
        email: "",
        pec: "",
        healthCenter: false,
        einvoicingCode: "",
        iban: "",
        swift: ""
    });

    const [invoiceBySubscriptionsData, setInvoiceBySubscriptionsData] = useState({
        idCompany: "",
        numDocLicenses: 0,
        numSecLicenses: 0,
        idSubcription: "",
        priceDocLicenses: 0,
        priceSecLicenses: 0,
        termconditionText: ""
    });

    const [isValid, setIsValid] = useState({
        companyName: true,
        address: true,
        city: true,
        vatNumber: true,
        fiscalCode: true,
        pec: true,
        email: true,
        zipCode: true,
        iban: true,
        swift: true,
        healthCenter: true,
        country: true,
        province: true,
        eInvoicingCode: true
    });

    const [isFormatValid, setIsFormatValid] = useState({
        pec: true,
        email: true,
    });

    const [feedback, setFeedback] = useState({
        severity: "success",
        text: "Success",
        open: false,
    });

    const [termconditionText, setTermconditionText] = useState('');
    const [termsAccepted, setTermsAccepted] = useState(false);
    const [termsViewed, setTermsViewed] = useState(false);
    const [openTerms, setOpenTerms] = useState(false);
    const [reloadList, setReloadList] = useState(false);
    const specializationCode = "m_est";
    
    const termsAcceptedAndViewed = () => {
        return termsAccepted && termsViewed;
    }
    const isValidCompanyFormToInvoice = (postData) => {

        // double check
        isValid.companyName = (!!postData.companyName && postData.companyName !== "");
        isValid.address = (!!postData.address && postData.address !== "");
        isValid.zipCode = (!!postData.zipCode && postData.zipCode !== "");
        isValid.city = (!!postData.city && postData.city !== "");
        isValid.province = (!!postData.province && postData.province !== "");
        isValid.country = (!!postData.country && postData.country !== "");
        isValid.vatNumber = (!!postData.vatNumber && postData.vatNumber !== "");
        isValid.fiscalCode = (!!postData.fiscalCode && postData.fiscalCode !== "");
        isValid.pec = (!!postData.pec && postData.pec !== "" && isEmail(postData.pec));
        isValid.eInvoicingCode = (!!postData.einvoicingCode && postData.einvoicingCode !== "");

        const formIsValid = (isValid.address && isValid.companyName && isValid.city && isValid.country &&
            isValid.fiscalCode && isValid.province && isValid.vatNumber && isValid.zipCode && (isValid.pec || isValid.eInvoicingCode));

        return formIsValid;

    };

    const handleCountry = (newvalue) => {
        if (companyData.country !== newvalue) {
            handleProvince("");
        }
        setCompanyData({
            ...companyData,
            country: newvalue
        });
        companyData.country = newvalue;
    };

    const handleProvince = (newvalue) => {
        setCompanyData({
            ...companyData,
            province: newvalue,
        });
        companyData.province = newvalue;
    };

    const getCompanyCountry = () => {
        return companyData.country;
    };

    const getCompanyProvince = () => {
        return companyData.province;
    };

    const checkTrialLicense = async () => {
        const token = await authService.getAccessTokenIfValid();
        const response = await fetch("/api/subscription/checktrial", {
            headers: !token ? {} : { Authorization: `Bearer ${token}` },
        });

        if (response.ok) {
            const data = await response.json();
            setUseOfTrialLicense(data);
        } else {
            const error = await response.text();
            props.feedbackCallback("error", error, true);
        }
    };




    useEffect(() => {

        const getPrice = async () => {
            const token = await authService.getAccessTokenIfValid();
            const response = await fetch("/api/subscription/price", {
                headers: !token ? {} : { Authorization: `Bearer ${token}` },
            });

            if (response.ok) {
                const data = await response.json();
                if (data) {
                    setPrice(data);
                } else {
                    props.feedbackCallback("error", "Errore durante la lettura dei prezzi", true);
                }
            } else {
                const error = await response.text();
                props.feedbackCallback("error", error, true);
            }
        };

        const getCompany = async () => {
            const token = await authService.getAccessTokenIfValid();
            const response = await fetch("/api/company", {
                headers: !token ? {} : { Authorization: `Bearer ${token}` },
            });

            if (response.ok) {
                setIsLoadingCompany(true);
                const data = await response.json();
                if (data) {
                    setCompanyData(data);
                    setIsLoadingCompany(false);
                } else {
                    props.feedbackCallback("error", "Errore durante la lettura delle informazioni sulla società", true);
                }
            } else {
                const error = await response.text();
                props.feedbackCallback("error", error, true);
            }
        };

        getPrice();
        getCompany();
        checkTrialLicense();
    }, []);

    useEffect(() => {
        if (isNaN(subscriptionData.docSubscription))
            subscriptionData.docSubscription = 0;
        if (isNaN(subscriptionData.secSubscription))
            subscriptionData.secSubscription = 0;

        setTotalPrice(
            price.doctor * subscriptionData.docSubscription +
            price.secretary * subscriptionData.secSubscription
        );
    }, [price, subscriptionData]);

    const handleChangeCompany = (event) => {
        const { id, value } = event.target;
        if (id) {

            if (id === "healthCenter") {
                let postData = { ...companyData, healthCenter: event.target.checked };
                SaveCompany(postData);
                setCompanyData({ ...companyData, healthCenter: event.target.checked });
            } else {
                setCompanyData({
                    ...companyData,
                    [id]: value,
                });
                setIsValid({ ...isValid, [id]: true });
                setIsFormatValid({ ...isFormatValid, [id]: true });

                if ((id === "email" || id === "pec") && value && !isEmail(value)) {
                    setIsFormatValid({ ...isFormatValid, [id]: false });
                }
            }
        }
    };

    const handleChangeSubscriptionData = (event) => {
        const { id, value } = event.target;
        if (id) {
            if ((id === "docSubscription" || id === "secSubscription") && value < 0) {
                return;
            }
            if (value) {
                setSubscriptionData({
                    ...subscriptionData,
                    [id]: Number(value),
                });

            } else {
                setSubscriptionData({
                    ...subscriptionData,
                    [id]: 0,
                });
            }
        }
    };

    const GoToBank = async (idSubscriptionToBeRenewed) => {

        invoiceBySubscriptionsData.idCompany = companyData.idCompany;
        invoiceBySubscriptionsData.numDocLicenses = Number(subscriptionData.docSubscription);
        invoiceBySubscriptionsData.numSecLicenses = Number(subscriptionData.secSubscription);
        invoiceBySubscriptionsData.idSubcription = idSubscriptionToBeRenewed;
        invoiceBySubscriptionsData.priceDocLicenses = price.doctor;
        invoiceBySubscriptionsData.priceSecLicenses = price.secretary;
        invoiceBySubscriptionsData.termconditionText = termconditionText;

        const token = await authService.getAccessTokenIfValid();
        const response = await fetch("/api/invoice/newInvoiceNumber", {
            method: 'POST',
            body: JSON.stringify(invoiceBySubscriptionsData),
            headers: !token ? {} : {
                Authorization: `Bearer ${token}`, 'Content-Type': 'application/json'
            }
        });
        const data = await response.text();
        // check for error response
        if (!response.ok) {
            // get error message from body or default to response statusText
            const error = (data && data.message) || response.statusText;
            props.feedbackCallback(
                "error",
                "Errore nella creazione del numero fattura",
                true
            );
            return Promise.reject(error);
        } else {
            setTransactioCode(data);
            setIsPayment(true);
        }
    };

    const handleBankRequest = async () => {
        GoToBank(YpatientsConsts.emptyGuid);
    };

    const handleTrialLicenseRequest = async () => {
        setIsLoadingLicenses(true);
        if (await StartTrial(userCtx)) {
            await ImportRequestTypesOfSpecialization(specializationCode, userCtx.doctor.idDoctor, userCtx.idCompany);
            setCompanyData({ ...companyData, idCompany: userCtx.idCompany });
            setUseOfTrialLicense(false);
            setReloadList(!reloadList);
        }
        
        setIsLoadingLicenses(false);
    };

    const handleRenewalCallBack = async (idSubscription) => {
        if (isValidCompanyFormToInvoice(companyData)) {
            const token = await authService.getAccessTokenIfValid();
            const response = await fetch("/api/subscription/info/" + idSubscription, {
                headers: !token ? {} : {
                    Authorization: `Bearer ${token}`
                }
            });

            if (response.ok) {
                const data = await response.json();

                if (data.type === "DOC") {
                    setTotalPrice(price.doctor);
                }

                if (data.type === "SEC") {
                    setTotalPrice(price.secretary);
                }

                handleForceupdateMethod();

                GoToBank(idSubscription);
            } else {
                const error = await response.text();
                props.feedbackCallback("error", error, true);
            }
        } else {
            props.feedbackCallback("error", "Dati per fatturazione non completi", true);
        }
    };

    const handleBlur = () => {
        SaveCompany(companyData);
    };

    const SaveCompany = async (postData) => {
        const token = await authService.getAccessTokenIfValid();
        let postUrl = "/api/company/update";
        let newCompany = false;
        if (postData.idCompany === YpatientsConsts.emptyGuid) {
            postUrl = "/api/company/new";
            newCompany = true;
        }

        const response = await fetch(postUrl, {
            method: 'POST',
            body: JSON.stringify(postData),
            headers: !token ? {} : {
                Authorization: `Bearer ${token}`, 'Content-Type': 'application/json'
            }
        });

        if (response.ok) {
            const data = await response.json();
            if (data) {
                props.feedbackCallback("success", "Dati salvati con successo", true);
                if (newCompany) {
                    setCompanyData({ ...companyData, idCompany: data });
                    userCtx.updateIdCompany(data);
                }
            }
        } else {
            const error = await response.text();
            props.feedbackCallback("error", error, true);
        }
    };

    const feedbackCallback = (severity, text, open) => {
        setFeedback({ severity: severity, text: text, open: open });
    };

    const handleTermsClosed = () => {
        setOpenTerms(false);
        setTermsViewed(true);
    };

    const handleTermsAccepted = () => {
        setOpenTerms(false);
        setTermsViewed(true);
        setTermsAccepted(true);

    };
    const termHtmlCallback = (html) => {
        setTermconditionText(html);
    }
    const changeTermsAccepted = () => {
        if (termsViewed == false) {
            setOpenTerms(true);
        }
        else {
            setTermsAccepted(!termsAccepted);
        }
    }

    return (
        <>
            {isPayment && (
                <BankGateway
                    feedbackCallback={props.feedbackCallback}
                    Amount={totalPrice}
                    TransactionCode={transactionCode}
                />
            )}
            {!isPayment && (
                <Grid container spacing={2} justifyContent="center">
                    <Grid item xs={12}>
                        {isLoadingLicenses && <Loading message="Aggiornamento license..." />}
                        {!isLoadingLicenses &&
                            <SubscriptionsList idCompany={companyData.idCompany} renewalCallBack={handleRenewalCallBack} priceData={price} termAcceptCallBack={termsAcceptedAndViewed} reload={reloadList} />
                        }
                    </Grid>
                    <Grid item xs={7} md={7}>
                        {isLoadingCompany && <Loading message="Lettura dati in corso...." />}
                        {!isLoadingCompany &&
                            <Card p={5}>
                                <CardHeader
                                    title="Informazioni di fatturazione"
                                    titleTypographyProps={{
                                        variant: "h6",
                                    }}
                                />
                                <CardContent>
                                    <Grid item container xs={12} spacing={2}>
                                        <Grid item xs={4}>
                                            <TextField
                                                fullWidth
                                                id="companyName"
                                                key="companyName"
                                                name="companyName"
                                                label="Denominazione (*)"
                                                variant="filled"
                                                size="small"
                                                value={companyData.companyName || ""}
                                                onChange={handleChangeCompany}
                                                onBlur={handleBlur}
                                            />
                                        </Grid>
                                        <Grid item xs={4}>
                                            <TextField
                                                fullWidth
                                                id="address"
                                                key="address"
                                                name="address"
                                                label="Indirizzo (*)"
                                                variant="filled"
                                                size="small"
                                                value={companyData.address || ""}
                                                onChange={handleChangeCompany}
                                                onBlur={handleBlur}
                                            />
                                        </Grid>
                                        <Grid item xs={4}>
                                            <TextField
                                                fullWidth
                                                id="zipCode"
                                                key="zipCode"
                                                name="zipCode"
                                                label="CAP (*)"
                                                variant="filled"
                                                size="small"
                                                value={companyData.zipCode || ""}
                                                onChange={handleChangeCompany}
                                                onBlur={handleBlur}
                                            />
                                        </Grid>
                                        <Grid item xs={4}>
                                            <TextField
                                                fullWidth
                                                id="city"
                                                key="city"
                                                name="city"
                                                label="Città (*)"
                                                variant="filled"
                                                size="small"
                                                value={companyData.city || ""}
                                                onChange={handleChangeCompany}
                                                onBlur={handleBlur}
                                            />
                                        </Grid>
                                        <Grid key="country" item xs={4}>
                                            <CountriesSelect
                                                handleChange={handleCountry}
                                                handleCountry={getCompanyCountry}
                                                handleBlur={handleBlur}
                                            />
                                        </Grid>
                                        <Grid key="province" item xs={4}>
                                            <ProvincesSelect
                                                handleChange={handleProvince}
                                                handleCountry={getCompanyCountry}
                                                handleProvince={getCompanyProvince}
                                                handleBlur={handleBlur}
                                            />
                                        </Grid>
                                        <Grid item xs={4}>
                                            <TextField
                                                fullWidth
                                                id="vatNumber"
                                                key="vatNumber"
                                                name="vatNumber"
                                                label="Partita IVA (*)"
                                                variant="filled"
                                                size="small"
                                                value={companyData.vatNumber || ""}
                                                onChange={handleChangeCompany}
                                                onBlur={handleBlur}
                                            />
                                        </Grid>
                                        <Grid item xs={4}>
                                            <TextField
                                                fullWidth
                                                id="fiscalCode"
                                                key="fiscalCode"
                                                name="fiscalCode"
                                                label="Codice Fiscale (*)"
                                                variant="filled"
                                                size="small"
                                                value={companyData.fiscalCode || ""}
                                                onChange={handleChangeCompany}
                                                onBlur={handleBlur}
                                            />
                                        </Grid>
                                        <Grid item xs={4}>
                                            <TextField
                                                fullWidth
                                                id="einvoicingCode"
                                                key="einvoicingCode"
                                                name="einvoicingCode"
                                                label="Codice Destinatario (**)"
                                                variant="filled"
                                                size="small"
                                                value={companyData.einvoicingCode || ""}
                                                onChange={handleChangeCompany}
                                                onBlur={handleBlur}
                                            />
                                        </Grid>
                                        <Grid item xs={4}>
                                            <TextField
                                                fullWidth
                                                id="pec"
                                                key="pec"
                                                name="pec"
                                                label="PEC (**)"
                                                variant="filled"
                                                size="small"
                                                value={companyData.pec || ""}
                                                error={!isFormatValid.pec}
                                                helperText={!isFormatValid.pec ? "email pec non valida" : ""}
                                                onChange={handleChangeCompany}
                                                onBlur={handleBlur}
                                            />
                                        </Grid>
                                        <Grid item xs={4}>
                                            <TextField
                                                fullWidth
                                                id="iban"
                                                key="iban"
                                                name="iban"
                                                label="IBAN"
                                                variant="filled"
                                                size="small"
                                                inputProps={{
                                                    maxLength: 27
                                                }}
                                                value={companyData.iban || ""}
                                                onChange={handleChangeCompany}
                                                onBlur={handleBlur}
                                            />
                                        </Grid>
                                        <Grid item xs={4}>
                                            <TextField
                                                fullWidth
                                                id="swift"
                                                key="swift"
                                                name="swift"
                                                label="SWIFT"
                                                variant="filled"
                                                size="small"
                                                inputProps={{
                                                    maxLength: 11
                                                }}
                                                value={companyData.swift || ""}
                                                onChange={handleChangeCompany}
                                                onBlur={handleBlur}
                                            />
                                        </Grid>
                                        <Grid item xs={4}>
                                            <TextField
                                                fullWidth
                                                id="email"
                                                key="email"
                                                name="email"
                                                label="E-mail"
                                                variant="filled"
                                                size="small"
                                                value={companyData.email || ""}
                                                error={!isFormatValid.email}
                                                helperText={!isFormatValid.email ? "email non valida" : ""}
                                                onChange={handleChangeCompany}
                                                onBlur={handleBlur}
                                            />
                                        </Grid>
                                        <Grid item xs={4}>
                                            <Typography>Centro medico</Typography>
                                            <Stack direction="row" spacing={1} alignItems="center">
                                                <Typography>No</Typography>
                                                <Switch
                                                    data-testid="medical-center"
                                                    id="healthCenter"
                                                    key="healthCenter"
                                                    name="healthCenter"
                                                    variant="filled"
                                                    size="small"
                                                    checked={companyData.healthCenter}
                                                    onChange={handleChangeCompany}
                                                />
                                                <Typography>Sì</Typography>
                                            </Stack>
                                        </Grid>
                                    </Grid>
                                    <Grid item container xs={12}>
                                        <Typography mt={3}>
                                            (*) Campi necessari per attivare l'acquisto di una licenza - (**) Campi necessari in alternativa
                                        </Typography>
                                    </Grid>
                                </CardContent>
                            </Card>
                        }
                    </Grid>
                    <Grid item xs={3} md={3}>
                        <Card p={5}>
                            <CardHeader
                                title="Aggiungi licenze"
                                titleTypographyProps={{
                                    variant: "h6",
                                }}
                            />
                            <CardContent>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        {/* <Typography mt={3}>
                                        Licenza medico ({price.doctor} €/anno)
                                    </Typography> */}
                                        <TextField
                                            label={"Licenze medico (" + price.doctor + "€/anno)"}
                                            fullWidth
                                            id="docSubscription"
                                            key="docSubscription"
                                            name="docSubscription"
                                            variant="filled"
                                            size="small"
                                            min="0"
                                            type="number"
                                            value={subscriptionData.docSubscription || 0}
                                            inputProps={{ "data-testid": "docSub" }}
                                            onChange={handleChangeSubscriptionData}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        {/* <Typography mt={3}>
                                        Licenza segreteria ({price.secretary} €/anno)
                                    </Typography> */}

                                        <TextField
                                            label={"Licenze segreteria (" + price.secretary + "€/anno)"}
                                            fullWidth
                                            id="secSubscription"
                                            key="secSubscription"
                                            name="secSubscription"
                                            variant="filled"
                                            size="small"
                                            min="0"
                                            type="number"
                                            value={subscriptionData.secSubscription || 0}
                                            inputProps={{ "data-testid": "secSub" }}
                                            onChange={handleChangeSubscriptionData}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography variant="h5" align="center" mt={3}>
                                            Totale: {totalPrice} € / Anno
                                        </Typography>
                                    </Grid>

                                    <Grid item xs={12} justifyContent="center">
                                        <Stack
                                            alignItems="center"
                                            justifyContent="center"
                                            spacing={2}
                                        >
                                            <Tooltip title="Al click sul pulsante verrete reindirizzati al sistema di pagamento">
                                                <span>
                                                    <Button variant="contained" onClick={handleBankRequest}
                                                        disabled={companyData.idCompany === YpatientsConsts.emptyGuid
                                                            || (subscriptionData.docSubscription === 0 && subscriptionData.secSubscription === 0)
                                                            || !isValidCompanyFormToInvoice(companyData)
                                                            || !termsAccepted
                                                            || !termsViewed
                                                        }
                                                        data-testid="buttonGoToBank"
                                                        startIcon={<AddCardIcon />}>
                                                        Acquista
                                                    </Button>
                                                </span>

                                            </Tooltip>
                                            <Typography variant="body2" align="justify" mt={0}>
                                                Prima di procedere con l'acquisto è necessario leggere ed accettere i
                                                termini e condizioni d'uso.
                                            </Typography>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={termsAccepted}
                                                        data-testid="terms-check"
                                                    />
                                                }
                                                onChange={() => changeTermsAccepted()}
                                                label={
                                                    <Typography variant="body2">
                                                        Accetto i&nbsp;
                                                        <Link
                                                            component="button"
                                                            variant="body2"
                                                            color="inherit"
                                                            data-testid="terms-link"
                                                            onClick={() => {
                                                                setOpenTerms(true);
                                                            }}
                                                        >
                                                            termini e le condizioni.
                                                        </Link>
                                                    </Typography>
                                                }
                                            ></FormControlLabel>
                                            <Dialog
                                                open={openTerms}
                                                onClose={handleTermsClosed}
                                                aria-labelledby="form-dialog-title"
                                            >
                                                <DialogTitle id="form-dialog-title">
                                                    Termini e condizioni
                                                </DialogTitle>
                                                <DialogContent>
                                                    <HtmlViewer

                                                        fileName="terms.html"
                                                        feedbackCallback={props.feedbackCallback}
                                                        htmlCallback={termHtmlCallback}
                                                    />
                                                </DialogContent>
                                                <DialogActions>
                                                    <Button
                                                        onClick={handleTermsAccepted}
                                                        color="primary"
                                                        data-testid="accept-button"
                                                    >
                                                        Accetto
                                                    </Button>
                                                </DialogActions>
                                            </Dialog>

                                        </Stack>
                                    </Grid>
                                </Grid>
                                {useOfTrialLicense &&
                                    <Grid item container xs={12} justifyContent="center">
                                        <Stack
                                            alignItems="center"
                                            justifyContent="center"
                                            spacing={2}
                                        >
                                            <br />
                                            <p>
                                                Prova il sistema con una licenza trial 30 giorni!&nbsp;&nbsp;
                                                <Button
                                                    data-testid="buttonActiveTrialLicense"
                                                    variant="outlined"
                                                    size="small"
                                                    startIcon={<ConstructionIcon fontSize="small" />}
                                                    onClick={handleTrialLicenseRequest}
                                                    
                                                >
                                                    Attivala subito!
                                                </Button>
                                            </p>
                                        </Stack>
                                    </Grid>
                                }
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            )}
        </>
    );
};

export default Subscription;
