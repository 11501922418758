import React, { useEffect, useState, useContext } from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import authService from "../api-authorization/AuthorizeService";
import Pagination from "@mui/material/Pagination";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import PersonOffIcon from "@mui/icons-material/PersonOff";
import MedicalServicesIcon from "@mui/icons-material/MedicalServices";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import ConstructionIcon from '@mui/icons-material/Construction';
import Typography from "@mui/material/Typography";
import UserContext from "../UserContext";
import WarningExpirationLicense from "../Shared/Licenses/WarningExpirationLicense";

export const UsersList = (props) => {
    const userCtx = useContext(UserContext);
    const pageSize = 10;
    const [page, setPage] = useState(1);

    const [availableLicenses, setAvailableLicenses] = useState({
        numberOfDoctorLicenses: 0,
        numberOfSecretaryLicenses: 0,
        numberOfTrialLicenses: 0
    });

    const [users, setUsers] = useState([
        {
            id: "",
            idSubscription: "",
            subscriptionEndDate: "",
            email: "",
            phoneNumber: "",
            emailConfirmed: "",
            roles: [],
        },
    ]);

    //cleanup state on unmount
    useEffect(() => {
        return () => {
            setUsers([]);
            setPage(1);
        };
    }, []);

    const columns = [
        { id: "email", label: "UserId", numeric: false },
        { id: "phoneNumber", label: "Numero di telefono", numeric: false },
        { id: "roles", label: "Ruoli", numeric: false },
        { id: "actions", label: "Azioni", numeric: false },
    ];

    const handleChange = (event, value) => {
        setPage(value);
    };

    const handleSetSubscription = async (user, role) => {
        const token = await authService.getAccessTokenIfValid();
        const postUrl = "/api/subscription/assign/" + role;
        const response = await fetch(postUrl, {
            method: "POST",
            body: JSON.stringify(user),
            headers: !token
                ? { "Content-Type": "application/json" }
                : {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "application/json",
                },
        });
        if (response.ok) {
            props.feedbackCallback(
                "success",
                "Licenza assegnata correttamente",
                true
            );
            getAvailableLicenses();
            getUsers();

            const data = await response.json();
            if (data.id === userCtx.idUser) {
                userCtx.updateRoles(data.roles);
                userCtx.updateValidLicense(true);
            }
        } else {
            props.feedbackCallback(
                "error",
                "Errore nell'assegnazione della licenza",
                true
            );
        }
    };

    const handleUnsubscribe = async (user) => {
        const token = await authService.getAccessTokenIfValid();
        const postUrl = "/api/subscription/unassign";
        const response = await fetch(postUrl, {
            method: "POST",
            body: JSON.stringify(user),
            headers: !token
                ? { "Content-Type": "application/json" }
                : {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "application/json",
                },
        });
        if (response.ok) {
            props.feedbackCallback(
                "success",
                "Licenza disattivata correttamente",
                true
            );
            getAvailableLicenses();
            getUsers();
            const data = await response.json();
            if (data.id === userCtx.idUser) {
                userCtx.updateRoles(data.roles);
                userCtx.updateValidLicense(false);
            }
        } else {
            props.feedbackCallback(
                "error",
                "Errore nella disattivazione della licenza",
                true
            );
        }
    };

    const getUsers = async () => {
        const token = await authService.getAccessTokenIfValid();
        const response = await fetch("/api/user/users", {
            headers: !token ? {} : { Authorization: `Bearer ${token}` },
        });
        if (!response.ok) {
            props.feedbackCallback(
                "error",
                "Errore nella lettura degli utenti",
                true
            );
        } else {
            const data = await response.json();
            setUsers(data);
        }
    };

    const getAvailableLicenses = async () => {
        const token = await authService.getAccessTokenIfValid();
        const response = await fetch("/api/subscription/available", {
            headers: !token ? {} : { Authorization: `Bearer ${token}` },
        });
        if (!response.ok) {
            props.feedbackCallback(
                "error",
                "Errore nella lettura delle licenze disponibili",
                true
            );
        } else {
            const data = await response.json();
            setAvailableLicenses(data);
        }
    }

    useEffect(() => {
        getAvailableLicenses();
        getUsers();
    }, []);

    const transalationRoles = (role) => {
        if (role === "Administrator") {
            return "Amministratore";
        }

        if (role === "Doctor") {
            return "Medico";
        }

        if (role === "Secretary") {
            return "Segreteria";
        }
    };

    const renderRoles = (emailConfirmed, roles) => {
        if (!emailConfirmed) {
            return "In attesa di conferma";
        } else {
            return (
                <Typography variant="body2" component="p">
                    {roles.map((role) => {
                        return transalationRoles(role) + " ";
                    })}
                </Typography>
            );
        }
    };

    const renderActions = (user) => {
        if (user.roles.includes("Doctor") || user.roles.includes("Secretary")) {
            return (
                <>
                    <Button
                        variant="outlined"
                        size="small"
                        startIcon={<PersonOffIcon fontSize="small" />}
                        onClick={() => handleUnsubscribe(user)}
                    >
                        Disattiva
                    </Button>
                    <WarningExpirationLicense endDate={user.subscriptionEndDate} />
                </>
            );
        }

        return (
            <React.Fragment>
                <ButtonGroup variant="contained">
                    {(availableLicenses.numberOfDoctorLicenses > 0) && user.emailConfirmed &&
                        <Button
                            variant="outlined"
                            size="small"
                            startIcon={<MedicalServicesIcon fontSize="small" />}
                            onClick={() => handleSetSubscription(user, "DOC")}
                        >
                            Medico
                        </Button>
                    }
                    {(availableLicenses.numberOfSecretaryLicenses > 0) && user.emailConfirmed &&
                        <Button
                            variant="outlined"
                            size="small"
                            startIcon={<SupportAgentIcon fontSize="small" />}
                            onClick={() => handleSetSubscription(user, "SEC")}
                        >
                            Segreteria
                        </Button>
                    }
                    {(availableLicenses.numberOfTrialLicenses > 0) && user.emailConfirmed &&
                        <Button
                            variant="outlined"
                            size="small"
                            startIcon={<ConstructionIcon fontSize="small" />}
                            onClick={() => handleSetSubscription(user, "TRI")}
                        >
                            Trial
                        </Button>
                    }
                </ButtonGroup>
            </React.Fragment>
        );
    };

    const renderTable = () => {
        let usersToShow = [];
        if (users.length > pageSize) {
            //select users to show
            const firstPageIndex = (page - 1) * pageSize;
            const lastPageIndex = firstPageIndex + pageSize;
            usersToShow = users.slice(firstPageIndex, lastPageIndex);
        } else {
            usersToShow = [...users];
        }
        return (
            <TableContainer>
                <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
                    <TableHead>
                        <TableRow>
                            {columns.map((headCell) => (
                                <TableCell
                                    key={headCell.id}
                                    align="center"
                                    padding={headCell.disablePadding ? "none" : "normal"}
                                >
                                    {headCell.label}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>

                    <TableBody>
                        {usersToShow.map((user) => (
                            <TableRow
                                key={user.email}
                                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                            >
                                <TableCell align="center">{user.email}</TableCell>
                                <TableCell align="center">{user.phoneNumber}</TableCell>
                                <TableCell align="center">{renderRoles(user.emailConfirmed, user.roles)}</TableCell>
                                <TableCell align="center">{renderActions(user)}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        );
    };

    return (
        <Card
            sx={{
                boxShadow: 0,
            }}
        >
            <CardContent>
                <Typography gutterBottom variant="subtitle3" component="div">
                    Utenti
                </Typography>
                {renderTable()}
                {users.length > pageSize && (
                    <Pagination
                        count={Math.floor(users.length / pageSize) + 1}
                        defaultPage={1}
                        onChange={handleChange}
                    />
                )}
            </CardContent>
        </Card>
    );
};

export default UsersList;
