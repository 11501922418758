import React, { useEffect, useState, useContext } from "react";
import WarningIcon from '@mui/icons-material/Warning';
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { Typography } from "@mui/material";
import * as YpatientsUtilities from "../Utilities";

export const WarningExpirationLicense = (props) => {
    const [isAlertVisible, setIsAlertVisible] = useState(false);
    const [isExpiredVisible, setIsExpiredVisible] = useState(false);
    const [openDialog, setOpenDialog] = useState(false);
    const [days, setDays] = useState("");
    const [endDate, setEndDate] = useState("");
    const [title, setTitle] = useState("");
    const [alertLevel, setAlertLevel] = useState(1); // 1 = Orange; 2 = Red

    const handleOpenDialog = () => {
        setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };
    useEffect(() => {
        const buildTextAlert = (endDate) => {
            if (endDate !== "") {
                const formattedEndDate = YpatientsUtilities.GetDateItalianFormat(endDate);
                const currentdate = new Date();
                const days = YpatientsUtilities.DiffTwoDatesInDays(endDate, currentdate);
                if (days > 0 && days <= 30) {
                    setIsAlertVisible(true);
                    setAlertLevel(1);
                    setTitle("Licenza utente in scadenza");
                } else {
                    setIsAlertVisible(false);
                }
                if (days < 0) {
                    setIsExpiredVisible(true);
                    setAlertLevel(2);
                    setTitle("Licenza utente scaduta");
                } else {
                    setIsExpiredVisible(false);
                }
                setDays(days);
                setEndDate(formattedEndDate);

            }
        };
        buildTextAlert(props.endDate);
    }, [props.endDate]);


    return (
        <>
            {(isAlertVisible || isExpiredVisible) && <>
                <Tooltip title={title} >
                    <IconButton edge="end" data-testid="buttonAlert" onClick={handleOpenDialog}>
                        <WarningIcon sx={alertLevel === 1 ? { color: "#ffa500" } : { color: "#ff0000" }} />
                    </IconButton>
                </Tooltip>
                <Dialog
                    open={openDialog}
                    onClose={handleCloseDialog}
                >
                    <DialogTitle>{title}</DialogTitle>
                    <DialogContent dividers>
                        {isAlertVisible && <Typography>
                            La licenza scadrà tra <strong>{days} giorni</strong> e precisamente il <strong> {endDate} </strong>.<br /><br />
                            Contatta il tuo amministratore per provvedere al suo rinnovo in tempo.<br /><br />
                            Considera che hai 7 giorni di tempo dopo la sua effettiva scadenza per poterla rinnovare e continuare ad usare il sistema.<br />
                            Dopo tale periodo le funzionalità del sistema non saranno più accessibili.
                        </Typography>
                        }
                        {isExpiredVisible && <Typography>
                            La licenza è scaduta il <strong> {endDate} </strong>.<br /><br />
                            Contatta il tuo amministratore per provvedere al suo rinnovo. <br /><br />
                            Trascorsi 7 giorni di tempo dopo la sua effettiva scadenza le funzionalità del sistema non saranno più accessibili.
                        </Typography>
                        }
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleCloseDialog} autoFocus >
                            Ok
                        </Button>
                    </DialogActions>
                </Dialog>
            </>
            }
        </>

    );
};

export default WarningExpirationLicense;
