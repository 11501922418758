import React, {  useEffect, useState } from 'react';
import authService from '../../api-authorization/AuthorizeService';
import Avatar from "@mui/material/Avatar";
import { FileUpload } from "./FileUpload";
import { Loading } from '../../Shared/Loading';
import * as YpatientsConsts from "../../Shared/Constants";

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

import "./PersonalPicture.css";

export const PersonalPicture = (props) => {
    const IdPatient = props.idPatient;
     
    const [avatarImgPath, setAvatarImgPath] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [openFileDialog, setOpenFileDialog] = useState(false);
    const [isNewPatient, setIsNewPatient] = useState(false);
    const isReadonly = props.isReadonly;
    const forceUpdateAvatar = props.forceUpdateAvatar;
    useEffect(() => {
        GetAvatar();
    }, [forceUpdateAvatar]);

    const handleOpenFileDialogOnClick = () => {
        setOpenFileDialog(true);
    };

    const handleCloseFileDialogOnClick = () => {
        setOpenFileDialog(false);
    };

    const handleUpdateAvatarOnClick = () => {
        GetAvatar();
        setOpenFileDialog(false);
    };



    const GetAvatar = async () => {
        setIsLoading(true);
        const token = await authService.getAccessTokenIfValid();
        let tempIdPatient = IdPatient;
        if (typeof tempIdPatient === "undefined") {
            tempIdPatient = YpatientsConsts.emptyGuid; 
            setIsNewPatient(true);
            setIsLoading(false);
            return;
        }
        const getUrl = '/api/filepatient/avatar/' + tempIdPatient;
        const response = await fetch(getUrl, {
            headers: !token ? {} : {
                Authorization: `Bearer ${token}` }
        });

        if (response.ok) {
            const data = await response.json();
            setAvatarImgPath(data);
            setIsLoading(false);
        } else {
            const error = await response.text();
            props.feedbackCallback("error", error, true);
        }
    };

    return (
        <div className='position-relative'>
            {isLoading && <Loading message="Loading image"/>}
            {!isLoading && <div>
                <Avatar
                    className="img-avatar"
                    alt={props.patientName}
                    src={avatarImgPath[0]}
                    sx={{ width: 100, height: 100 }}
                />
                {!isNewPatient && !isReadonly && <a className="avatar-icon" onClick={handleOpenFileDialogOnClick} data-testid="openDropModal"><i className="fas fa-cloud-upload-alt"></i></a>}
            </div>}
            <Dialog open={openFileDialog} onClose={handleCloseFileDialogOnClick}>
                <DialogTitle>Modifica Avatar paziente</DialogTitle>
                <DialogContent>
                    <FileUpload idPatient={IdPatient} returnClick={handleUpdateAvatarOnClick} feedbackCallback={props.feedbackCallback} />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseFileDialogOnClick} data-testid="closeDropModal">Chiudi</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default PersonalPicture;