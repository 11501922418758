import React, { useEffect, useState, useContext } from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemSecondaryAction from "@mui/material/ListItemSecondaryAction";
import Divider from "@mui/material/Divider";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import authService from "../api-authorization/AuthorizeService";
import TablePagination from "@mui/material/TablePagination";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Tooltip from "@mui/material/Tooltip";
import EditIcon from "@mui/icons-material/Edit";
import ClearIcon from "@mui/icons-material/Clear";
import AddIcon from "@mui/icons-material/Add";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import { UserContext } from "../UserContext";

export const RequestTypesManager = (props) => {
  const userCtx = useContext(UserContext);
  const pageSize = 5;
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(pageSize);
  const [requestTypes, setRequestTypes] = useState([]);
  const [newRequestType, setNewRequestType] = useState({idRequestType: "00000000-0000-0000-0000-000000000000", title: "", description: "", idDoctor: "", status: "1", treatmentTypes: []});
  const [selected, setSelected] = useState();
  const [treatmentTypes, setTreatmentTypes] = useState([]);
  const [addTreatmentTypeDialogOpen, setAddTreatmentTypeDialogOpen] =
    useState(false);
  const [treatmentTypeToAdd, setTreatmentTypeToAdd] = useState();

  const columns = [
    { id: "title", label: "Nome", numeric: false },
    { id: "description", label: "Descrizione", numeric: false },
    { id: "treatments", label: "Interventi", numeric: false },
    { id: "actions", label: "Azioni", numeric: false },
  ];
  //cleanup state on unmount
  useEffect(() => {
    return () => {
      setRequestTypes([]);
      setPage(0);
    };
  }, []);

  const handleChange = (requestType, event) => {
    const { id, value } = event.target;
    setRequestTypes(
      requestTypes.map((currentType) =>
        currentType.idRequestType === requestType.idRequestType
          ? { ...currentType, [id]: value }
          : currentType
      )
    );
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  //cleanup state on unmount
  useEffect(() => {
    return () => {
      setRequestTypes([]);
      setPage(0);
      setSelected(null);
      setTreatmentTypes([]);
    };
  }, []);

  const GetTreatmentTypes = async () => {
    const token = await authService.getAccessTokenIfValid();
    const response = await fetch("/api/treatment/type", {
      headers: !token ? {} : { Authorization: `Bearer ${token}` },
    });
    const data = await response.json();
    setTreatmentTypes(data);
  };

  const getRequestTypes = async () => {
    const token = await authService.getAccessTokenIfValid();
    const response = await fetch("/api/request/type", {
      headers: !token ? {} : { Authorization: `Bearer ${token}` },
    });
    const data = await response.json();
    setRequestTypes(data);
  };

  useEffect(() => {
    if (userCtx.doctor === undefined || userCtx.doctor.idDoctor === undefined) {
      //do nothing if idDoctor is not set
      return;
    }
    getRequestTypes();
  }, [userCtx.doctor]);

  const handlePageChange = (event, value) => {
    setPage(value);
  };



  const handleDeleteRequestType = async (type) => {
    const token = await authService.getAccessTokenIfValid();
    const path = "/api/request/type/delete";
    const requestOptions = {
      method: "POST",
      headers: !token
        ? { "Content-Type": "application/json" }
        : {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            Accept: "application/json",
          },
      body: JSON.stringify(type),
    };
    fetch(path, requestOptions).then(async (response) => {
      // check for error response
      if (!response.ok) {
        const data = await response.json();
        // get error message from body or default to response statusText
        const error = (data && data.message) || response.statusText;
        props.feedbackCallback(
          "error",
          "Impossibile eliminare. Tipologia in uso?",
          true
        );
        return Promise.reject(error);
      } else {
        props.feedbackCallback("success", "Tipologia eliminata", true);
        const newRequestTypes = requestTypes.filter(
          (x) => x.idRequestType !== type.idRequestType
        );
        setRequestTypes(newRequestTypes);
      }
    });
  };

  const handleChangeNewRequestType = (event) => {
    const { id, value } = event.target;
    setNewRequestType({ ...newRequestType, [id]: value });
  };

  const handleBlurNewRequestType = async (event) => {
    if (
      newRequestType.title === "" ||
      newRequestType.title === undefined ||
      newRequestType.title === null
    ) {
      return;
    } else {
      handleBlur(newRequestType, event);
      setNewRequestType({...newRequestType, title: ""});
    }
  };

  const handleBlur = async (requestType, event) => {
    if (
      requestType.title === "" ||
      requestType.title === undefined ||
      requestType.title === null
    ) {
      props.feedbackCallback(
        "warning",
        "Inserire un nome identificativo per procedere al salvataggio",
        true
      );
      return;
    }
    let token = await authService.getAccessTokenIfValid();
    let path = "/api/request/type/update";
    if (
      requestType.idRequestType === undefined ||
      requestType.idRequestType === "" ||
      requestType.idRequestType === "00000000-0000-0000-0000-000000000000"
    ) {
      path = "/api/request/type";
    }
    const requestOptions = {
      method: "POST",
      headers: !token
        ? { "Content-Type": "application/json" }
        : {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            Accept: "application/json",
          },
      body: JSON.stringify(requestType),
    };
    fetch(path, requestOptions).then(async (response) => {
      const data = await response.json();
      // check for error response
      if (!response.ok) {
        // get error message from body or default to response statusText
        const error = (data && data.message) || response.statusText;
        props.feedbackCallback("error", "Errore nel salvataggio", true);
        return Promise.reject(error);
      } else {
        if (data) {
          if (
            requestType.idRequestType === undefined ||
            requestType.idRequestType === "" ||
            requestType.idRequestType === "00000000-0000-0000-0000-000000000000"
          ) {
          
            getRequestTypes();
            setSelected(data.idRequestType);
          } else {
            let index = requestTypes.findIndex(
              (x) => x.idRequestType === requestType.idRequestType
            );
            requestTypes[index] = data;
            setRequestTypes([...requestTypes]);
          }
          props.feedbackCallback("success", "Dati salvati con successo", true);
        }
      }
    });
  };

  useEffect(() => {
    //set the page to the selected request type
    if (selected !== undefined && selected !== "") {
      let index = requestTypes.findIndex((x) => x.idRequestType === selected);
      if (index !== -1) {
        let page = Math.floor(index / rowsPerPage);
        setPage(page);
      }
    }
  }, [selected, requestTypes]);

  const handleAddTreatmentTypeDialogConfirm = async (request) => {
    //add treatmentType to request
    request.treatmentTypes = [...request.treatmentTypes, treatmentTypeToAdd];
    //define callback to update treatment type
    await handleBlur(request);
    setAddTreatmentTypeDialogOpen(false);
    setTreatmentTypeToAdd(null);
  };

  const renderTreatmentTypeDialog = (request) => {
    return (
      <Dialog open={addTreatmentTypeDialogOpen}>
        <DialogTitle>Aggiungi tipologia di intervento</DialogTitle>
        <DialogContent>
          <Autocomplete
            id="add-drug"
            data-testid="treatment-selector"
            options={treatmentTypes}
            getOptionLabel={(option) => option.title}
            onChange={(event, newValue) => {
              setTreatmentTypeToAdd(newValue);
            }}
            renderInput={(params) => (
              <TextField {...params} label="Intervento" variant="outlined" />
            )}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setAddTreatmentTypeDialogOpen(false);
              setTreatmentTypeToAdd(null);
            }}
          >
            Annulla
          </Button>
          <Button
            onClick={() =>
              handleAddTreatmentTypeDialogConfirm(request)
            }
          >
            Conferma
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  const handleDeleteTreatmentType = (row, treatmentType) => {
    let index = row.treatmentTypes.findIndex(
      (x) => x.idTreatmentType === treatmentType.idTreatmentType
    );
    row.treatmentTypes.splice(index, 1);
    handleBlur(row);
    setRequestTypes([...requestTypes]);
  };

  const renderTable = () => {
    let requestTypesToShow = [];
    if (requestTypes.length > rowsPerPage) {
      //select subscriptions to show
      const firstPageIndex = page  * rowsPerPage;
      const lastPageIndex = firstPageIndex + rowsPerPage;
      requestTypesToShow = requestTypes.slice(firstPageIndex, lastPageIndex);
    } else {
      requestTypesToShow = [...requestTypes];
    }
    return (
      <>
        <TableContainer>
          <Table
            sx={{ minWidth: 650 }}
            size="small"
            aria-label="request type list"
          >
            <TableHead>
              <TableRow>
                {columns.map((headCell) => (
                  <TableCell
                    key={headCell.id}
                    align= {headCell.id === "actions" ? "center" : "center"}
                    padding={headCell.disablePadding ? "none" : "normal"}
                  >
                    {headCell.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>

            <TableBody>
              {requestTypesToShow.map((row) =>
                selected !== row.idRequestType ? (
                  <TableRow
                    data-testid={row.idRequestType + "-row"}
                    hover
                    key={row.idRequestType}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    onClick={() => setSelected(row.idRequestType)}
                  >
                    <TableCell align="center">{row.title}</TableCell>
                    <TableCell align="center">{row.description}</TableCell>
                    <TableCell align="center">
                        {row.treatmentTypes &&
                          row.treatmentTypes.map((treatmentType) => (
                          <p key={treatmentType.idTreatmentType}>
                              {treatmentType.title}
                          </p>
                          ))}
                    </TableCell>
                    <TableCell align="center">
                      <Tooltip title="Modifica">
                        <IconButton
                          onClick={() => setSelected(row.idRequestType)}
                          data-testid={row.idTool + "-clearButton"}
                        >
                          <EditIcon />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Elimina">
                        <IconButton
                          aria-label="delete"
                          onClick={() => handleDeleteRequestType(row)}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                ) : (
                  <TableRow
                    hover
                    key={row.idRequestType}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    {renderTreatmentTypeDialog(row)}
                    <TableCell align="center">
                      <TextField
                        data-testid={
                          row.idRequestType
                            ? row.idRequestType + "-titleInput"
                            : "newTitleInput"
                        }
                        variant="filled"
                        hiddenLabel
                        fullWidth
                        margin="dense"
                        InputProps={{
                          disableUnderline: true,
                          style: { fontSize: 13 },
                        }}
                        id="title"
                        value={row.title}
                        onChange={(e) => handleChange(row, e)}
                        onBlur={(e) => handleBlur(row, e)}
                        size="small"
                      ></TextField>
                    </TableCell>
                    <TableCell align="center">
                      <TextField
                        data-testid={
                          row.idRequestType
                            ? row.idRequestType + "-descriptionInput"
                            : "newDescriptionInput"
                        }
                        variant="filled"
                        hiddenLabel
                        fullWidth
                        margin="dense"
                        InputProps={{
                          disableUnderline: true,
                          style: { fontSize: 13 },
                        }}
                        id="description"
                        value={row.description}
                        onChange={(e) => handleChange(row, e)}
                        onBlur={(e) => handleBlur(row, e)}
                        size="small"
                      ></TextField>
                    </TableCell>
                    <TableCell align="center">
                      <List>
                        {row.treatmentTypes &&
                          row.treatmentTypes.map((treatmentType) => (
                            <div key={treatmentType.idTreatmentType}>
                              <ListItem key={treatmentType.idTreatmentType}>
                                <ListItemText primary={treatmentType.title} />
                                <ListItemSecondaryAction>
                                  <IconButton
                                    data-testid={treatmentType.idTreatmentType + "-delete"}
                                    onClick={() =>
                                      handleDeleteTreatmentType(
                                        row,
                                        treatmentType
                                      )
                                    }
                                  >
                                    <DeleteIcon />
                                  </IconButton>
                                </ListItemSecondaryAction>
                              </ListItem>
                              <Divider />
                            </div>
                          ))}
                      </List>
                      <IconButton
                        data-testid="newTreatmentTypeButton"
                        onClick={() => {
                          setAddTreatmentTypeDialogOpen(true);
                          GetTreatmentTypes();
                        }}
                        aria-label="add"
                        size="small"
                        color="inherit"
                      >
                        <AddIcon />
                      </IconButton>
                    </TableCell>
                    <TableCell align="right">
                      <Tooltip title="Esci da modifica">
                        <IconButton
                          onClick={() => setSelected(null)}
                          data-testid={row.idTool + "-clearButton"}
                        >
                          <ClearIcon />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Elimina">
                        <IconButton
                          aria-label="delete"
                          onClick={() => handleDeleteRequestType(row)}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                )
              )}
            </TableBody>
          </Table>
        </TableContainer>
        
      </>
    );
  };

  return (
    <Card
      sx={{
        boxShadow: 0,
      }}
    >
      <CardContent>
        <Typography gutterBottom variant="subtitle3" component="div" align="left"
          sx={{ paddingBottom: "10px" }}>
          Percorsi terapeutici
          <Box sx={{ float: "right" }}>
            <TextField
              data-testid="newToolInput"
              variant="filled"
              hiddenLabel
              placeholder="Aggiungi percorso"
              size="small"
              margin="dense"
              InputProps={{
                disableUnderline: true,
                style: { fontSize: 11, paddingBottom: "0px" },
              }}
              id="title"
              value={newRequestType.title}
              onChange={handleChangeNewRequestType}
            />
            <IconButton onClick={handleBlurNewRequestType} color="inherit">
              <AddIcon />
            </IconButton>
          </Box>
        </Typography>
        {renderTable()}
          <TablePagination
            rowsPerPageOptions={[5, 10, 25, 50]}
            count={requestTypes.length}
            onChange={handleChange}
            component="div"
            labelRowsPerPage="Righe per pagina"
            page={page}
            onPageChange={handlePageChange}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
      </CardContent>
    </Card>
  );
};

export default RequestTypesManager;
