import React from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import { getProvincesForCountry, getProvince } from "./Data.js";

export const ProvincesSelect = (props) => {
  const handleChange = (event, value) => {
    handleUpdateProvince(value && value.state_code ? value.state_code : "");
  };

  const handleBlur = () => {
    if (props.handleBlur) {
      props.handleBlur();
    }
  };
  const handleUpdateProvince = (newcod) => {
    if (props.handleChange) {
      props.handleChange(newcod);
    }
  };
  const getPatientProvince = () => {
    let _currentProvince = props.handleProvince();
    return _currentProvince;
  };
  const getPatientCountry = () => {
    let _currentCountry = props.handleCountry();
    return _currentCountry;
  };

  const getProvinces = () => {
    let currentCountry = getPatientCountry();
    return getProvincesForCountry(currentCountry);
  };

  const getProvinceOption = () => {
    let _countryCode = getPatientCountry();
    let _provinceCode = getPatientProvince();
    let _provinceOption = getProvince(_countryCode, _provinceCode);
    return _provinceOption;
  };

  const getOptions = () => {
    let _options = getProvinces();
    return !_options ? [{ name: "...", state_code: "...", id: 0 }] : _options;
  };

  const getValue = () => {
    let _value = getProvinceOption();
    return !_value ? { name: "...", state_code: "...", id: 0 } : _value;
  };

  return (
    <Autocomplete
      id="regionSelect"
      options={getOptions()}
      autoHighlight
      getOptionLabel={(option) => option["name"] || ""}
      value={getValue()}
      defaultValue={{ name: "...", state_code: "...", id: 0 }}
      renderOption={(props, option) => (
        <Box
          component="li"
          sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
          {...props}
          key={option["state_code"]}
        >
          {option["name"]}
        </Box>
      )}
      renderInput={(params) => (
        <TextField
          fullWidth
          {...params}
          label="Provincia / Stato"
          inputProps={{
            ...params.inputProps,
            autoComplete: "new-password", // disable autocomplete and autofill
          }}
          variant="filled"
          size="small"
        />
      )}
      onChange={handleChange}
      onBlur={handleBlur}
      variant="filled"
      size="small"
      data-testid={"province"}
    />
  );
};
export default ProvincesSelect;
