import React, { useState, useContext, useEffect } from "react";
import {
  Route,
  Router,
  Link,
  matchPath,
  useLocation,
  useHistory,
} from "react-router-dom";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import Divider from "@mui/material/Divider";
import Subscription from "./Subscription";
import InvoiceList from "./InvoiceList";
import UserContext from "../UserContext";
import AuthorizeService from "../api-authorization/AuthorizeService";
import Profile from "./Profile";
import DoctorProfile from "./DoctorProfile";
import UsersManager from "./UsersManager";
import CardHeader from "@mui/material/CardHeader";
import FeedbackAlert from "../Shared/FeedbackAlert";
import Box from "@mui/material/Box";
import CardContent from "@mui/material/CardContent";

export const AccountSettings = (props) => {
    const history = useHistory();
    const [value, setValue] = useState(history.location.pathname);
    const userCtx = useContext(UserContext);
    const [feedback, setFeedback] = useState({
        severity: "success",
        text: "Success",
        open: false,
    });

    //cleanup state on unmount
    useEffect(() => {
        return () => {
            setFeedback({
                severity: "success",
                text: "Success",
                open: false,
            });
        };
    }, []);

    //enable tab change
    useEffect(() => {
        setValue(history.location.pathname);
    }, [history.location.pathname]);

    const alertClosed = () => {
        setFeedback({ ...feedback, open: false });
    };

    const [user, setUser] = useState(null);
    const [isAdmin, setIsAdmin] = useState(false);
    const [isDoctor, setIsDoctor] = useState(false);

    const feedbackCallback = (severity, text, open) => {
        setFeedback({ severity: severity, text: text, open: open });
    };

    useEffect(() => {
        if (userCtx.roles && Array.from(userCtx.roles).includes("Administrator")) {
            setIsAdmin(true);
        } else {
            setIsAdmin(false);
        }
        if (userCtx.roles && Array.from(userCtx.roles).includes("Doctor")) {
            setIsDoctor(true);
        } else {
            setIsDoctor(false);
        }
    }, [userCtx.roles]);


    useEffect(() => {
        AuthorizeService.getUser()
            .then((user) => {
                setUser(user);
            })
            .catch((error) => {
                console.log(error);
            });
    }, []);

    return (
        <Box p={2}>
            <Card>
                <CardHeader title="Impostazioni Account" />
                <CardContent
                    sx={{
                        flexGrow: 2,
                        display: "flex",
                        height: "90%",
                    }}
                >
                    <Router history={history}>
                        <Grid container spacing={1}>
                            <Grid item xs={2}>
                                <Tabs
                                    value={value}
                                    aria-label="account settings tabs"
                                    textColor="inherit"
                                    indicatorColor="primary"
                                    orientation="vertical"
                                >
                                    <Tab
                                        label="Profilo utente"
                                        id="profile"
                                        value="/Account/settings/profile"
                                        to="/Account/settings/profile"
                                        component={Link}
                                    />
                                    <Tab
                                        label="Profilo medico"
                                        disabled={!isDoctor}
                                        id="doctor"
                                        value="/Account/settings/doctor"
                                        to="/Account/settings/doctor"
                                        component={Link}
                                    />
                                    <Tab label="" icon={<Divider className="w-100" />} disabled />
                                    <Tab
                                        label="Sottoscrizioni"
                                        disabled={!isAdmin}
                                        id="subscription"
                                        value="/Account/settings/subscription"
                                        to="/Account/settings/subscription"
                                        component={Link}
                                    />
                                    <Tab
                                        label="Fatturazione"
                                        disabled={!isAdmin}
                                        id="billing"
                                        value="/Account/settings/billing"
                                        to="/Account/settings/billing"
                                        component={Link}
                                    />
                                    <Tab
                                        label="Utenti"
                                        disabled={!isAdmin}
                                        id="users"
                                        value="/Account/settings/users"
                                        to="/Account/settings/users"
                                        component={Link}
                                    />
                                </Tabs>
                            </Grid>
                            <Grid item xs={10}>
                                <Route path="/Account/settings/profile">
                                    {<Profile user={user} feedbackCallback={feedbackCallback} />}
                                </Route>
                                <Route path="/Account/settings/doctor">
                                    {
                                        <DoctorProfile
                                            user={user}
                                            feedbackCallback={feedbackCallback}
                                        />
                                    }
                                </Route>
                                <Route path="/Account/settings/subscription">
                                    {isAdmin && (
                                        <Subscription feedbackCallback={feedbackCallback} />
                                    )}
                                </Route>
                                <Route path="/Account/settings/billing">
                                    <InvoiceList user={user} />
                                </Route>
                                <Route path="/Account/settings/users">
                                    <UsersManager
                                        user={user}
                                        feedbackCallback={feedbackCallback}
                                    />
                                </Route>
                                <FeedbackAlert
                                    severity={feedback.severity}
                                    text={feedback.text}
                                    open={feedback.open}
                                    closedCallback={alertClosed}
                                />
                            </Grid>
                        </Grid>
                    </Router>
                </CardContent>
            </Card>
        </Box>
    );
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

export default AccountSettings;
