import React, { useEffect, useState } from "react";
import List from "@mui/material/List";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import TextField from "@mui/material/TextField";
import authService from "../api-authorization/AuthorizeService";
import TablePagination from "@mui/material/TablePagination";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import { ClickAwayListener, IconButton } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import ClearIcon from "@mui/icons-material/Clear";
import AddIcon from "@mui/icons-material/Add";
import Box from "@mui/material/Box";
import ConfirmDialog from "../Shared/ConfirmDialog";

export const ToolsList = (props) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [tools, setTools] = useState(props.tools);
  const [selected, setSelected] = useState(null);
  const [newTool, setNewTool] = useState({
    name: "",
    description: "",
    quantity: 0,
    minQuantity: 0,
  });
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [toolToDelete, setToolToDelete] = useState(null);

  const columns = [
    { id: "name", label: "Nome", numeric: false },
    { id: "description", label: "Descrizione", numeric: false },
    { id: "quantity", label: "Quantità", numeric: false },
    { id: "minQuantity", label: "Quantità di soglia", numeric: false },
    { id: "actions", label: "Azioni", numeric: false },
  ];

  const handleChangeNewTool = (event) => {
    const { id, value } = event.target;
    setNewTool({ ...newTool, [id]: value });
  };

  const handleChange = (changedTool, event) => {
    const { id, value } = event.target;
    let parsedValue = value;
    //if id is quantity or minQuantity, parse to int
    if (id === "quantity" || id === "minQuantity") {
      parsedValue = parseInt(value);
      if (isNaN(parsedValue) || parsedValue < 0 || parsedValue === "") {
        parsedValue = 0;
      }
    }
    setTools(
      tools.map((currentTool) =>
        currentTool.idTool === changedTool.idTool
          ? { ...currentTool, [id]: parsedValue }
          : currentTool
      )
    );
  };

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    setTools(props.tools);
  }, [props.tools]);

  //cleanup state on unmount
  useEffect(() => {
    return () => {
      setTools([]);
      setPage(1);
    };
  }, []);

  const handleBlurNewTool = async (event) => {
    if (
      newTool.name === "" ||
      newTool.name === undefined ||
      newTool.name === null
    ) {
    } else {
      handleBlur(newTool, event);
      setNewTool({ name: "", description: "", quantity: 0, minQuantity: 0 });
    }
  };

  const handleBlur = async (row, event) => {
    if (row.name === "" || row.name === undefined || row.name === null) {
      props.feedbackCallback(
        "warning",
        "Inserire un nome identificativo per procedere al salvataggio",
        true
      );
      return;
    }
    let token = await authService.getAccessTokenIfValid();
    let path = "/api/tool/update";
    if (row.idTool === undefined || row.idTool === "") {
      path = "/api/tool/create";
    }
    const requestOptions = {
      method: "POST",
      headers: !token
        ? { "Content-Type": "application/json" }
        : {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            Accept: "application/json",
          },
      body: JSON.stringify(row),
    };
    fetch(path, requestOptions).then(async (response) => {
      const data = await response.json();
      // check for error response
      if (!response.ok) {
        // get error message from body or default to response statusText
        const error = (data && data.message) || response.statusText;
        props.feedbackCallback("error", "Errore nel salvataggio", true);
        return Promise.reject(error);
      } else {
        if (data) {
          if (row.idTool === undefined || row.idTool === "") {
            setTools([...tools, data]);
            setSelected(data.idTool);
            //refresh to get the new id
            props.refreshData();
          } else {
            let index = tools.findIndex((x) => x.idTool === row.idTool);
            tools[index] = data;
            setTools([...tools]);
          }
          props.feedbackCallback("success", "Dati salvati con successo", true);
        }
      }
    });
  };

  useEffect(() => {
    //set page to where the selected tool is
    if (selected) {
      let index = tools.findIndex((x) => x.idTool === selected);
      if (index > -1) {
        let page = Math.floor(index / rowsPerPage);
        setPage(page);
      }
    }
  }, [selected, tools, rowsPerPage]);


  const handleDeleteConfirmation = (confirmation) => {
    if (confirmation) {
      deleteTool(toolToDelete);
  }
  setConfirmDialogOpen(false);
  };

  const handleDelete = async (toolToDelete) => {
    if (toolToDelete && toolToDelete.idTool) {
      setToolToDelete(toolToDelete);
      setConfirmDialogOpen(true);
    }
};

  const deleteTool = async (toolToDelete) => {
    if (toolToDelete && toolToDelete.idTool) {
      let token = await authService.getAccessTokenIfValid();
      fetch("/api/tool/delete", {
        method: "POST",
        headers: !token
          ? { "Content-Type": "application/json" }
          : {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
              Accept: "application/json",
            },
        body: JSON.stringify(toolToDelete),
      })
        .then((response) => {
          if (!response.ok) {
            // get error message from body or default to response statusText
            const error = response.statusText;
            props.feedbackCallback(
              "error",
              "Impossibile eliminare lo strumento",
              true
            );
            return Promise.reject(error);
          }
          props.refreshData();
        })
        .catch((error) => {
          console.error("There was an error!", error);
        });
    }
  };

  const renderTable = () => {
    let toolsToShow = [];
    if (tools.length > rowsPerPage) {
      //select subscriptions to show
      const firstPageIndex = page * rowsPerPage;
      const lastPageIndex = firstPageIndex + rowsPerPage;
      toolsToShow = tools.slice(firstPageIndex, lastPageIndex);
    } else {
      toolsToShow = [...tools];
    }
    return (
      <TableContainer>
        <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
          <TableHead>
            <TableRow key="head">
              {columns.map((headCell) => (
                <TableCell
                  key={headCell.id}
                  align="center"
                  padding={headCell.disablePadding ? "none" : "normal"}
                >
                  {headCell.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>

          <TableBody>
            {toolsToShow.map((row) =>
              selected !== row.idTool ? (
                <TableRow
                  hover
                  key={row.idTool}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  selected={selected === row.idTool}
                  onClick={() => setSelected(row.idTool)}
                >
                  <TableCell align="center">{row.name}</TableCell>
                  <TableCell align="center">{row.description}</TableCell>
                  <TableCell align="center">
                    <Typography
                      color={
                        row.quantity < row.minQuantity ? "red" : "text.primary"
                      }
                    >
                      {row.quantity}
                    </Typography>
                  </TableCell>
                  <TableCell align="center">{row.minQuantity}</TableCell>
                  <TableCell align="center">
                    <Tooltip title="Modifica">
                      <IconButton>
                        <EditIcon />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Elimina">
                      <IconButton
                        onClick={(e) => handleDelete(row, e)}
                        data-testid={row.idTool + "-deleteButton"}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              ) : (
                <TableRow
                  key={row.idTool}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell align="center">
                    <TextField
                      data-testid={
                        row.idTool ? row.idTool + "-nameInput" : "newNameInput"
                      }
                      variant="filled"
                      hiddenLabel
                      fullWidth
                      margin="dense"
                      InputProps={{
                        disableUnderline: true,
                        style: { fontSize: 13 },
                      }}
                      id="name"
                      value={row.name}
                      onChange={(e) => handleChange(row, e)}
                      onBlur={(e) => handleBlur(row, e)}
                      size="small"
                    ></TextField>
                  </TableCell>
                  <TableCell align="center">
                    <TextField
                      data-testid={
                        row.idTool
                          ? row.idTool + "-descriptionInput"
                          : "newDescriptionInput"
                      }
                      variant="filled"
                      hiddenLabel
                      fullWidth
                      margin="dense"
                      InputProps={{
                        disableUnderline: true,
                        style: { fontSize: 13 },
                      }}
                      id="description"
                      value={row.description}
                      onChange={(e) => handleChange(row, e)}
                      onBlur={(e) => handleBlur(row, e)}
                      size="small"
                    ></TextField>
                  </TableCell>
                  <TableCell align="center">
                    <TextField
                      data-testid={
                        row.idTool
                          ? row.idTool + "-quantityInput"
                          : "newQuantityInput"
                      }
                      variant="filled"
                      type="number"
                      hiddenLabel
                      margin="dense"
                      InputProps={{
                        disableUnderline: true,
                        style: { fontSize: 13, width: 100 },
                      }}
                      id="quantity"
                      value={row.quantity}
                      onChange={(e) => handleChange(row, e)}
                      onBlur={(e) => handleBlur(row, e)}
                      size="small"
                    ></TextField>
                  </TableCell>
                  <TableCell align="center">
                    <TextField
                      data-testid={
                        row.idTool
                          ? row.idTool + "-minQuantityInput"
                          : "newMinQuantityInput"
                      }
                      variant="filled"
                      type="number"
                      hiddenLabel
                      margin="dense"
                      InputProps={{
                        disableUnderline: true,
                        style: { fontSize: 13, width: 100 },
                      }}
                      id="minQuantity"
                      value={row.minQuantity}
                      onChange={(e) => handleChange(row, e)}
                      onBlur={(e) => handleBlur(row, e)}
                      size="small"
                    ></TextField>
                  </TableCell>
                  <TableCell align="center">
                  <Tooltip title="Esci da modifica">
                    <IconButton
                      onClick={() => setSelected(null)}
                      data-testid={row.idTool + "-clearButton"}
                    >
                      <ClearIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Elimina">
                    <IconButton
                      onClick={(e) => handleDelete(row, e)}
                      data-testid={row.idTool + "-deleteButton"}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Tooltip>
                  </TableCell>
                </TableRow>
              )
            )}
          </TableBody>
        </Table>
      </TableContainer>
    );
  };

  return (
    <>
    <Card
      sx={{
        boxShadow: 0,
      }}
    >
      <CardContent>
        <Typography
          gutterBottom
          variant="subtitle3"
          component="div"
          align="left"
          sx={{ paddingBottom: "10px" }}
        >
          Strumenti
            <Box sx={{ float: "right" }}>
                <TextField
                  data-testid="newToolInput"
                  variant="filled"
                  hiddenLabel
                  placeholder="Aggiungi strumento"
                  size="small"
                  margin="dense"
                  InputProps={{
                    disableUnderline: true,
                    style: { fontSize: 11, paddingBottom: "0px" },
                  }}
                  id="name"
                  value={newTool.name}
                  onChange={handleChangeNewTool}
                />
              <IconButton onClick={handleBlurNewTool} color="inherit">
                <AddIcon />
              </IconButton>
            </Box>
        </Typography>
        <List
          sx={{
            width: "100%",
            position: "relative",
            overflow: "auto",
            "& ul": { padding: 0 },
          }}
        >
          {renderTable()}
        </List>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25, 50]}  
          component="div"
          count={tools.length}
          labelRowsPerPage="Righe per pagina"
          page={page}
          onPageChange={handlePageChange}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </CardContent>
    </Card>
    <ConfirmDialog open={confirmDialogOpen}
        answerCallback={handleDeleteConfirmation}
        title="Attenzione - richiesta eliminazione!"
        text={`Si è richiesta l'eliminazione dello strumento: ${toolToDelete && toolToDelete.name}. Procedere con l'operazione?`}
        agreeText="Elimina"
        disagreeText="Annulla"/>
    </>
  );
};

export default ToolsList;
