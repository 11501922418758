import React, { useState, useEffect } from "react";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import FormControl from "@mui/material/FormControl";

import { Loading } from "../Shared/Loading.js";
import { MuiOtpInput } from 'mui-one-time-password-input'

export const Validate2FA = (props) => {
    const [otp, setOtp] = useState("");
    const [otpSent, setOtpSent] = useState(false)
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [loading, setLoading] = useState(false);
    const TOKEN_LENGTH = 6;

    useEffect(() => {
        return () => {
          
            setLoading(false);
        }
    }, []);


    useEffect(() => {
        if (props.request2FA) {
            OtpRequest(props.email);
        }
    }, [props.request2FA]);


    const handleOtpChange = (value) => {

        if (value || value === '') {
            setOtp(value);
            if (value.length == TOKEN_LENGTH) {
                ValidateOtp(value); 
            }
        }
    };

    const newOtpRequest = async (event) => {
        event.preventDefault();

        OtpRequest(props.email);
    };

    const OtpRequest = async (email) => {
        setLoading(true);
        setError(false);
        setErrorMessage('');
       
        let path = "/api/account/otp/new/"+email;
        const requestOptions = {
            method: "GET",
            headers: { "Content-Type": "application/json" },
        };
        fetch(path, requestOptions).then(async (response) => {
            setLoading(false);
           
            if (!response.ok) {
                const errorMes = await response.text();
                setErrorMessage(errorMes);
                setError(true);
                
            } else {
                setOtpSent(true);
            }
        });
        props.handle2FARequest(true);
    };
    const ValidateOtp = async (token) => {
        setLoading(true);
        
        let path = "/api/account/otp/validate/" + props.email + "/" + token + "/" + props.remember;
        const requestOptions = {
            method: "GET",
            headers: { "Content-Type": "application/json" },
        };
        fetch(path, requestOptions).then(async (response) => {
            setLoading(false);

            // check for error response
            if (!response.ok) {
                // get error message from body or default to response statusText
                const errorMes = await response.text();
                setOtp('');
                setErrorMessage(errorMes);
                setError(true);
            } else {
                if (response.redirected) {
                    window.location.href = response.url;
                }
                else {
                    window.location.href = "~/";
                }
            }
        });
    };


    const validateChar = (value, index) => {
        return !isNaN(value)
    }

    return (
        <Grid key="name" item xs={12}>
                               
            <FormControl
                variant="filled"
                fullWidth
                sx={{
                    mb: 2,
                }}
            >

        <MuiOtpInput value={otp} onChange={handleOtpChange} length={6} validateChar={validateChar} TextFieldsProps={{  gap: '2px', placeholder: '-' }} />
            </FormControl>
            {error && <Typography color="red">{errorMessage}</Typography>}
            {otpSent && <Typography>Codice di accesso temporaneo inviato</Typography>}
               
        <div className="w-100 d-flex justify-content-center pb-3">
            {loading ? (
                <Loading />
            ) : (
                    <Button
                        onClick={newOtpRequest}
                        sx={{
                            m: 1,
                        }}
                        type="submit"
                        data-testid="newOtpButton"
                        color="primary"
                        variant="contained"
                        disabled={loading}
                >
                    Ricevi Nuovo Otp
                </Button>
            )}
                   
        </div>
     </Grid>
    );
};

export default Validate2FA;
