import React, { useEffect, useState } from "react";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { useHistory } from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import DeleteIcon from '@mui/icons-material/Delete';
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import InputLabel from "@mui/material/InputLabel";
import authService from "../../api-authorization/AuthorizeService";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";

import { emptyGuid } from "../../Shared/Constants";
import Loading from "../../Shared/Loading";
import { Button } from "@mui/material";

export const GeneralInfoCard = (props) => {
    const history = useHistory();

    const quickOptions = ["Prima visita", "Trattamento / Intervento", "Controllo"];

    const [idVisitInternal, setIdVisitInternal] = useState(props.idVisit);
    const [visit, setVisit] = useState({
        idVisit: props.idVisit,
        idRequest: props.idRequest,
        description: "",
        date: new Date(),
        patientFullName: "",
        requestDescription: "",
    });
    const [loading, setLoading] = useState(true);

    const handleBackToVisitList = () => {
        setVisit(emptyGuid);
        props.setVisit(null);
    };

    const handleDatePickerChange = (newValue) => {
        setVisit({ ...visit, date: newValue });
    };

    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

    useEffect(() => {
        const populateVisit = async () => {
            const token = await authService.getAccessTokenIfValid();
            if (props.idVisit && emptyGuid !== props.idVisit) {
                fetch(`/api/visit/${props.idRequest}/${props.idVisit}`, {
                    headers: !token
                        ? {}
                        : {
                            Authorization: `Bearer ${token}`,
                        },
                }).then(async (response) => {
                    const data = await response.json();
                    // check for error response
                    if (!response.ok) {
                        // get error message from body or default to response statusText
                        const error = (data && data.message) || response.statusText;
                        return Promise.reject(error);
                    } else {
                        if (data) {
                            setVisit(data);
                            setIdVisitInternal(data.idVisit);
                            props.setVisit(data);
                        }
                    }
                });
            }
            setLoading(false);
        };
        populateVisit();
    }, []);


    const saveVisit = async () => {

        let path = "/api/visit/new/" + props.idRequest;
        if (idVisitInternal !== emptyGuid) {
            //path to request update of patient details
            path = "/api/visit/";
        }
        const token = await authService.getAccessTokenIfValid();
        const requestOptions = {
            method: "POST",
            headers: !token
                ? {}
                : {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "application/json",
                },
            body: JSON.stringify(visit),
        };
        fetch(path, requestOptions).then(async (response) => {
            const data = await response.json();
            // check for error response
            if (!response.ok) {
                // get error message from body or default to response statusText
                const error = (data && data.message) || response.statusText;
                return Promise.reject(error);
            } else {
                if (data) {
                    props.feedbackCallback(
                        "success",
                        "Dati salvati con successo!",
                        true
                    );
                    setVisit(data);
                    setIdVisitInternal(data.idVisit);
                    props.setVisit(data);
                }
            }
        });
    };

    const handleBlur = () => {
        if (idVisitInternal !== emptyGuid) {
            saveVisit();
        }
    };

    const handleCloseDeleteDialog = () => {
        setOpenDeleteDialog(false);

    };
    const handleOpenDeleteDialog = () => {
        setOpenDeleteDialog(true);
    }
    const deleteVisit = async () => {

        let path = "/api/visit/delete/" + props.idVisit;

        const token = await authService.getAccessTokenIfValid();
        const requestOptions = {
            method: "POST",
            headers: !token
                ? {}
                : {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "application/json",
                },
        };
        let response = await  fetch(path, requestOptions);
        const data = await response.json();
        // check for error response
        if (!response.ok) {
            // get error message from body or default to response statusText
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        } else {
            return true;
        }
    }

    const handleDeleteVisit = async () => {
        setOpenDeleteDialog(false);
        if (await deleteVisit()) {
            props.feedbackCallback("success", "Visita eliminata con successo!", true);
            handleBackToVisitList();
        }
        else {
            props.feedbackCallback("error", "Visita non eliminata!", false);
        }
    }

    const renderDescription = () => {
        if (loading) {
            return <Loading />;
        } else
            return (
                <>
                    <InputLabel>Motivo della visita</InputLabel>
                    <Autocomplete
                        size="small"
                        freeSolo
                        autoComplete
                        clearOnEscape
                        selectOnFocus
                        handleHomeEndKeys
                        disableClearable
                        fullWidth
                        value={visit.description || ""}
                        onChange={(event, newValue) => {
                            setVisit({ ...visit, description: newValue });
                        }}
                        inputValue={visit.description || ""}
                        onInputChange={(event, newInputValue) => {
                            setVisit({ ...visit, description: newInputValue });
                        }}
                        id="visit-description"
                        onBlur={handleBlur}
                        options={quickOptions}
                        renderInput={(params) => <TextField {...params}
                            fullWidth variant="filled" hiddenLabel data-testid="Visit-Description" />}
                    />
                </>
            );
    };

    const renderVisitDate = () => {
        if (loading) {
            return <Loading />;
        } else
            return (
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <InputLabel>Data Visita</InputLabel>
                    <DesktopDatePicker
                        size="small"
                        hiddenLabel
                        InputProps={{ hiddenLabel: true }}
                        inputFormat="dd/MM/yyyy"
                        value={visit.date}
                        onAccept={handleBlur}
                        onChange={handleDatePickerChange}
                        renderInput={(params) => (
                            <TextField
                                size="small"
                                hiddenLabel
                                variant="filled"
                                id="visit-date"
                                data-testid="visit-date"
                                {...params}
                            />
                        )}
                    />
                </LocalizationProvider>
            );
    };


    return (
        <Grid container spacing={1}>
            <Grid item md={8} xs={6}>
                {renderDescription()}
            </Grid>
            <Grid item md={2} xs={5}>
                {renderVisitDate()}

            </Grid>
            <Grid item md={1} xs={5}>
                {idVisitInternal === emptyGuid &&
                    <Button data-testid="Create-Visit" variant="contained" onClick={saveVisit} className="mt-4">
                        Crea Visita
                    </Button>
                }
            </Grid>
            <Grid item xs={1}>

                <div className="d-flex justify-content-end align-items-center">

                    <IconButton onClick={handleBackToVisitList} color="inherit" title="indietro">
                        <ArrowBackIcon />
                    </IconButton>
                    {idVisitInternal !== emptyGuid &&
                        <IconButton onClick={handleOpenDeleteDialog} color="inherit" title="elimina"  >
                            <DeleteIcon />
                        </IconButton>
                    }
                    <Dialog
                        open={openDeleteDialog}
                        onClose={handleCloseDeleteDialog}
                        fullWidth={false}
                        maxWidth="640"
                    >
                        <DialogTitle>Eliminare la visita?</DialogTitle>
                        <DialogActions>
                            <Grid container spacing={2} justify="center">
                                <Grid item>
                                    <Button onClick={handleDeleteVisit} color="error" variant="contained" className="mt-4" sx={{ width: 100, padding: 1, margin: 2 }}>
                                        Elimina
                                    </Button>
                                </Grid>
                                <Grid item>
                                    <Button onClick={handleCloseDeleteDialog} data-testid="closeImageZoomModal" variant="contained" className="mt-4" sx={{ width: 100, padding: 1, margin: 2 }} >
                                        Chiudi
                                    </Button>
                                </Grid>
                            </Grid>
                        </DialogActions>
                    </Dialog>
                </div>
            </Grid>
        </Grid>

    );
};
export default GeneralInfoCard;
