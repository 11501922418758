import { useState, useEffect, useContext } from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import TextField from "@mui/material/TextField";
import authService from "../api-authorization/AuthorizeService";
import Button from "@mui/material/Button";
import { Stack, Grid } from "@mui/material";
import { IsValidPhoneNumber } from "../Shared/Utilities";
import PasswordUpdate from "./PasswordUpdate";
import UserContext from "../UserContext";
import Typography from '@mui/material/Typography';

export const Profile = (props) => {
  const { roles } = useContext(UserContext);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [numberError, setNumberError] = useState("");

  //cleanup state on unmount
  useEffect(() => {
    return () => {
      setNumberError("");
      setPhoneNumber("");
    }
  } , []);


  useEffect(() => {
    const getPhoneNumber = async () => {
    let token = await authService.getAccessTokenIfValid();
    fetch("/api/user/phone", {
      headers: !token ? {} : { Authorization: "Bearer " + token },
    }).then(async (response) => {
      if (response.ok) {
        const data = await response.json();
        setPhoneNumber(data);
      }
    });
  }
  getPhoneNumber();
  }, []);

  const handleChange = (event) => {
    setPhoneNumber(event.target.value);
  };

  const handleSave = async () => {
    //validtion of input
    if (!IsValidPhoneNumber(phoneNumber)) {
      setNumberError("Numero di telefono non valido");
      return;
    }
    let token = await authService.getAccessTokenIfValid();
    const path = "/api/user/phone";
    const requestOptions = {
      method: "POST",
      headers: !token
        ? { "Content-Type": "application/json" }
        : {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            Accept: "application/json",
          },
      body: JSON.stringify(phoneNumber),
    };
    fetch(path, requestOptions).then(async (response) => {
      const data = await response.json();
      // check for error response
      if (!response.ok) {
        // get error message from body or default to response statusText
        const error = (data && data.message) || response.statusText;
        props.feedbackCallback("error", "Errore nel salvataggio", true);
        return Promise.reject(error);
      } else {
        if (data) {
          props.feedbackCallback(
            "success",
            "Numero salvato correttamente",
            true
          );
        }
      }
    });
  };

  return (
    <Grid container spacing={2} justifyContent="center">
      <Grid item xs={12} lg={4}>
        <Card
          sx={{ 
            boxShadow: 0 
          }}
        >
          <CardContent>
            <Typography gutterBottom variant="subtitle3" component="div">
              Profilo
            </Typography>            
            <Stack spacing={2}>
              <TextField
                variant="filled"
                label="Utente"
                value={props.user ? props.user.name : ""}
                disabled
              />
              <TextField
                variant="filled"
                label="Telefono"
                value={phoneNumber}
                onChange={handleChange}
                error={numberError !== ""}
                helperText={numberError}
              />
              <Button sx={{ pt: 11 }} onClick={handleSave}>
                Aggiorna telefono
              </Button>
            </Stack>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12} lg={4}>
        <PasswordUpdate
          user={props.user}
          feedbackCallback={props.feedbackCallback}
        />
      </Grid>
      <Grid item xs={12} lg={4} sx={{ height: '100%' }}>
        <Card
            sx={{ 
              boxShadow: 0 
            }}
          >
          <CardContent>
            <Typography gutterBottom variant="subtitle3" component="div">
              Ruoli
            </Typography>            
            Ruoli assegnati all'utente:
            <ul>
              {roles.map((role) => (
                <li key={role}>{role}</li>
              ))}
            </ul>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

export default Profile;
