import React, { useState, useEffect } from "react";
import Avatar from "@mui/material/Avatar";
import FolderIcon from "@mui/icons-material/Folder";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Divider from "@mui/material/Divider";
import { emptyGuid } from "../Shared/Constants";
import MedicalServicesIcon from '@mui/icons-material/MedicalServices';
import { Button } from "@mui/material";

export const VisitsList = (props) => {
  const [visits, setVisits] = useState(props.visits);
  const [idRequest, setIdRequest] = useState(
    props.request ? props.request.idRequest : null
  );

  useEffect(() => {
    setVisits(props.visits);
  }, [props.visits]);

  const GoToVisit = (event) => {
    if (props.request) {
      if (event.currentTarget) {
        if(event.currentTarget.id === "add-new") return;
        props.setIdVisit(event.currentTarget.id);
      } else {
        console.log("Visit not available yet.");
      }
    }
  };

  const GoToNewVisit = () => {
    if (props.request) {
      props.setIdVisit(emptyGuid);
    }
  };

  const renderAddButton = () => {
    if (props.request) {
      return (
              <div className="w-100 d-flex justify-content-end pt-3 pe-1">
                <Button data-testid="Add-Visit" onClick={GoToNewVisit} variant="contained" >Nuova visita</Button>
              </div>
      );
    } else {
      return (
        <>
        <div className="valign">
          <div className="align-middle d-table-cell">
          <div className="d-table mx-auto">      
            <div className="w-100 d-flex justify-content-center">
              <MedicalServicesIcon className="empty-placeholder-icon" fontSize="large"/>
            </div>
            <p className="text-center text-disabled pt-3">Selezionare una richiesta per aprire o aggiungere una visita.</p>
            </div>
          </div>
        </div>  
        </>
      );
    }
  };

  const renderTable = () => {
    if (props.request && props.visits) {
      let itemList = props.visits.map((visit) => {
        return (
          <React.Fragment key={visit.idVisit}>
            <ListItemButton
              id={visit.idVisit}
              onClick={GoToVisit}
            >
              <ListItemAvatar>
                <Avatar>
                  <FolderIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={visit.description.substring(0, 20)}
                secondary={new Date(visit.date).toLocaleDateString("it-IT")}
              />
            </ListItemButton>
            <Divider />
          </React.Fragment>
        );
      });
      return itemList;
    } else {
      return <></>;
    }
  };

  return (
    <>
    
      {renderAddButton()}
      <List
        sx={{
          width: "90%",
          position: "relative",
          overflow: "auto",
          "& ul": { padding: 0 },
        }}
        className="mt-minus-65"
        dense
      >
        {renderTable()}
      </List>
    </>
  );
};

export default VisitsList;
