import React, { useState, useEffect, useContext, useRef } from "react";
import { useHistory } from "react-router-dom";
import TextField from "@mui/material/TextField";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import CardActions from "@mui/material/CardActions";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import isEmail from "validator/lib/isEmail";
import { IsValidPassword } from "../Shared/Utilities.js";
import FilledInput from "@mui/material/FilledInput";
import InputAdornment from "@mui/material/InputAdornment";
import { VisibilityOff } from "@mui/icons-material";
import { Visibility } from "@mui/icons-material";
import IconButton from "@mui/material/IconButton";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Link from "@mui/material/Link";
import { Loading } from "../Shared/Loading.js";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import HtmlViewer from "../Shared/HtmlViewer.js";
import logo from "../../logo.png";
import authService, {
  AuthorizeService,
} from "../api-authorization/AuthorizeService";
import { useTheme } from "@mui/material/styles";
import { ThemeContext } from "../../theme";
import { Validate2FA } from "./Validate2FA.js";

export const Login = () => {
  const theme = useTheme();
  const history = useHistory();
  const [newUser, setNewUser] = useState({
    email: "",
    password: "",
    rememberMe: false,
    termconditionText: "",
    privacyText: "",
  });

  const [light, setLight] = useContext(ThemeContext);
  const [isValidLogin, setIsValidLogin] = useState(true);
  const [errorMessageLogin, setErrorMessageLogin] = useState("");
  const [isValidPW, setIsValidPW] = useState(true);

  const [isEmptyPassword, setIsEmptyPassword] = useState(true);
  const [isEmptyConfirmPassword, setIsEmptyConfirmPassword] = useState(true);
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);

  const [requiredPrivacy, setRequiredPrivacy] = useState(false);
  const [privacyPolicyAccepted, setPrivacyPolicyAccepted] = useState(false);
  const [privacyPolicyViewed, setPrivacyPolicyViewed] = useState(false);
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [termsViewed, setTermsViewed] = useState(false);
  const [openTerms, setOpenTerms] = useState(false);
  const [openPrivacyPolicy, setOpenPrivacyPolicy] = useState(false);
  const [open2FA, setOpen2FA] = useState(false);

  const [requiredEmailValidation, setRequiredEmailValidation] = useState(false);
  const [newConfirmEmailSent, setNewConfirmEmailSent] = useState(false);
  const [request2FA, setRequest2FA] = useState(false);

  const supportEmail = process.env.REACT_APP_SUPPORT_EMAIL;
  const infoEmail = process.env.REACT_APP_INFO_EMAIL;

  //cleanup state on unmount
  useEffect(() => {
    return () => {
      setNewUser({
        email: "",
        password: "",
        rememberMe: false,
      });
      setIsValidLogin(true);
      setErrorMessageLogin("mail non valida");
      setIsValidPW(true);
      setIsEmptyPassword(true);
      setIsEmptyConfirmPassword(true);
      setShowPassword(false);
      setLoading(false);
    };
  }, []);

  const GoToRegisterPage = () => {
    history.push({
      pathname: "/account/register",
    });
  };

  const GoToForgotPasswordPage = () => {
    history.push({
      pathname: "/account/forgotpassword",
    });
  };

  const handleEmailChange = (event) => {
    const { id, value } = event.target;
    if (id) {
      if (value) {
        if (isEmail(value)) {
          setIsValidLogin(true);
          setErrorMessageLogin("");

          setNewUser({
            ...newUser,
            email: value,
          });
        } else {
          setIsValidLogin(false);
          setErrorMessageLogin("indirizzo mail non valido");
        }
      } else {
        setNewUser({
          ...newUser,
          email: "",
        });
      }
    }
  };

  const handlePasswordChange = (event) => {
    const { id, value } = event.target;
    if (id) {
      if (value) {
        setNewUser({
          ...newUser,
          password: value,
        });
      }
    }
  };

  const handleRememberMeChange = (event) => {
    const { id, checked } = event.target;
    if (id) {
      setNewUser({
        ...newUser,
        rememberMe: checked,
      });
    }
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const LoginSubmit = async (event) => {
    setLoading(true);

    event.preventDefault();
    let path = "/api/account/login";
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(newUser),
    };
    fetch(path, requestOptions).then(async (response) => {
      setLoading(false);

      // check for error response
      if (!response.ok) {
        // get error message from body or default to response statusText
        const errorMes = await response.text();

        if (response.status === 422) {
          setRequiredPrivacy(true);
        } else if (response.status === 409) {
          setRequiredEmailValidation(true);
        } else if (response.status === 423) {
          setOpen2FA(true);
          setRequest2FA(true);
        } else {
          setIsValidLogin(false);
          setErrorMessageLogin(errorMes);
        }
      } else {
        if (response.redirected) {
          window.location.href = response.url;
        } else {
          const data = await response.json();
        }
      }
    });
  };
  const requiredNewConfirmationEmail = async (event) => {
    setLoading(true);
    event.preventDefault();
    let path = "/api/account/confirmation";
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(newUser),
    };
    fetch(path, requestOptions).then(async (response) => {
      setLoading(false);

      // check for error response
      if (!response.ok) {
        // get error message from body or default to response statusText
        const errorMes = await response.text();
        setErrorMessageLogin(errorMes);
      } else {
        setNewConfirmEmailSent(true);
      }
    });
  };

  const handlePrivacyPolicyClosed = () => {
    setOpenPrivacyPolicy(false);
    setPrivacyPolicyViewed(true);
  };

  const handlePrivacyPolicyAccepted = () => {
    setOpenPrivacyPolicy(false);
    setPrivacyPolicyViewed(true);
    setPrivacyPolicyAccepted(true);
  };

  const handleTermsClosed = () => {
    setOpenTerms(false);
    setTermsViewed(true);
  };

  const handleTermsAccepted = () => {
    setOpenTerms(false);
    setTermsViewed(true);
    setTermsAccepted(true);
  };
  const termHtmlCallback = (html) => {
    setNewUser({
      ...newUser,
      termconditionText: html,
    });
  };
  const privacyHtmlCallback = (html) => {
    setNewUser({
      ...newUser,
      privacyText: html,
    });
  };

  const handle2FAClosed = () => {
    setOpen2FA(false);
  };

  const handle2FARequest = () => {
    setRequest2FA(false);
  };

  return (
    <Container>
      <Grid container spacing={2} p={6}>
        <Grid item xs={12} md={8}>
          <section>
            <img src={logo} alt="Mosaycon" />
            <h2>Accedi alla gestione del tuo ambulatorio</h2>
            <hr />
            <p align="justify">
              Mosaycon è un'applicazione per la gestione di ambulatori medici.
              Registrati per scoprire tutte le funzionalità. Potrai attivare
              immediatamente una licenza di prova, senza impegno.
            </p>
            <p>
              Per avere più informazioni ed essere contattato dai nostri
              consulenti puoi scriverci a{" "}
              <a href={`mailto:${infoEmail}`} color="inherit">
                {infoEmail}
              </a>
              .
            </p>
            <p>
              Serve aiuto? Scrivi a{" "}
              <a href={`mailto:${supportEmail}`} color="inherit">
                {supportEmail}
              </a>{" "}
              o utilizza la chat che trovi integrata nella pagina.
            </p>
          </section>
        </Grid>
        <Grid item xs={12} md={4}>
          <Card>
            <CardHeader
              title="Login"
              titleTypographyProps={{
                variant: "h6",
              }}
            />

            <CardContent>
              <Grid key="name" item xs={12}>
                <TextField
                  sx={{
                    mb: 2,
                  }}
                  fullWidth
                  id="email"
                  key="email"
                  name="email"
                  label="E-mail *"
                  variant="filled"
                  size="small"
                  error={isValidLogin === false}
                  helperText={isValidLogin === false ? errorMessageLogin : ""}
                  onChange={handleEmailChange}
                  disabled={open2FA}
                ></TextField>
                <FormControl
                  variant="filled"
                  fullWidth
                  sx={{
                    mb: 2,
                  }}
                >
                  <InputLabel htmlFor="password" error={isValidLogin === false}>
                    Password *
                  </InputLabel>
                  <FilledInput
                    id="password"
                    type={showPassword ? "text" : "password"}
                    key="password"
                    name="password"
                    label="password"
                    variant="filled"
                    size="small"
                    error={isValidLogin === false}
                    helperText={isValidLogin === false ? errorMessageLogin : ""}
                    onChange={handlePasswordChange}
                    disabled={open2FA}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      id="rememberMe"
                      checked={newUser.rememberMe}
                      data-testid="remember-check"
                    />
                  }
                  onChange={handleRememberMeChange}
                  label={<Typography variant="body2">Ricordami</Typography>}
                ></FormControlLabel>
              </Grid>

              {requiredPrivacy && (
                <>
                  <Grid key="terms" item xs={12}>
                    <Typography variant="body2" align="justify" color="red">
                      &Egrave; necessario leggere ed accettere i nuovi termini e
                      condizioni d'uso e l'informativa sulla privacy.
                    </Typography>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={termsAccepted}
                          data-testid="terms-check"
                        />
                      }
                      onChange={() => setTermsAccepted(!termsAccepted)}
                      label={
                        <Typography variant="body2">
                          Accetto i&nbsp;
                          <Link
                            component="button"
                            variant="body2"
                            color="inherit"
                            data-testid="terms-link"
                            onClick={() => {
                              setOpenTerms(true);
                            }}
                          >
                            termini e le condizioni.
                          </Link>
                        </Typography>
                      }
                    ></FormControlLabel>
                  </Grid>
                  <Grid key="privacy" item xs={12} visible={requiredPrivacy}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={privacyPolicyAccepted}
                          data-testid="privacy-check"
                        />
                      }
                      onChange={() =>
                        setPrivacyPolicyAccepted(!privacyPolicyAccepted)
                      }
                      label={
                        <Typography variant="body2">
                          Accetto&nbsp;
                          <Link
                            data-testid="privacy-link"
                            component="button"
                            variant="body2"
                            color="inherit"
                            onClick={() => {
                              setOpenPrivacyPolicy(true);
                            }}
                          >
                            l'informativa sulla Privacy.
                          </Link>
                        </Typography>
                      }
                    />
                  </Grid>

                  <Dialog
                    open={openTerms}
                    onClose={handleTermsClosed}
                    aria-labelledby="form-dialog-title"
                  >
                    <DialogTitle id="form-dialog-title">
                      Termini e condizioni
                    </DialogTitle>
                    <DialogContent>
                      <HtmlViewer
                        fileName="terms.html"
                        htmlCallback={termHtmlCallback}
                      />
                    </DialogContent>
                    <DialogActions>
                      <Button
                        onClick={handleTermsAccepted}
                        color="primary"
                        data-testid="accept-button"
                      >
                        Accetto
                      </Button>
                    </DialogActions>
                  </Dialog>
                  <Dialog
                    open={openPrivacyPolicy}
                    onClose={handlePrivacyPolicyClosed}
                    aria-labelledby="form-dialog-title"
                  >
                    <DialogTitle id="form-dialog-title">
                      Informativa sulla privacy
                    </DialogTitle>
                    <DialogContent>
                      <HtmlViewer
                        fileName="privacy.html"
                        htmlCallback={privacyHtmlCallback}
                      />
                    </DialogContent>
                    <DialogActions>
                      <Button
                        onClick={handlePrivacyPolicyAccepted}
                        color="primary"
                        data-testid="accept-button"
                      >
                        Accetto
                      </Button>
                    </DialogActions>
                  </Dialog>
                </>
              )}

              {requiredEmailValidation && (
                <Grid key="confirm" item xs={12}>
                  {!newConfirmEmailSent ? (
                    <>
                      <Typography>
                        Grazie per esserti registrato. Ti abbiamo inviato
                        un'email di conferma all'indirizzo che hai fornito
                        durante la registrazione. Per accedere al tuo account,
                        devi prima confermare l'indirizzo email. Controlla la
                        tua casella di posta elettronica e segui le istruzioni
                        contenute nell'email di conferma. Se non hai ricevuto
                        l'email di conferma, controlla la cartella spam o prova
                        a richiederne un'altra facendo clic sul pulsante
                        'Richiedi nuova email di conferma' qui sotto."
                      </Typography>
                      <Button
                        sx={{
                          m: 2,
                        }}
                        data-testid="newEmailConfermationButton"
                        onClick={requiredNewConfirmationEmail}
                        color="primary"
                        variant="contained"
                      >
                        Richiedi nuova email di conferma
                      </Button>
                    </>
                  ) : (
                    <Typography>
                      Grazie per aver richiesto una nuova email di conferma. Ti
                      abbiamo appena inviato un'altra email all'indirizzo che
                      hai fornito durante la registrazione.
                    </Typography>
                  )}
                </Grid>
              )}

              <Dialog
                open={open2FA}
                onClose={handle2FAClosed}
                aria-labelledby="form-dialog-title"
              >
                <DialogTitle id="form-dialog-title">Inserisci OTP</DialogTitle>
                <DialogContent>
                  <Validate2FA
                    request2FA={request2FA}
                    handle2FARequest={handle2FARequest}
                    id="validate2fa"
                    email={newUser.email}
                    remember={newUser.rememberMe}
                  />
                </DialogContent>
              </Dialog>
            </CardContent>
            <CardActions>
              {/* <div className="w-100 d-flex justify-content-center pb-3"> */}

              <Grid
                container
                spacing={2}
                className="w-100 d-flex justify-content-center pb-3"
              >
                {loading ? (
                  <Loading />
                ) : (
                  <Grid item xs={12} alignContent="center">
                    <Button
                      fullWidth
                      onClick={LoginSubmit}
                      sx={{
                        m: 1,
                      }}
                      type="submit"
                      data-testid="registerButton"
                      color="primary"
                      variant="contained"
                      disabled={
                        requiredPrivacy &&
                        (!termsViewed ||
                          !privacyPolicyViewed ||
                          !termsAccepted ||
                          !privacyPolicyAccepted)
                      }
                    >
                      Entra
                    </Button>
                  </Grid>
                )}
                <Grid item xs={12}>
                <Typography variant="body2" align="center">
                  <Link
                    data-testid="gotoForgotPasswordLink"
                    href="/account/forgotpassword"
                    component="button"
                    variant="body2"
                    color="inherit"
                    onClick={GoToForgotPasswordPage}
                  >              
                      Ho dimenticato la password
                  </Link>
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="body2" align="center">
                    Nuovo Utente? &nbsp;
                    <Link
                      data-testid="goToRegisterLink"
                      href="/account/register"
                      variant="body2"
                      component="button"
                      color="inherit"
                      onClick={GoToRegisterPage}
                    >
                      Registrati
                    </Link>
                  </Typography>
                </Grid>
              </Grid>
            </CardActions>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Login;
